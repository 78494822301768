// src/components/Students/apps/StudentJournal.js

import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import PuzzleBanner from '../../../components/banners/animatedsvg copy 2.svg'; // Adjust the path if necessary

const StudentJournal = () => {
  // Function to add ordinal suffix to the day
  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // Function to format the current date and time
  const getFormattedDate = () => {
    const now = new Date();
    const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const day = getOrdinal(now.getDate());
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`; // Corrected template literal
    return `${weekday} the ${day} of ${month} ${formattedTime}`; // Corrected template literal
  };

  const navigate = useNavigate(); // Initialize useNavigate hook

  return (
    <div className="flex flex-col min-h-screen bg-gray-100" style={{ overflow: 'hidden' }}>
      
      {/* Header Section */}
      <header className="flex items-center justify-start px-4 py-4 space-x-4 bg-gray-100 shadow-lg">
        {/* Title Section */}
        <div className="flex-1 text-center">
          {/* Title */}
          <p className="text-3xl font-bold">Write</p>
          {/* Current Date and Time */}
          <div className="mt-2 text-xs font-bold text-gray-600">
            {getFormattedDate()}
          </div>
        </div>
      </header>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full border-gray-700 border-t-5"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', // Increased shadow size and darkness
        }}
      >
        {/* Banner image with responsive height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ 
            height: '8vh', // Reduced by 20% from 10vh
            width: '100%', 
            objectFit: 'cover' 
          }}
        />
      </div>

      {/* Main Content Area - Standard Mode */}
      <main className="flex items-center justify-center flex-grow w-full bg-gray-100" style={{ padding: '2rem' }}>
        <div
          className="w-full p-6 bg-white rounded-lg shadow-lg max-w-10xl"
          style={{
            transform: 'scale(1)',
            height: '75vh',
            transition: 'opacity 0.5s ease',
          }}
        >
          <textarea
            className="w-full h-full p-4 text-lg border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Start typing in Standard mode..."
            style={{
              resize: 'none',
              height: '100%',
              fontFamily: 'Arial, sans-serif',
              backgroundColor: 'transparent', // Make background transparent if needed
            }}
          ></textarea>
        </div>
      </main>
    </div>
  );
};

export default StudentJournal;
