import React from 'react';

const Footer = () => {
  return (
    <footer className="px-24 py-4 text-gray-800 bg-[#FCD34D] animate-fadeIn">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {/* Legal Section */}
          <div>
            <h4 className="mb-2 text-lg font-bold">Legal</h4>
            <ul className="space-y-1">
              <li>
                <a
                  href="https://www.termsfeed.com/live/4500990b-9e4b-49b6-894e-83a73efa479f"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block transition-colors hover:text-gray-900"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://www.termsfeed.com/live/4500990b-9e4b-49b6-894e-83a73efa479f"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block transition-colors hover:text-gray-900"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>

          {/* Connect Section */}
          <div>
            <h4 className="mb-2 text-lg font-bold">Connect</h4>
            <ul className="space-y-1">
              <li>
                <a
                  href="https://www.youtube.com/channel/UCbdkR1ck0uHY_APojeBZFcA"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block transition-colors hover:text-gray-900"
                >
                  YouTube
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h4 className="mb-2 text-lg font-bold">Contact</h4>
            <ul className="space-y-1">
              <li>
                <a
                  href="mailto:support@brightbeehives.com"
                  className="transition-colors hover:text-gray-900"
                >
                  support@brightbeehives.com
                </a>
              </li>
              <li>
                <a
                  href="mailto:sales@brightbeehives.com"
                  className="transition-colors hover:text-gray-900"
                >
                  sales@brightbeehives.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
