import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { Text } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';

const Earth = React.forwardRef((props, ref) => {
  const earthGroupRef = useRef();
  const { camera } = useThree();

  useFrame(() => {
    if (earthGroupRef.current) {
      const earthPosition = new THREE.Vector3();
      earthGroupRef.current.getWorldPosition(earthPosition);
      
      const distanceToEarth = camera.position.distanceTo(earthPosition);
      const atmosphereRadius = 6422.8; // km
      
      // Find the sky sphere mesh and update its visibility
      const skySphere = earthGroupRef.current.children.find(
        child => child.geometry instanceof THREE.SphereGeometry && 
        child.geometry.parameters.radius === atmosphereRadius
      );
      
      if (skySphere) {
        // Comment out or remove this log to stop the constant updates
        // console.log('Distance to Earth:', distanceToEarth, 'Atmosphere Radius:', atmosphereRadius);
        
        // Increased multiplier to make atmosphere visible from further away
        skySphere.visible = distanceToEarth <= atmosphereRadius * 2.5;
      }
    }
  });

  // Remove texture loader
  const worldTexture = useMemo(() => {
    return null;
  }, []);

  // Define land grid cells (renamed from australiaCells)
  const landCells = useMemo(() => new Set([
    'a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8', 'a9', 'a10', 'a11', 'a12', 'a13', 'a14', 'a15', 'a16', 'a17', 'a18', 'a19', 'a20', 'a21', 'a22', 'a23', 'a24', 'a25', 'a26', 'a27', 'a28', 'a29', 'a30', 'a31', 'a32', 'a33', 'a34', 'a35', 'a36', 'a37', 'a38', 'a39', 'a40', 'a41', 'a42', 'a43', 'a44', 'a45', 'a46', 'a47', 'a48', 'a49', 'a50', 'a51', 'a52', 'a53', 'a54', 'a55', 'a56', 'a57', 'a58', 'a59', 'a60',
    'b1', 'b2', 'b3', 'b4', 'b5', 'b6', 'b7', 'b8', 'b9', 'b10', 'b11', 'b12', 'b13', 'b14', 'b15', 'b16', 'b17', 'b18', 'b19', 'b20', 'b21', 'b22', 'b23', 'b24', 'b25', 'b26', 'b27', 'b28', 'b29', 'b30', 'b31', 'b32', 'b33', 'b34', 'b35', 'b36', 'b37', 'b38', 'b39', 'b40', 'b41', 'b42', 'b43', 'b44', 'b45', 'b46', 'b47', 'b48', 'b49', 'b50', 'b51', 'b52', 'b53', 'b54', 'b55', 'b56', 'b57', 'b58', 'b59', 'b60',
    'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10', 'c11', 'c12', 'c13', 'c14', 'c15', 'c16', 'c17', 'c18', 'c21', 'c22', 'c23', 'c24', 'c25', 'c26', 'c27', 'c30', 'c31', 'c32', 'c33', 'c34', 'c35', 'c36', 'c37', 'c38', 'c39', 'c40', 'c41', 'c42', 'c43', 'c44', 'c45', 'c46', 'c47', 'c48', 'c49', 'c50', 'c51', 'c52', 'c53', 'c54', 'c55', 'c56', 'c57', 'c58', 
    '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'o53', 'o54', 'o55', 'o51', 'o49', 'o48','o33', 'o34', 'o35', 'o36', 'o37',  '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'o17', 'o18', 'o19', 'o20', 'o21', 'o22', 'o23', 'o24', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'n33', 'n34', 'n35', 'n36', 'n37', 'n18', 'n20', 'n21', 'n22', 'n23', 'n24', 'n19', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'm52', 'm53', 'm55', 'm33', 'm34', 'm35', 'm36', 'm37', 'm39', 'm18', 'm19', 'm20', 'm21', 'm22', 'm23', 'm2', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'l50', 'l51', 'l52', 'l53', 'l54', 'l55','l33', 'l34', 'l35', 'l36', 'l38', 'l19', 'l20', 'l21', 'l22', 'l23', 'l2', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'k50', 'k51', 'k52', 'k53', 'k54', 'k55', 'k56','k34', 'k35', 'k19', 'k20', 'k21', 'k22', 'k1', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'j50', 'j51', 'j53', 'j54', 'j55', 'j34', 'j35', 'j19', 'j20', 'j21', 'j1', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'i19', 'i20', 'i60', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'h19', 'h59', 'h55', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'g19', 'g18', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'p33', 'p34', 'p35', 'p36', 'p37', 'p38', 'p48', 'p50', 'p32', 'p18', 'p19', 'p20', 'p21', 'p22', '', '', '', '', '',
    'q29', 'q30', 'q34', 'q35', 'q36', 'q37', 'q38', 'q39', 'q31', 'q32', 'q33', 'q43', 'q44','q47', 'q17', 'q18', 'q19', 'q20', 'q51', 'q54', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'r28', 'r29', 'r30', 'r31', 'r32', 'r33', 'r34', 'r35', 'r36', 'r37', 'r43', 'r44', 'r47','r48', 'r16', 'r15', 'r14', 'r51', 'r55', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    's28', 's29', 's30', 's31', 's32', 's33', 's34', 's35', 's36', 's38', 's39', 's40', 's43','s45', 's46', 's47', 's48', 's49', 's44', 's16', 's14', 's13', 's18', 's19', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    't29', 't30', 't31', 't32', 't33', 't34', 't35', 't36', 't38', 't41', 't42', 't43', 't44','t45', 't46', 't47', 't48', 't49', 't50', 't12', 't13', 't14', 't17', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'u30', 'u31', 'u32', 'u37', 'u38', 'u39', 'u40', 'u41', 'u42', 'u43', 'u44', 'u45', 'u46','u47', 'u48', 'u49', 'u50', 'u11', 'u12', 'u13', 'u14', 'u15', 'u16', 'u17', 'u53', 'u54', '', '', '', '', '', '', '', '', '', '', '',
    'v29', 'v35', 'v36', 'v37', 'v38', 'v34', 'v40', 'v41', 'v42', 'v43', 'v44', 'v45', 'v46','v47', 'v48', 'v49', 'v50', 'v52', 'v10', 'v11', 'v12', 'v13', 'v14', 'v15', 'v16', 'v17', 'v18', 'v54', '', '', '', '', '', '', '', '', '', '', '',
    'w31', 'w32', 'w33', 'w34', 'w30', 'w35', 'w37', 'w38', 'w40', 'w41', 'w42', 'w43', 'w44','w45', 'w46', 'w47', 'w48', 'w49', 'w50', 'w51', 'w52', 'w53', 'w29', 'w10', 'w11', 'w12', 'w13', 'w14', 'w15', 'w16', 'w17', 'w18', 'w19', 'w20', 'w54', '', '', '', '',
    'x31', 'x32', 'x33', 'x34', 'x35', 'x36', 'x37', 'x38', 'x39', 'x40', 'x41', 'x42', 'x43','x44', 'x45', 'x46', 'x47', 'x48', 'x49', 'x50', 'x51', 'x52', 'x53', 'x54', 'x9', 'x10', 'x11', 'x11', 'x12', 'x13', 'x14', 'x15', 'x16', 'x17', 'x18', 'x19', 'x20', 'x21', 'x57',
    'y30', 'y31', 'y35', 'y36', 'y37', 'y38', 'y38', 'y39', 'y40', 'y41', 'y42', 'y43', 'y44', 'y45', 'y46','y47', 'y48', 'y49', 'y50', 'y51', 'y52', 'y53', 'y57', 'y8', 'y9', 'y10', 'y11', 'y12', 'y13', 'y14', 'y15', 'y16', 'y18', 'y19', 'y20', 'y4', 'y5', '', '', '',
    'z32', 'z33', 'z35', 'z36', 'z37', 'z38', 'z39', 'z40', 'z41', 'z42', 'z43', 'z44', 'z45','z46', 'z47', 'z48', 'z49', 'z50', 'z51', 'z52', 'z53', 'z54', 'z55', 'z56', 'z57', 'z58', 'z59', 'z60', 'z23', 'z27', 'z28', 'z22', 'z17', 'z15', 'z14', 'z13', 'z12', 'z11', 'z10', 'z9', 'z8', 'z7', 'z6', 'z5', 'z4', 'z3', '', '', '', '', '', '',
    '[33', '[34', '[35', '[36', '[39', '[40', '[41', '[42', '[43', '[44', '[45', '[46', '[47', '[48', '[49', '[50', '[51', '[52', '[53', '[54', '[55', '[56', '[57', '[58', '[59', '[60', '[1', '[3', '[4', '[5', '[6', '[7', '[8', '[9', '[10', '[11', '[12', '[13', '[14', '[15', '[16', '[18', '[19', '[22', '[23', '[24', '[25', '[26', '[27', '[20',
    "$21", '$22', '$23', '$24', '$25', '$26', '$13', '$12', '$10', '$45', '$46', '$47', '$48', '$49', '$40', '$41', '', '', '', '', '', '',
    ']15', ']16', ']17', ']18', ']19', ']20', ']21', ']22', ']23', ']24', ']25', ']26', ']27', ']33', ']34',
    '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',// Ensure all labels are lowercase
    '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',

  ].filter(label => label !== '').map(label => label.toLowerCase())), []);

  // Generate grid cells
  const gridCells = useMemo(() => {
    const cells = [];
    const cellSize = 6.0;
    const radius = 6371;

    for (let lat = -90 + cellSize / 2; lat < 90; lat += cellSize) {
      for (let lon = -180 + cellSize / 2; lon < 180; lon += cellSize) {
        const phi = THREE.MathUtils.degToRad(90 - lat);
        const theta = THREE.MathUtils.degToRad(lon + 180);

        const x = -radius * Math.sin(phi) * Math.cos(theta);
        const y = radius * Math.cos(phi);
        const z = radius * Math.sin(phi) * Math.sin(theta);

        let rowLabel = String.fromCharCode(65 + Math.floor((lat + 90) / cellSize));
        if (rowLabel === '\\') {
          rowLabel = '$';
        }
        const colNum = Math.floor((lon + 180) / cellSize) + 1;
        const label = `${rowLabel}${colNum}`;

        cells.push({ 
          position: [x, y, z],
          label,
          normal: new THREE.Vector3(x, y, z).normalize(),
          isLand: landCells.has(label.toLowerCase())
        });
      }
    }
    return cells;
  }, [landCells]);

  // Create land DataTexture (renamed from australiaTexture)
  const landTexture = useMemo(() => {
    const width = 60;
    const height = 30;
    const data = new Uint8Array(width * height);

    gridCells.forEach((cell, index) => {
      data[index] = cell.isLand ? 255 : 0;
    });

    const texture = new THREE.DataTexture(data, width, height, THREE.RedFormat);
    texture.needsUpdate = true;
    texture.minFilter = THREE.NearestFilter;
    texture.magFilter = THREE.NearestFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    return texture;
  }, [gridCells]);

  return (
    <group ref={ref}>
      <group ref={earthGroupRef}>
        {/* Remove sky sphere mesh and only keep the Earth mesh */}
        <mesh renderOrder={3}>
          <sphereGeometry args={[6371, 220, 220]} />
          <shaderMaterial
            uniforms={{
              cellSize: { value: 6.0 },
              worldTexture: { value: worldTexture },
              australiaTexture: { value: landTexture },
              modelMatrix: { value: new THREE.Matrix4() },
            }}
            fragmentShader={`
              uniform float cellSize;
              uniform sampler2D worldTexture;
              uniform sampler2D australiaTexture;
              uniform mat4 modelMatrix;

              varying vec2 vUv;
              varying vec3 vPosition;
              varying vec3 vNormal;

              bool isLandCell(int row, int col) {
                  // Handle wrapping around the longitude
                  int wrappedCol = col;
                  if (col < 0) wrappedCol = 60 + col;
                  if (col >= 60) wrappedCol = col - 60;
                  
                  if (row < 0 || row >= 30 || wrappedCol < 0 || wrappedCol >= 60) return false;
                  float u = (float(wrappedCol) + 0.5) / 60.0;
                  float v = (float(row) + 0.5) / 30.0;
                  float flag = texture2D(australiaTexture, vec2(u, v)).r;
                  return flag > 0.5;
              }

              bool checkCorner(int row, int col, int corner) {
                  if (corner == 1) {  // top-left
                      return isLandCell(row-1, col) && isLandCell(row, col-1) && isLandCell(row-1, col-1);
                  } else if (corner == 2) {  // top-right
                      return isLandCell(row-1, col) && isLandCell(row, col+1) && isLandCell(row-1, col+1);
                  } else if (corner == 3) {  // bottom-left
                      return isLandCell(row+1, col) && isLandCell(row, col-1) && isLandCell(row+1, col-1);
                  } else if (corner == 4) {  // bottom-right
                      return isLandCell(row+1, col) && isLandCell(row, col+1) && isLandCell(row+1, col+1);
                  }
                  return false;
              }

              bool hasAnyValidCorner(int row, int col) {
                  return checkCorner(row, col, 1) || checkCorner(row, col, 2) || 
                         checkCorner(row, col, 3) || checkCorner(row, col, 4);
              }

              bool hasEmptyEdges(int row, int col, int corner) {
                  if (corner == 1) {  // top-left
                      return !isLandCell(row-1, col) && !isLandCell(row, col-1);
                  } else if (corner == 2) {  // top-right
                      return !isLandCell(row-1, col) && !isLandCell(row, col+1);
                  } else if (corner == 3) {  // bottom-left
                      return !isLandCell(row+1, col) && !isLandCell(row, col-1);
                  } else if (corner == 4) {  // bottom-right
                      return !isLandCell(row+1, col) && !isLandCell(row, col+1);
                  }
                  return false;
              }

              // Helper function to identify Antarctica rows
              bool isAntarcticaRow(int row) {
                  return row >= 0 && row <= 3;
              }

              // Helper function to check if a corner is related to Antarctica
              bool isCornerRelatedToAntarctica(int row, int col, int corner) {
                  if (corner == 1) {  // top-left
                      return isAntarcticaRow(row-1) || isAntarcticaRow(row);
                  } else if (corner == 2) {  // top-right
                      return isAntarcticaRow(row-1) || isAntarcticaRow(row);
                  } else if (corner == 3) {  // bottom-left
                      return isAntarcticaRow(row+1) || isAntarcticaRow(row);
                  } else if (corner == 4) {  // bottom-right
                      return isAntarcticaRow(row+1) || isAntarcticaRow(row);
                  }
                  return false;
              }

              void main() {
                  float lat = (vUv.y - 0.5) * 180.0;
                  float lon = (vUv.x - 0.5) * 360.0 + 60.0;
                  
                  vec4 baseColor = vec4(0.33, 0.61, 0.72, 1.0);    // Darker blue
                  vec4 landColor = vec4(0.09, 0.52, 0.17, 1.0);    // Green
                  vec4 darkLandColor = vec4(0.07, 0.42, 0.14, 1.0); // Darker green for offset
                  vec4 orangeLandColor = vec4(0.11, 0.62, 0.20, 1.0);  // Changed to lighter green
                  vec4 antarcticaColor = vec4(1.0, 1.0, 1.0, 1.0); // White
                  
                  // Define triangle colors with darker blue
                  vec4 redTriangleColor = vec4(0.07, 0.42, 0.14, 1.0);     
                  vec4 yellowTriangleColor = vec4(0.33, 0.61, 0.72, 1.0);  // Darker blue
                  vec4 orangeTriangleColor = vec4(0.33, 0.61, 0.72, 1.0);  // Darker blue
                  vec4 purpleTriangleColor = vec4(0.11, 0.62, 0.20, 1.0);  // Darker blue
                  vec4 pinkTriangleColor = vec4(0.11, 0.62, 0.20, 1.0);    

                  // Original grid
                  float rowFloat = floor((lat + 90.0) / cellSize);
                  float colFloat = floor((lon + 180.0) / cellSize);
                  int row = int(rowFloat);
                  int col = int(colFloat);
                  
                  // Left-down offset grid
                  float offsetLat = lat + cellSize * 0.1;
                  float offsetLon = lon + cellSize * 0.1;
                  float offsetRowFloat = floor((offsetLat + 90.0) / cellSize);
                  float offsetColFloat = floor((offsetLon + 180.0) / cellSize);
                  int offsetRow = int(offsetRowFloat);
                  int offsetCol = int(offsetColFloat);

                  // Right-up offset grid (new)
                  float offsetLat2 = lat - cellSize * 0.1;
                  float offsetLon2 = lon - cellSize * 0.1;
                  float offsetRowFloat2 = floor((offsetLat2 + 90.0) / cellSize);
                  float offsetColFloat2 = floor((offsetLon2 + 180.0) / cellSize);
                  int offsetRow2 = int(offsetRowFloat2);
                  int offsetCol2 = int(offsetColFloat2);

                  vec2 cellPos = vec2(
                      fract((lon + 180.0) / cellSize),
                      fract((lat + 90.0) / cellSize)
                  );

                  vec2 offsetCellPos = vec2(
                      fract((offsetLon + 180.0) / cellSize),
                      fract((offsetLat + 90.0) / cellSize)
                  );

                  vec2 offsetCellPos2 = vec2(
                      fract((offsetLon2 + 180.0) / cellSize),
                      fract((offsetLat2 + 90.0) / cellSize)
                  );

                  float margin = 0.5;
                  bool isInMainCell = cellPos.x > (0.5 - margin) && 
                                     cellPos.x < (0.5 + margin) && 
                                     cellPos.y > (0.5 - margin) && 
                                     cellPos.y < (0.5 + margin);

                  bool isInOffsetCell = offsetCellPos.x > (0.5 - margin) && 
                                       offsetCellPos.x < (0.5 + margin) && 
                                       offsetCellPos.y > (0.5 - margin) && 
                                       offsetCellPos.y < (0.5 + margin);

                  bool isInOffsetCell2 = offsetCellPos2.x > (0.5 - margin) && 
                                        offsetCellPos2.x < (0.5 + margin) && 
                                        offsetCellPos2.y > (0.5 - margin) && 
                                        offsetCellPos2.y < (0.5 + margin);

                  vec4 finalColor = baseColor;
                  vec4 blueTriangleColor = vec4(0.33, 0.61, 0.72, 1.0); // Darker blue for empty corner triangles

                  // First render left-down offset cells (using darker green)
                  if (isInOffsetCell) {
                      // Only apply green border effects to non-Antarctica-related cells
                      if (isLandCell(offsetRow, offsetCol)) {
                          if (isAntarcticaRow(offsetRow)) {
                              // For Antarctica cells, use white directly
                              finalColor = antarcticaColor;
                          } else {
                              // For non-Antarctica cells, use the normal dark green
                              finalColor = darkLandColor;
                              
                              // Reduced triangle size to 0.2
                              if (hasEmptyEdges(offsetRow, offsetCol, 1) && offsetCellPos.x + offsetCellPos.y < 0.2) {
                                  finalColor = blueTriangleColor;
                              }
                              if (hasEmptyEdges(offsetRow, offsetCol, 2) && (1.0 - offsetCellPos.x) + offsetCellPos.y < 0.2) {
                                  finalColor = blueTriangleColor;
                              }
                              if (hasEmptyEdges(offsetRow, offsetCol, 3) && offsetCellPos.x + (1.0 - offsetCellPos.y) < 0.2) {
                                  finalColor = blueTriangleColor;
                              }
                              if (hasEmptyEdges(offsetRow, offsetCol, 4) && (1.0 - offsetCellPos.x) + (1.0 - offsetCellPos.y) < 0.2) {
                                  bool diagonalIsLand = isLandCell(offsetRow + 1, offsetCol + 1);
                                  finalColor = diagonalIsLand ? vec4(0.07, 0.42, 0.14, 1.0) : vec4(0.5, 0.0, 0.5, 1.0);
                              }
                          }
                      } else if (hasAnyValidCorner(offsetRow, offsetCol)) {
                          // Skip corner effects for Antarctica-related corners
                          bool isAntarcticaCorner = 
                              isCornerRelatedToAntarctica(offsetRow, offsetCol, 1) ||
                              isCornerRelatedToAntarctica(offsetRow, offsetCol, 2) ||
                              isCornerRelatedToAntarctica(offsetRow, offsetCol, 3) ||
                              isCornerRelatedToAntarctica(offsetRow, offsetCol, 4);
                              
                          if (!isAntarcticaCorner) {
                              vec4 darkCornerColor = vec4(0.07, 0.42, 0.14, 1.0);  // Dark green for corners
                              if (checkCorner(offsetRow, offsetCol, 1) && offsetCellPos.x + offsetCellPos.y < 0.8) {
                                  finalColor = darkCornerColor;
                              }
                              if (checkCorner(offsetRow, offsetCol, 2) && (1.0 - offsetCellPos.x) + offsetCellPos.y < 0.8) {
                                  finalColor = darkCornerColor;
                              }
                              if (checkCorner(offsetRow, offsetCol, 3) && offsetCellPos.x + (1.0 - offsetCellPos.y) < 0.8) {
                                  finalColor = darkCornerColor;
                              }
                              if (checkCorner(offsetRow, offsetCol, 4) && (1.0 - offsetCellPos.x) + (1.0 - offsetCellPos.y) < 0.8) {
                                  finalColor = darkCornerColor;
                              }
                          }
                      }
                  }

                  // Then render right-up offset cells (using lighter green)
                  if (isInOffsetCell2) {
                      // Only apply green border effects to non-Antarctica-related cells
                      if (isLandCell(offsetRow2, offsetCol2)) {
                          if (isAntarcticaRow(offsetRow2)) {
                              // For Antarctica cells, use white directly
                              finalColor = antarcticaColor;
                          } else {
                              // For non-Antarctica cells, use the normal orange green
                              finalColor = orangeLandColor;
                              
                              // Reduced triangle size to 0.2
                              if (hasEmptyEdges(offsetRow2, offsetCol2, 1) && offsetCellPos2.x + offsetCellPos2.y < 0.2) {
                                  finalColor = vec4(0.33, 0.61, 0.72, 1.0);
                              }
                              if (hasEmptyEdges(offsetRow2, offsetCol2, 2) && (1.0 - offsetCellPos2.x) + offsetCellPos2.y < 0.2) {
                                  finalColor = vec4(0.33, 0.61, 0.72, 1.0);
                              }
                              if (hasEmptyEdges(offsetRow2, offsetCol2, 3) && offsetCellPos2.x + (1.0 - offsetCellPos2.y) < 0.2) {
                                  finalColor = vec4(0.33, 0.61, 0.72, 1.0);
                              }
                              if (hasEmptyEdges(offsetRow2, offsetCol2, 4) && (1.0 - offsetCellPos2.x) + (1.0 - offsetCellPos2.y) < 0.2) {
                                  bool diagonalIsLand = isLandCell(offsetRow2 + 1, offsetCol2 + 1);
                                  finalColor = diagonalIsLand ? vec4(0.07, 0.42, 0.14, 1.0) : vec4(0.33, 0.61, 0.72, 1.0);
                              }
                          }
                      } else if (hasAnyValidCorner(offsetRow2, offsetCol2)) {
                          // Skip corner effects for Antarctica-related corners
                          bool isAntarcticaCorner = 
                              isCornerRelatedToAntarctica(offsetRow2, offsetCol2, 1) ||
                              isCornerRelatedToAntarctica(offsetRow2, offsetCol2, 2) ||
                              isCornerRelatedToAntarctica(offsetRow2, offsetCol2, 3) ||
                              isCornerRelatedToAntarctica(offsetRow2, offsetCol2, 4);
                              
                          if (!isAntarcticaCorner) {
                              vec4 cornerColor = vec4(0.11, 0.62, 0.20, 1.0);
                              if (checkCorner(offsetRow2, offsetCol2, 1) && offsetCellPos2.x + offsetCellPos2.y < 0.8) {
                                  finalColor = cornerColor;
                              }
                              if (checkCorner(offsetRow2, offsetCol2, 2) && (1.0 - offsetCellPos2.x) + offsetCellPos2.y < 0.8) {
                                  finalColor = cornerColor;
                              }
                              if (checkCorner(offsetRow2, offsetCol2, 3) && offsetCellPos2.x + (1.0 - offsetCellPos2.y) < 0.8) {
                                  finalColor = cornerColor;
                              }
                              if (checkCorner(offsetRow2, offsetCol2, 4) && (1.0 - offsetCellPos2.x) + (1.0 - offsetCellPos2.y) < 0.8) {
                                  finalColor = cornerColor;
                              }
                          }
                      }
                  }

                  // Finally render main cells
                  if (isInMainCell) {
                      if (isLandCell(row, col)) {
                          finalColor = row >= 0 && row <= 3 ? antarcticaColor : landColor;
                          
                          // Skip Antarctica and keep triangle size at 0.6 for medium green only
                          if (!(row >= 0 && row <= 3)) {
                              if (hasEmptyEdges(row, col, 1) && cellPos.x + cellPos.y < 0.21) {
                                  finalColor = redTriangleColor;
                              }
                              if (hasEmptyEdges(row, col, 2) && (1.0 - cellPos.x) + cellPos.y < 0.21) {
                                  finalColor = yellowTriangleColor;
                              }
                              if (hasEmptyEdges(row, col, 3) && cellPos.x + (1.0 - cellPos.y) < 0.21) {
                                  finalColor = orangeTriangleColor;
                              }
                              if (hasEmptyEdges(row, col, 4) && (1.0 - cellPos.x) + (1.0 - cellPos.y) < 0.21) {
                                  bool diagonalIsLand = isLandCell(row + 1, col + 1);
                                  finalColor = diagonalIsLand ? pinkTriangleColor : purpleTriangleColor;
                              }
                          }
                      } else if (hasAnyValidCorner(row, col)) {
                          // Skip corner effects for Antarctica-related corners
                          bool isAntarcticaCorner = 
                              isCornerRelatedToAntarctica(row, col, 1) ||
                              isCornerRelatedToAntarctica(row, col, 2) ||
                              isCornerRelatedToAntarctica(row, col, 3) ||
                              isCornerRelatedToAntarctica(row, col, 4);
                              
                          if (!isAntarcticaCorner) {
                              vec4 purpleColor = vec4(0.09, 0.52, 0.17, 1.0);
                              if (checkCorner(row, col, 1) && cellPos.x + cellPos.y < 0.8) {
                                  finalColor = purpleColor;
                              }
                              if (checkCorner(row, col, 2) && (1.0 - cellPos.x) + cellPos.y < 0.8) {
                                  finalColor = purpleColor;
                              }
                              if (checkCorner(row, col, 3) && cellPos.x + (1.0 - cellPos.y) < 0.8) {
                                  finalColor = purpleColor;
                              }
                              if (checkCorner(row, col, 4) && (1.0 - cellPos.x) + (1.0 - cellPos.y) < 0.8) {
                                  finalColor = purpleColor;
                              }
                          }
                      }
                  }

                  // Update the shadow circles section near the end of the fragment shader
                  vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
                  vec3 toSun = normalize(-worldPos);
                  vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
                  
                  float dotProduct = dot(worldNormal, toSun);
                  if (dotProduct < 0.9) {
                    float angle = acos(-dotProduct) * 1.8;
                    if (angle < 3.15) {
                      // Create a smooth gradient for the shadow
                      float shadowStrength = smoothstep(3.15, 2.8, angle);
                      // Apply easing function to make the gradient more natural
                      shadowStrength = pow(shadowStrength, 1.5);
                      // Mix the shadow color with a slightly lower opacity
                      finalColor = mix(finalColor, vec4(0.0, 0.0, 0.0, 1.0), 0.45 * shadowStrength);
                    }
                  }
                  
                  gl_FragColor = finalColor;
              }
            `}
            vertexShader={`
              varying vec2 vUv;
              varying vec3 vPosition;
              varying vec3 vNormal;

              void main() {
                vUv = uv;
                vPosition = position;
                vNormal = normal;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
              }
            `}
            transparent={false}
            depthWrite={true}
            depthTest={true}
            side={THREE.FrontSide}
          />
        </mesh>
      </group>
    </group>
  );
});

export default Earth;