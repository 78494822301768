/* src/components/Resources.js */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // For SEO
import { CheckCircle } from 'lucide-react'; // Import CheckCircle icon
import { motion } from 'framer-motion'; // For animations

// Import Layout
import Layout from '../HeaderFooterLayoutBees/Layout'; // Import the Layout component

// Sample data for different resources
const resourcesData = {
  blog: {
    title: 'Blog',
    description:
      'Explore our latest articles and insights on educational technology, teaching strategies, and more.',
    features: [
      'In-depth articles on innovative teaching methods.',
      'Interviews with educational experts.',
      'Tips and tricks for maximizing Bright Beehives in your classroom.',
    ],
    benefits: [
      'Stay updated with the latest trends in education.',
      'Gain valuable insights to enhance your teaching practices.',
      'Learn how to effectively utilize Bright Beehives tools.',
    ],
    testimonials: [
      {
        name: 'Sophia Lee',
        role: 'Private Tutor',
        feedback:
          'The blog provides excellent insights that help me improve my tutoring sessions. I especially love the tips on personalized learning.',
      },
      {
        name: 'David Wilson',
        role: 'Online Tutor',
        feedback:
          "Bright Beehives' blog is a treasure trove of information. The articles are well-researched and highly relevant to my work.",
      },
    ],
  },
  'help-center': {
    title: 'Help Center',
    description:
      'Find answers to frequently asked questions and get support for using Bright Beehives.',
    features: [
      'Comprehensive FAQs covering all aspects of Bright Beehives.',
      'Step-by-step guides to help you get started.',
      'Contact support for personalized assistance.',
    ],
    benefits: [
      'Quickly find solutions to common issues.',
      'Enhance your understanding of Bright Beehives functionalities.',
      'Receive personalized support when needed.',
    ],
    testimonials: [
      {
        name: 'Linda Thompson',
        role: 'IT Director',
        feedback:
          'The Help Center is incredibly useful. I was able to resolve issues without needing to contact support, saving valuable time.',
      },
      {
        name: 'Robert Davis',
        role: 'Educational Technology Manager',
        feedback:
          'The step-by-step guides in the Help Center made integrating Bright Beehives into our systems a breeze.',
      },
    ],
  },
  webinars: {
    title: 'Webinars',
    description:
      'Join our live webinars to learn more about Bright Beehives and how to effectively integrate it into your teaching.',
    features: [
      'Live demonstrations of Bright Beehives tools.',
      'Interactive Q&A sessions with experts.',
      'Recordings of past webinars available for later viewing.',
    ],
    benefits: [
      'Gain hands-on experience with Bright Beehives.',
      'Get your questions answered in real-time.',
      'Access valuable content at your convenience.',
    ],
    testimonials: [
      {
        name: 'Emily Clark',
        role: 'Private Tutor',
        feedback:
          'The webinars are fantastic! They provide a deep dive into the features of Bright Beehives and offer practical tips for usage.',
      },
      {
        name: 'Michael Brown',
        role: 'School Administrator',
        feedback:
          "Participating in the webinars has greatly enhanced our team's ability to utilize Bright Beehives effectively.",
      },
    ],
  },
};

// FeatureItem Component
const FeatureItem = ({ text }) => (
  <motion.div
    className="flex items-start p-4 space-x-3 transition-shadow duration-300 bg-white rounded-lg shadow hover:shadow-lg"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <CheckCircle className="w-6 h-6 mt-1 text-green-500" />
    <p className="text-gray-700">{text}</p>
  </motion.div>
);

// TestimonialItem Component
const TestimonialItem = ({ testimonial }) => (
  <motion.div
    className="p-6 transition-shadow duration-300 bg-white rounded-lg shadow-md hover:shadow-xl"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <p className="mb-4 italic text-gray-600">"{testimonial.feedback}"</p>
    <div className="flex items-center">
      <div className="flex-shrink-0 w-12 h-12 mr-4 bg-gray-200 rounded-full"></div>
      <div>
        <h5 className="text-lg font-semibold text-gray-800">{testimonial.name}</h5>
        <p className="text-sm text-gray-500">{testimonial.role}</p>
      </div>
    </div>
  </motion.div>
);

// BenefitsSection Component
const BenefitsSection = ({ benefits }) => (
  <motion.div
    className="mt-12"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="mb-6 text-2xl font-bold text-yellow-900">Benefits</h3>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {benefits.map((benefit, index) => (
        <motion.div
          key={index}
          className="flex items-start p-4 space-x-3 bg-yellow-100 rounded-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
          <CheckCircle className="w-6 h-6 mt-1 text-yellow-600" />
          <p className="text-gray-700">{benefit}</p>
        </motion.div>
      ))}
    </div>
  </motion.div>
);

// TestimonialsSection Component
const TestimonialsSection = ({ testimonials }) => (
  <motion.div
    className="mt-12"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="mb-6 text-2xl font-bold text-yellow-900">What Our Clients Say</h3>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {testimonials.map((testimonial, index) => (
        <TestimonialItem key={index} testimonial={testimonial} />
      ))}
    </div>
  </motion.div>
);

// AnimatedBee Component
const AnimatedBee = () => (
  <motion.img
    src="/assets/beesolo.svg" // Adjust the path if necessary
    alt="Animated Bee"
    className="absolute w-12 h-12"
    initial={{ x: 0, y: 0 }}
    animate={{
      x: [0, 100, -100, 0],
      y: [0, -50, 50, 0],
    }}
    transition={{
      duration: 10,
      repeat: Infinity,
      ease: 'linear',
    }}
  />
);

const Resources = () => {
  const location = useLocation();

  // Extract the hash from the URL and remove the '#' symbol
  const hash = location.hash.replace('#', '').toLowerCase();

  // Determine the selected category based on the hash
  const selectedCategory = resourcesData[hash] ? hash : null;

  return (
    <Layout>
      <div className="relative flex flex-col min-h-screen overflow-hidden font-sans bg-yellow-50">
        {/* Animated Bees */}
        <AnimatedBee />
        <AnimatedBee style={{ top: '20%', left: '10%' }} />
        <AnimatedBee style={{ top: '50%', left: '80%' }} />
        <AnimatedBee style={{ top: '70%', left: '30%' }} />

        {/* SEO Optimization */}
        <Helmet>
          <title>Resources | Bright Beehives</title>
          <meta
            name="description"
            content="Explore our resources including blogs, help center, and webinars to enhance your educational experience with Bright Beehives."
          />
        </Helmet>

        {/* Main Content */}
        <main className="z-10 flex-grow">
          <section className="relative px-6 py-12 bg-yellow-50">
            <div className="mx-auto max-w-7xl">
              {/* Render Only the Selected Category */}
              {selectedCategory ? (
                <div
                  className="p-8 mb-16 bg-white rounded-lg shadow-lg"
                >
                  <div className="mb-10 text-center">
                    <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">
                      {resourcesData[selectedCategory].title}
                    </h2>
                    <p className="text-lg text-gray-700 md:text-xl">
                      {resourcesData[selectedCategory].description}
                    </p>
                  </div>

                  {/* Key Features */}
                  <div className="mb-12">
                    <h3 className="mb-6 text-2xl font-semibold text-yellow-900">Key Features</h3>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                      {resourcesData[selectedCategory].features.map((feature, index) => (
                        <FeatureItem key={index} text={feature} />
                      ))}
                    </div>
                  </div>

                  {/* Benefits Section */}
                  <BenefitsSection benefits={resourcesData[selectedCategory].benefits} />

                  {/* Testimonials Section */}
                  <TestimonialsSection testimonials={resourcesData[selectedCategory].testimonials} />
                </div>
              ) : (
                // Optional: Display a message or default content when no category is selected
                <div className="p-8 mb-16 bg-white rounded-lg shadow-lg">
                  <div className="mb-10 text-center">
                    <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">
                      Welcome to Our Resources
                    </h2>
                    <p className="text-lg text-gray-700 md:text-xl">
                      Please select a category from the menu to view its resources.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default Resources;
