import React, { useRef, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import PropTypes from 'prop-types';

export const SpaceshipControls = ({ speed = 50, onUpdatePositions, planetLabels, guidanceSystem, selectedCelestialBody }) => {
  const { camera } = useThree();
  const moveState = useRef({
    forward: false,
    backward: false,
    left: false,
    right: false,
    up: false,
    down: false,
  });
  
  // Remove mouseState ref since we don't need it anymore
  const velocity = useRef(new THREE.Vector3());
  const acceleration = 100000; // Units per second²
  const maxSpeed = speed * 90000; // Maximum speed limit

  // Add initial camera setup
  useEffect(() => {
    // Remove the initial camera position setup
  }, [camera]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.code) {
        case 'KeyW': moveState.current.forward = true; break;
        case 'KeyS': moveState.current.backward = true; break;
        case 'KeyA': moveState.current.left = true; break;
        case 'KeyD': moveState.current.right = true; break;
        case 'Space': moveState.current.up = true; break;
        case 'ShiftLeft': moveState.current.down = true; break;
        default: break;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.code) {
        case 'KeyW': moveState.current.forward = false; break;
        case 'KeyS': moveState.current.backward = false; break;
        case 'KeyA': moveState.current.left = false; break;
        case 'KeyD': moveState.current.right = false; break;
        case 'Space': moveState.current.up = false; break;
        case 'ShiftLeft': moveState.current.down = false; break;
        default: break;
      }
    };

    // Remove mouse-related event listeners, only keep keyboard controls
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [camera]);

  useFrame((state, delta) => {
    // Only apply manual controls if no planet is selected AND not transitioning
    if (!selectedCelestialBody) {
      const moveSpeed = acceleration * delta;
      const movement = new THREE.Vector3();
      const currentVelocityDir = velocity.current.clone().normalize();

      // Handle instant stop with S/Down keys
      if (moveState.current.backward || moveState.current.down) {
        // Instantly stop all velocity
        velocity.current.set(0, 0, 0);
      } else {
        // Normal acceleration for other movements
        if (moveState.current.forward) movement.z -= moveSpeed;
        if (moveState.current.left) movement.x -= moveSpeed;
        if (moveState.current.right) movement.x += moveSpeed;
        if (moveState.current.up) movement.y += moveSpeed;

        // Transform movement direction based on camera rotation and add to velocity
        if (movement.length() > 0) {
          movement.applyQuaternion(camera.quaternion);
          velocity.current.add(movement);
        }
      }

      // Limit maximum speed
      if (velocity.current.length() > maxSpeed) {
        velocity.current.normalize().multiplyScalar(maxSpeed);
      }

      // Apply velocity to camera position
      camera.position.add(velocity.current.clone().multiplyScalar(delta));
    }

    // Move guidance system update code here, outside the movement condition
    if (guidanceSystem && planetLabels) {
      const positions = {};
      const frustum = new THREE.Frustum();
      const projScreenMatrix = new THREE.Matrix4();
      projScreenMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
      frustum.setFromProjectionMatrix(projScreenMatrix);

      planetLabels.forEach(planet => {
        const planetPosition = new THREE.Vector3(
          planet.position.x,
          planet.position.y,
          planet.position.z
        );

        // Calculate distance from camera to planet
        const distance = camera.position.distanceTo(planetPosition);

        // Create vector in world space and project to screen space
        const screenVector = planetPosition.clone();
        screenVector.project(camera);
        
        // Convert normalized device coordinates (-1 to +1) to pixels
        const widthHalf = window.innerWidth / 2;
        const heightHalf = window.innerHeight / 2;
        const x = (screenVector.x * widthHalf) + widthHalf;
        const y = -(screenVector.y * heightHalf) + heightHalf;

        // Check if the point is in front of the camera and within frustum
        const isVisible = screenVector.z < 1 && frustum.containsPoint(planetPosition);

        positions[planet.name] = {
          x,
          y,
          distance,
          isVisible,
          worldPosition: planet.position  // Use the original position directly from planetLabels
        };
      });

      // Pass positions up to parent component
      if (typeof onUpdatePositions === 'function') {
        onUpdatePositions(positions);
      }
    } else if (!guidanceSystem && typeof onUpdatePositions === 'function') {
      // Clear positions when guidance system is off
      onUpdatePositions({});
    }
  });

  return null;
};

SpaceshipControls.propTypes = {
  speed: PropTypes.number,
  onUpdatePositions: PropTypes.func,
  planetLabels: PropTypes.array,
  guidanceSystem: PropTypes.bool,
  selectedCelestialBody: PropTypes.string,
};
