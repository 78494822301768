import axios from 'axios';

// Using our own proxy server instead of a CORS proxy service
export const callClaudeAPI = async (prompt, useAWS = false) => {
  try {
    // Log the full prompt instead of just the first 50 characters
    console.log('===== FULL PROMPT START =====');
    console.log(prompt);
    console.log('===== FULL PROMPT END =====');
    
    // Get the proxy URL from environment variable or use default
    const proxyUrl = process.env.REACT_APP_API_PROXY_URL || 'http://localhost:5000/api';
    
    console.log('Using proxy server:', proxyUrl);
    console.log('Using AWS Bedrock:', useAWS);
    
    // Make request through our own proxy server
    const response = await axios.post(
      `${proxyUrl}/claude`,
      {
        prompt: prompt,
        model: 'claude-3-7-sonnet-20250219',
        useAWS: useAWS
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    
    console.log('Response received:', response.status);
    return response.data;
  } catch (error) {
    console.error('Error calling Claude API:', error);
    
    // More detailed error logging
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up request:', error.message);
    }
    
    throw error;
  }
};