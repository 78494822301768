import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported
import { useDataController } from '../../../contexts/datacontroller';
import { callClaudeAPI } from './claudeService';

// Import banner image similar to Whiteboard
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

// Helper to extract SVG data from string
const extractSVGDataFromString = (svgString) => {
  try {
    // Create temporary DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgString, 'image/svg+xml');
    const svg = doc.querySelector('svg');
    
    if (!svg) {
      return { viewBox: [0, 0, 100, 100], shapes: [] };
    }
    
    // Extract viewBox
    const viewBoxStr = svg.getAttribute('viewBox') || '0 0 100 100';
    const viewBox = viewBoxStr.split(' ').map(Number);
    
    // Extract shapes from children
    const shapes = Array.from(svg.children)
      .filter(child => child.nodeType === 1) // Filter out text nodes
      .map(child => {
        const type = child.tagName.toLowerCase();
        
        // Base attributes
        const baseAttrs = {
          fill: child.getAttribute('fill') || '#000000',
          opacity: parseFloat(child.getAttribute('opacity') || 1),
          stroke: child.getAttribute('stroke'),
          strokeWidth: child.getAttribute('stroke-width')
        };

        switch (type) {
          case 'rect':
            return {
              type: 'rect',
              x: parseFloat(child.getAttribute('x') || 0),
              y: parseFloat(child.getAttribute('y') || 0),
              width: parseFloat(child.getAttribute('width')),
              height: parseFloat(child.getAttribute('height')),
              ...baseAttrs
            };

          case 'circle':
            return {
              type: 'circle',
              cx: parseFloat(child.getAttribute('cx')),
              cy: parseFloat(child.getAttribute('cy')),
              r: parseFloat(child.getAttribute('r')),
              ...baseAttrs
            };
            
          case 'ellipse':
            return {
              type: 'ellipse',
              cx: parseFloat(child.getAttribute('cx')),
              cy: parseFloat(child.getAttribute('cy')),
              rx: parseFloat(child.getAttribute('rx')),
              ry: parseFloat(child.getAttribute('ry')),
              ...baseAttrs
            };

          case 'path':
            return {
              type: 'path',
              d: child.getAttribute('d'),
              ...baseAttrs
            };
            
          case 'line':
            return {
              type: 'line',
              x1: parseFloat(child.getAttribute('x1') || 0),
              y1: parseFloat(child.getAttribute('y1') || 0),
              x2: parseFloat(child.getAttribute('x2') || 0),
              y2: parseFloat(child.getAttribute('y2') || 0),
              ...baseAttrs
            };
            
          case 'polygon':
            return {
              type: 'polygon',
              points: child.getAttribute('points') || '',
              ...baseAttrs
            };
            
          case 'polyline':
            return {
              type: 'polyline',
              points: child.getAttribute('points') || '',
              ...baseAttrs
            };
            
          case 'text':
            return {
              type: 'text',
              x: parseFloat(child.getAttribute('x') || 0),
              y: parseFloat(child.getAttribute('y') || 0),
              text: child.textContent,
              fontSize: child.getAttribute('font-size'),
              fontFamily: child.getAttribute('font-family'),
              ...baseAttrs
            };

          // For any other shape type, capture all attributes
          default:
            const attrs = {};
            Array.from(child.attributes).forEach(attr => {
              attrs[attr.name] = attr.value;
            });
            
            return {
              type,
              ...attrs,
              ...baseAttrs,
              // Store the outerHTML for direct rendering if needed
              outerHTML: child.outerHTML
            };
        }
      })
      .filter(Boolean); // Remove null entries

    return { viewBox, shapes };
  } catch (error) {
    console.error("Error parsing SVG:", error);
    return { viewBox: [0, 0, 100, 100], shapes: [] };
  }
};

const SlideComponents = ({ onClose, classID }) => {
  const { setModuleStatus } = useDataController();
  const leftCanvasRef = useRef(null);
  const [inputText, setInputText] = useState('');
  const [submittedText, setSubmittedText] = useState('');
  const [codeText, setCodeText] = useState(''); // Add state for code textarea
  const [svgContent, setSvgContent] = useState(null); // Add state for parsed SVG content
  const [svgData, setSvgData] = useState({ viewBox: [0, 0, 100, 100], shapes: [] });
  const [reactComponentStructure, setReactComponentStructure] = useState({ 
    before: '', 
    after: '' 
  }); // Store React component structure
  const [selectedShapeIndices, setSelectedShapeIndices] = useState([]); // Replace single selection with array
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [resizing, setResizing] = useState(false);
  const [resizeHandle, setResizeHandle] = useState(null); // 'tl', 'tr', 'bl', 'br'
  const [initialDragPosition, setInitialDragPosition] = useState({ x: 0, y: 0 });
  const [initialShapePositions, setInitialShapePositions] = useState([]); // Array for multiple shapes
  const svgRef = useRef(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState("#000000");
  // Add BoxSelect mode state
  const [boxSelectMode, setBoxSelectMode] = useState(false);
  const [selectionBox, setSelectionBox] = useState(null);
  // Add new state for display mode
  const [displayMode, setDisplayMode] = useState('Light'); // Initial mode is 'Light'

  // Add useEffect to handle keyboard events for Delete key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Delete' && selectedShapeIndices.length > 0) {
        handleDelete();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);
    
    // Clean up
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedShapeIndices]); // Re-attach when selection changes

  // Handle text input change
  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  // Handle code textarea change
  const handleCodeChange = (e) => {
    setCodeText(e.target.value);
    
    // Try to extract SVG content from the code
    try {
      // Check if this is a React component with SVG
      const reactComponentMatch = e.target.value.match(
        /^([\s\S]*?)(<svg[\s\S]*?<\/svg>)([\s\S]*?)$/
      );
      
      if (reactComponentMatch) {
        // Extract parts
        const [_, beforeSvg, svgContent, afterSvg] = reactComponentMatch;
        
        // Store React component structure
        setReactComponentStructure({
          before: beforeSvg,
          after: afterSvg
        });
        
        // Parse the SVG to add style for proper scaling
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgContent, 'image/svg+xml');
        const svgElement = doc.querySelector('svg');
        
        if (svgElement) {
          // Add style attribute for proper scaling
          svgElement.setAttribute('style', 'max-width: 100%; max-height: 100%; width: auto; height: auto; object-fit: contain;');
          
          // Convert back to string
          const formattedSvgContent = new XMLSerializer().serializeToString(svgElement);
          
          setSvgContent(formattedSvgContent);
          
          // Parse SVG data
          const parsedData = extractSVGDataFromString(formattedSvgContent);
          setSvgData(parsedData);
        }
      } else {
        // Check for standalone SVG
        const svgMatch = e.target.value.match(/<svg[\s\S]*?<\/svg>/);
        if (svgMatch) {
          let svgContent = svgMatch[0];
          
          // Reset React component structure
          setReactComponentStructure({ before: '', after: '' });
          
          // Parse the SVG to add style for proper scaling
          const parser = new DOMParser();
          const doc = parser.parseFromString(svgContent, 'image/svg+xml');
          const svgElement = doc.querySelector('svg');
          
          if (svgElement) {
            // Add style attribute for proper scaling
            svgElement.setAttribute('style', 'max-width: 100%; max-height: 100%; width: auto; height: auto; object-fit: contain;');
            
            // Convert back to string
            svgContent = new XMLSerializer().serializeToString(svgElement);
          }
          
          setSvgContent(svgContent);
          
          // Parse SVG data
          const parsedData = extractSVGDataFromString(svgContent);
          setSvgData(parsedData);
        } else {
          setSvgContent(null);
          setSvgData({ viewBox: [0, 0, 100, 100], shapes: [] });
          setReactComponentStructure({ before: '', after: '' });
        }
      }
    } catch (error) {
      console.error("Error parsing SVG:", error);
      setSvgContent(null);
      setSvgData({ viewBox: [0, 0, 100, 100], shapes: [] });
      setReactComponentStructure({ before: '', after: '' });
    }
  };

  // Update your handleSubmit function
  const handleSubmit = async () => {
    if (inputText.trim() !== '') {
      setSubmittedText('Processing your request...');
      
      try {
        // Create enhanced prompt with more detailed instructions
        const enhancedPrompt = `Create an SVG image of: ${inputText}.
Front perspective, highly detailed, using rectangles, squares, circles, ellipses, and paths.
Make it the most detailed ${inputText} SVG image possible with the following characteristics:
- Use a variety of shapes and colors for visual interest
- Include fine details and textures where appropriate
- Ensure proper layering of elements
- Use appropriate proportions and perspective
- Optimize the SVG for clarity and visual appeal
- Include at least 15-20 distinct elements or shapes
- Use a color palette that best represents the subject

Please provide ONLY the SVG code without any explanation or markdown formatting.`;
        
        // Log the full prompt to verify what's being sent
        console.log('===== PROMPT START =====');
        console.log(enhancedPrompt);
        console.log('===== PROMPT END =====');
        
        // Call Claude API through our proxy server with enhanced prompt
        const response = await callClaudeAPI(enhancedPrompt);
        
        // Extract the response content
        const claudeResponse = response.content[0].text;
        
        // Look for SVG content in the response
        const svgMatch = claudeResponse.match(/<svg[\s\S]*?<\/svg>/);
        if (svgMatch) {
          let svgContent = svgMatch[0];
          
          // Parse the SVG to add style for proper scaling
          const parser = new DOMParser();
          const doc = parser.parseFromString(svgContent, 'image/svg+xml');
          const svgElement = doc.querySelector('svg');
          
          if (svgElement) {
            // Add style attribute for proper scaling
            svgElement.setAttribute('style', 'max-width: 100%; max-height: 100%; width: auto; height: auto; object-fit: contain;');
            
            // Convert back to string
            svgContent = new XMLSerializer().serializeToString(svgElement);
          }
          
          setSvgContent(svgContent);
          
          // Also update the code text field with the SVG content
          setCodeText(svgContent);
          
          // Parse SVG data
          const parsedData = extractSVGDataFromString(svgContent);
          setSvgData(parsedData);
          
          setSubmittedText("SVG generated successfully!");
        } else {
          // If no SVG found, just display the text response
          setSubmittedText(claudeResponse);
        }
      } catch (error) {
        console.error('Error in handleSubmit:', error);
        setSubmittedText(`Error: ${error.message || 'Unknown error occurred'}`);
      }
      
      setInputText(''); // Clear the input field after submission
    }
  };

  // Handle key press for Enter key
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  // Helper function to convert screen coordinates to SVG coordinates
  const screenToSVGCoordinates = (clientX, clientY) => {
    if (!svgRef.current) return { x: clientX, y: clientY };
    
    const svg = svgRef.current;
    const svgPoint = svg.createSVGPoint();
    
    // Set the point in screen coordinates
    svgPoint.x = clientX;
    svgPoint.y = clientY;
    
    // Convert to SVG coordinate system
    const CTM = svg.getScreenCTM();
    if (!CTM) return { x: clientX, y: clientY };
    
    const transformedPoint = svgPoint.matrixTransform(CTM.inverse());
    return { x: transformedPoint.x, y: transformedPoint.y };
  };

  // Toggle BoxSelect mode
  const toggleBoxSelectMode = () => {
    setBoxSelectMode(!boxSelectMode);
  };

  // Modify handleMouseDown to handle box selection start
  const handleMouseDown = (index, e) => {
    if (boxSelectMode) {
      // If in box select mode, start drawing the selection box
      e.stopPropagation();
      const svgCoords = screenToSVGCoordinates(e.clientX, e.clientY);
      setDragStart(svgCoords);
      setSelectionBox({
        x: svgCoords.x,
        y: svgCoords.y,
        width: 0,
        height: 0,
      });
      setDragging(true);
      return;
    }

    if (e.shiftKey) {
      // If shift key is pressed, toggle selection
      setSelectedShapeIndices(prev => {
        if (prev.includes(index)) {
          return prev.filter(i => i !== index);
        } else {
          return [...prev, index];
        }
      });
    } else {
      // If shift key is not pressed, replace selection
      if (!selectedShapeIndices.includes(index)) {
        setSelectedShapeIndices([index]);
      }
    }
    
    setDragging(true);
    
    // Get SVG coordinates of the mouse position
    const svgCoords = screenToSVGCoordinates(e.clientX, e.clientY);
    setDragStart(svgCoords);
    setInitialDragPosition(svgCoords);
    
    // Store the initial positions of all selected shapes
    const initialPositions = [];
    const shapesToStore = e.shiftKey ? 
      [...selectedShapeIndices, index].filter((v, i, a) => a.indexOf(v) === i) : 
      [index];
    
    shapesToStore.forEach(shapeIndex => {
      const shape = svgData.shapes[shapeIndex];
      let initialPosition = {};
      
      switch (shape.type) {
        case 'rect':
          initialPosition = { x: shape.x, y: shape.y };
          break;
        case 'circle':
        case 'ellipse':
          initialPosition = { cx: shape.cx, cy: shape.cy };
          break;
        case 'line':
          initialPosition = { x1: shape.x1, y1: shape.y1, x2: shape.x2, y2: shape.y2 };
          break;
        case 'text':
          initialPosition = { x: shape.x, y: shape.y };
          break;
        case 'polygon':
        case 'polyline':
          initialPosition = { points: shape.points };
          break;
        case 'path':
          initialPosition = { d: shape.d };
          break;
        default:
          break;
      }
      
      initialPositions[shapeIndex] = initialPosition;
    });
    
    setInitialShapePositions(initialPositions);
  };

  // Add svg container mouse down handler for box selection
  const handleSvgContainerMouseDown = (e) => {
    if (boxSelectMode) {
      const svgCoords = screenToSVGCoordinates(e.clientX, e.clientY);
      setDragStart(svgCoords);
      setSelectionBox({
        x: svgCoords.x,
        y: svgCoords.y,
        width: 0,
        height: 0,
      });
      setDragging(true);
    } else if (!e.target.closest('circle, rect, ellipse, line, polygon, polyline, text, path')) {
      // If clicking empty area and not in box select mode, clear selection
      setSelectedShapeIndices([]);
    }
  };

  // Add the missing handleResizeHandleMouseDown function
  const handleResizeHandleMouseDown = (handle, shapeIndex, e) => {
    e.stopPropagation();
    setResizing(true);
    setResizeHandle(handle);
    
    // Only resize the specific shape that was clicked
    if (!e.shiftKey) {
      setSelectedShapeIndices([shapeIndex]);
    }
    
    // Get SVG coordinates of the mouse position
    const svgCoords = screenToSVGCoordinates(e.clientX, e.clientY);
    setDragStart(svgCoords);
    setInitialDragPosition(svgCoords);
  };

  // Modify handleMouseMove to update selection box
  const handleMouseMove = (e) => {
    // Get current mouse position in SVG coordinates
    const currentSVGCoords = screenToSVGCoordinates(e.clientX, e.clientY);
    
    if (boxSelectMode && dragging && selectionBox) {
      // Update selection box size
      const width = currentSVGCoords.x - dragStart.x;
      const height = currentSVGCoords.y - dragStart.y;
      
      // Calculate normalized box (handle negative width/height)
      const x = width < 0 ? dragStart.x + width : dragStart.x;
      const y = height < 0 ? dragStart.y + height : dragStart.y;
      const absWidth = Math.abs(width);
      const absHeight = Math.abs(height);
      
      setSelectionBox({
        x,
        y,
        width: absWidth,
        height: absHeight
      });
      return;
    }
    
    if (resizing && selectedShapeIndices.length > 0) {
      const dx = currentSVGCoords.x - dragStart.x;
      const dy = currentSVGCoords.y - dragStart.y;
      
      // Resizing with multiple selection (currently only resizes first selected item)
      setSvgData((prevData) => {
        const newShapes = [...prevData.shapes];
        
        // Apply resize to all selected shapes
        selectedShapeIndices.forEach(selectedIndex => {
          const shape = { ...newShapes[selectedIndex] };
          
          // Handle resizing based on shape type
          if (shape.type === 'rect') {
            switch (resizeHandle) {
              case 'tl': // top-left
                shape.x += dx;
                shape.y += dy;
                shape.width -= dx;
                shape.height -= dy;
                break;
              case 'tr': // top-right
                shape.y += dy;
                shape.width += dx;
                shape.height -= dy;
                break;
              case 'bl': // bottom-left
                shape.x += dx;
                shape.width -= dx;
                shape.height += dy;
                break;
              case 'br': // bottom-right
                shape.width += dx;
                shape.height += dy;
                break;
            }
            
            // Ensure width and height don't go negative
            if (shape.width < 0) {
              shape.x += shape.width;
              shape.width = Math.abs(shape.width);
            }
            if (shape.height < 0) {
              shape.y += shape.height;
              shape.height = Math.abs(shape.height);
            }
          } else if (shape.type === 'circle') {
            // For circles, adjust radius
            const newRadius = shape.r + Math.max(dx, dy) / 2;
            shape.r = Math.max(1, newRadius); // Ensure radius is at least 1
          } else if (shape.type === 'ellipse') {
            // For ellipses, adjust rx and ry
            switch (resizeHandle) {
              case 'tl': case 'bl': // left handles
                shape.rx = Math.max(1, shape.rx - dx / 2);
                break;
              case 'tr': case 'br': // right handles
                shape.rx = Math.max(1, shape.rx + dx / 2);
                break;
            }
            
            switch (resizeHandle) {
              case 'tl': case 'tr': // top handles
                shape.ry = Math.max(1, shape.ry - dy / 2);
                break;
              case 'bl': case 'br': // bottom handles
                shape.ry = Math.max(1, shape.ry + dy / 2);
                break;
            }
          }
          
          newShapes[selectedIndex] = shape;
        });
        
        return { ...prevData, shapes: newShapes };
      });
      
      setDragStart(currentSVGCoords);
    } else if (dragging && selectedShapeIndices.length > 0 && !resizing) {
      // Calculate exact displacement in SVG coordinates
      const totalDx = currentSVGCoords.x - initialDragPosition.x;
      const totalDy = currentSVGCoords.y - initialDragPosition.y;
      
      setSvgData((prevData) => {
        const newShapes = [...prevData.shapes];
        
        // Apply movement to all selected shapes
        selectedShapeIndices.forEach(selectedIndex => {
          const shape = { ...newShapes[selectedIndex] };
          const initialPos = initialShapePositions[selectedIndex];

          if (!initialPos) return; // Skip if no initial position stored
          
          switch (shape.type) {
            case 'rect':
              shape.x = initialPos.x + totalDx;
              shape.y = initialPos.y + totalDy;
              break;
            case 'circle':
            case 'ellipse':
              shape.cx = initialPos.cx + totalDx;
              shape.cy = initialPos.cy + totalDy;
              break;
            case 'line':
              shape.x1 = initialPos.x1 + totalDx;
              shape.y1 = initialPos.y1 + totalDy;
              shape.x2 = initialPos.x2 + totalDx;
              shape.y2 = initialPos.y2 + totalDy;
              break;
            case 'text':
              shape.x = initialPos.x + totalDx;
              shape.y = initialPos.y + totalDy;
              break;
            case 'polygon':
            case 'polyline':
              // Calculate from original points
              shape.points = initialPos.points
                .split(' ')
                .map((point) => {
                  const [px, py] = point.split(',').map(Number);
                  return `${px + totalDx},${py + totalDy}`;
                })
                .join(' ');
              break;
            case 'path':
              // Path dragging is complex; we skip it for now
              break;
            default:
              break;
          }

          newShapes[selectedIndex] = shape;
        });
        
        return { ...prevData, shapes: newShapes };
      });

      // For screen updates
      setDragStart(currentSVGCoords);
    }
  };

  // Modify handleMouseUp to finalize box selection
  const handleMouseUp = (e) => {
    if (boxSelectMode && dragging && selectionBox) {
      // Finalize box selection
      const selectedIndices = [];
      
      // Normalized selection box
      const selectBox = {
        left: selectionBox.x,
        right: selectionBox.x + selectionBox.width,
        top: selectionBox.y,
        bottom: selectionBox.y + selectionBox.height
      };

      // Process shapes from top to bottom
      for (let index = svgData.shapes.length - 1; index >= 0; index--) {
        const shape = svgData.shapes[index];
        
        // Get bounding box of the shape
        let shapeBounds;
        
        switch (shape.type) {
          case 'rect':
            shapeBounds = {
              left: shape.x,
              right: shape.x + shape.width,
              top: shape.y,
              bottom: shape.y + shape.height
            };
            break;
            
          case 'circle':
            shapeBounds = {
              left: shape.cx - shape.r,
              right: shape.cx + shape.r,
              top: shape.cy - shape.r,
              bottom: shape.cy + shape.r
            };
            break;
            
          case 'ellipse':
            shapeBounds = {
              left: shape.cx - shape.rx,
              right: shape.cx + shape.rx,
              top: shape.cy - shape.ry,
              bottom: shape.cy + shape.ry
            };
            break;
            
          case 'line':
            shapeBounds = {
              left: Math.min(shape.x1, shape.x2),
              right: Math.max(shape.x1, shape.x2),
              top: Math.min(shape.y1, shape.y2),
              bottom: Math.max(shape.y1, shape.y2)
            };
            break;
            
          case 'text':
            shapeBounds = {
              left: shape.x,
              right: shape.x + (shape.fontSize ? parseFloat(shape.fontSize) * 10 : 100),
              top: shape.y - (shape.fontSize ? parseFloat(shape.fontSize) : 20),
              bottom: shape.y + 5
            };
            break;
            
          case 'polygon':
          case 'polyline':
            const vertices = shape.points.split(' ').map(p => {
              const [x, y] = p.split(',').map(Number);
              return { x, y };
            });
            
            if (vertices.length === 0) continue;
            
            const xValues = vertices.map(p => p.x);
            const yValues = vertices.map(p => p.y);
            
            shapeBounds = {
              left: Math.min(...xValues),
              right: Math.max(...xValues),
              top: Math.min(...yValues),
              bottom: Math.max(...yValues)
            };
            break;
            
          case 'path':
            // For paths, use a rough estimation based on available data
            const pathString = shape.d || '';
            const numbers = pathString.split(/[^-.\d]/).filter(s => s.match(/^-?\d+(\.\d+)?$/)).map(Number);
            
            if (numbers.length >= 2) {
              const pairs = [];
              for (let i = 0; i < numbers.length - 1; i += 2) {
                pairs.push({ x: numbers[i], y: numbers[i+1] });
              }
              
              const pathXValues = pairs.map(p => p.x);
              const pathYValues = pairs.map(p => p.y);
              
              shapeBounds = {
                left: Math.min(...pathXValues),
                right: Math.max(...pathXValues),
                top: Math.min(...pathYValues),
                bottom: Math.max(...pathYValues)
              };
            }
            break;
            
          default:
            continue;
        }

        // If we have valid bounds and they intersect with the selection box
        if (shapeBounds &&
            shapeBounds.right >= selectBox.left &&
            shapeBounds.left <= selectBox.right &&
            shapeBounds.bottom >= selectBox.top &&
            shapeBounds.top <= selectBox.bottom) {
          
          // Check if this shape is visually accessible (not completely covered)
          let isVisible = false;
          
          // Check corners and center point
          const testPoints = [
            { x: (shapeBounds.left + shapeBounds.right) / 2, y: (shapeBounds.top + shapeBounds.bottom) / 2 }, // center
            { x: shapeBounds.left, y: shapeBounds.top }, // top-left
            { x: shapeBounds.right, y: shapeBounds.top }, // top-right
            { x: shapeBounds.left, y: shapeBounds.bottom }, // bottom-left
            { x: shapeBounds.right, y: shapeBounds.bottom } // bottom-right
          ];

          for (const point of testPoints) {
            // Check if this point is within selection box
            if (point.x >= selectBox.left && point.x <= selectBox.right &&
                point.y >= selectBox.top && point.y <= selectBox.bottom) {
              // Check if any shape above this one covers this point
              let isCovered = false;
              for (let aboveIndex = svgData.shapes.length - 1; aboveIndex > index; aboveIndex--) {
                if (selectedIndices.includes(aboveIndex)) {
                  const aboveShape = svgData.shapes[aboveIndex];
                  if (isPointInsideShape(point, aboveShape)) {
                    isCovered = true;
                    break;
                  }
                }
              }
              if (!isCovered) {
                isVisible = true;
                break;
              }
            }
          }

          if (isVisible) {
            selectedIndices.push(index);
          }
        }
      }
      
      // Update selection
      if (e.shiftKey) {
        // Add to existing selection
        setSelectedShapeIndices(prev => {
          const combined = [...prev, ...selectedIndices];
          return [...new Set(combined)]; // Remove duplicates
        });
      } else {
        // Replace selection
        setSelectedShapeIndices(selectedIndices);
      }
      
      // Clear selection box
      setSelectionBox(null);
    }
    
    if (dragging || resizing) {
      setDragging(false);
      setResizing(false);
      setResizeHandle(null);
      updateSVGCode();
    }
  };

  // Helper function to check if a point is inside a shape
  const isPointInsideShape = (point, shape) => {
    switch (shape.type) {
      case 'rect':
        return point.x >= shape.x && point.x <= shape.x + shape.width &&
               point.y >= shape.y && point.y <= shape.y + shape.height;
        
      case 'circle':
        const dx = point.x - shape.cx;
        const dy = point.y - shape.cy;
        return dx * dx + dy * dy <= shape.r * shape.r;
        
      case 'ellipse':
        const ex = (point.x - shape.cx) / shape.rx;
        const ey = (point.y - shape.cy) / shape.ry;
        return ex * ex + ey * ey <= 1;
        
      case 'polygon':
        // Point in polygon test
        const vertices = shape.points.split(' ').map(p => {
          const [x, y] = p.split(',').map(Number);
          return { x, y };
        });
        return isPointInPolygon(point, vertices);
        
      default:
        // For other shapes, use bounding box as approximation
        const bounds = getShapeBounds(shape);
        return bounds ? (
          point.x >= bounds.left && point.x <= bounds.right &&
          point.y >= bounds.top && point.y <= bounds.bottom
        ) : false;
    }
  };

  // Helper function for point in polygon test
  const isPointInPolygon = (point, vertices) => {
    let inside = false;
    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
      const xi = vertices[i].x, yi = vertices[i].y;
      const xj = vertices[j].x, yj = vertices[j].y;
      
      const intersect = ((yi > point.y) !== (yj > point.y))
          && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  };

  // Helper function to get shape bounds
  const getShapeBounds = (shape) => {
    switch (shape.type) {
      case 'rect':
        return {
          left: shape.x,
          right: shape.x + shape.width,
          top: shape.y,
          bottom: shape.y + shape.height
        };
      // Add other cases as needed
      default:
        return null;
    }
  };

  const updateSVGCode = () => {
    const { viewBox, shapes } = svgData;
    const svgElements = shapes.map((shape) => {
      switch (shape.type) {
        case 'rect':
          return `<rect x="${shape.x}" y="${shape.y}" width="${shape.width}" height="${shape.height}" fill="${shape.fill}" />`;
        case 'circle':
          return `<circle cx="${shape.cx}" cy="${shape.cy}" r="${shape.r}" fill="${shape.fill}" />`;
        case 'ellipse':
          return `<ellipse cx="${shape.cx}" cy="${shape.cy}" rx="${shape.rx}" ry="${shape.ry}" fill="${shape.fill}" />`;
        case 'line':
          return `<line x1="${shape.x1}" y1="${shape.y1}" x2="${shape.x2}" y2="${shape.y2}" stroke="${shape.stroke}" stroke-width="${shape.strokeWidth}" />`;
        case 'polygon':
          return `<polygon points="${shape.points}" fill="${shape.fill}" />`;
        case 'polyline':
          return `<polyline points="${shape.points}" fill="${shape.fill}" />`;
        case 'text':
          return `<text x="${shape.x}" y="${shape.y}" fill="${shape.fill}" font-size="${shape.fontSize}" font-family="${shape.fontFamily}">${shape.text}</text>`;
        case 'path':
          return `<path d="${shape.d}" fill="${shape.fill}" />`;
        case 'triangle': // Support for triangle
          return `<polygon points="${shape.points}" fill="${shape.fill}" />`;
        default:
          return shape.outerHTML || '';
      }
    });

    const svgString = `<svg viewBox="${viewBox.join(' ')}" xmlns="http://www.w3.org/2000/svg">\n${svgElements.join('\n')}\n</svg>`;
    
    // If we have React component structure, embed SVG in it
    if (reactComponentStructure.before || reactComponentStructure.after) {
      setCodeText(`${reactComponentStructure.before}${svgString}${reactComponentStructure.after}`);
    } else {
      setCodeText(svgString);
    }
  };

  // Helper function to get resize handle positions for a shape
  const getResizeHandles = (shape) => {
    switch (shape.type) {
      case 'rect':
        return [
          { id: 'tl', x: shape.x, y: shape.y },  // top-left
          { id: 'tr', x: shape.x + shape.width, y: shape.y },  // top-right
          { id: 'bl', x: shape.x, y: shape.y + shape.height },  // bottom-left
          { id: 'br', x: shape.x + shape.width, y: shape.y + shape.height },  // bottom-right
        ];
      case 'circle':
        return [
          { id: 'tl', x: shape.cx - shape.r, y: shape.cy - shape.r },
          { id: 'tr', x: shape.cx + shape.r, y: shape.cy - shape.r },
          { id: 'bl', x: shape.cx - shape.r, y: shape.cy + shape.r },
          { id: 'br', x: shape.cx + shape.r, y: shape.cy + shape.r },
        ];
      case 'ellipse':
        return [
          { id: 'tl', x: shape.cx - shape.rx, y: shape.cy - shape.ry },
          { id: 'tr', x: shape.cx + shape.rx, y: shape.cy - shape.ry },
          { id: 'bl', x: shape.cx - shape.rx, y: shape.cy + shape.ry },
          { id: 'br', x: shape.cx + shape.rx, y: shape.cy + shape.ry },
        ];
      default:
        return [];
    }
  };

  // Handle move up button
  const handleMoveUp = () => {
    if (selectedShapeIndices.length > 0 && Math.min(...selectedShapeIndices) > 0) {
      setSvgData((prevData) => {
        const newShapes = [...prevData.shapes];
        // Swap the selected shape with the one above it
        const minIndex = Math.min(...selectedShapeIndices);
        [newShapes[minIndex], newShapes[minIndex - 1]] = 
        [newShapes[minIndex - 1], newShapes[minIndex]];
        return { ...prevData, shapes: newShapes };
      });
      // Update selected shape indices
      setSelectedShapeIndices(prev => [...prev.filter(i => i !== 0), 0]);
      updateSVGCode();
    }
  };

  // Handle move down button
  const handleMoveDown = () => {
    if (selectedShapeIndices.length > 0 && Math.max(...selectedShapeIndices) < svgData.shapes.length - 1) {
      setSvgData((prevData) => {
        const newShapes = [...prevData.shapes];
        // Swap the selected shape with the one below it
        const maxIndex = Math.max(...selectedShapeIndices);
        [newShapes[maxIndex], newShapes[maxIndex + 1]] = 
        [newShapes[maxIndex + 1], newShapes[maxIndex]];
        return { ...prevData, shapes: newShapes };
      });
      // Update selected shape indices
      setSelectedShapeIndices(prev => [...prev.filter(i => i !== svgData.shapes.length - 1), svgData.shapes.length - 1]);
      updateSVGCode();
    }
  };

  // Handle color change
  const handleColorChange = (color) => {
    setCurrentColor(color.hex);
    if (selectedShapeIndices.length > 0) {
      setSvgData((prevData) => {
        const newShapes = [...prevData.shapes];
        
        // Update the fill color of all selected shapes
        selectedShapeIndices.forEach(selectedIndex => {
          const shape = { ...newShapes[selectedIndex] };
          shape.fill = color.hex;
          newShapes[selectedIndex] = shape;
        });
        
        return { ...prevData, shapes: newShapes };
      });
      updateSVGCode();
    }
  };

  // Handle delete button
  const handleDelete = () => {
    if (selectedShapeIndices.length > 0) {
      setSvgData((prevData) => {
        const newShapes = [...prevData.shapes];
        // Remove the selected shapes in reverse order to avoid index shifting issues
        [...selectedShapeIndices].sort((a, b) => b - a).forEach(index => {
          newShapes.splice(index, 1);
        });
        return { ...prevData, shapes: newShapes };
      });
      setSelectedShapeIndices([]);
      updateSVGCode();
    }
  };

  // Handle duplicate button
  const handleDuplicate = () => {
    if (selectedShapeIndices.length > 0) {
      setSvgData((prevData) => {
        const newShapes = [...prevData.shapes];
        let newIndices = [];
        let offset = 0;
        
        // Duplicate each selected shape
        selectedShapeIndices.forEach(selectedIndex => {
          // Create a deep copy of the selected shape
          const originalShape = { ...newShapes[selectedIndex] };
          let duplicatedShape = { ...originalShape };
          
          // Add a small offset to make the duplicate visible
          switch (duplicatedShape.type) {
            case 'rect':
              duplicatedShape.x += 10;
              duplicatedShape.y += 10;
              break;
            case 'circle':
            case 'ellipse':
              duplicatedShape.cx += 10;
              duplicatedShape.cy += 10;
              break;
            case 'line':
              duplicatedShape.x1 += 10;
              duplicatedShape.y1 += 10;
              duplicatedShape.x2 += 10;
              duplicatedShape.y2 += 10;
              break;
            case 'text':
              duplicatedShape.x += 10;
              duplicatedShape.y += 10;
              break;
            case 'polygon':
            case 'polyline':
              // Offset points
              duplicatedShape.points = originalShape.points
                .split(' ')
                .map((point) => {
                  const [px, py] = point.split(',').map(Number);
                  return `${px + 10},${py + 10}`;
                })
                .join(' ');
              break;
            case 'path':
              // Paths are complex to offset, so we keep it as is for now
              break;
            default:
              break;
          }
          
          // Insert the duplicate after the original (accounting for previously inserted duplicates)
          const insertIndex = selectedIndex + 1 + offset;
          newShapes.splice(insertIndex, 0, duplicatedShape);
          newIndices.push(insertIndex);
          offset++;
        });
        
        return { ...prevData, shapes: newShapes };
      });
      
      // Update selection to the duplicated shapes
      updateSVGCode();
    }
  };

  // Toggle color picker
  const toggleColorPicker = () => {
    if (selectedShapeIndices.length > 0) {
      // Set current color to the first selected shape's fill color
      const shape = svgData.shapes[selectedShapeIndices[0]];
      if (shape && shape.fill) {
        setCurrentColor(shape.fill);
      }
      setShowColorPicker(!showColorPicker);
    } else {
      alert("Please select a shape first");
    }
  };

  // Add function to cycle through display modes
  const cycleDisplayMode = () => {
    setDisplayMode(currentMode => {
      switch (currentMode) {
        case 'Light': return 'Shadow';
        case 'Shadow': return 'Both';
        case 'Both': return 'None';
        case 'None': return 'Light';
        default: return 'Light';
      }
    });
  };

  // Add this new function to convert shapes to polygon
  const handleConvertToPolygon = () => {
    if (selectedShapeIndices.length === 0) {
      alert("Please select one or more shapes to convert");
      return;
    }
    
    setSvgData((prevData) => {
      const newShapes = [...prevData.shapes];
      
      selectedShapeIndices.forEach(index => {
        const shape = { ...newShapes[index] };
        
        // Convert based on shape type
        if (shape.type === 'rect') {
          // Convert rectangle to polygon (4 points)
          const x = shape.x;
          const y = shape.y;
          const width = shape.width;
          const height = shape.height;
          
          const points = `${x},${y} ${x + width},${y} ${x + width},${y + height} ${x},${y + height}`;
          
          // Create new polygon shape
          newShapes[index] = {
            type: 'polygon',
            points,
            fill: shape.fill,
            opacity: shape.opacity,
            stroke: shape.stroke,
            strokeWidth: shape.strokeWidth
          };
        } 
        else if (shape.type === 'circle') {
          // Convert circle to polygon approximation (12 points)
          const cx = shape.cx;
          const cy = shape.cy;
          const r = shape.r;
          const numPoints = 12;
          
          let points = '';
          for (let i = 0; i < numPoints; i++) {
            const angle = (i / numPoints) * 2 * Math.PI;
            const x = cx + r * Math.cos(angle);
            const y = cy + r * Math.sin(angle);
            points += `${x},${y} `;
          }
          
          // Create new polygon shape
          newShapes[index] = {
            type: 'polygon',
            points: points.trim(),
            fill: shape.fill,
            opacity: shape.opacity,
            stroke: shape.stroke,
            strokeWidth: shape.strokeWidth
          };
        }
        else if (shape.type === 'ellipse') {
          // Convert ellipse to polygon approximation (16 points)
          const cx = shape.cx;
          const cy = shape.cy;
          const rx = shape.rx;
          const ry = shape.ry;
          const numPoints = 16;
          
          let points = '';
          for (let i = 0; i < numPoints; i++) {
            const angle = (i / numPoints) * 2 * Math.PI;
            const x = cx + rx * Math.cos(angle);
            const y = cy + ry * Math.sin(angle);
            points += `${x},${y} `;
          }
          
          // Create new polygon shape
          newShapes[index] = {
            type: 'polygon',
            points: points.trim(),
            fill: shape.fill,
            opacity: shape.opacity,
            stroke: shape.stroke,
            strokeWidth: shape.strokeWidth
          };
        }
      });
      
      return { ...prevData, shapes: newShapes };
    });
    
    // Update SVG code after conversion
    updateSVGCode();
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-white">
      {/* Header Section with SVG Banner and Date/Time */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4 shadow-md">
        {/* Title */}
        <h1 className="text-3xl font-bold text-center">SVG to Slide Converter</h1>
        {/* Current Date and Time */}
        <div className="mt-2 text-xs font-bold text-gray-600">
          {new Date().toLocaleString()}
        </div>
      </div>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ height: '96px', width: '100%', objectFit: 'cover' }}
        />
      </div>

      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute text-3xl text-gray-500 top-4 right-4 hover:text-gray-700"
        aria-label="Close Interactive"
      >
        <FaTimes />
      </button>

      {/* Shape Control Buttons */}
      <div className="flex flex-wrap gap-2 px-6 pt-6">
        <button
          onClick={handleMoveUp}
          disabled={selectedShapeIndices.length === 0 || Math.min(...selectedShapeIndices) === 0}
          className="px-3 py-1 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Move Up
        </button>
        <button
          onClick={handleMoveDown}
          disabled={selectedShapeIndices.length === 0 || Math.max(...selectedShapeIndices) === svgData.shapes.length - 1}
          className="px-3 py-1 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Move Down
        </button>
        <button
          onClick={toggleColorPicker}
          disabled={selectedShapeIndices.length === 0}
          className="px-3 py-1 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Colour
        </button>
        <button
          onClick={handleDelete}
          disabled={selectedShapeIndices.length === 0}
          className="px-3 py-1 text-sm font-medium text-white bg-red-500 rounded hover:bg-red-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Delete
        </button>
        <button
          onClick={handleDuplicate}
          disabled={selectedShapeIndices.length === 0}
          className="px-3 py-1 text-sm font-medium text-white bg-purple-500 rounded hover:bg-purple-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Duplicate
        </button>
        {/* Add Convert to Polygon button */}
        <button
          onClick={handleConvertToPolygon}
          disabled={selectedShapeIndices.length === 0}
          className="px-3 py-1 text-sm font-medium text-white bg-orange-500 rounded hover:bg-orange-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Convert to Polygon
        </button>
        {/* Add BoxSelect button */}
        <button
          onClick={toggleBoxSelectMode}
          className={`px-3 py-1 text-sm font-medium text-white rounded ${
            boxSelectMode ? 'bg-yellow-600 hover:bg-yellow-700' : 'bg-yellow-500 hover:bg-yellow-600'
          }`}
        >
          BoxSelect {boxSelectMode ? '(On)' : ''}
        </button>
        
        {/* New Display Mode Button */}
        <button
          onClick={cycleDisplayMode}
          className="px-3 py-1 text-sm font-medium text-white bg-indigo-500 rounded hover:bg-indigo-600"
        >
          Display: {displayMode}
        </button>
        
        {/* Color Picker */}
        {showColorPicker && (
          <div className="absolute z-10 p-2 mt-2 bg-white rounded-md shadow-lg" style={{ top: '200px' }}>
            <div className="flex justify-end mb-1">
              <button 
                onClick={() => setShowColorPicker(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                X
              </button>
            </div>
            <div 
              className="color-picker-container"
              style={{ 
                width: '220px',
                // The following assumes you're using a color picker library 
                // If not, you'll need to implement your own color picker UI
              }}
            >
              {/* Color spectrum with hue, saturation, and brightness */}
              <div className="mb-2">
                {/* Hue slider */}
                <input 
                  type="range" 
                  min="0" 
                  max="360" 
                  className="w-full"
                  onChange={(e) => {
                    const h = e.target.value;
                    // Convert HSL to hex and update color
                    // This is a simplified example - you'd need proper color conversion
                    const newColor = `hsl(${h}, 100%, 50%)`;
                    handleColorChange({ hex: newColor });
                  }}
                />
                
                {/* Color grid for selection */}
                <div 
                  className="grid grid-cols-8 gap-1 mt-2"
                  style={{ width: '100%', height: '120px' }}
                >
                  {/* Basic color palette */}
                  {["#ff0000", "#ff8000", "#ffff00", "#80ff00", "#00ff00", 
                    "#00ff80", "#00ffff", "#0080ff", "#0000ff", "#8000ff", 
                    "#ff00ff", "#ff0080", "#000000", "#444444", "#888888", 
                    "#cccccc", "#ffffff"].map((color, i) => (
                    <div 
                      key={i}
                      style={{
                        backgroundColor: color,
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                        border: currentColor === color ? '2px solid black' : '1px solid #ddd'
                      }}
                      onClick={() => handleColorChange({ hex: color })}
                    />
                  ))}
                </div>
                
                {/* Current color preview */}
                <div className="flex items-center mt-2">
                  <div 
                    style={{
                      backgroundColor: currentColor,
                      width: '30px',
                      height: '30px',
                      border: '1px solid #ddd'
                    }}
                  />
                  <input 
                    type="text"
                    value={currentColor}
                    className="px-2 py-1 ml-2 border rounded"
                    onChange={(e) => handleColorChange({ hex: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Text Entry Field and Submit Button */}
      <div className="flex px-6 pt-4 pb-2">
        <input
          type="text"
          placeholder="Enter text here..."
          className="flex-grow p-2 mr-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500"
          value={inputText}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        <button
          className="px-4 py-2 font-bold text-white transition-colors duration-200 bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline disabled:bg-blue-300"
          onClick={handleSubmit}
          disabled={!inputText.trim()}
        >
          Submit
        </button>
      </div>

      {/* Main Content Area - Now just the left canvas */}
      <div className="flex flex-grow overflow-auto bg-gray-100">
        <div className="w-full p-6 pt-0">
          <div 
            ref={leftCanvasRef} 
            className="bg-white rounded-lg shadow-md interactive-container"
            style={{ 
              position: 'relative', 
              width: '100%', 
              height: '600px', 
              background: '#eef',
              overflow: 'hidden'
            }}
          >
            {/* Display SVG content if available, otherwise show submitted text or empty message */}
            <div className="flex flex-col items-center justify-center h-full">
              {svgContent ? (
                <div
                  className="w-full h-full"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    padding: '20px',
                    cursor: boxSelectMode ? 'crosshair' : 'default'
                  }}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                  onMouseDown={handleSvgContainerMouseDown}
                >
                  <svg
                    ref={svgRef}
                    viewBox={svgData.viewBox.join(' ')}
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                  >
                    {svgData.shapes.map((shape, index) => {
                      const isSelected = selectedShapeIndices.includes(index);
                      const commonProps = {
                        key: index,
                        onMouseDown: (e) => handleMouseDown(index, e),
                        style: {
                          cursor: boxSelectMode ? 'crosshair' : 'move',
                          stroke: isSelected ? 'blue' : shape.stroke,
                          strokeWidth: isSelected ? 2 : shape.strokeWidth,
                        },
                      };

                      // Render the appropriate shape
                      let shapeElement;
                      switch (shape.type) {
                        case 'rect':
                          shapeElement = <rect {...commonProps} x={shape.x} y={shape.y} width={shape.width} height={shape.height} fill={shape.fill} />;
                          break;
                        case 'circle':
                          shapeElement = <circle {...commonProps} cx={shape.cx} cy={shape.cy} r={shape.r} fill={shape.fill} />;
                          break;
                        case 'ellipse':
                          shapeElement = <ellipse {...commonProps} cx={shape.cx} cy={shape.cy} rx={shape.rx} ry={shape.ry} fill={shape.fill} />;
                          break;
                        case 'line':
                          shapeElement = <line {...commonProps} x1={shape.x1} y1={shape.y1} x2={shape.x2} y2={shape.y2} stroke={shape.stroke} strokeWidth={shape.strokeWidth} />;
                          break;
                        case 'polygon':
                          shapeElement = <polygon {...commonProps} points={shape.points} fill={shape.fill} />;
                          break;
                        case 'polyline':
                          shapeElement = <polyline {...commonProps} points={shape.points} fill={shape.fill} />;
                          break;
                        case 'text':
                          shapeElement = <text {...commonProps} x={shape.x} y={shape.y} fill={shape.fill} fontSize={shape.fontSize} fontFamily={shape.fontFamily}>{shape.text}</text>;
                          break;
                        case 'path':
                          shapeElement = <path {...commonProps} d={shape.d} fill={shape.fill} />;
                          break;
                        default:
                          shapeElement = null;
                      }
                      
                      // Only render resize handles for selected shapes
                      const resizeHandles = isSelected ? 
                        getResizeHandles(shape).map(handle => (
                          <circle
                            key={`resize-${handle.id}`}
                            cx={handle.x}
                            cy={handle.y}
                            r={5}
                            fill="orange"
                            stroke="white"
                            strokeWidth={1}
                            style={{ cursor: 'pointer' }}
                            onMouseDown={(e) => handleResizeHandleMouseDown(handle.id, index, e)}
                          />
                        )) : [];
                      
                      return (
                        <g key={`shape-group-${index}`}>
                          {shapeElement}
                          {resizeHandles}
                        </g>
                      );
                    })}
                    
                    {/* Render selection box if in box select mode and dragging */}
                    {boxSelectMode && selectionBox && (
                      <rect
                        x={selectionBox.x}
                        y={selectionBox.y}
                        width={selectionBox.width}
                        height={selectionBox.height}
                        fill="rgba(0, 0, 255, 0.1)"
                        stroke="blue"
                        strokeWidth="1"
                        strokeDasharray="5,5"
                        pointerEvents="none"
                      />
                    )}
                  </svg>
                </div>
              ) : submittedText ? (
                <div className="p-4 text-center">
                  <p className="text-2xl font-semibold text-blue-800">{submittedText}</p>
                </div>
              ) : (
                <p className="text-lg text-gray-500">SVG Canvas Area</p>
              )}
            </div>
          </div>
          
          {/* Code Display Area */}
          <div className="mt-4">
            <h3 className="mb-2 text-lg font-semibold text-gray-700">SVG Code</h3>
            <textarea
              className="w-full p-3 font-mono text-sm text-green-400 bg-gray-800 rounded-md shadow-inner"
              rows="10"
              placeholder="// Paste SVG code here"
              value={codeText}
              onChange={handleCodeChange}
              style={{
                overflowY: 'auto',
                resize: 'none',
                lineHeight: '1.5',
                tabSize: '2'
              }}
            ></textarea>
            
            {/* Add a button to copy the SVG code */}
            {codeText && (
              <button
                className="px-4 py-2 mt-2 font-medium text-white bg-green-500 rounded hover:bg-green-600"
                onClick={() => {
                  navigator.clipboard.writeText(codeText);
                  alert('SVG code copied to clipboard!');
                }}
              >
                Copy SVG Code
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SlideComponents.propTypes = {
  onClose: PropTypes.func.isRequired,
  classID: PropTypes.string.isRequired
};

export default SlideComponents;