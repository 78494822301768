import React, { useMemo, useRef, useCallback } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import PropTypes from 'prop-types';

const Saturn = ({ timelapse = 0 }) => {
  const saturnShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          vUv = uv;
          vNormal = normal;
          vPosition = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        const float PI = 3.14159265359;

        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - 1.0 * f);
          return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.96, 0.64, 0.38); // Sandy color for Saturn
          
          // Calculate angle around the sphere for seamless waves
          float angle = atan(vPosition.z, vPosition.x);
          // Using multiples of 2PI to ensure complete cycles
          float wave1 = sin(angle * 8.0 + time * 0.5) * 0.05;  // 8 complete waves
          float wave2 = sin(angle * 6.0 + time * 0.3 + PI * 2.7) * 0.03;  // 6 complete waves
          float wave3 = sin(angle * 4.0 - time * 0.4 + PI * 1.8) * 0.02;  // 4 complete waves
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          float bands = noise(vec2(modifiedY * 1.0, time * 0.1)) * 0.7;
          bands += noise(vec2(modifiedY * 25.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          
          // Create two special layers with the new color (#07AEA7)
          // and add middle stripes with #09B7CF
          if (modifiedY > 0.6 && modifiedY < 0.7) {
              if (modifiedY > 0.63 && modifiedY < 0.67) {
                  bandColor = mix(
                      vec3(0.353, 0.424, 0.898),  // #5A6CE5
                      vec3(0.98, 0.84, 0.65),     // Light cream
                      bands
                  );
              } else {
                  bandColor = mix(
                      vec3(0.027, 0.682, 0.655),  // #07AEA7
                      vec3(0.98, 0.84, 0.65),     // Light cream
                      bands
                  );
              }
          } else if (modifiedY > 0.3 && modifiedY < 0.4) {
              if (modifiedY > 0.33 && modifiedY < 0.37) {
                  bandColor = mix(
                      vec3(0.353, 0.424, 0.898),  // #5A6CE5
                      vec3(0.98, 0.84, 0.65),     // Light cream
                      bands
                  );
              } else {
                  bandColor = mix(
                      vec3(0.027, 0.682, 0.655),  // #07AEA7
                      vec3(0.98, 0.84, 0.65),     // Light cream
                      bands
                  );
              }
          } else {
              bandColor = mix(
                  vec3(0.82, 0.53, 0.28),     // Original darker orange-brown
                  vec3(0.98, 0.84, 0.65),     // Light cream
                  bands
              );
          }
          
          // Add some golden and reddish tints
          bandColor *= mix(
              vec3(1.0, 0.9, 0.7),     // Golden tint
              vec3(1.0, 0.85, 0.8),    // Slight reddish tint
              sin(modifiedY * 8.0) * 0.5 + 0.5
          );
          
          vec3 finalColor = mix(bandColor, baseColor, 0.2);
          
          // New gradient shadow logic (based on Earth's shader gradient)
          // Note: The multiplication factor (2.0) and thresholds
          // have been adjusted slightly to fit Saturn's larger scale.
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct < 0.9) {
            float angle = acos(-dotProduct) * 2.0;
            if (angle < 3.3) {
              float shadowStrength = smoothstep(3.3, 3.0, angle);
              shadowStrength = pow(shadowStrength, 1.5);
              finalColor = mix(finalColor, vec3(0.0), 0.45 * shadowStrength);
            }
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  // --- NEW ATMOSPHERE SETUP ---
  const { camera } = useThree();
  const saturnAtmosphereGroupRef = useRef();

  // Saturn surface radius is 58232. We use similar margins as Neptune:
  // Inner Atmosphere: ~1.53% larger, Outer Atmosphere: ~4.8% larger.
  const saturnSurfaceRadius = 58232;
  const saturnInnerAtmosphereRadius = saturnSurfaceRadius * 1.0153;
  const saturnOuterAtmosphereRadius = saturnSurfaceRadius * 1.04785;

  // Create the Saturn atmosphere shader using Neptune's atmosphere shader code.
  const saturnAtmosphereShader = useMemo(() => {
    return {
      uniforms: {
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec3 vPosition;
        varying vec3 vNormal;
        
        void main() {
          vPosition = position;
          vNormal = normal;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec3 vPosition;
        varying vec3 vNormal;
        uniform mat4 modelMatrix;
        
        float easeInOutPower(float x) {
          return x < 0.5 ? 4.0 * x * x * x : 1.0 - pow(-2.0 * x + 2.0, 3.0) / 2.0;
        }
        
        void main() {
          vec4 skyColor = vec4(0.53, 0.81, 0.92, 0.0);
          vec4 finalColor = skyColor;
          
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct > -0.9) {
            float angle = acos(dotProduct) * 2.0;
            if (angle < 3.0) {
              finalColor = vec4(0.25, 0.61, 0.85, 0.5);
            } else if (angle < 3.9) {
              float gradient = smoothstep(3.9, 3.0, angle);
              gradient = easeInOutPower(gradient);
              finalColor = mix(skyColor, vec4(0.25, 0.61, 0.85, 0.5), gradient);
            }
          }
          
          gl_FragColor = finalColor;
        }
      `
    };
  }, []);

  // Set the atmosphere visibility based on distance from the camera,
  // compensating for the larger scale of Saturn.
  useFrame(() => {
    if (saturnAtmosphereGroupRef.current) {
      const saturnPos = new THREE.Vector3();
      saturnAtmosphereGroupRef.current.getWorldPosition(saturnPos);
      const distance = camera.position.distanceTo(saturnPos);
      saturnAtmosphereGroupRef.current.children.forEach(child => {
        if (child.geometry instanceof THREE.SphereGeometry) {
          const r = child.geometry.parameters.radius;
          if (r === saturnInnerAtmosphereRadius || r === saturnOuterAtmosphereRadius) {
            child.visible = distance <= r * 2.5;
          }
        }
      });
    }
  });

  // --- REFS --- 
  // saturnGroupRef now groups all of Saturn's objects so that its rotation (or any other transform)
  // is applied uniformly.
  const saturnGroupRef = useRef();

  // Add new ref for rings
  const ringsGroupRef = useRef();

  // Add rotation animation for rings
  useFrame(() => {
    if (ringsGroupRef.current) {
      // Create a vector pointing to the sun (which is at 0,0,0)
      const toSun = new THREE.Vector3(0, 0, 0);
      // Get Saturn's world position
      const saturnPosition = new THREE.Vector3();
      ringsGroupRef.current.parent.getWorldPosition(saturnPosition);
      // Calculate direction from Saturn to Sun
      toSun.sub(saturnPosition).normalize();
      
      // Make rings face the sun while staying tilted 15 degrees
      // Using a tilted up vector to create the angle
      const tiltAngle = THREE.MathUtils.degToRad(15);
      ringsGroupRef.current.up.set(Math.sin(tiltAngle), Math.cos(tiltAngle), 0);
      ringsGroupRef.current.lookAt(new THREE.Vector3(toSun.x, 0, toSun.z));
    }
  });

  return (
    // Wrap all of Saturn's objects in a parent group so that any rotations (e.g. Saturn's spin)
    // are applied uniformly.
    <group ref={saturnGroupRef}>
      <mesh renderOrder={2}>
        <sphereGeometry args={[58232, 64, 64]} />
        <shaderMaterial 
          attach="material"
          {...saturnShader}
          side={THREE.FrontSide}
        />
      </mesh>

      {/* Remove the initial rotation since lookAt will handle it */}
      <group ref={ringsGroupRef}>
        {[...Array(8)].map((_, index) => {
          let startAngle, endAngle;
          
          if (index === 0) { // Light blue half
            startAngle = 0;
            endAngle = Math.PI;
            return (
              <mesh key={index} rotation={[Math.PI / 2, 0, 0]}>
                <ringGeometry 
                  args={[75000, 90000, 32, 1, startAngle, endAngle - startAngle]} 
                />
                <meshBasicMaterial 
                  color="#4b7CB7" 
                  transparent 
                  opacity={0.999}
                  side={THREE.DoubleSide}
                />
              </mesh>
            );
          } else if (index === 5) { // Purple half ring
            startAngle = 0;
            endAngle = Math.PI;
            return (
              <mesh key={index} rotation={[Math.PI / 2, 0, 0]}>
                <ringGeometry 
                  args={[90000, 105000, 32, 1, startAngle, endAngle - startAngle]} 
                />
                <meshBasicMaterial 
                  color="#36519E" 
                  transparent 
                  opacity={0.999}
                  side={THREE.DoubleSide}
                />
              </mesh>
            );
          } else if (index === 6 || index === 7) { // Light blue and purple half rings
            startAngle = 0;
            endAngle = Math.PI;
            const startRadius = index === 6 ? 105000 : 120000;
            return (
              <mesh key={index} rotation={[Math.PI / 2, 0, 0]}>
                <ringGeometry 
                  args={[startRadius, startRadius + 15000, 32, 1, startAngle, endAngle - startAngle]} 
                />
                <meshBasicMaterial 
                  color={index === 7 ? "#4B7CB7" : "#31B0Bf"}  // Purple for index 7, Light blue for index 6
                  transparent 
                  opacity={0.999}
                  side={THREE.DoubleSide}
                />
              </mesh>
            );
          } else if (index === 1 || index === 3) { // Green rings with shadow
            startAngle = Math.PI;
            endAngle = Math.PI * 2;
            const gradientTexture = new THREE.CanvasTexture(createGradientCanvas(
              index === 1 ? [
                { position: 0.1, color: "#4B7CB7" },
                { position: 0.2, color: "#3A5E8B" },  // Darker blue
                { position: 0.3, color: "#2A436B" },  // Even darker blue
                { position: 0.7, color: "#2A436B" },  // Even darker blue
                { position: 0.8, color: "#3A5E8B" },  // Darker blue
                { position: .9, color: "#4B7CB7" }
              ] : [
                { position: 0.1, color: "#31B0BF" },
                { position: 0.2, color: "#247D87" },  // Darker cyan
                { position: 0.3, color: "#1A5A61" }, // Even darker cyan
                { position: 0.7, color: "#1A5A61" }, // Even darker cyan
                { position: 0.8, color: "#247D87" },  // Darker cyan
                { position: .9, color: "#31B0BF" }
              ]
            ));
            // First green ring: 75000 to 90000
            // Second green ring: 105000 to 120000
            const startRadius = index === 1 ? 75000 : 105000;
            return (
              <mesh key={index} rotation={[Math.PI / 2, 0, 0]}>
                <ringGeometry args={[startRadius, startRadius + 15000, 32, 1, startAngle, endAngle - startAngle]} />
                <meshBasicMaterial 
                  map={gradientTexture}
                  transparent 
                  opacity={0.999}
                  side={THREE.DoubleSide}
                />
              </mesh>
            );
          } else if (index === 2 || index === 4) { // Orange rings with shadow
            startAngle = Math.PI;
            endAngle = Math.PI * 2;
            const gradientTexture = new THREE.CanvasTexture(createGradientCanvas(
              index === 2 ? [
                { position: 0.1, color: "#36519E" },    // Bright purple
                { position: 0.16, color: "#2A3D77" },   // Darker purple
                { position: 0.299, color: "#1E2B54" },  // Darkest purple
                { position: 0.755, color: "#1E2B54" },  // Darkest purple
                { position: 0.85, color: "#2A3D77" },   // Darker purple
                { position: .9, color: "#36519E" }      // Bright purple
              ] : [
                { position: 0.16, color: "#4B7CB7" },   // Bright blue
                { position: 0.24, color: "#385D8A" },   // Darker blue
                { position: 0.35, color: "#264061" },   // Darkest blue
                { position: 0.7, color: "#264061" },    // Darkest blue
                { position: 0.8, color: "#385D8A" },    // Darker blue
                { position: .9, color: "#4B7CB7" }      // Bright blue
              ]
            ));
            // First orange ring: 90000 to 105000
            // Second orange ring: 120000 to 135000
            const startRadius = index === 2 ? 90000 : 120000;
            return (
              <mesh key={index} rotation={[Math.PI / 2, 0, 0]}>
                <ringGeometry args={[startRadius, startRadius + 15000, 32, 1, startAngle, endAngle - startAngle]} />
                <meshBasicMaterial 
                  map={gradientTexture}
                  transparent 
                  opacity={0.999}
                  side={THREE.DoubleSide}
                />
              </mesh>
            );
          }
        })}
      </group>

      {/* Existing Inner Atmosphere layer */}
      <mesh renderOrder={-1}>
        <sphereGeometry args={[58000, 128, 640]} />
        <meshBasicMaterial 
          color="#f4d03f"
          transparent={true}
          opacity={0.0001}
          side={THREE.BackSide}
          depthWrite={true}
          depthTest={true}
        />
      </mesh>

      {/* NEW Saturn Atmosphere layers using the shader:
          These layers will only be visible when the camera is close enough */}
      <group ref={saturnAtmosphereGroupRef}>
        {/* Outer Atmosphere Layer */}
        <mesh renderOrder={1}>
          <sphereGeometry args={[saturnOuterAtmosphereRadius, 128, 640]} />
          <shaderMaterial 
            attach="material"
            {...saturnAtmosphereShader}
            side={THREE.BackSide}
            transparent={true}
            depthWrite={false}
            blending={THREE.CustomBlending}
            blendEquation={THREE.AddEquation}
            blendSrc={THREE.SrcAlphaFactor}
            blendDst={THREE.OneMinusSrcAlphaFactor}
          />
        </mesh>
      </group>
    </group>
  );
};

Saturn.propTypes = {
  timelapse: PropTypes.number
};

Saturn.defaultProps = {
  timelapse: 0
};

export default Saturn;

// Update the helper function to support multiple color stops
const createGradientCanvas = (colorStops) => {
  const canvas = document.createElement('canvas');
  canvas.width = 256;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 256, 0);
  
  colorStops.forEach(stop => {
    gradient.addColorStop(stop.position, stop.color);
  });
  
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, 256, 1);
  return canvas;
};
