import React, { useRef, memo, useMemo, useState, useEffect, createContext, useContext, useCallback } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
// Import your SVG component directly
import PersonWatchingTV from './PersonWatchingTV1';
// Import the edge components
import ModifiedCircleWithEdges from './CircleEdge';
import ModifiedRectWithEdges from './RectEdge';

// Create a context to manage overlay state
export const OverlayContext = createContext();
// Create a context to share sun position - initialize with a proper structure
export const SunContext2 = createContext({ sunContext: { current: { x: -3, y: 4, z: 0.1 } } });

// Shared geometries and materials to reduce memory usage
export const sharedGeometries = {
  // Create the circle geometry once and reuse it for caps
  circleGeometry: new THREE.CircleGeometry(1, 8),
  // Create the unit plane geometry for rectangles
  planeGeometry: new THREE.PlaneGeometry(1, 1)
};

// SVG conversion helpers
export const svgToThreeCoords = (x, y, viewBox) => {
  const [vbX, vbY, vbWidth, vbHeight] = viewBox;
  const scale = Math.min(1/vbWidth, 1/vbHeight);
  return [
    (x - vbWidth/2) * scale,
    -(y - vbHeight/2) * scale,
    0.02  // z-offset
  ];
};

// Helper to extract SVG data from JSX component
export const extractSVGData = (Component) => {
  // Create temporary DOM element
  const div = document.createElement('div');
  const svg = Component().props.children;
  
  // Extract viewBox
  const viewBox = svg.props.viewBox.split(' ').map(Number);
  
  // Extract shapes from children
  const shapes = svg.props.children
    .filter(child => child?.type && typeof child.type === 'string') // Filter out comments
    .map(child => {
      const props = child.props;
      const type = child.type;
      // Base attributes
      const baseAttrs = {
        fill: props.fill || '#000000',
        opacity: props.opacity || 1,
        stroke: props.stroke,
        strokeWidth: props['strokeWidth']
      };
      switch (type) {
        case 'rect':
          return {
            type: 'rect',
            x: parseFloat(props.x || 0),
            y: parseFloat(props.y || 0),
            width: parseFloat(props.width),
            height: parseFloat(props.height),
            ...baseAttrs
          };
        case 'circle':
          return {
            type: 'circle',
            cx: parseFloat(props.cx),
            cy: parseFloat(props.cy),
            r: parseFloat(props.r),
            ...baseAttrs
          };
        case 'polygon':
          return {
            type: 'polygon',
            points: props.points,
            ...baseAttrs
          };
        // Add other shape types as needed
        default:
          console.warn(`Unsupported shape type: ${type}`);
          return null;
      }
    })
    .filter(Boolean); // Remove null entries
  return { viewBox, shapes };
};

// Sun component that moves back and forth
const MovingSun = ({ scale = 1 }) => {
  const sunRef = useRef();
  const direction = useRef(1); // 1 for right, -1 for left
  const sunPosition = useRef({ x: -3, y: 4, z: 0.1 }); // Positioned higher up
  const { sunContext } = useContext(SunContext2);
  
  useFrame(({ clock }) => {
    if (!sunRef.current) return;
    
    // Update sun position
    const speed = 0.01;
    sunPosition.current.x += speed * direction.current;
    
    // Change direction when reaching bounds
    if (sunPosition.current.x > 3) {
      direction.current = -1;
    } else if (sunPosition.current.x < -3) {
      direction.current = 1;
    }
    
    // Apply position to mesh
    sunRef.current.position.x = sunPosition.current.x;
    sunRef.current.position.y = sunPosition.current.y;
    
    // Make sure sunContext exists and has a current property before updating
    if (sunContext && typeof sunContext === 'object') {
      sunContext.current = sunPosition.current;
    }
  });
  
  return (
    <mesh ref={sunRef} position={[sunPosition.current.x, sunPosition.current.y, sunPosition.current.z]} scale={[scale/10, scale/10, 1]}>
      <circleGeometry args={[0.2, 32]} />
      <meshBasicMaterial color="yellow" />
    </mesh>
  );
};

// Instanced shape manager for circles and rectangles
class ShapeInstanceManager {
  constructor() {
    // Maps to store instanced meshes by material color+opacity
    this.circleInstances = new Map();
    this.rectInstances = new Map();
    
    // Dummy object for matrix updates
    this.dummy = new THREE.Object3D();
    
    // Pre-create geometries
    this.circleGeometry = new THREE.CircleGeometry(1, 32);
    this.rectGeometry = new THREE.PlaneGeometry(1, 1);
  }
  
  // Get a key for the material map
  getMaterialKey(color, opacity) {
    return `${color}_${opacity}`;
  }
  
  // Get or create a circle instanced mesh for a specific material
  getCircleInstance(color, opacity, renderOrder, scene) {
    const key = this.getMaterialKey(color, opacity);
    
    if (!this.circleInstances.has(key)) {
      // Create material
      const material = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: opacity,
        side: THREE.DoubleSide,
        depthTest: false,
        depthWrite: false
      });
      material.renderOrder = renderOrder;
      
      // Create instance mesh - start with capacity for 10 instances
      const instancedMesh = new THREE.InstancedMesh(this.circleGeometry, material, 10);
      instancedMesh.renderOrder = renderOrder;
      instancedMesh.count = 0;
      scene.add(instancedMesh);
      
      this.circleInstances.set(key, {
        mesh: instancedMesh,
        count: 0,
        capacity: 10
      });
    }
    
    const instance = this.circleInstances.get(key);
    
    // Expand capacity if needed
    if (instance.count >= instance.capacity) {
      const oldMesh = instance.mesh;
      const newCapacity = instance.capacity * 2;
      
      const newMesh = new THREE.InstancedMesh(
        this.circleGeometry, 
        oldMesh.material, 
        newCapacity
      );
      newMesh.renderOrder = renderOrder;
      
      // Copy existing matrices
      for (let i = 0; i < instance.count; i++) {
        oldMesh.getMatrixAt(i, this.dummy.matrix);
        newMesh.setMatrixAt(i, this.dummy.matrix);
      }
      
      newMesh.count = instance.count;
      newMesh.instanceMatrix.needsUpdate = true;
      
      // Replace in scene
      scene.remove(oldMesh);
      scene.add(newMesh);
      
      // Update in map
      instance.mesh = newMesh;
      instance.capacity = newCapacity;
    }
    
    // Return the instance and the current index
    return {
      mesh: instance.mesh,
      index: instance.count++
    };
  }
  
  // Get or create a rectangle instanced mesh for a specific material
  getRectInstance(color, opacity, renderOrder, scene) {
    const key = this.getMaterialKey(color, opacity);
    
    if (!this.rectInstances.has(key)) {
      // Create material
      const material = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: opacity,
        side: THREE.DoubleSide,
        depthTest: false,
        depthWrite: false
      });
      material.renderOrder = renderOrder;
      
      // Create instance mesh - start with capacity for 10 instances
      const instancedMesh = new THREE.InstancedMesh(this.rectGeometry, material, 10);
      instancedMesh.renderOrder = renderOrder;
      instancedMesh.count = 0;
      scene.add(instancedMesh);
      
      this.rectInstances.set(key, {
        mesh: instancedMesh,
        count: 0,
        capacity: 10
      });
    }
    
    const instance = this.rectInstances.get(key);
    
    // Expand capacity if needed
    if (instance.count >= instance.capacity) {
      const oldMesh = instance.mesh;
      const newCapacity = instance.capacity * 2;
      
      const newMesh = new THREE.InstancedMesh(
        this.rectGeometry, 
        oldMesh.material, 
        newCapacity
      );
      newMesh.renderOrder = renderOrder;
      
      // Copy existing matrices
      for (let i = 0; i < instance.count; i++) {
        oldMesh.getMatrixAt(i, this.dummy.matrix);
        newMesh.setMatrixAt(i, this.dummy.matrix);
      }
      
      newMesh.count = instance.count;
      newMesh.instanceMatrix.needsUpdate = true;
      
      // Replace in scene
      scene.remove(oldMesh);
      scene.add(newMesh);
      
      // Update in map
      instance.mesh = newMesh;
      instance.capacity = newCapacity;
    }
    
    // Return the instance and the current index
    return {
      mesh: instance.mesh,
      index: instance.count++
    };
  }
  
  // Add a circle to the scene using instancing
  addCircle(position, radius, color, opacity, renderOrder, scene) {
    const { mesh, index } = this.getCircleInstance(color, opacity, renderOrder, scene);
    
    // Set position and scale for this instance
    this.dummy.position.set(position[0], position[1], position[2]);
    this.dummy.scale.set(radius, radius, 1);
    this.dummy.updateMatrix();
    
    // Apply to instance
    mesh.setMatrixAt(index, this.dummy.matrix);
    mesh.instanceMatrix.needsUpdate = true;
    
    return { mesh, index };
  }
  
  // Add a rectangle to the scene using instancing
  addRect(position, scale, color, opacity, renderOrder, scene) {
    const { mesh, index } = this.getRectInstance(color, opacity, renderOrder, scene);
    
    // Set position and scale for this instance
    this.dummy.position.set(position[0], position[1], position[2]);
    this.dummy.scale.set(scale[0], scale[1], 1);
    this.dummy.updateMatrix();
    
    // Apply to instance
    mesh.setMatrixAt(index, this.dummy.matrix);
    mesh.instanceMatrix.needsUpdate = true;
    
    return { mesh, index };
  }
}

// Updated SVGScene component with instanced shapes
const SVGScene = React.memo(({ renderOrder = 12, scale = 1 }) => {
  // Initialize with a proper structure that includes current
  const sunContextRef = useRef({ current: { x: -3, y: 4, z: 0.1 } });
  const shapeInstanceManager = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  
  // Enable stencil and clipping features globally
  useEffect(() => {
    const renderer = THREE.WebGLRenderer.instance;
    if (renderer) {
      // Enable both local clipping and stencil buffer
      renderer.localClippingEnabled = true;
      renderer.state.buffers.stencil.setTest(true);
      
      // Set some global renderer settings to ensure proper stencil operations
      renderer.autoClearStencil = true;
      renderer.stencil = true;
    }
    
    // Disable frustum culling for edge lines
    const disableCulling = () => {
      const edgeLines = document.querySelectorAll('.edge-line');
      edgeLines.forEach(line => {
        if (line.object && line.object.frustumCulled !== undefined) {
          line.object.frustumCulled = false;
        }
      });
    };
    
    // Run initially and set up an interval to catch any newly added lines
    disableCulling();
    const interval = setInterval(disableCulling, 1000);
    
    return () => clearInterval(interval);
  }, []);
  
  // Initialize the shape instance manager
  useEffect(() => {
    if (!shapeInstanceManager.current) {
      shapeInstanceManager.current = new ShapeInstanceManager();
    }
  }, []);
  
  // Extract SVG data
  const { viewBox, shapes: svgShapes } = useMemo(() => {
    return extractSVGData(PersonWatchingTV);
  }, []);
  
  // Process all shapes maintaining original ordering from SVG
  const processedShapes = useMemo(() => {
    return svgShapes.map((shape, index) => {
      // Each shape in the SVG gets a base renderOrder based on its position in the array
      // This preserves the original stacking order from the SVG
      const shapeRenderOrder = renderOrder + index;
      
      if (shape.type === 'circle') {
        const scaleValue = Math.min(1/viewBox[2], 1/viewBox[3]);
        const circleRadius = shape.r * scaleValue;
        const position = svgToThreeCoords(
          shape.cx,
          shape.cy,
          viewBox
        );
        
        return {
          type: 'circle',
          position,
          radius: circleRadius,
          color: shape.fill,
          opacity: shape.opacity,
          renderOrder: shapeRenderOrder,
          shapeIndex: index
        };
      } else if (shape.type === 'rect') {
        const geometry = new THREE.PlaneGeometry(1, 1);  // Use unit size
        const position = svgToThreeCoords(
          shape.x + shape.width/2,
          shape.y + shape.height/2,
          viewBox
        );
        const scaleValue = Math.min(1/viewBox[2], 1/viewBox[3]);
        const scaleX = shape.width * scaleValue;
        const scaleY = shape.height * scaleValue;
        
        return {
          type: 'rect',
          geometry,
          position,
          scale: [scaleX, scaleY, 1],
          color: shape.fill,
          opacity: shape.opacity,
          renderOrder: shapeRenderOrder,
          shapeIndex: index
        };
      } else if (shape.type === 'polygon') {
        try {
          // Parse the points string into coordinates
          const pointsString = shape.points.trim();
          const pointPairs = pointsString.split(/\s+/); // Split by whitespace
          
          const pointsArray = pointPairs.map(pair => {
            // Each pair should be "x,y"
            const [x, y] = pair.split(',').map(parseFloat);
            return [x, y]; // Return as [x,y] array
          });
          
          if (pointsArray.length < 3) {
            console.warn("Polygon needs at least 3 points:", shape);
            return null;
          }
          
          // Create a THREE.js Shape for the polygon
          const polygonShape = new THREE.Shape();
          polygonShape.moveTo(pointsArray[0][0], pointsArray[0][1]);
          
          for (let i = 1; i < pointsArray.length; i++) {
            polygonShape.lineTo(pointsArray[i][0], pointsArray[i][1]);
          }
          
          polygonShape.closePath();
          
          // Create geometry from the shape
          const geometry = new THREE.ShapeGeometry(polygonShape);
          
          // FIXED: Use more accurate bounding box center calculation method
          // Calculate bounding box for the polygon
          let minX = Infinity, minY = Infinity;
          let maxX = -Infinity, maxY = -Infinity;
          
          pointsArray.forEach(point => {
            minX = Math.min(minX, point[0]);
            minY = Math.min(minY, point[1]);
            maxX = Math.max(maxX, point[0]);
            maxY = Math.max(maxY, point[1]);
          });
          
          // Use center of bounding box
          const centerX = (minX + maxX) / 2;
          const centerY = (minY + maxY) / 2;
          
          // Convert center to Three.js coordinates
          const position = svgToThreeCoords(centerX, centerY, viewBox);
          
          // Scale based on viewBox
          const scaleValue = Math.min(1/viewBox[2], 1/viewBox[3]);
          
          // Important: Center the geometry
          geometry.center();
          
          return {
            type: 'polygon',
            geometry,
            position,
            scale: [scaleValue, -scaleValue, 1], // Flip Y axis for SVG coordinates
            color: shape.fill,
            opacity: shape.opacity, 
            renderOrder: shapeRenderOrder + 1,
            shapeIndex: index
          };
        } catch (error) {
          console.error("Error processing polygon:", error, shape);
          return null;
        }
      }
      
      return null;
    }).filter(Boolean);
  }, [svgShapes, viewBox, renderOrder]);
  
  // Apply base scale (6.6) multiplied by the passed scale prop
  const baseScale = 6.6 * scale;
  
  return (
    <SunContext2.Provider value={{ sunContext: sunContextRef }}>
      <group scale={[baseScale, baseScale, 1]} position={[0, -0.97, 0.01]}>
        {/* Add moving sun */}
        <MovingSun scale={baseScale} />
        
        {/* Render all shapes based on original ordering */}
        {processedShapes.map((shape) => {
          if (shape.type === 'circle') {
            return (
              <ModifiedCircleWithEdges
                key={`circle-${shape.shapeIndex}`}
                position={shape.position}
                radius={shape.radius}
                color={shape.color}
                opacity={shape.opacity}
                renderOrder={shape.renderOrder}
                contextType="overlay2"
              />
            );
          } else if (shape.type === 'rect') {
            // Use our new component for rectangles
            return (
              <ModifiedRectWithEdges
                key={`rect-${shape.shapeIndex}`}
                position={shape.position}
                scale={shape.scale}
                color={shape.color}
                opacity={shape.opacity}
                renderOrder={shape.renderOrder}
                contextType="overlay2"
              />
            );
          } else if (shape.type === 'polygon') {
            return (
              <mesh
                key={`polygon-${shape.shapeIndex}`}
                geometry={shape.geometry}
                position={shape.position}
                scale={shape.scale}
                renderOrder={shape.renderOrder}
              >
                <meshBasicMaterial
                  color={shape.color}
                  transparent={true}
                  opacity={shape.opacity || 1.0}
                  side={THREE.DoubleSide}
                  depthTest={false}
                  depthWrite={false}
                  polygonOffset={true}
                  polygonOffsetFactor={-10}
                />
              </mesh>
            );
          } else {
            return (
              <mesh
                key={`shape-${shape.shapeIndex}`}
                geometry={shape.geometry}
                position={shape.position}
                scale={shape.scale}
                renderOrder={shape.renderOrder}
              >
                <meshBasicMaterial
                  color={shape.color}
                  transparent={true}
                  opacity={shape.opacity}
                  side={THREE.DoubleSide}
                  depthTest={false}
                  depthWrite={false}
                />
              </mesh>
            );
          }
        })}
      </group>
    </SunContext2.Provider>
  );
});

// Custom Overlays component that will contain your custom shapes
export const Overlays2 = memo(({ scale = 1, isCameraChild = true, renderOrder = 12 }) => {
  const svgSceneRef = useRef();
  const { camera, scene } = useThree();
  
  // Disable frustum culling for all lines in the scene
  useEffect(() => {
    const disableCullingForLines = () => {
      scene.traverse(object => {
        // Check if this is a Line object
        if (object.isLine || object.isLineSegments) {
          object.frustumCulled = false;
        }
      });
    };
    
    // Run initially and set up an interval to catch any newly added lines
    disableCullingForLines();
    const interval = setInterval(disableCullingForLines, 1000);
    
    return () => clearInterval(interval);
  }, [scene]);
  
  // Reset the mounted flag when the component is mounted
  useEffect(() => {
    // Reset the flag when the component mounts
    if (isCameraChild) {
      window.__overlayMounted = true;
    }
    
    // Clean up on unmount
    return () => {
      if (isCameraChild) {
        window.__overlayMounted = false;
      }
    };
  }, [isCameraChild]);
  
  // NEW EFFECT: Disable frustum culling for all objects in this overlay
  // Moved this before the conditional return to satisfy React Hooks rules
  useEffect(() => {
    const disableFrustumCullingRecursively = (object) => {
      if (!object) return;
      
      // Disable on the object itself if it has the property
      if (object.frustumCulled !== undefined) {
        object.frustumCulled = false;
      }
      
      // If it has children, process them too (recursively)
      if (object.children && object.children.length > 0) {
        object.children.forEach(child => disableFrustumCullingRecursively(child));
      }
    };
    
    // Run it on the group and set an interval to catch new objects
    const disableAllCulling = () => {
      if (svgSceneRef.current) {
        disableFrustumCullingRecursively(svgSceneRef.current);
      }
    };
    
    // Run immediately and at regular intervals
    disableAllCulling();
    const interval = setInterval(disableAllCulling, 500);
    
    return () => clearInterval(interval);
  }, []);
  
  // Check if this overlay should be active
  const isActive = isCameraChild && window.__overlayMounted;
  
  // Only render if active
  if (!isActive) {
    return null;
  }
  
  return (
    <group>
      <group ref={svgSceneRef}>
        <SVGScene renderOrder={renderOrder} scale={scale} />
      </group>
    </group>
  );
});