import React, { useMemo, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const Venus = () => {
  const { camera } = useThree();
  const venusGroupRef = useRef();

  // Venus constants
  const venusRadius = 6051.8;
  // Use an offset so the atmosphere mesh appears outside the surface
  const atmosphereOffset = 51.8;
  const venusAtmosphereRadius = venusRadius + atmosphereOffset;

  // Check the camera distance and toggle atmosphere visibility (just like Earth or Jupiter)
  useFrame(() => {
    if (venusGroupRef.current) {
      const venusPos = new THREE.Vector3();
      venusGroupRef.current.getWorldPosition(venusPos);
      const distance = camera.position.distanceTo(venusPos);

      // Find the atmosphere mesh by matching its sphere geometry radius
      const atmMesh = venusGroupRef.current.children.find(
        (child) =>
          child.geometry instanceof THREE.SphereGeometry &&
          child.geometry.parameters.radius === venusAtmosphereRadius
      );
      if (atmMesh) {
        atmMesh.visible = distance <= venusAtmosphereRadius * 2.5;
      }
    }
  });

  // Venus Surface Shader updated with shadow design from Earth
  const venusShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          float angle = radians(177.3);
          mat3 rotationMatrix = mat3(
            1.0, 0.0, 0.0,
            0.0, cos(angle), -sin(angle),
            0.0, sin(angle), cos(angle)
          );
          
          vec3 rotatedPosition = rotationMatrix * position;
          vec3 rotatedNormal = rotationMatrix * normal;
          
          vUv = uv;
          vNormal = rotatedNormal;
          vPosition = rotatedPosition;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        const float PI = 3.14159265359;

        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - f);
          return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.95, 0.87, 0.26); // #F2DF42 in RGB
          
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 8.0 + time * 0.15) * 0.025;
          float wave2 = sin(angle * 5.0 + time * 0.1 + PI * 0.6) * 0.02;
          float wave3 = sin(angle * 3.0 - time * 0.2 + PI * 0.3) * 0.015;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          float clouds = noise(vec2(modifiedY * 3.0, time * 0.1)) * 0.7;
          clouds += noise(vec2(modifiedY * 8.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          
          if (modifiedY > 0.9) {
              bandColor = mix(
                  vec3(1.0, 0.95, 0.2),
                  vec3(1.0, 0.98, 0.3),
                  clouds
              );
          } else if (modifiedY > 0.8) {
              bandColor = mix(
                  vec3(1.0, 0.6, 0.0),
                  vec3(1.0, 0.7, 0.1),
                  clouds
              );
          } else if (modifiedY > 0.7) {
              bandColor = mix(
                  vec3(0.95, 0.87, 0.26),
                  vec3(1.0, 0.9, 0.4),
                  clouds
              );
          } else if (modifiedY > 0.6) {
              bandColor = mix(
                  vec3(0.9, 0.4, 0.0),
                  vec3(1.0, 0.5, 0.1),
                  clouds
              );
          } else if (modifiedY > 0.5) {
              bandColor = mix(
                  vec3(1.0, 0.9, 0.2),
                  vec3(1.0, 0.85, 0.3),
                  clouds
              );
          } else if (modifiedY > 0.4) {
              bandColor = mix(
                  vec3(1.0, 0.65, 0.1),
                  vec3(1.0, 0.75, 0.2),
                  clouds
              );
          } else if (modifiedY > 0.3) {
              bandColor = mix(
                  vec3(0.95, 0.8, 0.2),
                  vec3(1.0, 0.9, 0.3),
                  clouds
              );
          } else if (modifiedY > 0.2) {
              bandColor = mix(
                  vec3(0.8, 0.3, 0.0),
                  vec3(0.9, 0.4, 0.1),
                  clouds
              );
          } else if (modifiedY > 0.1) {
              bandColor = mix(
                  vec3(1.0, 0.95, 0.2),
                  vec3(0.95, 0.87, 0.26),
                  clouds
              );
          } else {
              bandColor = mix(
                  vec3(1.0, 0.7, 0.1),
                  vec3(1.0, 0.8, 0.2),
                  clouds
              );
          }
          
          bandColor *= mix(
              vec3(1.4, 1.3, 1.1),
              vec3(1.0, 0.9, 0.7),
              sin(modifiedY * 7.0 + clouds * 2.0) * 0.5 + 0.5
          );
          
          vec3 finalColor = mix(bandColor, baseColor, 0.01);
          
          // Updated shadow logic (from Earth's implementation)
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct < 0.9) {
            float shadowAngle = acos(-dotProduct) * 1.8;
            if (shadowAngle < 3.15) {
              float shadowStrength = smoothstep(3.15, 2.8, shadowAngle);
              shadowStrength = pow(shadowStrength, 1.5);
              finalColor = mix(finalColor, vec3(0.0), 0.45 * shadowStrength);
            }
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  // Venus Atmosphere Shader (using Earth's atmosphere logic)
  const venusAtmosphereShader = useMemo(() => {
    return {
      uniforms: {
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec3 vPosition;
        varying vec3 vNormal;
        
        void main() {
          vPosition = position;
          vNormal = normal;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec3 vPosition;
        varying vec3 vNormal;
        uniform mat4 modelMatrix;
        
        float easeInOutPower(float x) {
          return x < 0.5 ? 4.0 * x * x * x : 1.0 - pow(-2.0 * x + 2.0, 3.0) / 2.0;
        }
        
        void main() {
          vec4 skyColor = vec4(0.53, 0.81, 0.92, 0.0);
          vec4 finalColor = skyColor;
          
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct > -0.9) {
            float angle = acos(dotProduct) * 2.0;
            if (angle < 3.0) {
              finalColor = vec4(0.25, 0.61, 0.85, 0.5);
            } else if (angle < 3.9) {
              float gradient = smoothstep(3.9, 3.0, angle);
              gradient = easeInOutPower(gradient);
              finalColor = mix(skyColor, vec4(0.25, 0.61, 0.85, 0.5), gradient);
            }
          }
          
          gl_FragColor = finalColor;
        }
      `
    };
  }, []);

  return (
    <group ref={venusGroupRef}>
      {/* Venus Atmosphere Mesh */}
      <mesh renderOrder={1}>
        <sphereGeometry args={[venusAtmosphereRadius, 32, 32]} />
        <shaderMaterial
          attach="material"
          {...venusAtmosphereShader}
          side={THREE.BackSide}
          transparent={true}
          depthWrite={false}
          blending={THREE.CustomBlending}
          blendEquation={THREE.AddEquation}
          blendSrc={THREE.SrcAlphaFactor}
          blendDst={THREE.OneMinusSrcAlphaFactor}
        />
      </mesh>

      {/* Venus Surface Mesh */}
      <mesh renderOrder={2}>
        <sphereGeometry args={[venusRadius, 32, 32]} />
        <shaderMaterial
          attach="material"
          {...venusShader}
          side={THREE.FrontSide}
        />
      </mesh>
    </group>
  );
};

export default Venus;
