import React, { useEffect, useRef } from 'react';
import { 
  drawLegLine, 
  initializeLegs, 
  updateFootStep, 
  updateFeet, 
  updateRedCircle,
  drawLegs
} from './BeeLegs';
import {
  drawBeeEye,
  drawBeeEyePair,
  drawFrontEyePair,
  drawBottomEye
} from './BeeEyes';

// Add props to receive configuration
const BreathingAnimation = ({ config }) => {
  const canvasRef = useRef(null);
  
  // Store the current config in a ref so it's accessible in the animation loop
  const configRef = useRef(config);
  
  // Update the ref when props change
  useEffect(() => {
    configRef.current = config;
  }, [config]);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Create a global object to store eye configuration that can be accessed by BeeEyes.js
    window.beeEyeConfig = {
      topEyeSquareHeight: 3, // Default multiplier
      bottomEyeSquareHeight: 1, // Default multiplier
      topEyeCircleHeight: 3, // Default multiplier
      bottomEyeCircleHeight: 1, // Default multiplier
    };

    // Add a debounce function to avoid too many updates
    let configUpdateTimeout = null;
    
    // Function to update the eye configuration based on slider values
    const updateEyeConfig = () => {
      if (!configRef.current) return;
      
      // Clear any pending timeout
      if (configUpdateTimeout) {
        clearTimeout(configUpdateTimeout);
      }
      
      // Set a new timeout to update the config after a short delay
      configUpdateTimeout = setTimeout(() => {
        // Map the 0-100 range to appropriate multipliers (1-5)
        window.beeEyeConfig = {
          topEyeSquareHeight: 1 + (configRef.current.topEyeSquareHeight / 25), // 0-100 → 1-5
          bottomEyeSquareHeight: 1 + (configRef.current.bottomEyeSquareHeight / 25), // 0-100 → 1-5
          topEyeCircleHeight: 1 + (configRef.current.topEyeCircleHeight / 25), // 0-100 → 1-5
          bottomEyeCircleHeight: 1 + (configRef.current.bottomEyeCircleHeight / 25), // 0-100 → 1-5
        };
      }, 50); // 50ms debounce
    };

    // Listen for configuration updates from SlideComponents
    const handleConfigUpdate = (event) => {
      configRef.current = event.detail;
      updateEyeConfig();
    };

    document.addEventListener('updateCharacterConfig', handleConfigUpdate);

    // =========================
    // BREATHING ANIMATION STATE (existing)
    // =========================
    var currentBend = 1; // initial bend value
    var currentPurpleBend = 1;
    var currentAquaBend = -1;
    var targetPurpleBend = 1;
    var targetAquaBend = -1;
    var bendTransitionSpeed = 0.1; // Controls how fast the bend transitions

    // Floor parameters
    var floor = {
      height: 50,
      colour: "#654321"
    };

    // Initialize legs and related state from BeeLegs module
    const {
      circle,
      dot,
      stepThreshold,
      stepHeight,
      lastSteppedFoot: initialLastSteppedFoot,
      leftFoot,
      rightFoot,
      redCircle,
      redCircleVelocity,
      floorFootY
    } = initializeLegs(canvas, floor.height);
    
    let lastSteppedFoot = initialLastSteppedFoot;

    // Landing animation variables
    var landingAnimationActive = false;
    var landingAnimationProgress = 0;
    var landingAnimationDuration = 30; // # frames
    var maxBounce = 10;
    var bounce = 0;

    // Movement/drag and gravity state variables.
    var dragging = false;
    var dragOffsetX, dragOffsetY;
    var gravityOn = false;
    var gravityAcceleration = 0.5;
    var wasOnFloor = false;
    var onFloor = false;
    var moveSpeed = 10;
    var leftInterval, rightInterval;
    var intervalDelay = 50;
    var currentDirection = null;
    var lastCircleX = circle.x;

    // Click transition variables.
    var targetCircle = null;
    var transitionActive = false;
    var transitionStartTime = 0;
    var transitionDuration = 2000; // Now a minimum duration
    var startPos = { x: circle.x, y: circle.y };
    var targetPos = { x: 0, y: 0 };
    var clickInitiatedOnCircle = false;
    var currentTransitionDuration = 2000; // Will be calculated based on distance

    // Add timestamp tracking for feet animation
    var lastTime = performance.now();
    var stepSpeed = 0.01; // Increased by 4x for faster steps

    // Easing function.
    function easeInOut(t) {
      return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }

    // Utility function for mouse position.
    function getMousePos(evt) {
      var rect = canvas.getBoundingClientRect();
      return { x: evt.clientX - rect.left, y: evt.clientY - rect.top };
    }

    function checkFloorCollision() {
      var floorY = canvas.height - floor.height;
      var feetY = circle.y + dot.offsetY;
      if (feetY + dot.radius > floorY) {
        onFloor = true;
        circle.y = floorFootY - dot.offsetY;
        circle.velocityY = 0;
      } else {
        onFloor = false;
      }
    }

    function triggerLandingAnimation() {
      if (onFloor && !wasOnFloor && !dragging) {
        landingAnimationActive = true;
        landingAnimationProgress = 0;
      }
    }

    function updateLandingAnimation() {
      if (landingAnimationActive) {
        landingAnimationProgress++;
        var t = landingAnimationProgress / landingAnimationDuration;
        bounce = maxBounce * Math.sin(Math.PI * t);
        if (landingAnimationProgress >= landingAnimationDuration) {
          landingAnimationActive = false; bounce = 0;
        }
      }
    }

    function drawBreathingCharacter() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // Draw floor
      ctx.fillStyle = floor.colour;
      ctx.fillRect(0, canvas.height - floor.height, canvas.width, floor.height);

      // Draw the red circle (with damping) more visibly
      ctx.beginPath();
      ctx.arc(redCircle.x, redCircle.y, 25, 0, Math.PI * 2); // Increased radius from 15 to 25
      ctx.fillStyle = "rgba(255, 0, 0, 0.7)";              // Semi-transparent fill for a softer look
      ctx.fill();
      ctx.lineWidth = 4;
      ctx.strokeStyle = "darkred";                          // Add a contrasting stroke
      ctx.stroke();
      ctx.closePath();

      // Draw legs and feet using the BeeLegs module
      drawLegs(ctx, redCircle, leftFoot, rightFoot, currentBend);
      
      // Draw blue target marker if active.
      if (targetCircle) {
        ctx.beginPath();
        ctx.arc(targetCircle.x, targetCircle.y, 20, 0, Math.PI * 2); // Increased radius for better visibility
        ctx.fillStyle = "rgba(0, 0, 255, 0.3)"; // Semi-transparent fill
        ctx.strokeStyle = "blue";             // Blue outline to make it stand out
        ctx.lineWidth = 4;
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      }
    }

    // =========================
    // YELLOW CHARACTER STATE & UTILITY FUNCTIONS
    // =========================
    // Global scale and sizes for yellow character
    const compositeScale = 1.5;  // Changed from 0.75 to 1.5
    const blackCircleRadius = 100 * compositeScale;
    const outerOffset = 10 * compositeScale;

    let rectangle = {
      x: redCircle.x - 30 * compositeScale,
      y: redCircle.y - 35 * compositeScale,
      width: 60 * compositeScale,
      height: 60 * compositeScale,
      dragging: false
    };

    // Rename the yellow character's orange circle so it does not conflict.
    let orangeCircleYellow = {
      x: redCircle.x + blackCircleRadius,
      y: redCircle.y,
      radius: 25,
      colour: "orange",
      dragging: false,
      positionFixed: false  // Add this flag to track if position has been manually set
    };

    let purpleCircle = {
      x: 0,
      y: 0,
      radius: 5 * compositeScale,
      dragging: false,
      baseY: 0,
      distanceFromCenter: -60 * compositeScale,
      heightOffset: -50 * compositeScale,  // Initial height offset
      customHeightOffset: 0  // Add this to track manual y-position changes
    };

    let aquaCircle = {
      x: 0,
      y: 0,
      radius: 5 * compositeScale,
      dragging: false,
      baseY: 0,
      distanceFromCenter: 60 * compositeScale,
      heightOffset: -50 * compositeScale,  // Initial height offset
      customHeightOffset: 0  // Add this to track manual y-position changes
    };

    // Offsets for dragging yellow elements.
    let orangeOffsetXYellow = 0, orangeOffsetYYellow = 0;
    let bodyOffsetX = 0, bodyOffsetY = 0;
    let purpleOffsetX = 0, purpleOffsetY = 0;
    let aquaOffsetX = 0, aquaOffsetY = 0;

    // ---------- Utility Drawing Helpers for Yellow Character ----------
    function drawVerticalPill(ctx, x, y, width, height) {
      const radius = width / 2;
      ctx.beginPath();
      ctx.arc(x + width / 2, y + radius, radius, Math.PI, 0, false);
      ctx.lineTo(x + width, y + height - radius);
      ctx.arc(x + width / 2, y + height - radius, radius, 0, Math.PI, false);
      ctx.closePath();
    }

    function drawBeePill(ctx, x, y, width, height) {
      ctx.save();
      drawVerticalPill(ctx, x, y, width, height);
      ctx.clip();
      
      // Base yellow color
      const baseYellow = "#FFDD00";
      
      // Draw the main body first
      const headSectionHeight = height * 0.45;
      const seamY = y + headSectionHeight;
      const headArc = headSectionHeight * 0.1;
      ctx.fillStyle = baseYellow;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, seamY);
      ctx.quadraticCurveTo(x + width / 2, seamY + headArc, x + width, seamY);
      ctx.lineTo(x + width, y);
      ctx.closePath();
      ctx.fill();

      // Draw the stripes
      const numStripes = 5;
      const heightRemaining = (y + height) - seamY;
      const stripeHeight = heightRemaining / numStripes;
      const stripeColours = ["black", baseYellow, "black", baseYellow, "black"];
      for (let i = 0; i < numStripes; i++) {
        const stripeY = seamY + i * stripeHeight;
        const stripeArc = stripeHeight * 0.2;
        ctx.fillStyle = stripeColours[i];
        ctx.beginPath();
        ctx.moveTo(x, stripeY);
        ctx.quadraticCurveTo(x + width / 2, stripeY + stripeArc, x + width, stripeY);
        ctx.lineTo(x + width, stripeY + stripeHeight);
        ctx.quadraticCurveTo(x + width / 2, stripeY + stripeHeight + stripeArc, x, stripeY + stripeHeight);
        ctx.closePath();
        ctx.fill();
      }
      
      ctx.restore();
    }

    // ---------- Drawing the Yellow Character ----------
    function drawYellowCharacter() {
      // Update rectangle position to follow red circle
      if (!rectangle.dragging) {
        rectangle.x = redCircle.x - 30 * compositeScale;
        rectangle.y = redCircle.y - 35 * compositeScale;
      }

      // Update orangeCircleYellow position if not being dragged and not fixed
      if (!orangeCircleYellow.dragging && !orangeCircleYellow.positionFixed) {
        const centerX = rectangle.x + rectangle.width / 2;
        const centerY = rectangle.y + 75 * compositeScale;
        // Maintain the same angle but update the center point
        const angle = Math.atan2(orangeCircleYellow.y - centerY, orangeCircleYellow.x - centerX);
        orangeCircleYellow.x = centerX + blackCircleRadius * Math.cos(angle);
        orangeCircleYellow.y = centerY + blackCircleRadius * Math.sin(angle);
      }

      // Calculate dimensions and positions:
      const pullWidth = rectangle.width * 1.2 * 0.9;
      const pullHeight = 120 * compositeScale;  // Keep this the same
      const pullX = rectangle.x + rectangle.width / 2 - pullWidth / 2;
      const pullY = rectangle.y - pullHeight / 2 + 20 * compositeScale; // Changed from 10 to 30 to move body down
      const centerX = rectangle.x + rectangle.width / 2;
      const centerY = pullY + pullHeight / 2;

      // Calculate angle between orangeCircleYellow and center.
      const angle = Math.atan2(orangeCircleYellow.y - centerY, orangeCircleYellow.x - centerX);
      let degrees = Math.round(angle * (180 / Math.PI) + 90);
      if (degrees < 0) degrees += 360;
      if (degrees >= 360) degrees -= 360;

      // Compute interpolation factors and positions (upper, middle, lower sets).
      const circleRadius = 5 * compositeScale;
      const leftBaseX = pullX;
      const rightBaseX = pullX + pullWidth;
      const lowerY = centerY;
      const upperY = centerY - pullHeight * 0.35;
      let interpolationFactor = degrees <= 180 ? (180 - degrees) / 180 : (degrees - 180) / 180;
      let redDegrees = degrees - 40; if (redDegrees < 0) redDegrees += 360;
      let redInterpolationFactor = redDegrees <= 180 ? (180 - redDegrees) / 180 : (redDegrees - 180) / 180;
      let whiteDegrees = degrees + 220; if (whiteDegrees >= 360) whiteDegrees -= 360;
      let whiteInterpolationFactor = whiteDegrees <= 180 ? (180 - whiteDegrees) / 180 : (whiteDegrees - 180) / 180;
      let brownDegrees = degrees - 40; if (brownDegrees < 0) brownDegrees += 360;
      let brownInterpolationFactor = brownDegrees <= 180 ? (180 - brownDegrees) / 180 : (brownDegrees - 180) / 180;
      let greyDegrees = degrees + 220; if (greyDegrees >= 360) greyDegrees -= 360;
      let greyInterpolationFactor = greyDegrees <= 180 ? (180 - greyDegrees) / 180 : (greyDegrees - 180) / 180;
      
      // Add the missing pinkFactor definition - using the same approach as other factors
      let pinkDegrees = degrees - 20; if (pinkDegrees < 0) pinkDegrees += 360;
      let pinkFactor = pinkDegrees <= 180 ? (180 - pinkDegrees) / 180 : (pinkDegrees - 180) / 180;

      const leftX = leftBaseX + (rightBaseX - leftBaseX) * interpolationFactor;
      const rightX = rightBaseX - (rightBaseX - leftBaseX) * interpolationFactor;
      const redX = leftBaseX + (rightBaseX - leftBaseX) * redInterpolationFactor;
      const whiteX = leftBaseX + (rightBaseX - leftBaseX) * whiteInterpolationFactor;
      const brownX = leftBaseX + (rightBaseX - leftBaseX) * brownInterpolationFactor;
      const greyX = leftBaseX + (rightBaseX - leftBaseX) * greyInterpolationFactor;

      // Upper set (red and white) - eyes
      const eyeSpacingFactor = 1.2; // Reduced from 1.7 to bring eyes closer together

      // Calculate additional offset based on angle
      // This will be maximum at 90° and 270°, and zero at 0° and 180°
      const angleOffsetFactor = Math.abs(Math.sin(angle)); // Ranges from 0 to 1

      // Combine the base spacing with the angle-based offset
      const effectiveSpacingFactor = eyeSpacingFactor * (1 - 0.5 * angleOffsetFactor);

      // Define highlight variables outside the conditionals so they're available to both eye blocks
      const highlightRadius = circleRadius * 0.4;
      const highlightOffsetX = circleRadius * 0.2;
      const highlightOffsetY = -circleRadius * 0.2;

      // Apply sine wave easing to the interpolation factors to slow down at extremes
      // This creates a non-linear mapping that slows down as it approaches the edges
      const easeInterpolation = (factor) => {
        // Convert 0-1 range to -π/2 to π/2 range
        const angle = (factor - 0.5) * Math.PI;
        // Apply sine function and normalize back to 0-1 range
        return (Math.sin(angle) + 1) / 2;
      };

      // Apply the easing to the interpolation factors
      const easedRedInterpolation = easeInterpolation(redInterpolationFactor);
      const easedWhiteInterpolation = easeInterpolation(whiteInterpolationFactor);

      // Adjust the positions with the effective spacing factor and eased interpolation
      const adjustedRedX = leftBaseX + (rightBaseX - leftBaseX) * (easedRedInterpolation * effectiveSpacingFactor + (1 - effectiveSpacingFactor) * 0.5);
      const adjustedWhiteX = leftBaseX + (rightBaseX - leftBaseX) * (easedWhiteInterpolation * effectiveSpacingFactor + (1 - effectiveSpacingFactor) * 0.5);

      // Calculate eye vertical offset
      const eyeVerticalOffset = circleRadius * 1.2; // Changed from 0.8 to 1.2 to move eyes down more

      // Define eye radius for the bottom eye
      const eyeRadius = 6 * compositeScale;

      // Calculate positions for the foreground circles
      const middleY = centerY - pullHeight * 0.15;
      const uniformCircleSize = circleRadius * 1.2;

      // Calculate inverted positions using inverted factors
      const invertedBrownInterpolationFactor = 1 - brownInterpolationFactor;
      const invertedGreyInterpolationFactor = 1 - greyInterpolationFactor;
      const invertedBrownX = leftBaseX + (rightBaseX - leftBaseX) * invertedBrownInterpolationFactor;
      const invertedGreyX = leftBaseX + (rightBaseX - leftBaseX) * invertedGreyInterpolationFactor;

      // Calculate the black circle position (moved up from later in the code)
      const blackX = centerX - (rectangle.width / 2) * Math.sin(degrees * Math.PI / 180);
      const blackY = centerY + pullHeight * 0.3; // Changed from 0.2 to 0.3 to move down

      // Calculate mouth parameters
      const mouthY = upperY + circleRadius * 4; // Changed from 3 to 4 to move mouth down more
      const maxMouthWidth = circleRadius * 2.52;
      const mouthHeight = 4 * compositeScale;

      // Calculate offset factor (0 at center, 1 at max offset)
      const offsetFactor = Math.abs(Math.sin(degrees * Math.PI / 180));
      // Mouth gets shorter as it moves away from center - reduce the shrinking effect by half
      const adjustedMouthWidth = maxMouthWidth * (1 - offsetFactor * 0.4);

      // Center the mouth between the two eyes
      const eyesMidpoint = (adjustedRedX + adjustedWhiteX) / 2;

      // Calculate mouth position with smooth transition
      // Use the actual angle (not just offsetFactor) to determine direction
      // This prevents the glitch when transitioning from left to right
      const angleRadians = degrees * Math.PI / 180;

      // Use sine function directly for smooth transition across the center
      // This creates a continuous motion without jumps
      const directionFactor = Math.sin(angleRadians);

      // Apply exponential function to make mouth travel farther
      // Math.sign preserves direction while pow increases magnitude
      const enhancedOffset = Math.sign(directionFactor) * Math.pow(Math.abs(directionFactor), 0.7) * 10;

      const mouthCenterX = eyesMidpoint + enhancedOffset;
      const mouthX = mouthCenterX - adjustedMouthWidth/2;
      const smileCurvature = mouthHeight * 2; // Controls how much the smile curves upward

      // Draw the eyes using the imported functions
      // Draw the main eye pair (blue background, black foreground)
      drawBeeEyePair(
        ctx, 
        adjustedRedX, 
        adjustedWhiteX, 
        upperY + eyeVerticalOffset, 
        circleRadius * 1.5, 
        degrees, 
        "black", 
        "blue"
      );

      // FIRST DRAW COPIES OF THE FOREGROUND CIRCLES THAT ARE ALWAYS VISIBLE (BEHIND THE BODY)
      // These are the same as the conditional foreground circles but always visible
      ctx.beginPath();
      ctx.arc(invertedBrownX, middleY, uniformCircleSize, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();
      
      ctx.beginPath();
      ctx.arc(invertedGreyX, middleY, uniformCircleSize, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();
      
      // Draw the black circle first (so it appears behind everything)
      ctx.beginPath();
      ctx.arc(blackX, blackY, eyeRadius, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;
      ctx.stroke();

      // Define antenna variables here, before using them
      const topY = centerY - pullHeight * 0.45; // Changed from 0.42 to 0.45 to move antennas up more
      const antennaHeight = 20 * compositeScale;
      const antennaWidth = 4 * compositeScale;
      const antennaTopRadius = 5 * compositeScale;
      
      // Calculate the center midpoint of the character
      const characterMidpointX = centerX;
      
      // Position antennas above the eyes but with 10% offset toward the center
      // Left antenna - positioned above left eye with offset toward center
      const leftEyeX = adjustedRedX;
      const leftAntennaX = leftEyeX + (characterMidpointX - leftEyeX) * 0.3; // 10% offset toward center
      
      // Calculate curve direction based on angle - INVERTED
      // When angle is between 0-180, curve RIGHT (positive bend)
      // When angle is between 180-360, curve LEFT (negative bend)
      const leftAntennaCurveFactor = (degrees >= 0 && degrees < 180) ? 1 : -1;
      
      // Interpolate the curve strength based on angle
      // Maximum curve at 90° and 270°, minimum at 0°/180°/360°
      const antennaAngleRadians = degrees * Math.PI / 180;
      const curveStrengthFactor = Math.abs(Math.sin(antennaAngleRadians));
      const maxCurveStrength = 8 * compositeScale; // Reduced from 15 to 8 for less curve
      const leftAntennaCurveStrength = curveStrengthFactor * maxCurveStrength;
      
      // Right antenna - positioned above right eye with offset toward center
      const rightEyeX = adjustedWhiteX;
      const rightAntennaX = rightEyeX + (characterMidpointX - rightEyeX) * 0.3; // 10% offset toward center
      
      // Calculate curve for right antenna - INVERTED
      // When angle is between 0-180, curve RIGHT (positive bend)
      // When angle is between 180-360, curve LEFT (negative bend)
      const rightAntennaCurveFactor = (degrees >= 0 && degrees < 180) ? 1 : -1;
      const rightAntennaCurveStrength = curveStrengthFactor * maxCurveStrength;

      // Draw duplicate antennas that are always visible behind the body
      // Left antenna duplicate (always visible)
      // Draw curved antenna line
      ctx.beginPath();
      ctx.moveTo(leftAntennaX, topY);
      ctx.quadraticCurveTo(
        leftAntennaX + (leftAntennaCurveFactor * leftAntennaCurveStrength), // Control point X
        topY - antennaHeight/2, // Control point Y (halfway up)
        leftAntennaX, // End X (same as start for vertical antenna)
        topY - antennaHeight // End Y
      );
      ctx.lineWidth = 6 * compositeScale;
      ctx.strokeStyle = "black";
      ctx.stroke();
      
      // Draw antenna top circle
      ctx.beginPath();
      ctx.arc(leftAntennaX, topY - antennaHeight, antennaTopRadius, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();
      
      // Right antenna duplicate (always visible)
      // Draw curved antenna line
      ctx.beginPath();
      ctx.moveTo(rightAntennaX, topY);
      ctx.quadraticCurveTo(
        rightAntennaX + (rightAntennaCurveFactor * rightAntennaCurveStrength), // Control point X
        topY - antennaHeight/2, // Control point Y (halfway up)
        rightAntennaX, // End X (same as start for vertical antenna)
        topY - antennaHeight // End Y
      );
      ctx.lineWidth = 6 * compositeScale;
      ctx.strokeStyle = "black";
      ctx.stroke();
      
      // Draw antenna top circle
      ctx.beginPath();
      ctx.arc(rightAntennaX, topY - antennaHeight, antennaTopRadius, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();

      // Draw a copy of the mouth that's always visible behind the character
      ctx.beginPath();
      ctx.moveTo(mouthX, mouthY);
      ctx.lineCap = "round"; // Round the ends of the line
      ctx.lineWidth = mouthHeight;
      ctx.strokeStyle = "black";
      // Use the same smile curve as the front-facing mouth
      ctx.quadraticCurveTo(
        mouthX + adjustedMouthWidth/2, // Control point x (middle of the mouth)
        mouthY + smileCurvature,       // Control point y (BELOW the mouth line to curve upward)
        mouthX + adjustedMouthWidth,   // End point x
        mouthY                         // End point y
      );
      ctx.stroke();
      ctx.lineCap = "butt"; // Reset line cap

      // Define the dots on the body and arm bend values before drawing
      const purpleDotOnBody = {
        x: leftX,
        y: lowerY
      };

      const aquaDotOnBody = {
        x: rightX,
        y: lowerY
      };

      // Draw the body connection points
      // Purple dot
      ctx.beginPath();
      ctx.arc(purpleDotOnBody.x, purpleDotOnBody.y, 6 * compositeScale, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();

      // Aqua dot
      ctx.beginPath();
      ctx.arc(aquaDotOnBody.x, aquaDotOnBody.y, 6 * compositeScale, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();

      // Calculate purple arm bend with smooth transition
      const purpleLineBendValue = 1;
      // Determine target bend direction
      if (purpleCircle.x < purpleDotOnBody.x) {
        targetPurpleBend = purpleLineBendValue * -1; // Target inverted bend
      } else {
        targetPurpleBend = purpleLineBendValue; // Target normal bend
      }
      // Smoothly transition to target bend
      currentPurpleBend += (targetPurpleBend - currentPurpleBend) * bendTransitionSpeed;
      let adjustedPurpleBend = currentPurpleBend;

      // Calculate aqua arm bend with smooth transition
      const aquaLineBendValue = -1;
      // Determine target bend direction
      if (aquaCircle.x > aquaDotOnBody.x) {
        targetAquaBend = aquaLineBendValue * -1; // Target inverted bend
      } else {
        targetAquaBend = aquaLineBendValue; // Target normal bend
      }
      // Smoothly transition to target bend
      currentAquaBend += (targetAquaBend - currentAquaBend) * bendTransitionSpeed;
      let adjustedAquaBend = currentAquaBend;

      // FIRST DRAW THE PINK COPIES OF BOTH ARMS AND HANDS (behind the body)
      // Black copy of purple arm (behind the body)
      ctx.strokeStyle = "black";
      ctx.lineWidth = 6;
      drawLegLine(ctx, purpleDotOnBody.x, purpleDotOnBody.y, purpleCircle.x, purpleCircle.y, adjustedPurpleBend);

      // Black copy of purple hand (circle) - behind the body
      ctx.beginPath();
      ctx.arc(purpleCircle.x, purpleCircle.y, purpleCircle.radius * 1.5, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();

      // Black copy of aqua arm (behind the body)
      ctx.strokeStyle = "black";
      ctx.lineWidth = 6;
      drawLegLine(ctx, aquaDotOnBody.x, aquaDotOnBody.y, aquaCircle.x, aquaCircle.y, adjustedAquaBend);

      // Black copy of aqua hand (circle) - behind the body
      ctx.beginPath();
      ctx.arc(aquaCircle.x, aquaCircle.y, aquaCircle.radius * 1.5, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();

      // Add this line before using bodyWidthMultiplier
      const bodyWidthMultiplier = 1.0; // Define the body width multiplier
      
      // NOW DRAW THE BEE PILL BODY (which will cover the background circles)
      // First, draw just the yellow base of the bee body without stripes
      ctx.save();
      drawVerticalPill(ctx, pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), pullY, pullWidth * bodyWidthMultiplier, pullHeight);
      ctx.clip();
      
      // Base yellow color
      const baseYellow = "#FFDD00";
      
      // Draw the main body first (just the yellow part)
      const headSectionHeight = pullHeight * 0.45;
      const seamY = pullY + headSectionHeight;
      const headArc = headSectionHeight * 0.1;
      ctx.fillStyle = baseYellow;
      ctx.beginPath();
      ctx.moveTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), pullY);
      ctx.lineTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), seamY);
      ctx.quadraticCurveTo(
        pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier / 2, 
        seamY + headArc, 
        pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier, 
        seamY
      );
      ctx.lineTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier, pullY);
      ctx.closePath();
      ctx.fill();
      ctx.restore();

      // Store body dimensions for masking
      const bodyDimensions = {
        x: pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2),
        y: pullY,
        width: pullWidth * bodyWidthMultiplier,
        height: pullHeight
      };
      
      // Draw the front-facing eyes after the yellow base but before the stripes
      drawFrontEyePair(
        ctx, 
        adjustedRedX, 
        adjustedWhiteX, 
        upperY + eyeVerticalOffset, 
        circleRadius * 1.5, 
        degrees, 
        "black",
        bodyDimensions // Pass the body dimensions for masking
      );

      // Now draw the stripes on top of the eyes
      ctx.save();
      drawVerticalPill(ctx, pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), pullY, pullWidth * bodyWidthMultiplier, pullHeight);
      ctx.clip();
      
      // Draw the stripes
      const numStripes = 5;
      const heightRemaining = (pullY + pullHeight) - seamY;
      const stripeHeight = heightRemaining / numStripes;
      const stripeColours = ["black", baseYellow, "black", baseYellow, "black"];
      for (let i = 0; i < numStripes; i++) {
        const stripeY = seamY + i * stripeHeight;
        const stripeArc = stripeHeight * 0.2;
        ctx.fillStyle = stripeColours[i];
        ctx.beginPath();
        ctx.moveTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), stripeY);
        ctx.quadraticCurveTo(
          pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier / 2, 
          stripeY + stripeArc, 
          pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier, 
          stripeY
        );
        ctx.lineTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier, stripeY + stripeHeight);
        ctx.quadraticCurveTo(
          pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier / 2, 
          stripeY + stripeHeight + stripeArc, 
          pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), 
          stripeY + stripeHeight
        );
        ctx.closePath();
        ctx.fill();
      }
      ctx.restore();

      // THEN DRAW THE FOREGROUND CIRCLES (now black instead of pink and blue)
      // Only draw first foreground circle when angle IS between 220 and 30 degrees
      if (degrees <= 30 || degrees >= 220) {
        ctx.beginPath();
        ctx.arc(invertedBrownX, middleY, uniformCircleSize, 0, Math.PI * 2);
        ctx.fillStyle = "black";
        ctx.fill();
      }

      // Draw second foreground circle - only when angle is greater than 320 or less than 140
      if (degrees < 140 || degrees > 320) {
        ctx.beginPath();
        ctx.arc(invertedGreyX, middleY, uniformCircleSize, 0, Math.PI * 2);
        ctx.fillStyle = "black";
        ctx.fill();
      }

      // Update purple circle position to follow the character
      if (!purpleCircle.dragging) {
        // Calculate the base position
        purpleCircle.baseY = centerY + 75 * compositeScale + purpleCircle.heightOffset + purpleCircle.customHeightOffset;
        purpleCircle.y = purpleCircle.baseY;
        
        // Calculate x position based on angle and distance from center
        let purpleX;
        if (degrees <= 180) {
          const progress = (180 - degrees) / 180;
          const targetX = centerX + (purpleCircle.distanceFromCenter * -1);
          purpleX = centerX + purpleCircle.distanceFromCenter + (targetX - (centerX + purpleCircle.distanceFromCenter)) * progress;
        } else {
          const progress = (degrees - 180) / 180;
          const targetX = centerX + (purpleCircle.distanceFromCenter * -1);
          purpleX = centerX + purpleCircle.distanceFromCenter + (targetX - (centerX + purpleCircle.distanceFromCenter)) * progress;
        }
        if (degrees === 90 || degrees === 270) { purpleX = centerX; }
        purpleCircle.x = purpleX;
      }
      
      // Update aqua circle position similarly
      if (!aquaCircle.dragging) {
        // Calculate the base position
        aquaCircle.baseY = centerY + 75 * compositeScale + aquaCircle.heightOffset + aquaCircle.customHeightOffset;
        aquaCircle.y = aquaCircle.baseY;
        
        // Calculate x position based on angle and distance from center
        let aquaX;
        if (degrees <= 180) {
          const progress = (180 - degrees) / 180;
          const targetX = centerX + (aquaCircle.distanceFromCenter * -1);
          aquaX = centerX + aquaCircle.distanceFromCenter + (targetX - (centerX + aquaCircle.distanceFromCenter)) * progress;
        } else {
          const progress = (degrees - 180) / 180;
          const targetX = centerX + (aquaCircle.distanceFromCenter * -1);
          aquaX = centerX + aquaCircle.distanceFromCenter + (targetX - (centerX + aquaCircle.distanceFromCenter)) * progress;
        }
        if (degrees === 90 || degrees === 270) { aquaX = centerX; }
        aquaCircle.x = aquaX;
      }

      // Draw draggable orange circle.
      ctx.beginPath();
      ctx.arc(orangeCircleYellow.x, orangeCircleYellow.y, orangeCircleYellow.radius, 0, Math.PI * 2);
      ctx.fillStyle = orangeCircleYellow.colour;
      ctx.fill();

      // Display current angle.
      ctx.fillStyle = 'black';
      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${degrees}°`, orangeCircleYellow.x, orangeCircleYellow.y);

      // Draw offset values.
      ctx.font = '16px Arial';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'left';
      ctx.fillText(`X Offset: ${Math.round(orangeCircleYellow.x - centerX)}`, 10, 30);
      ctx.fillText(`Y Offset: ${Math.round(orangeCircleYellow.y - centerY)}`, 10, 50);

      // Only draw left antenna when angle is between 70 and 220 degrees
      if (degrees >= 70 && degrees <= 220) {
        // Draw curved antenna line
        ctx.beginPath();
        ctx.moveTo(leftAntennaX, topY);
        ctx.quadraticCurveTo(
          leftAntennaX + (leftAntennaCurveFactor * leftAntennaCurveStrength), // Control point X
          topY - antennaHeight/2, // Control point Y (halfway up)
          leftAntennaX, // End X (same as start for vertical antenna)
          topY - antennaHeight // End Y
        );
        ctx.lineWidth = 6 * compositeScale; // Increased from 4 to 6 for thicker antennas
        ctx.strokeStyle = "black";
        ctx.stroke();
        
        // Draw antenna top circle
        ctx.beginPath();
        ctx.arc(leftAntennaX, topY - antennaHeight, antennaTopRadius, 0, Math.PI * 2);
        ctx.fillStyle = "black";
        ctx.fill();
      }
      
      // Right antenna - positioned above right eye with offset toward center
      if (degrees >= 90 && degrees <= 280) {
        // Draw curved antenna line
        ctx.beginPath();
        ctx.moveTo(rightAntennaX, topY);
        ctx.quadraticCurveTo(
          rightAntennaX + (rightAntennaCurveFactor * rightAntennaCurveStrength), // Control point X
          topY - antennaHeight/2, // Control point Y (halfway up)
          rightAntennaX, // End X (same as start for vertical antenna)
          topY - antennaHeight // End Y
        );
        ctx.lineWidth = 6 * compositeScale; // Increased from 4 to 6 for thicker antennas
        ctx.strokeStyle = "black";
        ctx.stroke();
        
        // Draw antenna top circle
        ctx.beginPath();
        ctx.arc(rightAntennaX, topY - antennaHeight, antennaTopRadius, 0, Math.PI * 2);
        ctx.fillStyle = "black";
        ctx.fill();
      }

      // Draw the black mouth line after the body (so it appears on top)
      // Only draw the black mouth when angle is between 90 and 270 degrees
      if (degrees >= 80 && degrees <= 280) {
        ctx.beginPath();
        // Instead of drawing a straight line, use a quadratic curve for a smile
        ctx.moveTo(mouthX, mouthY);
        ctx.lineCap = "round"; // Round the ends of the line
        ctx.lineWidth = mouthHeight;
        ctx.strokeStyle = "black";
        // Flip the curve by changing the control point to be BELOW the line
        ctx.quadraticCurveTo(
          mouthX + adjustedMouthWidth/2, // Control point x (middle of the mouth)
          mouthY + smileCurvature,       // Control point y (BELOW the mouth line to curve upward)
          mouthX + adjustedMouthWidth,   // End point x
          mouthY                         // End point y
        );
        ctx.stroke();
        ctx.lineCap = "butt"; // Reset line cap
      }

      // After drawing the bee pill body, draw the front-facing arms when needed
      // Only draw the black right arm and hand when angle is between 180 and 360 degrees
      if (degrees > 0 && degrees <= 180) {
        // Draw the purple arm and hand on top
        ctx.strokeStyle = "black";
        ctx.lineWidth = 6;
        drawLegLine(ctx, purpleDotOnBody.x, purpleDotOnBody.y, purpleCircle.x, purpleCircle.y, adjustedPurpleBend);
        
        // Draw the purple hand (circle)
        ctx.beginPath();
        ctx.arc(purpleCircle.x, purpleCircle.y, purpleCircle.radius * 1.5, 0, Math.PI * 2);
        ctx.fillStyle = "black";
        ctx.fill();
      }

      // Only draw the black left arm and hand when angle is between 0 and 180 degrees
      if (degrees > 180 && degrees <= 360) {
        // Draw the aqua arm and hand on top
        ctx.strokeStyle = "black";
        ctx.lineWidth = 6;
        drawLegLine(ctx, aquaDotOnBody.x, aquaDotOnBody.y, aquaCircle.x, aquaCircle.y, adjustedAquaBend);
        
        // Draw the aqua hand (circle)
        ctx.beginPath();
        ctx.arc(aquaCircle.x, aquaCircle.y, aquaCircle.radius * 1.5, 0, Math.PI * 2);
        ctx.fillStyle = "black";
        ctx.fill();
      }

      // Another eye (purple circle) - only visible when angle is above 260 or below 90 //THIS IS THE TAIL FOR THE BEE
      // This is drawn AFTER the bee body so it appears on top
      if (degrees < 90 || degrees > 260) {
        ctx.beginPath();
        ctx.arc(blackX, blackY, eyeRadius, 0, Math.PI * 2); // Using the same blackY value that was moved down
        ctx.fillStyle = "black";
        ctx.fill();
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;
        ctx.stroke();
      }
    }

    // ---------- Event Listeners for the BREATHING ANIMATION ----------
    const handleMouseDown = function(evt) {
      const pos = getMousePos(evt);
      
      // Check if clicking on the yellow character's orange circle first
      const distOrange = Math.hypot(pos.x - orangeCircleYellow.x, pos.y - orangeCircleYellow.y);
      if (distOrange < orangeCircleYellow.radius) {
        // If clicking on orange circle, don't allow character dragging
        orangeCircleYellow.dragging = true;
        orangeOffsetXYellow = pos.x - orangeCircleYellow.x;
        orangeOffsetYYellow = pos.y - orangeCircleYellow.y;
        return; // Exit early to prevent character dragging
      }
      
      // Always set clickInitiatedOnCircle to false since we disabled dragging
      clickInitiatedOnCircle = false;
    };

    const handleMouseMove = function(evt) {
      if (dragging) {
        var pos = getMousePos(evt);
        circle.x = pos.x - dragOffsetX;
        if (onFloor) { circle.y = floorFootY - dot.offsetY; }
        else { circle.y = pos.y - dragOffsetY; }
        leftFoot.x = circle.x - dot.offsetX;
        leftFoot.y = circle.y + dot.offsetY;
        rightFoot.x = circle.x + dot.offsetX;
        rightFoot.y = circle.y + dot.offsetY;
        drawBreathingCharacter();
      }
    };

    const handleMouseUp = function() {
      dragging = false;
    };

    const handleMouseLeave = function() {
      dragging = false;
    };

    const handleClick = function(evt) {
      if (!clickInitiatedOnCircle) {
        var pos = getMousePos(evt);
        targetCircle = { x: pos.x, y: pos.y };
        transitionActive = true;
        transitionStartTime = performance.now();
        startPos = { x: circle.x, y: circle.y };
        targetPos = { x: pos.x, y: pos.y };
        // NEW: Record green circle start position for its transition
        // greenCircleStartPos = { x: greenCircle.x, y: greenCircle.y };
        
        // Calculate distance-based duration
        var dx = targetPos.x - startPos.x;
        var dy = targetPos.y - startPos.y;
        var distance = Math.sqrt(dx * dx + dy * dy);
        var maxDistance = Math.sqrt(canvas.width * canvas.width + canvas.height * canvas.height);
        var maxDuration = 5000; // 5 seconds for max distance
        currentTransitionDuration = Math.max(
          transitionDuration, // Minimum duration (2000ms)
          transitionDuration + (maxDuration - transitionDuration) * (distance / maxDistance)
        );
      }
      clickInitiatedOnCircle = false;
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mouseleave", handleMouseLeave);
    canvas.addEventListener("click", handleClick);

    // Toggle gravity button.
    document.getElementById("toggleGravity").addEventListener("click", function() {
      gravityOn = !gravityOn;
      this.textContent = gravityOn ? "Disable Gravity" : "Enable Gravity";
    });

    // Movement speed dial.
    var speedDial = document.getElementById("speedDial");
    var speedValueDisplay = document.getElementById("speedValue");
    speedDial.addEventListener("input", function() {
      moveSpeed = Number(this.value);
      speedValueDisplay.textContent = moveSpeed;
    });
    var leftButton = document.getElementById("moveLeft");
    var rightButton = document.getElementById("moveRight");
    leftButton.addEventListener("mousedown", function() {
      currentDirection = "left";
      circle.x = Math.max(circle.radius, circle.x - moveSpeed);
      leftInterval = setInterval(() => {
        circle.x = Math.max(circle.radius, circle.x - moveSpeed);
      }, intervalDelay);
    });
    leftButton.addEventListener("mouseup", function() { clearInterval(leftInterval); currentDirection = null; });
    leftButton.addEventListener("mouseleave", function() { clearInterval(leftInterval); currentDirection = null; });
    rightButton.addEventListener("mousedown", function() {
      currentDirection = "right";
      circle.x = Math.min(canvas.width - circle.radius, circle.x + moveSpeed);
      rightInterval = setInterval(() => {
        circle.x = Math.min(canvas.width - circle.radius, circle.x + moveSpeed);
      }, intervalDelay);
    });
    rightButton.addEventListener("mouseup", function() { clearInterval(rightInterval); currentDirection = null; });
    rightButton.addEventListener("mouseleave", function() { clearInterval(rightInterval); currentDirection = null; });

    // ---------- Event Listeners for the YELLOW CHARACTER ----------
    function yellowHandleMouseDown(e) {
      const pos = getMousePos(e);
      
      // Check the yellow character's orange circle first
      const distOrange = Math.hypot(pos.x - orangeCircleYellow.x, pos.y - orangeCircleYellow.y);
      if (distOrange < orangeCircleYellow.radius) {
        orangeCircleYellow.dragging = true;
        orangeCircleYellow.positionFixed = true;
        orangeOffsetXYellow = pos.x - orangeCircleYellow.x;
        orangeOffsetYYellow = pos.y - orangeCircleYellow.y;
        return;
      }
      
      // Check purple circle
      const distPurple = Math.hypot(pos.x - purpleCircle.x, pos.y - purpleCircle.y);
      if (distPurple < purpleCircle.radius * 1.5) { // Increased hit area to match visual size
        purpleCircle.dragging = true;
        purpleOffsetX = pos.x - purpleCircle.x;
        purpleOffsetY = pos.y - purpleCircle.y;
        return;
      }
      
      // Check aqua circle
      const distAqua = Math.hypot(pos.x - aquaCircle.x, pos.y - aquaCircle.y);
      if (distAqua < aquaCircle.radius * 1.5) { // Increased hit area to match visual size
        aquaCircle.dragging = true;
        aquaOffsetX = pos.x - aquaCircle.x;
        aquaOffsetY = pos.y - aquaCircle.y;
        return;
      }
    }

    function yellowHandleMouseMove(e) {
      const pos = getMousePos(e);
      const centerX = rectangle.x + rectangle.width / 2;
      
      // Calculate pullWidth, pullHeight, and pullY the same way as in drawYellowCharacter
      const pullWidth = rectangle.width * 1.2 * 0.9;
      const pullHeight = 120 * compositeScale;
      const pullY = rectangle.y - pullHeight / 2 + 20 * compositeScale;
      const centerY = pullY + pullHeight / 2;
      
      if (purpleCircle.dragging) {
        // Set the immediate position for dragging
        purpleCircle.x = pos.x - purpleOffsetX;
        purpleCircle.y = pos.y - purpleOffsetY;
        
        // Store the offsets relative to the character's center
        purpleCircle.distanceFromCenter = purpleCircle.x - centerX;
        purpleCircle.customHeightOffset = purpleCircle.y - (centerY + 75 * compositeScale + purpleCircle.heightOffset);
      }
      
      if (orangeCircleYellow.dragging) {
        orangeCircleYellow.x = pos.x - orangeOffsetXYellow;
        orangeCircleYellow.y = pos.y - orangeOffsetYYellow;
      }
      
      if (aquaCircle.dragging) {
        // Set the immediate position for dragging
        aquaCircle.x = pos.x - aquaOffsetX;
        aquaCircle.y = pos.y - aquaOffsetY;
        
        // Store the offsets relative to the character's center
        aquaCircle.distanceFromCenter = aquaCircle.x - centerX;
        aquaCircle.customHeightOffset = aquaCircle.y - (centerY + 75 * compositeScale + aquaCircle.heightOffset);
      }
    }

    function yellowHandleMouseUp(e) {
      if (orangeCircleYellow.dragging) {
        orangeCircleYellow.positionFixed = true; // Set the flag when releasing the orange circle
      }
      purpleCircle.dragging = false;
      aquaCircle.dragging = false;
      orangeCircleYellow.dragging = false;
      rectangle.dragging = false;
    }

    canvas.addEventListener("mousedown", yellowHandleMouseDown);
    canvas.addEventListener("mousemove", yellowHandleMouseMove);
    canvas.addEventListener("mouseup", yellowHandleMouseUp);

    // ---------- ANIMATION LOOP ----------
    function animate() {
      const currentTime = performance.now();
      const deltaTime = currentTime - lastTime;
      
      // Update bend based on movement and transitions.
      var targetBend;
      if (transitionActive) {
        var overallDx = targetPos.x - startPos.x;
        targetBend = overallDx > 0 ? -1 : 1;
      } else if (currentDirection === "right") {
        targetBend = -1;
      } else if (currentDirection === "left") {
        targetBend = 1;
      } else {
        var dx = circle.x - lastCircleX;
        targetBend = Math.abs(dx) > 0.1 ? (dx > 0 ? -1 : 1) : currentBend;
      }
      currentBend += (targetBend - currentBend) * 0.05;

      // Handle transition for the main circle.
      if (transitionActive) {
        var elapsed = currentTime - transitionStartTime;
        var t = Math.min(elapsed / currentTransitionDuration, 1);
        var easedT = easeInOut(t);
        circle.x = startPos.x + (targetPos.x - startPos.x) * easedT;
        circle.y = startPos.y + (targetPos.y - startPos.y) * easedT;
        if (t === 1) { transitionActive = false; targetCircle = null; }
      } else if (gravityOn && !dragging) {
        circle.velocityY += gravityAcceleration;
        circle.y += circle.velocityY;
      }

      checkFloorCollision();
      triggerLandingAnimation();
      if (onFloor) { updateLandingAnimation(); }
      
      // Update feet using BeeLegs module
      const { updatedLastSteppedFoot } = updateFeet(
        circle, 
        leftFoot, 
        rightFoot, 
        dot, 
        stepThreshold, 
        stepHeight, 
        lastCircleX, 
        onFloor,
        lastSteppedFoot
      );
      if (updatedLastSteppedFoot) {
        lastSteppedFoot = updatedLastSteppedFoot;
      }
      
      // Update foot steps with time-based animation
      updateFootStep(leftFoot, stepHeight, deltaTime, stepSpeed);
      updateFootStep(rightFoot, stepHeight, deltaTime, stepSpeed);
      
      if (!leftFoot.isStepping) leftFoot.y = circle.y + dot.offsetY;
      if (!rightFoot.isStepping) rightFoot.y = circle.y + dot.offsetY;

      // Update red circle to follow smoothly using BeeLegs module
      updateRedCircle(redCircle, redCircleVelocity, circle, bounce, onFloor);

      // Draw both parts.
      drawBreathingCharacter();
      drawYellowCharacter();
      
      lastCircleX = circle.x;
      wasOnFloor = onFloor;
      lastTime = currentTime; // Update lastTime at the end of each frame
      requestAnimationFrame(animate);
    }

    animate();

    window.addEventListener("resize", () => {
      canvas.width = 1600; // or window.innerWidth if desired
      canvas.height = 1200; // or window.innerHeight if desired
    });

    // ---------- CLEANUP ----------
    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mouseleave", handleMouseLeave);
      canvas.removeEventListener("click", handleClick);
      canvas.removeEventListener("mousedown", yellowHandleMouseDown);
      canvas.removeEventListener("mousemove", yellowHandleMouseMove);
      canvas.removeEventListener("mouseup", yellowHandleMouseUp);
      document.removeEventListener('updateCharacterConfig', handleConfigUpdate);
    };
  }, []); // Run once on mount

  return (
    <div className="flex flex-col items-center">
      <canvas 
        ref={canvasRef} 
        width={1600}
        height={1200}
        className="mb-5 border border-black border-solid"
      />
      <button 
        id="toggleGravity"
        className="px-5 py-2.5 text-base mb-2.5"
      >
        Enable Gravity
      </button>
      <div className="flex items-center text-base m-2.5">
        <label htmlF2or="speedDial" className="mr-2.5">
          Move Speed: <span id="speedValue">10</span>
        </label>
        <input 
          type="range" 
          id="speedDial" 
          min="1" 
          max="20" 
          defaultValue="10"
        />
      </div>
      <div className="flex justify-center mt-2.5">
        <button id="moveLeft" className="px-5 py-2.5 text-base mx-1.25">←</button>
        <button id="moveRight" className="px-5 py-2.5 text-base mx-1.25">→</button>
      </div>
    </div>
  );
};

export default BreathingAnimation;