// src/components/Teachers/Build/Scene.jsx

import React, { useEffect, useCallback, Suspense, useMemo, useState, useRef } from 'react';
import { useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import FirstPersonControls from './FirstPersonControls';
import PropTypes from 'prop-types';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { Stats } from '@react-three/drei';
import pzImage from './voxelassets/pz.png';  // You already have this
import nzImage from './voxelassets/nz.png';  // You'll need to add these files
import pxImage from './voxelassets/px.png';
import nxImage from './voxelassets/nx.png';
import pyImage from './voxelassets/py.png';
import nyImage from './voxelassets/ny.png';
import { v4 as uuidv4 } from 'uuid';
import HexagonalBase from './HexagonalBaseMain'; // Import the HexagonalBase component
import HexagonalBaseEmpty from './HexagonalBaseEmpty'; // Add import for HexagonalBaseEmpty

// Define MemoizedHexagonalBaseEmpty outside of the component to prevent redefinition on each render
// Add a proper comparison function to prevent unnecessary re-renders
const MemoizedHexagonalBaseEmpty = React.memo(HexagonalBaseEmpty, (prevProps, nextProps) => {
  // Only re-render if these props change
  return (
    prevProps.position[0] === nextProps.position[0] &&
    prevProps.position[1] === nextProps.position[1] &&
    prevProps.position[2] === nextProps.position[2] &&
    prevProps.radius === nextProps.radius &&
    prevProps.height === nextProps.height &&
    prevProps.color === nextProps.color &&
    prevProps.bottomOffset === nextProps.bottomOffset
  );
});

// GroundComponent needs to accept isInsideHexagon as a prop
const GroundComponent = ({ position, rotation, onPointerDown, removeBlock, isInsideHexagon }) => {
  // Use useCallback to prevent recreating this function on every render
  const handleGroundClick = useCallback((event) => {
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }

    event.stopPropagation();

    const { button, point } = event;

    const x = Math.round(point.x);
    const z = Math.round(point.z);
    const y = 0; // Ground level

    if (button === 0) { // Left-click to add voxel
      // Only add block if it's within the hexagonal boundaries
      if (isInsideHexagon(x, z)) {
        onPointerDown(x, y, z, 1); // Pass size=1
      } else {
        console.log(`Cannot add block at (${x}, ${y}, ${z}): outside hexagonal boundaries`);
      }
    } else if (button === 2) { // Right-click to remove voxel
      // Remove block by passing an array
      removeBlock([x, y, z]);
    }
  }, [onPointerDown, removeBlock, isInsideHexagon]);

  return (
    <mesh
      position={position}
      rotation={rotation}
      receiveShadow
      onPointerDown={handleGroundClick}
      onContextMenu={(e) => {
        if (e && typeof e.preventDefault === 'function') {
          e.preventDefault();
        }
      }} // Prevent context menu on right-click
    >
      <planeGeometry args={[100, 100]} /> {/* Increased size to cover the hexagonal area */}
      <meshStandardMaterial 
        color="#a8e6a8" 
        wireframe={false}
        opacity={0}          // Make the ground invisible
        transparent={true}   // Enable transparency
      />
    </mesh>
  );
};

// Update propTypes for GroundComponent
GroundComponent.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  rotation: PropTypes.arrayOf(PropTypes.number).isRequired,
  onPointerDown: PropTypes.func.isRequired,
  removeBlock: PropTypes.func.isRequired,
  isInsideHexagon: PropTypes.func.isRequired, // Add this prop type
};

const GrassField = React.memo(({ startPos, gridSize, renderOrder, depthWrite }) => {
  const instancedMeshRef = useRef();
  const grassCount = gridSize * gridSize * 4; // 24 grass instances per voxel
  const dummy = useMemo(() => new THREE.Object3D(), []);
  const { camera } = useThree();

  // Update grass geometry to have pointed tips
  const grassGeometry = useMemo(() => {
    const height = .8;      // Total height of grass blade
    const strandWidth = 0.15; // Width of each strand
    const segments = 4;      // Number of segments
    const geo = new THREE.BufferGeometry();
    
    const positions = [];
    const uvs = [];
    
    const rowOffsets = [
      -0.3,  // Back row
      0,     // Middle row
      0.3    // Front row
    ];
    
    const strands = [
      { angle: -0.8, zOffset: -0.2 },
      { angle: -0.6, zOffset: -0.1 },
      { angle: -0.3, zOffset: 0.1 },
      { angle: -0.1, zOffset: 0.2 },
      { angle: 0.1, zOffset: 0.15 },
      { angle: 0.3, zOffset: 0.0 },
      { angle: 0.6, zOffset: -0.15 },
      { angle: 0.8, zOffset: -0.25 }
    ];
    
    // Create strands for each row
    rowOffsets.forEach(rowOffset => {
      strands.forEach(({ angle, zOffset }) => {
        const baseX = angle * 0.01;
        const topX = angle * 1.2;
        const controlX = angle * 0.6;
        
        // Create segments for the blade
        for (let i = 0; i < segments - 1; i++) {
          const t = i / (segments - 1);
          const nextT = (i + 1) / (segments - 1);
          
          const t2 = t * t;
          const t1 = 1 - t;
          const t12 = t1 * t1;
          
          const nextT2 = nextT * nextT;
          const nextT1 = 1 - nextT;
          const nextT12 = nextT1 * nextT1;
          
          const x1 = baseX * t12 + controlX * 2 * t1 * t + topX * t2;
          const y1 = t * height;
          
          const x2 = baseX * nextT12 + controlX * 2 * nextT1 * nextT + topX * nextT2;
          const y2 = nextT * height;
          
          const curveIntensity = Math.pow(t, 1.5) * 0.3;
          const nextCurveIntensity = Math.pow(nextT, 1.5) * 0.3;
          
          const z1 = zOffset * t + rowOffset;
          const z2 = zOffset * nextT + rowOffset;

          // For the last segment, create a pointed tip
          if (i === segments - 2) {
            // Create triangles that converge to a point
            positions.push(
              x1 - strandWidth/2 + curveIntensity, y1, z1,       // bottom left
              x1 + strandWidth/2 + curveIntensity, y1, z1,       // bottom right
              x2, y2, z2                                         // top point
            );
            
            // UVs for the pointed tip
            uvs.push(
              0, t,
              1, t,
              0.5, 1
            );
          } else {
            // Regular segments
            positions.push(
              x1 - strandWidth/2 + curveIntensity, y1, z1,
              x1 + strandWidth/2 + curveIntensity, y1, z1,
              x2 - strandWidth/2 + nextCurveIntensity, y2, z2,
              
              x1 + strandWidth/2 + curveIntensity, y1, z1,
              x2 + strandWidth/2 + nextCurveIntensity, y2, z2,
              x2 - strandWidth/2 + nextCurveIntensity, y2, z2
            );
            
            // UVs for regular segments
            uvs.push(
              0, t,
              1, t,
              0, nextT,
              1, t,
              1, nextT,
              0, nextT
            );
          }
        }
      });
    });
    
    geo.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geo.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
    
    // Add random variation to z-position
    const positionAttribute = geo.attributes.position;
    for (let i = 0; i < positionAttribute.count; i++) {
      const y = positionAttribute.getY(i);
      if (y > 0) {
        const heightFactor = y / height;
        positionAttribute.setZ(i, positionAttribute.getZ(i) + (Math.random() - 0.5) * 0.1 * heightFactor);
      }
    }
    
    geo.computeVertexNormals();
    geo.translate(0, -.5, 0);
    return geo;
  }, []);

  // Optimize shader by reducing precision and calculations
  const grassMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        windStrength: { value: 1.0 },
        customCameraPosition: { value: new THREE.Vector3() },
        darkColor: { value: new THREE.Color(0x2a4601) },  // Slightly brighter base
        lightColor: { value: new THREE.Color(0x5da32c) }  // Brighter top color
      },
      vertexShader: `
        // Add precision hints at the top
        precision mediump float;
        precision mediump int;
        
        uniform float time;
        uniform float windStrength;
        uniform vec3 customCameraPosition;
        
        attribute vec3 instanceOffset;
        attribute float windOffset;
        attribute float heightScale;
        attribute float colorOffset;
        
        varying vec2 vUv;
        varying float vVisibility;
        varying float vColorOffset;
        varying float vShouldDiscard;
        
        // Pre-calculate constants
        const float PI = 3.14159;
        const float DISTANCE_NEAR = 25.0;
        const float DISTANCE_FAR = 85.0;
        const float DISTANCE_RANGE = DISTANCE_FAR - DISTANCE_NEAR;
        
        void main() {
          vUv = uv;
          vColorOffset = colorOffset;
          vShouldDiscard = 0.0;
          
          vec3 pos = position;
          pos.y *= heightScale;
          
          vec3 worldPos = instanceOffset + pos;
          
          // Optimize vector calculations
          vec3 toCamera = customCameraPosition - worldPos;
          float distanceToCamera = length(toCamera);
          toCamera *= 1.0/distanceToCamera; // Normalize after length calculation
          
          // Simplified dot product with up vector (0,1,0)
          float dotProduct = toCamera.y;
          
          // Optimized distance culling
          if(distanceToCamera > DISTANCE_NEAR) {
            float cutoffThreshold = (distanceToCamera - DISTANCE_NEAR) / DISTANCE_RANGE;
            if(fract(windOffset * 43758.5453) < cutoffThreshold * 0.85) {
              vShouldDiscard = 1.0;
            }
          }
          
          // Simplified back-face culling
          vShouldDiscard += step(dotProduct, -0.2);
          
          vVisibility = 1.0 - (distanceToCamera - DISTANCE_NEAR) / DISTANCE_RANGE;
          
          // Distance limits
          vShouldDiscard += step(DISTANCE_FAR, distanceToCamera);
          vShouldDiscard += step(distanceToCamera, 0.5);
          
          // Modified wind calculation - apply less movement at base
          float heightRatio = pos.y / (0.9 * heightScale);
          float windPhase = time * 2.0 + windOffset;
          float bendPower = heightRatio * heightRatio;
          
          // Only apply wind if not at the base (y > 0)
          if (pos.y > -0.45) {  // Adjusted threshold to match the grass base position
            // Gradually increase movement from base to tip
            float baseToTip = smoothstep(-0.45, 0.3, pos.y);
            
            // Combined wind movements with base anchoring
            float windX = windStrength * sin(windPhase) * 2.0 * bendPower * 0.8 * baseToTip;
            windX += sin(heightRatio * 3.0 + time * 2.0) * 0.15 * heightRatio * baseToTip;
            
            // Simplified strand movement with base anchoring
            float strandPhase = floor(position.x * 5.0) * 2.0;
            vec2 strandOffset = vec2(
              sin(time * 2.0 + strandPhase),
              cos(time * 1.5 + strandPhase)
            ) * 0.05 * heightRatio * baseToTip;
            
            pos.xz += strandOffset;
            pos.x += windX;
          }

          // Optimized billboarding - Modified to handle camera height smoothly
          float randomAngle = windOffset * 6.28318;
          float c = cos(randomAngle);
          float s = sin(randomAngle);
          
          // Create camera-facing direction that adapts based on camera height
          vec3 cameraDir = normalize(customCameraPosition - instanceOffset);
          vec3 worldUp = vec3(0.0, 1.0, 0.0);
          
          // Calculate camera height factor (0 when camera is at grass level, 1 when directly above)
          float heightFactor = smoothstep(-0.2, 0.8, dot(cameraDir, worldUp));
          
          // Blend between full billboarding and vertical orientation based on camera height
          vec3 right = normalize(cross(cameraDir, worldUp));
          vec3 forward = normalize(cross(worldUp, right));
          
          // Interpolate between regular billboarding and vertical orientation
          vec3 blendedRight = mix(right, vec3(c, 0.0, s), heightFactor);
          vec3 blendedForward = mix(forward, vec3(-s, 0.0, c), heightFactor);
          
          // Apply the blended transformation while maintaining y-axis scaling
          vec3 finalPos = instanceOffset + 
            blendedRight * pos.x + 
            worldUp * pos.y + 
            blendedForward * pos.z;
          
          vec4 finalPosition = modelViewMatrix * vec4(finalPos, 1.0);
          
          gl_Position = projectionMatrix * finalPosition;
        }
      `,
      fragmentShader: `
        precision mediump float;
        
        uniform vec3 darkColor;
        uniform vec3 lightColor;
        varying vec2 vUv;
        varying float vVisibility;
        varying float vColorOffset;
        varying float vShouldDiscard;
        
        vec3 hsv2rgb(vec3 c) {
          vec3 rgb = clamp(abs(mod(c.x * 6.0 + vec3(0.0,4.0,2.0), 6.0) - 3.0) - 1.0, 0.0, 1.0);
          return c.z * mix(vec3(1.0), rgb, c.y);
        }
        
        void main() {
          if(vShouldDiscard > 0.5) discard;
          if(vVisibility <= 0.0) discard;
          
          vec3 baseColor = mix(darkColor, lightColor, vUv.y * 0.7); // Slightly increased mixing
          vec3 variedColor = hsv2rgb(vec3(
            0.22 + vColorOffset * 0.1,   // Same hue variation
            0.65 + vColorOffset * 0.2,   // Slightly higher saturation
            0.6 + vUv.y * 0.3           // Slightly higher brightness base
          ));
          
          gl_FragColor = vec4(mix(baseColor, variedColor, 0.3), 1.0);
          
          if(gl_FragColor.a < 0.1) discard;
        }
      `,
      side: THREE.DoubleSide,
      transparent: false,
      depthWrite: true,
      depthTest: true,
      cullFace: THREE.BackSide,
      blending: THREE.NormalBlending,
      dithering: false,
    });
  }, []);

  // Optimize instance updates with TypedArrays
  useEffect(() => {
    if (!instancedMeshRef.current) return;

    const [startX, startY, startZ] = startPos;
    const instanceCount = grassCount;
    
    // Pre-allocate arrays
    const instanceOffsets = new Float32Array(instanceCount * 3);
    const windOffsets = new Float32Array(instanceCount);
    const heightScales = new Float32Array(instanceCount);    
    const colorOffsets = new Float32Array(instanceCount);
    
    // Use a single random number generator
    const random = () => Math.random();
    
    // Batch process instances - increased spread radius
    for (let i = 0; i < instanceCount; i++) {
      const idx = i * 3;
      // Increased spread radius and added circular distribution
      const angle = random() * Math.PI * 2;
      const radius = 0.35 * Math.sqrt(random()); // Sqrt for more uniform circular distribution
      
      instanceOffsets[idx] = startX + Math.cos(angle) * radius;
      instanceOffsets[idx + 1] = startY;
      instanceOffsets[idx + 2] = startZ + Math.sin(angle) * radius;
      
      windOffsets[i] = random() * Math.PI * 2;
      heightScales[i] = 0.8 + random() * 1.2;  // More height variation    
      colorOffsets[i] = random();                 
    }

    // Batch attribute updates
    const geometry = instancedMeshRef.current.geometry;
    geometry.setAttribute('instanceOffset', new THREE.InstancedBufferAttribute(instanceOffsets, 3));
    geometry.setAttribute('windOffset', new THREE.InstancedBufferAttribute(windOffsets, 1));
    geometry.setAttribute('heightScale', new THREE.InstancedBufferAttribute(heightScales, 1));
    geometry.setAttribute('colorOffset', new THREE.InstancedBufferAttribute(colorOffsets, 1));
  }, [startPos, gridSize, grassCount]);

  // Update uniforms every frame
  useFrame((state) => {
    if (!instancedMeshRef.current?.material?.uniforms) return;
    
    const uniforms = instancedMeshRef.current.material.uniforms;
    uniforms.time.value = state.clock.getElapsedTime();
    uniforms.windStrength.value = 1.0;
    uniforms.customCameraPosition.value.copy(camera.position);
  });

  return (
    <instancedMesh
      ref={instancedMeshRef}
      args={[grassGeometry, grassMaterial, grassCount]}
      frustumCulled={false}
      renderOrder={renderOrder}
    />
  );
});

GrassField.propTypes = {
  startPos: PropTypes.arrayOf(PropTypes.number).isRequired,
  gridSize: PropTypes.number.isRequired,
  renderOrder: PropTypes.number.isRequired,
  depthWrite: PropTypes.bool.isRequired,
};

const Scene = ({
  blocks,
  addBlock,
  removeBlock,
  currentColor,
  placementSize,
  onFaceCount,
  touchMode,
  isInventoryOpen,
  setIsInventoryOpen,
}) => {
  const { camera, scene, gl } = useThree();

  // State to handle cube texture loading errors
  const [cubeTexture, setCubeTexture] = useState(null);

  // Directions for each face
  const FACE_DIRECTIONS = useMemo(() => [
    { name: 'front', normal: [0, 0, 1], rotation: [0, 0, 0], shadeFactor: 0.8 },
    { name: 'back', normal: [0, 0, -1], rotation: [0, Math.PI, 0], shadeFactor: 0.8 },
    { name: 'top', normal: [0, 1, 0], rotation: [-Math.PI / 2, 0, 0], shadeFactor: 1.0 },
    { name: 'bottom', normal: [0, -1, 0], rotation: [Math.PI / 2, 0, 0], shadeFactor: 0.5 },
    { name: 'right', normal: [-1, 0, 0], rotation: [0, -Math.PI / 2, 0], shadeFactor: 0.7 },
    { name: 'left', normal: [1, 0, 0], rotation: [0, Math.PI / 2, 0], shadeFactor: 0.7 },
  ], []);

  // Optimize the occupiedPositions Set creation with useMemo
  const occupiedPositions = useMemo(() => {
    const set = new Set();
    blocks.forEach(block => {
      const [x, y, z] = block.position;
      set.add(`${x},${y},${z}`);
    });
    return set;
  }, [blocks]); // Only recreate when blocks array changes

  // Optimize block lookup with a Map
  const blockMap = useMemo(() => {
    const map = new Map();
    blocks.forEach(block => {
      const key = `${block.position[0]},${block.position[1]},${block.position[2]}`;
      map.set(key, block);
    });
    return map;
  }, [blocks]);

  // Optimize isOpaqueNeighbor function
  const isOpaqueNeighbor = useCallback((x, y, z, isCurrentTransparent) => {
    const key = `${x},${y},${z}`;
    if (!occupiedPositions.has(key)) return false;
    
    const block = blockMap.get(key);
    if (!block) return false;

    const isNeighborTransparent = block.color === 'rgba(33, 150, 143, 0.5)';
    
    if (isCurrentTransparent && isNeighborTransparent) return true;
    return !isCurrentTransparent && !isNeighborTransparent;
  }, [occupiedPositions, blockMap]); // Dependencies updated

  // Create a ref to store grass instances
  const grassInstancesRef = useRef(new Map());

  // Modify the voxelFaces useMemo to handle grass instances with top face culling
  const voxelFaces = useMemo(() => {
    const temp = [];
    const newGrassInstances = new Map();
    
    blocks.forEach(block => {
      const [x, y, z] = block.position;
      const isCurrentTransparent = block.color === 'rgba(33, 150, 143, 0.5)';
      
      // Handle grass instances for green blocks
      if (block.color === '#1a3601') {
        // Check if there's a block above this one
        const hasBlockAbove = isOpaqueNeighbor(x, y + 1, z, isCurrentTransparent);
        
        // Only create grass instance if there's no block above
        if (!hasBlockAbove) {
          const key = `${x},${y},${z}`;
          if (!grassInstancesRef.current.has(key)) {
            newGrassInstances.set(key, {
              position: [x, y + 0.9, z],
              id: block.id
            });
          } else {
            newGrassInstances.set(key, grassInstancesRef.current.get(key));
          }
        }
      }

      FACE_DIRECTIONS.forEach(face => {
        const [dx, dy, dz] = face.normal;
        const neighborX = x + dx;
        const neighborY = y + dy;
        const neighborZ = z + dz;

        // Pass isCurrentTransparent to isOpaqueNeighbor
        if (!isOpaqueNeighbor(neighborX, neighborY, neighborZ, isCurrentTransparent)) {
          const position = [
            x + dx * 0.5,
            y + dy * 0.5,
            z + dz * 0.5,
          ];
          const rotation = face.rotation;
          
          let color;
          if (isCurrentTransparent) {
            color = new THREE.Color(0x87CEEB);
          } else {
            // Apply the shade factor to the block color
            const baseColor = new THREE.Color(block.color);
            baseColor.multiplyScalar(face.shadeFactor);
            color = baseColor;
          }

          temp.push({ 
            position, 
            rotation, 
            color,
            isTransparent: isCurrentTransparent,
            blockPosition: block.position, 
            faceNormal: face.normal 
          });
        }
      });
    });

    // Update grass instances ref
    grassInstancesRef.current = newGrassInstances;
    
    return temp;
  }, [blocks, FACE_DIRECTIONS, isOpaqueNeighbor]);

  // Reference to store face data for event handling
  const opaqueFaceDataRef = useRef([]);
  const transparentFaceDataRef = useRef([]);

  // Build merged geometry
  const mergedGeometry = useMemo(() => {
    const opaqueGeos = [];
    const transparentGeos = [];
    opaqueFaceDataRef.current = [];
    transparentFaceDataRef.current = [];

    voxelFaces.forEach(face => {
      const { position, rotation, color, isTransparent } = face;
      const plane = new THREE.PlaneGeometry(1, 1);
      const quaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(...rotation));
      plane.applyQuaternion(quaternion);
      plane.translate(...position);

      const colors = [];
      for (let i = 0; i < plane.attributes.position.count; i++) {
        colors.push(color.r, color.g, color.b);
      }
      plane.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

      const faceData = {
        blockPosition: face.blockPosition,
        faceNormal: face.faceNormal,
        isTransparent: isTransparent
      };

      if (isTransparent) {
        transparentFaceDataRef.current.push(faceData);
        transparentGeos.push(plane);
      } else {
        opaqueFaceDataRef.current.push(faceData);
        opaqueGeos.push(plane);
      }
    });

    return {
      opaqueGeometry: opaqueGeos.length > 0 ? mergeGeometries(opaqueGeos, true) : null,
      transparentGeometry: transparentGeos.length > 0 ? mergeGeometries(transparentGeos, true) : null
    };
  }, [voxelFaces]);

  // Create a persistent raycaster reference
  const raycaster = useRef(new THREE.Raycaster());

  // Add refs to track the meshes
  const opaqueMeshRef = useRef();
  const transparentMeshRef = useRef();

  // Define grid boundaries for a hexagonal shape rather than a square
  const gridSize = 45;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const effectiveGridSize = isMobile ? 45 : gridSize;
  const hexRadius = effectiveGridSize / 2; // Radius of the hexagon in voxel units

  // Function to check if a point is inside the hexagon - simplified for exactly 6 sides
  const isInsideHexagon = useCallback((x, z) => {
    // First rotate the point 30 degrees clockwise around the origin
    const rotationAngle = Math.PI / 6; // 30 degrees in radians
    const cosTheta = Math.cos(rotationAngle);
    const sinTheta = Math.sin(rotationAngle);
    
    // Apply rotation transformation
    const rotatedX = x * cosTheta + z * sinTheta;
    const rotatedZ = -x * sinTheta + z * cosTheta;
    
    // Use a simpler and more direct hexagon check
    // For a regular hexagon, we can test if the point is inside using 6 half-planes
    const size = hexRadius * 0.85;
    
    // These angles represent the 6 directions from the center to the vertices
    const angles = [0, Math.PI/3, 2*Math.PI/3, Math.PI, 4*Math.PI/3, 5*Math.PI/3];
    
    // Check if the point is inside all 6 half-planes
    return angles.every(angle => {
      const nx = Math.cos(angle); // Normal x component
      const nz = Math.sin(angle); // Normal z component
      // Test if the point is on the inner side of this edge
      return (rotatedX * nx + rotatedZ * nz) <= size;
    });
  }, [hexRadius]);

  const handleAddBlock = useCallback((x, y, z, size) => {
    // Check if the block is within the hexagonal boundaries
    if (isInsideHexagon(x, z)) {
      addBlock(x, y, z, size);
    } else {
      console.log(`Block at (${x}, ${y}, ${z}) is outside the hexagonal boundaries`);
    }
  }, [addBlock, isInsideHexagon]);

  // Modify handleVoxelInteraction to handle the unified entity
  const handleVoxelInteraction = useCallback((event) => {
    const canvas = gl.domElement;
    if (event.target !== canvas) return;
    
    event.preventDefault();
    
    const isTouchEvent = event.type.startsWith('touch');
    const actionType = isTouchEvent ? 
      (touchMode === 'delete' ? 'remove' : 'add') : 
      (event.button === 0 ? 'add' : 'remove');

    // Memoize raycaster calculations
    const ray = raycaster.current || new THREE.Raycaster();
    raycaster.current = ray;
    ray.setFromCamera({ x: 0, y: 0 }, camera);

    // Get intersections only from relevant meshes
    const intersects = [];
    if (opaqueMeshRef.current) {
      intersects.push(...ray.intersectObject(opaqueMeshRef.current));
    }
    if (transparentMeshRef.current) {
      intersects.push(...ray.intersectObject(transparentMeshRef.current));
    }

    if (intersects.length === 0) return;

    // Sort intersections by distance
    intersects.sort((a, b) => a.distance - b.distance);

    // Find the first valid intersection
    const validIntersect = intersects.find(intersect => {
      const isFromOpaqueGeometry = opaqueMeshRef.current?.geometry === intersect.object.geometry;
      const faceIndex = Math.floor(intersect.faceIndex / 2);
      const faceData = isFromOpaqueGeometry ? 
        opaqueFaceDataRef.current[faceIndex] : 
        transparentFaceDataRef.current[faceIndex];
      return faceData != null;
    });

    if (!validIntersect) return;

    const isFromOpaqueGeometry = opaqueMeshRef.current?.geometry === validIntersect.object.geometry;
    const faceIndex = Math.floor(validIntersect.faceIndex / 2);
    const faceData = isFromOpaqueGeometry ? 
      opaqueFaceDataRef.current[faceIndex] : 
      transparentFaceDataRef.current[faceIndex];

    const { blockPosition, faceNormal } = faceData;
    const [bx, by, bz] = blockPosition;
    const [fx, fy, fz] = faceNormal;

    if (actionType === 'add') {
      const newPos = [
        bx + fx * placementSize,
        by + fy * placementSize,
        bz + fz * placementSize,
      ];
      handleAddBlock(newPos[0], newPos[1], newPos[2], placementSize);
    } else if (actionType === 'remove') {
      const key = `${bx},${by},${bz}`;
      const block = blockMap.get(key);

      if (block) {
        removeBlock([bx, by, bz]);
      }
    }
  }, [camera, gl, addBlock, removeBlock, placementSize, touchMode, handleAddBlock, blockMap]);

  useEffect(() => {
    // Add event listeners for both mouse and touch events
    window.addEventListener('mousedown', handleVoxelInteraction);
    window.addEventListener('touchstart', handleVoxelInteraction, { passive: false });
    
    return () => {
      window.removeEventListener('mousedown', handleVoxelInteraction);
      window.removeEventListener('touchstart', handleVoxelInteraction);
    };
  }, [handleVoxelInteraction]);

  // Prevent context menu from appearing on right-click
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  // Add this ref near the top of the component
  const hasInitialized = useRef(false);

  // 1. Move addBlockBatch declaration BEFORE the initialization useEffect
  const addBlockBatch = useCallback((blocksToAdd) => {
    console.log(`Adding batch of ${blocksToAdd.length} blocks`);
    try {
      // Process blocks in batches to avoid freezing the UI
      blocksToAdd.forEach(block => {
        addBlock(
          block.x, 
          block.y, 
          block.z, 
          1, // Using size=1 for all initial blocks
          block.color,
          true // Mark as initial blocks
        );
      });
    } catch (error) {
      console.error('Error in addBlockBatch:', error);
    }
  }, [addBlock]);

  // Modified initialization effect
  useEffect(() => {
    // Check if already initialized when remounting
    if (blocks.length > 0) {
      console.log('Blocks already exist, skipping initialization');
      hasInitialized.current = true;
      return;
    }
    
    if (hasInitialized.current) return;
    
    try {
      console.log('Starting voxel initialization...');
      hasInitialized.current = true;

      const y = 0; // Height level for grey base
      const brownY = 1; // Height level for brown layer
      const greenY = 2; // Height level for green layer

      const greyColor = '#808080';
      const brownColor = '#8B4513';
      const greenColor = '#1a3601';

      // Create array to hold blocks
      const newBlocks = [];
      
      // Determine the range to check for hexagon inclusion
      const range = Math.ceil(hexRadius * 1.2); // Slightly larger than hexagon radius
      const startX = -range;
      const startZ = -range;
      const endX = range;
      const endZ = range;
      
      console.log(`Preparing to create hexagonal grid of voxels...`);

      // Create blocks in smaller batches to avoid overwhelming the renderer
      const batchSize = isMobile ? 100 : 200;
      let batchCount = 0;
      let blockCount = 0;

      // Add blocks in hexagonal pattern for all three layers
      for (let x = startX; x <= endX; x++) {
        for (let z = startZ; z <= endZ; z++) {
          // Only add blocks that are inside the hexagon
          if (isInsideHexagon(x, z)) {
            // Grey base layer
            newBlocks.push({ x, y, z, color: greyColor });
            
            // Brown middle layer
            newBlocks.push({ x, y: brownY, z, color: brownColor });
            
            // Green top layer
            newBlocks.push({ x, y: greenY, z, color: greenColor });
            
            blockCount += 3;
            
            if (newBlocks.length >= batchSize) {
              addBlockBatch(newBlocks);
              batchCount++;
              console.log(`Added batch ${batchCount} of blocks (total: ${blockCount})`);
              newBlocks.length = 0; // Clear the array
            }
          }
        }
      }

      // Add any remaining blocks
      if (newBlocks.length > 0) {
        addBlockBatch(newBlocks);
        blockCount += newBlocks.length;
        console.log(`Added final batch of ${newBlocks.length} blocks (total: ${blockCount})`);
      }
      
      console.log('Voxel initialization complete');
    } catch (error) {
      console.error('Error during voxel initialization:', error);
      hasInitialized.current = false;
    }
  }, [addBlock, addBlockBatch, blocks.length, isInsideHexagon]);

  // Inside the Scene component, add this after the cubeTexture state
  const [skyboxTexture, setSkyboxTexture] = useState(null);

  // Add this useEffect to handle WebGL context recovery
  useEffect(() => {
    // Create a function to handle visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Force WebGL context recovery when page becomes visible again
        if (gl && gl.domElement) {
          console.log('Attempting to recover WebGL context after visibility change');
          gl.forceContextRestore();
          
          // Re-apply the skybox if it was already loaded
          if (skyboxTexture) {
            scene.background = skyboxTexture;
          } else {
            // Set a fallback color until textures load
            scene.background = new THREE.Color('#87CEEB');
          }
        }
      }
    };

    // Add event listeners for page visibility changes
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // Add a special handler for iOS Safari
    window.addEventListener('pageshow', (event) => {
      // The persisted property indicates if the page is loaded from cache
      if (event.persisted) {
        console.log('Page was restored from bfcache, forcing context recovery');
        setTimeout(() => {
          // Force a rerender/recovery after a small delay
          if (gl && gl.domElement) {
            gl.forceContextRestore();
            
            // Reset the skybox
            if (skyboxTexture) {
              scene.background = skyboxTexture;
            } else {
              scene.background = new THREE.Color('#87CEEB');
            }
          }
        }, 100);
      }
    });

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      // We don't remove the pageshow event listener to ensure it persists
    };
  }, [gl, scene, skyboxTexture]);

  // Modify the skybox texture loading to be more robust
  useEffect(() => {
    // Ensure hasInitialized state is reset on mount
    hasInitialized.current = false;
    
    // Create new instances of the textures to avoid any caching issues
    const textureLoader = new THREE.TextureLoader();
    
    // Make sure the scene has a fallback color immediately
    scene.background = new THREE.Color('#87CEEB');
    
    // Set up a promise-based loading approach with timeout and retry
    const loadTexture = (src, retries = 2) => {
      return new Promise((resolve, reject) => {
        const tryLoad = (attemptsLeft) => {
          textureLoader.load(
            src, 
            texture => {
              // Enable texture compression for better performance on mobile
              texture.generateMipmaps = false;
              texture.minFilter = THREE.LinearFilter;
              texture.magFilter = THREE.LinearFilter;
              texture.needsUpdate = true;
              resolve(texture);
            },
            undefined, 
            error => {
              console.warn(`Error loading texture: ${src}`, error);
              if (attemptsLeft > 0) {
                console.log(`Retrying texture load (${attemptsLeft} attempts left)...`);
                setTimeout(() => tryLoad(attemptsLeft - 1), 500);
              } else {
                reject(error);
              }
            }
          );
        };
        tryLoad(retries);
      });
    };
    
    // Load all textures with a timeout
    const loadAllTextures = async () => {
      try {
        const texturePromises = [
          loadTexture(pxImage),
          loadTexture(nxImage),
          loadTexture(pyImage),
          loadTexture(nyImage),
          loadTexture(pzImage),
          loadTexture(nzImage)
        ];
        
        // Add a timeout for the entire loading process
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Texture loading timed out')), 10000);
        });
        
        const textures = await Promise.race([
          Promise.all(texturePromises),
          timeoutPromise
        ]);
        
        console.log('All skybox textures loaded successfully');
        const cubeTexture = new THREE.CubeTexture([
          textures[0].image, 
          textures[1].image, 
          textures[2].image, 
          textures[3].image, 
          textures[4].image, 
          textures[5].image
        ]);
        cubeTexture.needsUpdate = true;
        setSkyboxTexture(cubeTexture);
        scene.background = cubeTexture;
      } catch (error) {
        console.error('Error loading skybox textures:', error);
        // Fall back to a simple color background if textures fail to load
        scene.background = new THREE.Color('#87CEEB');
      }
    };
    
    loadAllTextures();

    return () => {
      // Properly dispose of textures to prevent memory leaks
      if (scene.background && scene.background.dispose) {
        scene.background.dispose();
      }
      scene.background = null;
    };
  }, [scene]);

  // Add this near the beginning of the Scene component
  useEffect(() => {
    // Check if we're on an iPad or mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    if (isMobile) {
      console.log('Mobile device detected, applying optimizations');
      // Optimize renderer for mobile
      if (gl) {
        gl.setPixelRatio(window.devicePixelRatio > 1 ? 1.5 : 1);
        gl.setClearColor(new THREE.Color('#87CEEB'));
      }
      
      // Reduce grid size for mobile devices
      hasInitialized.current = false; // Reset to force reinitialization
    }
  }, [gl]);

  // Use stable values for main hex parameters to prevent unnecessary recalculations
  const hexParameters = useMemo(() => {
    return {
      mainRadius: 24,        // Reduced by 40% from 40
      mainY: -20,            // Keep the same Y position 
      height: 20,            // Keep the same height
      color: "#ffffff",
      segments: 6,
      rotation: [-Math.PI / 2, 0, 0],
      glowColor: "#ffffff",
      glowIntensity: 0.2,
      wallHeight: 4,          // Keep the same wall height
      gridSize: 40,           // Keep the same grid size
      bottomOffset: 500       // Keep the same tower height
    };
  }, []); // Empty dependency array ensures this is only calculated once

  // Use useMemo to prevent regeneration of floorTiles on every render
  const floorTiles = useMemo(() => {
    const tiles = [];
    
    // Create a proper random number generator with a fixed seed for consistency
    const createRandomGenerator = (seed) => {
      return () => {
        // Simple pseudo-random number generator
        seed = (seed * 9301 + 49297) % 233280;
        return seed / 233280;
      };
    };
    
    // Use a global seed for initial randomness
    const globalRandom = createRandomGenerator(12345);
    
    // Generate a grid of hex positions (axial coordinates) for a 5x5 grid, skip center
    for (let col = -2; col <= 2; col++) {
      for (let row = -2; row <= 2; row++) {
        if (col === 0 && row === 0) continue; // skip main hex
        
        // For flat-topped hex grid, but with 40% smaller spacing:
        const x = hexParameters.mainRadius * 1.5 * col;
        const z = hexParameters.mainRadius * Math.sqrt(3) * (row + col / 2);
        
        // Create a unique random generator for each position
        const positionSeed = Math.floor(globalRandom() * 10000);
        const random = createRandomGenerator(positionSeed);
        
        // Keep the same random height range relative to mainY
        const randomHeight = hexParameters.mainY - (100 + random() * 300);
        
        tiles.push({ position: [x, randomHeight, z] });
      }
    }
    return tiles;
  }, [hexParameters]); // Only depends on the memoized hexParameters

  return (
    <>
      <Stats 
        className="performance-stats"
        showPanel={0}  // 0: FPS, 1: MS, 2: MB
        position="bottom-right" // default is top-left
      />

      {/* Lighting removed, using hemisphere light for basic visibility */}
      <hemisphereLight 
        args={['#ffffff', '#303030', 1]} 
        intensity={1.5} 
      />

      {/* Main Hexagonal Base - using HexagonalBase (from HexagonalBaseMain) for the central platform */}
      <HexagonalBase
        position={[0, hexParameters.mainY, 0]}
        radius={hexParameters.mainRadius}
        height={hexParameters.height} 
        color={hexParameters.color} 
        segments={hexParameters.segments}
        rotation={hexParameters.rotation} 
        glowColor={hexParameters.glowColor}
        glowIntensity={hexParameters.glowIntensity}
        wallHeight={hexParameters.wallHeight} 
        gridSize={hexParameters.gridSize} 
        bottomOffset={hexParameters.bottomOffset}
      />

      {/* Repeating floor pattern of empty hex tiles around the main hex */}
      {floorTiles.map((tile, index) => (
        <MemoizedHexagonalBaseEmpty
          key={`floor-${index}`}
          position={tile.position}
          radius={hexParameters.mainRadius}
          height={hexParameters.height}
          color={hexParameters.color}
          segments={hexParameters.segments}
          rotation={hexParameters.rotation}
          glowColor={hexParameters.glowColor}
          glowIntensity={hexParameters.glowIntensity}
          wallHeight={hexParameters.wallHeight}
          bottomOffset={hexParameters.bottomOffset}
        />
      ))}

      {/* Controls */}
      <FirstPersonControls 
        isInventoryOpen={isInventoryOpen} 
        setIsInventoryOpen={setIsInventoryOpen} 
      />

      {/* Editable Ground Plane with Pointer Events */}
      <GroundComponent
        position={[0, 0, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        onPointerDown={addBlock}
        removeBlock={removeBlock}
        isInsideHexagon={isInsideHexagon}
      />

      {/* Merged Mesh for Voxel Faces - updated materials */}
      {mergedGeometry.opaqueGeometry && (
        <mesh
          ref={opaqueMeshRef}
          geometry={mergedGeometry.opaqueGeometry}
          castShadow
          receiveShadow
        >
          <meshBasicMaterial
            vertexColors={true}
            side={THREE.FrontSide}
          />
        </mesh>
      )}
      {mergedGeometry.transparentGeometry && (
        <mesh
          ref={transparentMeshRef}
          geometry={mergedGeometry.transparentGeometry}
          castShadow
          receiveShadow
          renderOrder={1}
        >
          <meshBasicMaterial
            vertexColors={true}
            side={THREE.FrontSide}
            transparent={true}
            opacity={0.25}
            depthWrite={false}
            blending={THREE.NormalBlending}
          />
        </mesh>
      )}

      {/* Render grass instances from ref */}
      {Array.from(grassInstancesRef.current.values()).map(({ position, id }) => (
        <GrassField 
          key={`grass-${id}`}
          startPos={position}
          gridSize={1}
          renderOrder={3}
          depthWrite={false}
        />
      ))}
    </>
  );
};

Scene.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      position: PropTypes.arrayOf(PropTypes.number).isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  addBlock: PropTypes.func.isRequired,
  removeBlock: PropTypes.func.isRequired,
  currentColor: PropTypes.string.isRequired,
  placementSize: PropTypes.number.isRequired,
  onFaceCount: PropTypes.func,
  touchMode: PropTypes.string,
  isInventoryOpen: PropTypes.bool,
  setIsInventoryOpen: PropTypes.func,
};

export default Scene;
