// src/components/common/Modal.js

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import FaTimesIcon from '../assets/icons/FaTimes.svg';

const Modal = ({ children, onClose, disableClose, noPadding }) => {
  // Lock the scroll when the modal is open
  useEffect(() => {
    // Save the current overflow style
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling
    document.body.style.overflow = 'hidden';
    // Cleanup: Restore original overflow style when modal is closed
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  // Ensure that the modal is rendered in the 'modal-root' div
  // If 'modal-root' doesn't exist, create it dynamically
  const modalRoot = document.getElementById('modal-root') || createModalRoot();

  function createModalRoot() {
    const root = document.createElement('div');
    root.setAttribute('id', 'modal-root');
    document.body.appendChild(root);
    return root;
  }

  const handleOverlayClick = (e) => {
    if (!disableClose && e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleOverlayClick}
    >
      <div className="relative w-full h-full bg-white">
        {/* Close Button - Increased z-index to 100 to ensure visibility */}
        {!disableClose && (
          <button
            onClick={onClose}
            className="absolute text-gray-500 top-4 right-4 hover:text-gray-700 focus:outline-none z-100"
            aria-label="Close"
            style={{ zIndex: 100 }}
          >
            <img src={FaTimesIcon} alt="Close" width="24" height="24" />
          </button>
        )}
        {/* Modal Content */}
        <div className={`w-full h-full overflow-auto ${noPadding ? '' : 'p-6'}`}>
          {children}
        </div>
      </div>
    </div>,
    modalRoot
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  disableClose: PropTypes.bool,
  noPadding: PropTypes.bool,
};

Modal.defaultProps = {
  disableClose: false,
  noPadding: false,
};

export default Modal;
