// src/components/Teachers/TeacherHomepage.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { db, rtdb } from '../../../firebase';
import {
  doc,
  getDoc,
  collection,
  query,
  onSnapshot,
} from 'firebase/firestore';
import TeacherExcalidrawWhiteboard from '../Whiteboard/TeacherExcalidrawWhiteboard';
import TeacherWorkbook from '../Workbook/teacherworkbook';
import SlideshowApp from '../Slideshow/SlideshowApp';
import AssessmentApp from '../AssessmentApp';
import ClassManagement from '../Management/classmanagement';
import Sidebar from '../Management/Sidebar';
import Toolbar from './toolbar';
import { useDataController } from '../../../contexts/datacontroller';
import logo from '../../assets/BrightBeehives/BightBeehivesLogo.png';
import { Hexagons2 } from './HexagonButton2';
import './HexagonButton2.css';
import Bee from './Bee copy';
import './TeacherHomepage.css';
import ViewSites from '../Sites/viewsites';
import Games from '../Read App/Read';
import Tests from '../Build/Build';
import treeImage from './gif1.gif';
import { ref as dbRef, get } from 'firebase/database';
import Progress from '../Progress/Progress.js';
import Timer from './Timer.js';
import JoinCode from './JoinCode.js';
import Points from './Points.js';

// Define constants for level calculations
const MAX_LEVEL = 100; // Ensure this matches the MAX_LEVEL in Progress.js
const xpPerLevel = Array(MAX_LEVEL).fill(0).map((_, index) => 100 * (index + 1)); // XP required for each level

// Function to calculate level from XP
const getLevelFromXP = (xp) => {
  if (xp < 0) return 0;
  let accumulatedXP = 0;
  let level = 0;

  while (level < MAX_LEVEL && accumulatedXP + xpPerLevel[level] <= xp) {
    accumulatedXP += xpPerLevel[level];
    level++;
  }

  return level;
};

const TeacherHomepage = () => {
  const { currentUser, userRole } = useAuth();
  console.log(
    'TeacherHomepage rendered. CurrentUser:',
    currentUser,
    'UserRole:',
    userRole
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isToolbarVisible, setIsToolbarVisible] = useState(() => {
    const savedState = localStorage.getItem('isToolbarVisible');
    return savedState ? JSON.parse(savedState) : false;
  });

  // New state variables for toggles
  const [showNames, setShowNames] = useState(() => {
    const saved = localStorage.getItem('showNames');
    return saved ? JSON.parse(saved) : true; // Default to true
  });

  const [showActiveApps, setShowActiveApps] = useState(() => {
    const saved = localStorage.getItem('showActiveApps');
    return saved ? JSON.parse(saved) : true; // Default to true
  });

  // New state variable for Online/All toggle
  const [showOnlyOnline, setShowOnlyOnline] = useState(() => {
    const saved = localStorage.getItem('showOnlyOnline');
    return saved ? JSON.parse(saved) : true; // Default to true (show only online)
  });

  const [username, setUsername] = useState('');
  const [fullscreenApp, setFullscreenApp] = useState(null);
  const [classes, setClasses] = useState([]);
  const [showClassManagement, setShowClassManagement] = useState(false);

  const treeContainerRef = useRef(null);
  const [treeDimensions, setTreeDimensions] = useState(null);

  const { activeClasses, isLoading } = useDataController();
  const [shownClassCodes, setShownClassCodes] = useState({});

  // Update activeBees to store objects with classId, name, xp, currentApp, and isOnline
  const [activeBees, setActiveBees] = useState([]); // Changed from array of names to array of objects
  const [whiteboardInitialData, setWhiteboardInitialData] = useState(null);
  const [isLoadingWhiteboardData, setIsLoadingWhiteboardData] = useState(false);

  // State for Progress Modal
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progressBees, setProgressBees] = useState([]);

  // New state for Timer visibility
  const [isTimerVisible, setIsTimerVisible] = useState(false);

  // Placeholder states for Poll and Calendar (grey buttons)
  const [isPollActive, setIsPollActive] = useState(false);
  const [isCalendarActive, setIsCalendarActive] = useState(false);

  // New State for Average XP and Average Level
  const [averageXP, setAverageXP] = useState(0);
  const [averageLevel, setAverageLevel] = useState(0); // New state for average level

  // Add state for JoinCode visibility
  const [isJoinCodeVisible, setIsJoinCodeVisible] = useState(false);
  // Add state to track which class code to show
  const [selectedClassCode, setSelectedClassCode] = useState(null);

  // Add state for Points visibility
  const [isPointsVisible, setIsPointsVisible] = useState(false);

  const toggleClassCode = (classId, classcode) => {
    // Show the join code popup
    showJoinCode(classId, classcode);
    
    // Also update the button state (if you want to keep this behavior)
    setShownClassCodes((prev) => ({
      ...prev,
      [classId]: !prev[classId],
    }));
  };

  const updateTreeDimensions = useCallback(() => {
    if (treeContainerRef.current) {
      const rect = treeContainerRef.current.getBoundingClientRect();
      setTreeDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
  }, []);

  useEffect(() => {
    updateTreeDimensions();
    window.addEventListener('resize', updateTreeDimensions);

    return () => {
      window.removeEventListener('resize', updateTreeDimensions);
    };
  }, [updateTreeDimensions]);

  useEffect(() => {
    console.log('TeacherHomepage useEffect triggered.');
    let unsubscribeClasses = null;

    const fetchData = async () => {
      if (currentUser) {
        console.log('Fetching data for currentUser:', currentUser.uid);
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          console.log('Fetching user document:', userDocRef.path);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('User data fetched:', userData);
            setUsername(userData.name || 'Teacher');
          } else {
            console.warn('No user document found for:', currentUser.uid);
            setUsername('Teacher');
          }

          const classesQuery = query(
            collection(db, `users/${currentUser.uid}/classes`)
          );
          console.log('Setting up onSnapshot listener for classes.');
          unsubscribeClasses = onSnapshot(classesQuery, (snapshot) => {
            const fetchedClasses = snapshot.docs.map((docSnap) => ({
              id: docSnap.id,
              ...docSnap.data(),
            }));
            console.log('Fetched classes:', fetchedClasses);
            setClasses(fetchedClasses);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
          setUsername('Teacher');
        }
      } else {
        console.log('No currentUser. Clearing classes and username.');
        setClasses([]);
        setUsername('');
      }
    };

    fetchData();

    return () => {
      if (unsubscribeClasses) {
        console.log('Unsubscribing from classes onSnapshot.');
        unsubscribeClasses();
      }
    };
  }, [currentUser]);

  // Persist toolbar visibility
  useEffect(() => {
    localStorage.setItem('isToolbarVisible', JSON.stringify(isToolbarVisible));
  }, [isToolbarVisible]);

  // Persist showNames state
  useEffect(() => {
    localStorage.setItem('showNames', JSON.stringify(showNames));
  }, [showNames]);

  // Persist showActiveApps state
  useEffect(() => {
    localStorage.setItem('showActiveApps', JSON.stringify(showActiveApps));
  }, [showActiveApps]);

  // Persist showOnlyOnline state
  useEffect(() => {
    localStorage.setItem('showOnlyOnline', JSON.stringify(showOnlyOnline));
  }, [showOnlyOnline]);

  const handleStartApp = useCallback((appName, payload = {}) => {
    console.log(`handleStartApp called with appName: ${appName}, payload:`, payload);
    setFullscreenApp({ appName, ...payload });
  }, []);

  const handleCloseApp = useCallback(() => {
    console.log('handleCloseApp called.');
    setFullscreenApp(null);
  }, []);

  const toggleSidebar = useCallback(() => {
    console.log(`Sidebar toggle. Currently open: ${isSidebarOpen}`);
    setIsSidebarOpen((prev) => !prev);
  }, [isSidebarOpen]);

  const handleManageClasses = useCallback(() => {
    console.log('handleManageClasses called.');
    setShowClassManagement(true);
  }, []);

  const handleCloseClassManagement = useCallback(() => {
    console.log('handleCloseClassManagement called.');
    setShowClassManagement(false);
  }, []);

  // Handler Functions for Hexagons
  const handleStartSlides = useCallback(() => {
    console.log('handleStartSlides called.');
    if (classes.length === 0) {
      alert('No classes available to start Slides.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Slideshow', { classID });
  }, [classes, handleStartApp]);

  const handleStartWhiteboard = useCallback(async () => {
    console.log('handleStartWhiteboard called.');
    if (classes.length === 0) {
      alert('No classes available to start the Whiteboard.');
      return;
    }

    setIsLoadingWhiteboardData(true);
    try {
      const activeClassIDs = classes.map((cls) => cls.id);
      const fetchedData = await fetchWhiteboardData(activeClassIDs);
      setWhiteboardInitialData(fetchedData);
      handleStartApp('Whiteboard', { activeClassIDs });
    } catch (error) {
      console.error('Error fetching whiteboard data:', error);
      alert('Failed to load whiteboard data.');
    } finally {
      setIsLoadingWhiteboardData(false);
    }
  }, [classes, handleStartApp]);

  const handleStartBookQuest = useCallback(() => {
    console.log('handleStartBookQuest called.');
    if (classes.length === 0) {
      alert('No classes available to start Book Quest.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('BookQuest', { classID });
  }, [classes, handleStartApp]);

  const handleStartAssessment = useCallback(() => {
    console.log('Opening Progress Modal.');
    setProgressBees(activeBees);
    setShowProgressModal(true);
  }, [activeBees]);

  const handleStartTeacherWorkbook = useCallback(() => {
    console.log('handleStartTeacherWorkbook called.');
    if (classes.length === 0) {
      alert('No classes available to open the Workbook.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Workbook', { classID });
  }, [classes, handleStartApp]);

  const handleStartViewSites = useCallback(() => {
    console.log('handleStartViewSites called.');
    handleStartApp('ViewSites');
  }, [handleStartApp]);

  const handleStartLibrary = useCallback(() => {
    console.log('handleStartLibrary called.');
    if (classes.length === 0) {
      alert('No classes available to open Library.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Games', { classID });
  }, [classes, handleStartApp]);

  const handleStartBuild = useCallback(() => {
    console.log('handleStartBuild called.');
    if (classes.length === 0) {
      alert('No classes available to open Build.');
      return;
    }
    const classID = classes[0].id;
    handleStartApp('Tests', { classID });
  }, [classes, handleStartApp]);

  // Handler to close Progress Modal
  const handleCloseProgressModal = useCallback(() => {
    console.log('Closing Progress Modal.');
    setShowProgressModal(false);
  }, []);

  // Synchronize activeBees based on activeClasses and filters
  useEffect(() => {
    const bees = [];
    for (const classId in activeClasses) {
      const classData = activeClasses[classId];
      if (classData.beeNames) {
        for (const beeKey in classData.beeNames) {
          const bee = classData.beeNames[beeKey];
          const beeName = (bee.name || beeKey || '').trim(); // Ensure beeName is a trimmed string

          if (showOnlyOnline) {
            // Show only online bees
            if (bee.logged === 'yes' && beeName.length > 0) {
              bees.push({
                classId,
                name: beeName,
                isOnline: true,
                xp: bee.totalxp || 0, // Assuming totalxp is stored
                currentApp: bee.currentApp || 'None', // Assuming currentApp is stored
              });
              console.log(`Adding bee: "${beeName}" from class: "${classId}" (Online)`);
            }
          } else {
            // Show all bees
            if (beeName.length > 0) {
              bees.push({
                classId,
                name: beeName,
                isOnline: bee.logged === 'yes',
                xp: bee.totalxp || 0, // Assuming totalxp is stored
                currentApp: bee.currentApp || 'None', // Assuming currentApp is stored
              });
              console.log(
                `Adding bee: "${beeName}" from class: "${classId}" (Online: ${bee.logged === 'yes'})`
              );
            }
          }
        }
      }
    }
    setActiveBees(bees);
    console.log('Active bees set to:', bees);
  }, [activeClasses, showOnlyOnline]);

  /**
   * Fetch whiteboard data from RTDB for the given class IDs.
   * @param {Array<string>} classIDs - Array of class IDs.
   * @returns {Promise<Object>} - Combined whiteboard data.
   */
  const fetchWhiteboardData = async (classIDs) => {
    const combinedElements = [];
    const combinedAppState = {};

    for (const classId of classIDs) {
      const whiteboardRef = dbRef(rtdb, `activeclasses/${classId}/whiteboard/Teacher`);
      const snapshot = await get(whiteboardRef);
      if (snapshot.exists()) {
        const classWhiteboardData = snapshot.val();
        if (classWhiteboardData.elements) {
          combinedElements.push(...classWhiteboardData.elements);
        }
        if (classWhiteboardData.appState) {
          Object.assign(combinedAppState, classWhiteboardData.appState);
        }
      }
    }

    return { elements: combinedElements, appState: combinedAppState };
  };

  // Calculate Average XP and Average Level
  useEffect(() => {
    if (activeBees.length === 0) {
      setAverageXP(0);
      setAverageLevel(0);
      return;
    }
    const totalXP = activeBees.reduce((acc, bee) => acc + (bee.xp || 0), 0);
    const avgXP = totalXP / activeBees.length;
    setAverageXP(avgXP);

    const avgLevel = getLevelFromXP(avgXP);
    setAverageLevel(avgLevel);

    console.log(`Calculated averageXP: ${avgXP}, averageLevel: ${avgLevel}`);
  }, [activeBees]);

  // Toggle Timer visibility
  const toggleTimer = () => {
    setIsTimerVisible((prev) => !prev);
  };

  // Toggle Poll active state (placeholder)
  const togglePoll = () => {
    setIsPollActive((prev) => !prev);
  };

  // Toggle Calendar active state (placeholder)
  const toggleCalendar = () => {
    setIsCalendarActive((prev) => !prev);
  };

  // Add function to toggle JoinCode visibility with a specific class
  const showJoinCode = (classId, classCode) => {
    console.log("Showing join code for class:", classId, "Code:", classCode);
    setSelectedClassCode({
      id: classId,
      code: classCode
    });
    setIsJoinCodeVisible(true);
  };

  // Add function to hide JoinCode
  const hideJoinCode = () => {
    setIsJoinCodeVisible(false);
  };

  // Modify the toolbar JoinCode toggle handler to automatically get the first available class code
  const toggleJoinCodeFromToolbar = () => {
    if (isJoinCodeVisible) {
      // If already visible, just hide it
      hideJoinCode();
    } else {
      // If not visible, find the first available class code
      
      // First try from activeClasses (which has the most up-to-date data)
      if (Object.keys(activeClasses).length > 0) {
        const firstActiveClassKey = Object.keys(activeClasses)[0];
        const firstActiveClass = activeClasses[firstActiveClassKey];
        
        if (firstActiveClass && firstActiveClass.classcode) {
          console.log("Found class code in activeClasses:", firstActiveClass.classcode);
          showJoinCode(firstActiveClass.id, firstActiveClass.classcode);
          return;
        }
      }
      
      // If no active class was found with a code, try from the classes array
      if (classes.length > 0) {
        const firstClass = classes[0];
        if (firstClass && firstClass.classcode) {
          console.log("Found class code in classes array:", firstClass.classcode);
          showJoinCode(firstClass.id, firstClass.classcode);
          return;
        }
      }
      
      // If still no code was found, show a message
      alert("No class codes available. Please create a class with a join code first.");
    }
  };

  // Add toggle function for Points
  const togglePoints = () => {
    setIsPointsVisible(prev => !prev);
  };

  return (
    <div className="relative flex flex-col w-full min-h-screen p-0 bg-gradient-to-br from-blue-50 to-green-50">
      {/* Sidebar */}
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        username={username}
        handleStartApp={handleStartApp}
        onManageClasses={handleManageClasses}
        classes={classes}
        setClasses={setClasses}
      />
      
      {/* Main Content Wrapper */}
      <div className="flex flex-col flex-1 min-h-0 p-4">
        {/* Header with Logo */}
        <header className="flex items-center justify-center flex-none h-20 mb-4">
          <img src={logo} alt="Logo" className="w-auto h-20" />
        </header>

        {/* Content Area */}
        <div className="flex flex-col flex-1 min-h-0">
          {/* Tree Image and Bees Container */}
          <div className="relative w-full mx-auto mb-8 bg-transparent" 
               style={{ 
                 flex: '0 0 auto', 
                 height: 'min(30vh, 400px)',
                 minHeight: '200px'
               }}>
            <div className="relative flex justify-center w-full h-full" ref={treeContainerRef}>
              <img
                src={treeImage}
                alt="Welcome Tree"
                className="object-contain w-auto h-full max-w-full"
                style={{ 
                  maxHeight: '100%',
                  width: 'auto',
                  transform: 'scale(1)',
                  transformOrigin: 'center center'
                }}
                onLoad={updateTreeDimensions}
              />

              {/* Bees Flying Around the Tree */}
              {treeDimensions && activeBees.length > 0 && (
                <div className="absolute inset-0">
                  {activeBees
                    .filter(({ name }) => typeof name === 'string' && name.trim().length > 0)
                    .map(({ classId, name, isOnline }) => (
                      <Bee
                        key={`${classId}-${name}`}
                        classId={classId}
                        name={name}
                        boundaries={treeDimensions}
                        showName={showNames}
                        showActiveApp={showActiveApps}
                        isOnline={isOnline}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>

          {/* Tiles Section with Hexagon Arrangement */}
          <div className="relative flex-1 w-full mx-auto mt-8 bg-transparent" style={{ height: '60vh' }}>
            <div className="flex flex-col w-full h-full">
              <Hexagons2
                className="w-full h-full"
                handleStartSlides={handleStartSlides}
                handleStartWhiteboard={handleStartWhiteboard}
                handleStartBookQuest={handleStartBookQuest}
                handleStartAssessment={handleStartAssessment}
                handleStartTeacherWorkbook={handleStartTeacherWorkbook}
                handleStartViewSites={handleStartViewSites}
                handleStartLibrary={handleStartLibrary}
                handleStartBuild={handleStartBuild}
                averageXP={averageXP}
                averageLevel={averageLevel}
              />
            </div>
          </div>

          {/* Footer Section */}
          <footer className="w-full py-2 text-center bg-transparent" style={{ flex: '0 0 auto' }}>
            <p className="text-transparent">
              © {new Date().getFullYear()} Your School Name. All rights reserved.
            </p>
          </footer>
        </div>
      </div>

      {/* Floating Elements Section - FIXED POSITION */}
      <div className="fixed inset-0 z-50 pointer-events-none">
        <div className="pointer-events-auto">
          {/* Timer Component */}
          {isTimerVisible && <Timer classId={classes.length > 0 ? classes[0].id : 'default-class-id'} />}
          
          {/* Points Component */}
          {isPointsVisible && <Points activeBees={activeBees} onClose={togglePoints} />}
          
          {/* Add JoinCode Component */}
          {isJoinCodeVisible && selectedClassCode && (
            <JoinCode 
              classId={selectedClassCode.id}
              classCode={selectedClassCode.code}
              onClose={hideJoinCode}
            />
          )}

          {/* Toolbar Component */}
          <Toolbar
            isVisible={isToolbarVisible}
            toggleToolbar={() => setIsToolbarVisible((prev) => !prev)}
            showNames={showNames}
            toggleShowNames={() => setShowNames((prev) => !prev)}
            showActiveApps={showActiveApps}
            toggleShowActiveApps={() => setShowActiveApps((prev) => !prev)}
            showOnlyOnline={showOnlyOnline}
            toggleShowOnlyOnline={() => setShowOnlyOnline((prev) => !prev)}
            onToggleTimer={toggleTimer}
            timerActive={isTimerVisible}
            pollActive={isPollActive}
            togglePoll={togglePoll}
            calendarActive={isCalendarActive}
            toggleCalendar={toggleCalendar}
            onToggleJoinCode={toggleJoinCodeFromToolbar}
            joinCodeActive={isJoinCodeVisible}
            onTogglePoints={togglePoints}
            pointsActive={isPointsVisible}
            handleOpenProgressModal={handleStartAssessment}
          />

          {/* Fullscreen App Overlays */}
          {fullscreenApp && fullscreenApp.appName && (
            <>
              {fullscreenApp.appName === 'Whiteboard' && (
                <TeacherExcalidrawWhiteboard
                  show={true}
                  handleClose={handleCloseApp}
                  activeClassIDs={fullscreenApp.activeClassIDs}
                  initialData={whiteboardInitialData}
                  isLoading={isLoadingWhiteboardData}
                />
              )}
              {fullscreenApp.appName === 'Workbook' && (
                <TeacherWorkbook
                  onClose={handleCloseApp}
                  classID={fullscreenApp.classID}
                />
              )}
              {fullscreenApp.appName === 'Slideshow' && (
                <SlideshowApp
                  onClose={handleCloseApp}
                  classID={fullscreenApp.classID}
                />
              )}
              {fullscreenApp.appName === 'Assessment' && (
                <AssessmentApp
                  onClose={handleCloseApp}
                  classID={fullscreenApp.classID}
                />
              )}
              {fullscreenApp.appName === 'ClassManagement' && (
                <ClassManagement
                  onClose={handleCloseClassManagement}
                  classes={classes}
                  setClasses={setClasses}
                />
              )}
              {fullscreenApp.appName === 'ViewSites' && (
                <ViewSites show={true} handleClose={handleCloseApp} />
              )}
              {fullscreenApp.appName === 'Games' && (
                <Games onClose={handleCloseApp} classID={fullscreenApp.classID} />
              )}
              {fullscreenApp.appName === 'Tests' && (
                <Tests onClose={handleCloseApp} classID={fullscreenApp.classID} />
              )}
            </>
          )}

          {/* Class Management Modal */}
          {showClassManagement && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 mb-50">
              <div className="w-11/12 max-w-3xl p-4 bg-white rounded-lg shadow-lg">
                <ClassManagement
                  onClose={handleCloseClassManagement}
                  classes={classes}
                  setClasses={setClasses}
                />
              </div>
            </div>
          )}

          {/* Progress Modal */}
          <Progress
            show={showProgressModal}
            handleClose={handleCloseProgressModal}
            title="Class Progress"
            bees={progressBees}
          />
        </div>
      </div>
    </div>
  );
};

export default TeacherHomepage;
