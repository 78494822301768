// src/components/Teachers/Tests/VoxelEditor.jsx

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Scene from './Scene'; // Adjusted import path
import { Canvas } from '@react-three/fiber';
import { EffectComposer, SSAO } from '@react-three/postprocessing';
import * as THREE from 'three';
import { Stats as DreiStats } from '@react-three/drei';

// Define a list of predefined colors for the toolbar
const COLORS = [
  '#FF0000', // Bright Red
  '#1a3601', // Dark Green (matching grass base) - previously '#4CAF50'
  '#2196F3', // Bright Blue
  '#FFC107', // Bright Yellow
  '#E91E63', // Bright Pink
  '#00BCD4', // Bright Cyan
  '#FFFFFF', // White
  '#9E9E9E', // Medium Gray
  'rgba(33, 150, 143, 0.5)', // Transparent Blue
];

// Update the color constants
const HOTBAR_COLORS = [
  '#FF0000', // Red
  '#4CAF50', // Green
  '#2196F3', // Blue
  '#FFC107', // Yellow
  '#FF9800', // Orange
  '#FFFFFF', // White
  '#E91E63', // Pink
  '#8B4513', // Brown
  'rgba(33, 150, 143, 0.5)', // Transparent
];

const PALETTE_COLORS = [
  // Column 1: Red
  ['#FF0000', '#E60000', '#CC0000', '#B30000', '#990000', '#800000'],
  
  // Column 2: Green
  ['#00FF00', '#00E600', '#00CC00', '#00B300', '#009900', '#008000'],
  
  // Column 3: Blue
  ['#0000FF', '#0000E6', '#0000CC', '#0000B3', '#000099', '#000080'],
  
  // Column 4: Yellow
  ['#FFD700', '#E6C200', '#CCAC00', '#B39700', '#998200', '#806C00'],
  
  // Column 5: Orange
  ['#FFA500', '#E69500', '#CC8400', '#B37300', '#996300', '#805300'],
  
  // Column 6: Grayscale
  ['#FFFFFF', '#CCCCCC', '#999999', '#666666', '#333333', '#000000'],
  
  // Column 7: Pink to Purple
  ['#FF69B4', '#E060A0', '#C4578C', '#A84D78', '#8B4364', '#6F3950'],
  
  // Column 8: Brown
  ['#D2B48C', '#BC9D7E', '#A68870', '#907362', '#7A5E54', '#644A46'],
  
  // Column 9: Transparent
  [
    'rgba(255, 0, 0, 0.5)',    // Red
    'rgba(0, 255, 0, 0.5)',    // Green
    'rgba(0, 0, 255, 0.5)',    // Blue
    'rgba(255, 255, 0, 0.5)',  // Yellow
    'rgba(33, 150, 243, 0.5)', // Light Blue
    'rgba(255, 255, 255, 0.5)', // White
  ],
];

const VoxelEditor = () => {
  const [blocks, setBlocks] = useState([]);
  const [currentColor, setCurrentColor] = useState(COLORS[0]);
  const [faceCount, setFaceCount] = useState(0);
  const [moveInput, setMoveInput] = useState({ x: 0, y: 0 });
  const [isTouchDevice] = useState(() => 'ontouchstart' in window || navigator.maxTouchPoints > 0);
  const [touchMode, setTouchMode] = useState('place');
  const [isHotbarExpanded, setIsHotbarExpanded] = useState(false);
  const [hotbarColors, setHotbarColors] = useState(HOTBAR_COLORS);
  const [isCursorVisible, setIsCursorVisible] = useState(true);
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('plain');

  const selectedColorIndex = useRef(0);

  const addBlock = useCallback(
    (x, y, z, size = 1, color = currentColor, isInitial = false) => {
      const newPos = [x, y, z];
      
      setBlocks(prev => {
        // Check if block already exists at this position
        const exists = prev.some(
          (block) =>
            block.position[0] === newPos[0] &&
            block.position[1] === newPos[1] &&
            block.position[2] === newPos[2]
        );

        // Only update state if we're actually adding a new block
        if (!exists) {
          const newBlock = { id: uuidv4(), position: newPos, color: color, isInitial };
          return [...prev, newBlock];
        }
        
        // Return the previous state if no change
        return prev;
      });
    },
    [currentColor] // Only depends on currentColor, not blocks array
  );

  const removeBlock = useCallback((posToRemove) => {
    if (!Array.isArray(posToRemove) || posToRemove.length !== 3) return;

    const [rx, ry, rz] = posToRemove;
    setBlocks(prev => {
      // Only create new array if we actually remove a block
      const blockIndex = prev.findIndex(block => {
        const [bx, by, bz] = block.position;
        return bx === rx && by === ry && bz === rz && !block.isInitial;
      });
      
      if (blockIndex === -1) return prev;
      return [...prev.slice(0, blockIndex), ...prev.slice(blockIndex + 1)];
    });
  }, []); // No dependencies needed

  const handlePointerDown = useCallback(
    (x, y, z, size) => {
      // Adjust y position to align the block correctly above the ground
      const adjustedY = y + size / 2;
      addBlock(x, adjustedY, z, size);
    },
    [addBlock]
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.key.toLowerCase();
      if (key === 't') {
        setIsHotbarExpanded(prev => !prev);
        setIsCursorVisible(prev => !prev);
        if (document.pointerLockElement) {
          document.exitPointerLock();
        }
      } else {
        const index = parseInt(e.key, 10) - 1;
        if (index >= 0 && index < hotbarColors.length) {
          setCurrentColor(hotbarColors[index]);
          selectedColorIndex.current = index;
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [hotbarColors]);

  const handleFaceCount = (count) => {
    setFaceCount(count);
  };

  const handleJoystickMove = useCallback((x, y) => {
    setMoveInput({ x: -x, y: -y }); // Invert values for intuitive control
  }, []);

  // Add drag and drop handlers
  const handleDragStart = (e, color) => {
    e.dataTransfer.setData('color', color);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const color = e.dataTransfer.getData('color');
    const newHotbarColors = [...hotbarColors];
    newHotbarColors[index] = color;
    setHotbarColors(newHotbarColors);
    
    // If we're modifying the currently selected slot, update the current color
    if (selectedColorIndex.current === index) {
      setCurrentColor(color);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden', // Prevent scrollbars
      }}
    >
      {/* Escape Button */}
      <button
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          padding: '10px 20px',
          backgroundColor: '#f44336',
          color: 'white',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
          fontSize: '16px',
          fontWeight: 'bold',
          zIndex: 2,
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        }}
        onClick={() => {
          // Add your escape functionality here
          console.log('Escape clicked');
        }}
      >
        Press Escape to Exit
      </button>

      {/* Center Cursor (Crosshair) */}
      <div
        style={{
          position: 'absolute',
          top: '49%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'rgba(0, 0, 0, 0.4)',
          fontSize: '100px',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      >
        +
      </div>

      {/* Up/Down Movement Controls - Completely separate */}
      <div
        data-ui-element="toolbar"
        style={{
          position: 'absolute',
          bottom: 20,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          background: 'white',
          padding: '5px',
          border: '5px solid #F8B83A',
          borderRadius: '16px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          height: 'fit-content',
          zIndex: 1,
          marginLeft: '20px',
        }}
      >
        <button
          onPointerDown={() => {
            if (window.moveRef) window.moveRef.current.up = true;
          }}
          onPointerUp={() => {
            if (window.moveRef) window.moveRef.current.up = false;
          }}
          onPointerLeave={() => {
            if (window.moveRef) window.moveRef.current.up = false;
          }}
          style={{
            width: '50px',
            height: '50px',
            backgroundColor: '#4CAF50',
            border: 'none',
            borderRadius: '16px',
            cursor: 'pointer',
            outline: 'none',
            fontWeight: 'bold',
            fontSize: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            boxShadow: 'inset 0 0 3px rgba(0,0,0,0.3)',
            touchAction: 'none',
          }}
        >
          ↑
        </button>
        <button
          onPointerDown={() => {
            if (window.moveRef) window.moveRef.current.down = true;
          }}
          onPointerUp={() => {
            if (window.moveRef) window.moveRef.current.down = false;
          }}
          onPointerLeave={() => {
            if (window.moveRef) window.moveRef.current.down = false;
          }}
          style={{
            width: '50px',
            height: '50px',
            backgroundColor: '#f44336',
            border: 'none',
            borderRadius: '16px',
            cursor: 'pointer',
            outline: 'none',
            fontWeight: 'bold',
            fontSize: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            boxShadow: 'inset 0 0 3px rgba(0,0,0,0.3)',
            touchAction: 'none',
          }}
        >
          ↓
        </button>
      </div>

      {/* Container for Toolbars */}
      <div
        data-ui-element="toolbar"
        style={{
          position: 'absolute',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: '20px',
          zIndex: 1,
          flexWrap: 'nowrap',
          alignItems: 'flex-end',
        }}
      >
        {/* Treasure Chest Container */}
        <div
          data-ui-element="toolbar"
          style={{
            background: 'white',
            padding: '5px',
            border: '5px solid #F8B83A',
            borderRadius: '16px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <button
            onClick={() => {
              setIsHotbarExpanded(prev => !prev);
              setIsCursorVisible(prev => !prev);
              if (document.pointerLockElement) {
                document.exitPointerLock();
              }
            }}
            style={{
              width: '50px',
              height: '50px',
              border: 'none',
              borderRadius: '16px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#F8B83A',
              fontSize: '24px',
              fontWeight: 'bold',
              background: 'white',
              textShadow: `
                -3px -3px 0 #000,  
                 3px -3px 0 #000,
                -3px  3px 0 #000,
                 3px  3px 0 #000,
                -2px -2px 0 #000,  
                 2px -2px 0 #000,
                -2px  2px 0 #000,
                 2px  2px 0 #000`,
            }}
          >
            T
          </button>
        </div>

        {/* Main Hotbar Container */}
        <div
          data-ui-element="toolbar"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            background: 'white',
            padding: '5px',
            border: '5px solid #F8B83A',
            borderRadius: '16px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'height 0.3s ease',
          }}
        >
          {/* Extended Color Palette */}
          {isHotbarExpanded && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              {/* Tabs */}
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  marginBottom: '10px',
                }}
              >
                {['plain', 'brick', 'wood', 'large brick', 'glass', 'objects'].map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    style={{
                      padding: '8px 16px',
                      backgroundColor: activeTab === tab ? '#F8B83A' : '#ffffff',
                      border: '2px solid #F8B83A',
                      borderBottom: activeTab === tab ? 'none' : '2px solid #F8B83A',
                      borderRadius: '8px 8px 0 0',
                      cursor: 'pointer',
                      fontWeight: activeTab === tab ? 'bold' : 'normal',
                      color: activeTab === tab ? '#000000' : '#666666',
                      textTransform: 'capitalize',
                      position: 'relative',
                      top: activeTab === tab ? '2px' : '0',
                      transition: 'all 0.2s ease',
                    }}
                  >
                    {tab}
                  </button>
                ))}
              </div>

              {/* Tab Content */}
              <div style={{ height: '330px', width: '470px' }}> {/* Fixed size container */}
                {activeTab === 'plain' && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '5px',
                    }}
                  >
                    {PALETTE_COLORS.map((column, columnIndex) => (
                      <div
                        key={`column-${columnIndex}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        {column.map((color, colorIndex) => (
                          <div
                            key={`palette-${columnIndex}-${colorIndex}`}
                            draggable
                            onDragStart={(e) => handleDragStart(e, color)}
                            style={{
                              width: '50px',
                              height: '50px',
                              backgroundColor: color,
                              borderRadius: '16px',
                              cursor: 'grab',
                              boxShadow: 'inset 0 0 3px rgba(0,0,0,0.3)',
                            }}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                )}

                {(activeTab === 'brick' || activeTab === 'wood' || activeTab === 'large brick' || activeTab === 'glass' || activeTab === 'objects') && (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#666666',
                    }}
                  >
                    {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} options coming soon...
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Hotbar Colors */}
          <div style={{ display: 'flex', gap: '5px' }}>
            {hotbarColors.map((color, index) => (
              <button
                key={index}
                onClick={() => {
                  setCurrentColor(color);
                  selectedColorIndex.current = index;
                }}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, index)}
                style={{
                  width: '50px',
                  height: '50px',
                  backgroundColor: color,
                  border: selectedColorIndex.current === index
                    ? '3px solid #FFD700'
                    : 'none',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  fontSize: '18px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#fff',
                  boxShadow: 'inset 0 0 3px rgba(0,0,0,0.3)',
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>

        {/* Size Toolbar with Touch Mode Toggle */}
        {isTouchDevice && (
          <div
            data-ui-element="toolbar"
            style={{
              position: 'absolute',
              bottom: 0,
              right: -100,
              background: 'white',
              padding: '5px',
              border: '5px solid #F8B83A',
              borderRadius: '16px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <button
              data-ui-element="toolbar"
              onClick={(e) => {
                e.stopPropagation();
                setTouchMode(prev => prev === 'place' ? 'delete' : 'place');
              }}
              style={{
                width: '50px',
                height: '50px',
                backgroundColor: touchMode === 'place' ? '#4CAF50' : '#f44336',
                border: 'none',
                borderRadius: '16px',
                cursor: 'pointer',
                outline: 'none',
                fontWeight: 'bold',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                boxShadow: 'inset 0 0 3px rgba(0,0,0,0.1)',
                transition: 'transform 0.3s, background-color 0.3s',
              }}
            >
              {touchMode === 'place' ? 'PLACE' : 'DEL'}
            </button>
          </div>
        )}
      </div>

      {/* 3D Canvas */}
      <Canvas
        gl={{ powerPreference: 'default', antialias: false }}
        camera={{ position: [0, 20, 50], fov: 50 }}
        shadows
        style={{ background: '#87ceeb', width: '100%', height: '100%' }}
        onCreated={({ gl, camera }) => {
          gl.shadowMap.enabled = true;
          gl.shadowMap.type = THREE.PCFSoftShadowMap;
          camera.lookAt(0, 0, 0);
          
          // Add iOS-specific WebGL context handling
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
          if (isIOS) {
            // Set lower pixel ratio for iOS
            gl.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));
            
            // Add failsafe renderer reset
            window.addEventListener('orientationchange', () => {
              setTimeout(() => {
                gl.setSize(0, 0);
                setTimeout(() => {
                  gl.setSize(
                    gl.domElement.clientWidth,
                    gl.domElement.clientHeight
                  );
                }, 0);
              }, 100);
            });
          }
        }}
      >
        {/* Performance Stats */}
        {/* <DreiStats showPanel={0} /> */}

        {/* Scene with Voxel Rendering */}
        <Scene
          blocks={blocks}
          addBlock={addBlock}
          removeBlock={removeBlock}
          currentColor={currentColor}
          placementSize={1}
          onFaceCount={handleFaceCount}
          touchMode={touchMode}
          isInventoryOpen={isInventoryOpen}
          setIsInventoryOpen={setIsInventoryOpen}
        />
      </Canvas>

      {/* Optionally Display Face Count */}
      {/* <div
        style={{
          position: 'absolute',
          top: 20,
          left: 20,
          padding: '10px',
          background: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '4px',
          zIndex: 1,
        }}
      >
        Total Faces Rendered: {faceCount}
      </div> */}

      {/* Touch Controls Indicators - Removing this section */}
      {/* {('ontouchstart' in window) && (
        <>
          <div
            style={{
              position: 'absolute',
              left: 20,
              bottom: 100,
              padding: '10px',
              background: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '4px',
              zIndex: 1,
            }}
          >
            Left side: Move
          </div>
          <div
            style={{
              position: 'absolute',
              right: 20,
              bottom: 100,
              padding: '10px',
              background: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '4px',
              zIndex: 1,
            }}
          >
            Right side: Look
          </div>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              bottom: 100,
              transform: 'translateX(-50%)',
              padding: '10px',
              background: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '4px',
              zIndex: 1,
            }}
          >
            Single touch: Place block
            <br />
            Double touch: Remove block
          </div>
        </>
      )} */}

    </div>
  );
};

export default VoxelEditor;
