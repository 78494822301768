import React, { useMemo, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const Mars = () => {
  const { camera } = useThree();
  // Create a ref for the Mars group so we can update its children
  const marsGroupRef = useRef();

  // Define Mars constants (in km) and the atmosphere radius.
  const marsRadius = 3389.5;
  // Use a similar offset as used on Earth (e.g. ~51.8 km greater than the surface radius)
  const atmosphereOffset = 51.8;
  const marsAtmosphereRadius = marsRadius + atmosphereOffset;

  // Atmosphere visibility logic similar to Earth: only show when close enough.
  useFrame(() => {
    if (marsGroupRef.current) {
      const marsPosition = new THREE.Vector3();
      marsGroupRef.current.getWorldPosition(marsPosition);
      const distance = camera.position.distanceTo(marsPosition);
      
      // Find the atmosphere mesh by matching the sphere geometry's radius
      const atmMesh = marsGroupRef.current.children.find(
        child =>
          child.geometry instanceof THREE.SphereGeometry &&
          child.geometry.parameters.radius === marsAtmosphereRadius
      );
      if (atmMesh) {
        atmMesh.visible = distance <= marsAtmosphereRadius * 2.5;
      }
    }
  });

  // Mars surface shader with new shadow logic (borrowed from Earth)
  const marsSurfaceShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          // Apply a rotation so that Mars is tilted as desired
          float angle = radians(25.2);
          mat3 rotationMatrix = mat3(
            1.0, 0.0, 0.0,
            0.0, cos(angle), -sin(angle),
            0.0, sin(angle), cos(angle)
          );
          
          vec3 rotatedPosition = rotationMatrix * position;
          vec3 rotatedNormal = rotationMatrix * normal;
          
          vUv = uv;
          vNormal = rotatedNormal;
          vPosition = rotatedPosition;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        const float PI = 3.14159265359;

        // Simple random and noise functions for the surface texture
        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - f);
          return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.8, 0.3, 0.2); // Rusty red base for Mars
          
          // Create a wavy effect using the angle from the x/z plane
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 6.0 + time * 0.2) * 0.02;
          float wave2 = sin(angle * 4.0 + time * 0.15 + PI * 0.8) * 0.015;
          float wave3 = sin(angle * 3.0 - time * 0.3 + PI * 0.4) * 0.01;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          // Combine two noise scales for surface variations
          float terrain = noise(vec2(modifiedY * 2.0, time * 0.1)) * 0.7;
          terrain += noise(vec2(modifiedY * 16.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          if (modifiedY > 0.8) {
              bandColor = mix(vec3(0.75, 0.25, 0.15), vec3(0.85, 0.35, 0.25), terrain);
          } else if (modifiedY > 0.6) {
              bandColor = mix(vec3(0.7, 0.3, 0.2), vec3(0.8, 0.4, 0.3), terrain);
          } else if (modifiedY > 0.4) {
              bandColor = mix(vec3(0.65, 0.25, 0.15), vec3(0.75, 0.35, 0.25), terrain);
          } else if (modifiedY > 0.2) {
              bandColor = mix(vec3(0.7, 0.28, 0.18), vec3(0.8, 0.38, 0.28), terrain);
          } else {
              bandColor = mix(vec3(0.65, 0.22, 0.12), vec3(0.75, 0.32, 0.22), terrain);
          }
          
          // Apply a warm/dusty tint
          bandColor *= mix(vec3(1.0, 0.95, 0.9), vec3(0.95, 0.9, 0.85), sin(modifiedY * 6.0) * 0.5 + 0.5);
          vec3 finalColor = mix(bandColor, baseColor, 0.2);

          // NEW Shadow logic (from Earth's implementation)
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct < 0.9) {
              // Using the Earth shadow style (with a factor of 1.8)
              float shadowAngle = acos(-dotProduct) * 1.8;
              if (shadowAngle < 3.15) {
                  float shadowStrength = smoothstep(3.15, 2.8, shadowAngle);
                  shadowStrength = pow(shadowStrength, 1.5);
                  finalColor = mix(finalColor, vec3(0.0), 0.45 * shadowStrength);
              }
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  // Mars atmosphere shader (copied from Earth's atmosphere logic)
  const marsAtmosphereShader = useMemo(() => {
    return {
      uniforms: {
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec3 vPosition;
        varying vec3 vNormal;
        
        void main() {
          vPosition = position;
          vNormal = normal;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec3 vPosition;
        varying vec3 vNormal;
        uniform mat4 modelMatrix;

        float easeInOutPower(float x) {
          return x < 0.5 ? 4.0 * x * x * x : 1.0 - pow(-2.0 * x + 2.0, 3.0) / 2.0;
        }
        
        void main() {
          vec4 skyColor = vec4(0.53, 0.81, 0.92, 0.0);  // Fully transparent base
          vec4 finalColor = skyColor;
          
          // Calculate the world position and normal
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct > -0.9) {
            float angle = acos(dotProduct) * 2.0;
            if (angle < 3.0) {
              finalColor = vec4(0.25, 0.61, 0.85, 0.5);
            } else if (angle < 3.9) {
              float gradient = smoothstep(3.9, 3.0, angle);
              gradient = easeInOutPower(gradient);
              finalColor = mix(skyColor, vec4(0.25, 0.61, 0.85, 0.5), gradient);
            }
          }
          
          gl_FragColor = finalColor;
        }
      `
    };
  }, []);

  return (
    <group ref={marsGroupRef}>
      {/* Mars Atmosphere Mesh */}
      <mesh renderOrder={1}>
        <sphereGeometry args={[marsAtmosphereRadius, 220, 220]} />
        <shaderMaterial
          attach="material"
          {...marsAtmosphereShader}
          side={THREE.BackSide}
          transparent={true}
          depthWrite={false}
          blending={THREE.CustomBlending}
          blendEquation={THREE.AddEquation}
          blendSrc={THREE.SrcAlphaFactor}
          blendDst={THREE.OneMinusSrcAlphaFactor}
        />
      </mesh>

      {/* Mars Surface Mesh */}
      <mesh renderOrder={2}>
        <sphereGeometry args={[marsRadius, 80, 80]} />
        <shaderMaterial
          attach="material"
          {...marsSurfaceShader}
          side={THREE.FrontSide}
        />
      </mesh>
    </group>
  );
};

export default Mars;
