// src/pixi/CharacterThreeJS.js

import React, { useEffect, useState, useRef, memo, useMemo } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { BackgroundScene } from './BackgroundScene';
import { SpaceshipControls } from '../SpaceshipControls';
import WorldSphere from './WorldSphere';
import Stats from 'three/examples/jsm/libs/stats.module';
import gsap from 'gsap';

// Styled components for the UI overlay
const HotbarOverlay = {
  position: 'absolute',
  top: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  padding: '10px 20px',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)'
};

const Button = {
  padding: '8px 16px',
  backgroundColor: '#2196F3',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '14px',
  transition: 'background-color 0.2s',
  ':hover': {
    backgroundColor: '#1976D2'
  }
};

const Slider = {
  width: '200px',
  margin: '0 10px'
};

/* ------------------------------------------------------------------
  Camera Controller Component for smooth FOV adjustments
------------------------------------------------------------------ */
const CameraController = ({ targetFOV }) => {
  const { camera } = useThree();
  const currentFOV = useRef(camera.fov);
  const tweenRef = useRef(null);
  const isInitialRender = useRef(true);
  
  useEffect(() => {
    // On initial render, set FOV immediately without animation
    if (isInitialRender.current) {
      camera.fov = targetFOV;
      camera.updateProjectionMatrix();
      currentFOV.current = targetFOV;
      isInitialRender.current = false;
      return;
    }
    
    // Kill any existing tween
    if (tweenRef.current) {
      tweenRef.current.kill();
    }
    
    // Create a new tween with exactly 2 seconds duration
    tweenRef.current = gsap.to(currentFOV, {
      current: targetFOV,
      duration: 2,
      ease: "power2.inout",
      onUpdate: () => {
        camera.fov = currentFOV.current;
        camera.updateProjectionMatrix();
      }
    });
    
    // Cleanup on unmount
    return () => {
      if (tweenRef.current) {
        tweenRef.current.kill();
      }
    };
  }, [targetFOV, camera]);
  
  return null;
};

/* ------------------------------------------------------------------
  Main ThreeSVGRenderer
------------------------------------------------------------------ */

// SharedWorldSphere component to render WorldSphere and related components
const SharedWorldSphere = memo(({ 
  guidanceSystem, 
  orbitLock, 
  viewLock, 
  gravity, 
  gravityAssist, 
  land, 
  selectedCelestialBody, 
  speed, 
  timelapse, 
  planetLabels, 
  onUpdatePositions, 
  zoom, 
  axisD, 
  axisZoom, 
  vertical, 
  horizontal, 
  cameraOrbitalSpeed,
  sceneCameraControl,
  targetFOV
}) => {
  return (
    <>
      <CameraController targetFOV={targetFOV} />
      <BackgroundScene />
      <SpaceshipControls 
        speed={speed} 
        onUpdatePositions={onUpdatePositions}
        planetLabels={planetLabels}
        guidanceSystem={guidanceSystem}
        zoom={zoom}
        selectedCelestialBody={selectedCelestialBody}
        vertical={vertical}
      />
      <WorldSphere 
        guidanceSystem={guidanceSystem}
        orbitLock={orbitLock}
        viewLock={viewLock}
        gravity={gravity}
        gravityAssist={gravityAssist}
        land={land}
        selectedCelestialBody={selectedCelestialBody}
        speed={speed}
        timelapse={timelapse}
        planetLabels={planetLabels}
        onUpdatePositions={onUpdatePositions}
        zoom={zoom}
        axisD={axisD}
        axisZoom={axisZoom}
        vertical={vertical}
        horizontal={horizontal}
        cameraOrbitalSpeed={cameraOrbitalSpeed}
        sceneCameraControl={sceneCameraControl}
      />
    </>
  );
});

// Add this new component for renderer control
const RendererSettings = () => {
  const { gl, clock } = useThree();
  
  useEffect(() => {
    // Configure WebGL context with better settings for consistent performance
    gl.setPixelRatio(Math.min(window.devicePixelRatio, 1.5)); // Reduce to 1.5 max
    gl.setClearColor(0x000000, 0); // Ensure transparent background
    
    // Proper way to unlock framerate with React Three Fiber
    if (typeof gl.setAnimationLoop === 'function') {
      const originalSetAnimationLoop = gl.setAnimationLoop;
      gl.setAnimationLoop = function(callback) {
        // Use the standard requestAnimationFrame instead of waiting for vsync
        let rafId;
        if (callback) {
          const animate = (time) => {
            callback(time);
            rafId = requestAnimationFrame(animate);
          };
          rafId = requestAnimationFrame(animate);
          return () => cancelAnimationFrame(rafId);
        } else if (rafId) {
          cancelAnimationFrame(rafId);
        }
      };
    }
    
    // Handle fullscreen changes
    const handleFullscreenChange = () => {
      setTimeout(() => {
        // Reset pixel ratio
        gl.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));
        
        // Force renderer update (safer approach)
        if (!gl.disposed) {
          gl.setSize(gl.domElement.clientWidth, gl.domElement.clientHeight, false);
        }
      }, 100);
    };
    
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
    };
  }, [gl, clock]);
  
  return null;
};

const ThreeSVGRenderer = ({ 
  guidanceSystem,
  orbitLock,
  viewLock,
  gravity,
  gravityAssist,
  land,
  selectedCelestialBody: propSelectedCelestialBody,
  speed,
  timelapse,
  planetLabels,
  zoom,
  axisD,
  axisZoom,
  vertical,
  horizontal,
  cameraOrbitalSpeed,
  sceneCameraControl,
}) => {
  const [positions, setPositions] = useState({});
  const [selectedCelestialBody, setSelectedCelestialBody] = useState('Earth');
  const statsRef = useRef();
  
  // Update targetFOV calculation to use the prop
  const targetFOV = useMemo(() => {
    // 25 is the default FOV, lower values = more zoom
    // Invert the range: 81->25 (least zoom), 105->5 (most zoom)
    return 25 - ((sceneCameraControl - 81) / (105 - 81)) * 20;
  }, [sceneCameraControl]);

  // Initialize Stats only in development
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      statsRef.current = new Stats();
      statsRef.current.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild(statsRef.current.dom);
      
      // Position the stats panel in the top-right corner
      statsRef.current.dom.style.position = 'absolute';
      statsRef.current.dom.style.top = '0px';
      statsRef.current.dom.style.right = '0px';

      return () => {
        document.body.removeChild(statsRef.current.dom);
      };
    }
  }, []);

  // Update stats every frame
  const animate = () => {
    statsRef.current?.update();
    requestAnimationFrame(animate);
  };

  useEffect(() => {
    animate();
    return () => cancelAnimationFrame(animate);
  }, []);

  useEffect(() => {
    // Update local state when prop changes
    setSelectedCelestialBody(propSelectedCelestialBody);
  }, [propSelectedCelestialBody]);

  const handleUpdatePositions = (newPositions) => {
    setPositions(newPositions);
  };

  // Memoize the shared world sphere content to render only once
  const worldSphereContent = useMemo(() => (
    <SharedWorldSphere
      guidanceSystem={guidanceSystem}
      orbitLock={orbitLock}
      viewLock={viewLock}
      gravity={gravity}
      gravityAssist={gravityAssist}
      land={land}
      selectedCelestialBody={selectedCelestialBody}
      speed={speed}
      timelapse={timelapse}
      planetLabels={planetLabels}
      onUpdatePositions={handleUpdatePositions}
      zoom={zoom}
      axisD={axisD}
      axisZoom={axisZoom}
      vertical={vertical}
      horizontal={horizontal}
      cameraOrbitalSpeed={cameraOrbitalSpeed}
      sceneCameraControl={sceneCameraControl}
      targetFOV={targetFOV}
    />
  ), [
    guidanceSystem, orbitLock, viewLock, gravity, gravityAssist, land,
    selectedCelestialBody, speed, timelapse, planetLabels, zoom,
    axisD, axisZoom, vertical, horizontal, cameraOrbitalSpeed, sceneCameraControl, targetFOV
  ]);

  return (
    <div style={{ 
      position: 'absolute', 
      inset: 0,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      {/* Fixed aspect ratio container */}
      <div style={{ 
        position: 'relative',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        // This maintains the aspect ratio
        aspectRatio: '1920/1080'
      }}>
        <Canvas
          camera={{
            position: [149600000 + 10000, 5000, 10000],
            fov: 25,
            near: 1,
            far: 9000000000,
            up: [0, 1, 0],
          }}
          style={{ 
            width: '100%',
            height: '100%',
          }}
          gl={{
            antialias: true,
            alpha: true,
            depth: true,
            powerPreference: 'high-performance',
            logarithmicDepthBuffer: true,
            pixelRatio: Math.min(window.devicePixelRatio, 1.5),
            // Add these parameters for better performance
            precision: 'lowp',
            stencil: false
          }}
        >
          <RendererSettings />
          {worldSphereContent}
        </Canvas>
      </div>
    </div>
  );
};

ThreeSVGRenderer.propTypes = {
  guidanceSystem: PropTypes.bool,
  orbitLock: PropTypes.bool,
  viewLock: PropTypes.bool,
  gravity: PropTypes.bool,
  gravityAssist: PropTypes.bool,
  land: PropTypes.bool,
  selectedCelestialBody: PropTypes.string,
  speed: PropTypes.number,
  timelapse: PropTypes.number,
  zoom: PropTypes.number,
  axisD: PropTypes.number,
  axisZoom: PropTypes.number,
  vertical: PropTypes.number,
  horizontal: PropTypes.number,
  planetLabels: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      z: PropTypes.number
    }),
    color: PropTypes.string
  })),
  cameraOrbitalSpeed: PropTypes.number,
  sceneCameraControl: PropTypes.number,
};

export default ThreeSVGRenderer;