// BeeAnimation.js - Module for creating animated bee texture for Three.js

import { BeeLegs } from './BeeLegs.js';
import { BeeEyes } from './BeeEyes.js';

export class BeeAnimation {
  constructor(width = 4800, height = 1200, config = {}) {
    // Create canvas
    this.canvas = document.createElement('canvas');
    this.canvas.width = width;
    this.canvas.height = height;
    this.ctx = this.canvas.getContext('2d');
    
    // Store the current config in a property
    this.config = {
      topEyeSquareHeight: 50,
      bottomEyeSquareHeight: 50,
      topEyeCircleHeight: 50,
      bottomEyeCircleHeight: 50,
      ...config
    };
    
    // Initialize utility modules
    this.beeLegs = new BeeLegs();
    this.beeEyes = new BeeEyes();
    
    // Create a global object to store eye configuration
    window.beeEyeConfig = {
      topEyeSquareHeight: 3, // Default multiplier
      bottomEyeSquareHeight: 1, // Default multiplier
      topEyeCircleHeight: 3, // Default multiplier
      bottomEyeCircleHeight: 1, // Default multiplier
    };
    
    // Setup debounce for config updates
    this.configUpdateTimeout = null;
    
    // Add frame throttling to reduce update frequency
    this.lastFrameTime = 0;
    this.frameInterval = 1000 / 30; // Target 30fps for internal animation
    
    // Pre-calculate and cache frequently used values
    this.cachedValues = {};
    this.cachedGeometries = {};
    
    // Initialize animation state
    this.initializeAnimationState();
    
    // Add rendering optimization properties
    this.needsUpdate = true;  // Only redraw when something has changed
    this.renderRequested = false;  // Flag to prevent multiple render requests
    this.lastStateSnapshot = {};  // Used to detect state changes
    this.dirtyRegions = [];  // Track regions that need redrawing
    
    // Start animation loop with optimized rendering
    this.initOptimizedRendering();
  }
  
  // Initialize animation state
  initializeAnimationState() {
    // =========================
    // BREATHING ANIMATION STATE
    // =========================
    this.currentBend = 1; // initial bend value
    this.currentPurpleBend = 1;
    this.currentAquaBend = -1;
    this.targetPurpleBend = 1;
    this.targetAquaBend = -1;
    this.bendTransitionSpeed = 0.1; // Controls how fast the bend transitions

    // Floor parameters
    this.floor = {
      height: 50,
      colour: "#654321"
    };

    // Initialize legs and related state
    const {
      circle,
      dot,
      stepThreshold,
      stepHeight,
      lastSteppedFoot,
      leftFoot,
      rightFoot,
      redCircle,
      redCircleVelocity,
      floorFootY
    } = this.beeLegs.initializeLegs(this.canvas, this.floor.height);
    
    this.circle = circle;
    this.dot = dot;
    this.stepThreshold = stepThreshold;
    this.stepHeight = stepHeight;
    this.lastSteppedFoot = lastSteppedFoot;
    this.leftFoot = leftFoot;
    this.rightFoot = rightFoot;
    this.redCircle = redCircle;
    this.redCircleVelocity = redCircleVelocity;
    this.floorFootY = floorFootY;

    // Landing animation variables
    this.landingAnimationActive = false;
    this.landingAnimationProgress = 0;
    this.landingAnimationDuration = 30; // # frames
    this.maxBounce = 10;
    this.bounce = 0;

    // Movement/drag and gravity state variables
    this.dragging = false;
    this.gravityOn = false;
    this.gravityAcceleration = 0.5;
    this.wasOnFloor = false;
    this.onFloor = false;
    this.lastCircleX = this.circle.x;

    // Click transition variables
    this.targetCircle = null;
    this.transitionActive = false;
    this.transitionStartTime = 0;
    this.transitionDuration = 2000; // Now a minimum duration
    this.startPos = { x: this.circle.x, y: this.circle.y };
    this.targetPos = { x: 0, y: 0 };
    this.clickInitiatedOnCircle = false;
    this.currentTransitionDuration = 2000; // Will be calculated based on distance

    // Add timestamp tracking for feet animation
    this.lastTime = performance.now();
    this.stepSpeed = 0.01; // Increased by 4x for faster steps

    // =========================
    // YELLOW CHARACTER STATE
    // =========================
    // Global scale and sizes for yellow character
    this.compositeScale = 1.5;
    this.blackCircleRadius = 100 * this.compositeScale;
    this.outerOffset = 10 * this.compositeScale;

    this.rectangle = {
      x: this.redCircle.x - 30 * this.compositeScale,
      y: this.redCircle.y - 35 * this.compositeScale,
      width: 60 * this.compositeScale,
      height: 60 * this.compositeScale,
      dragging: false
    };

    // Rename the yellow character's orange circle so it does not conflict
    this.orangeCircleYellow = {
      x: this.redCircle.x + this.blackCircleRadius,
      y: this.redCircle.y,
      radius: 25,
      colour: "orange",
      dragging: false,
      positionFixed: false, // Add this flag to track if position has been manually set
      angle: 0, // Add initial angle for rotation
      rotationSpeed: 0.005 // Speed of rotation (radians per frame)
    };

    this.purpleCircle = {
      x: 0,
      y: 0,
      radius: 5 * this.compositeScale,
      dragging: false,
      baseY: 0,
      distanceFromCenter: -60 * this.compositeScale,
      heightOffset: -50 * this.compositeScale, // Initial height offset
      customHeightOffset: 0 // Add this to track manual y-position changes
    };

    this.aquaCircle = {
      x: 0,
      y: 0,
      radius: 5 * this.compositeScale,
      dragging: false,
      baseY: 0,
      distanceFromCenter: 60 * this.compositeScale,
      heightOffset: -50 * this.compositeScale, // Initial height offset
      customHeightOffset: 0 // Add this to track manual y-position changes
    };

    // Remove existing event listeners first to avoid duplicates
    this.canvas.removeEventListener('mousedown', this.handleMouseDown.bind(this));
    document.removeEventListener('mousemove', this.handleMouseMove.bind(this));
    document.removeEventListener('mouseup', this.handleMouseUp.bind(this));
    this.canvas.removeEventListener('touchstart', this.handleTouchStart.bind(this));
    document.removeEventListener('touchmove', this.handleTouchMove.bind(this));
    document.removeEventListener('touchend', this.handleTouchEnd.bind(this));
    
    // Create bound versions of the handlers to ensure proper binding
    this._boundMouseDown = this.handleMouseDown.bind(this);
    this._boundMouseMove = this.handleMouseMove.bind(this);
    this._boundMouseUp = this.handleMouseUp.bind(this);
    this._boundTouchStart = this.handleTouchStart.bind(this);
    this._boundTouchMove = this.handleTouchMove.bind(this);
    this._boundTouchEnd = this.handleTouchEnd.bind(this);
    
    // Add event listeners with bound handlers
    this.canvas.addEventListener('mousedown', this._boundMouseDown);
    document.addEventListener('mousemove', this._boundMouseMove);
    document.addEventListener('mouseup', this._boundMouseUp);
    
    // Touch support for mobile devices with lower passive setting
    this.canvas.addEventListener('touchstart', this._boundTouchStart, { passive: false });
    document.addEventListener('touchmove', this._boundTouchMove, { passive: false });
    document.addEventListener('touchend', this._boundTouchEnd);
  }
  
  // Initialize the optimized rendering system
  initOptimizedRendering() {
    // Create a state snapshot to compare against for changes
    this.updateStateSnapshot();
    
    // Initial render
    this.draw();
    
    // Instead of constantly animating, only request frames when needed
    this.scheduleNextUpdate();
  }
  
  // Create a snapshot of current state to detect changes
  updateStateSnapshot() {
    this.lastStateSnapshot = {
      circlePos: { x: this.circle.x, y: this.circle.y },
      redCirclePos: { x: this.redCircle.x, y: this.redCircle.y },
      orangePos: { x: this.orangeCircleYellow.x, y: this.orangeCircleYellow.y },
      leftFootPos: { x: this.leftFoot.x, y: this.leftFoot.y },
      rightFootPos: { x: this.rightFoot.x, y: this.rightFoot.y },
      purpleCirclePos: { x: this.purpleCircle.x, y: this.purpleCircle.y },
      aquaCirclePos: { x: this.aquaCircle.x, y: this.aquaCircle.y },
      currentBend: this.currentBend,
      onFloor: this.onFloor,
      landingAnimationActive: this.landingAnimationActive,
      transitionActive: this.transitionActive
    };
  }
  
  // Check if state has changed significantly enough to require redraw
  hasStateChanged() {
    if (!this.lastStateSnapshot) return true;
    
    // Check key positions for changes greater than 0.5 pixels
    const threshold = 0.5;
    
    const positionHasChanged = (current, last) => {
      return Math.abs(current.x - last.x) > threshold || 
             Math.abs(current.y - last.y) > threshold;
    };
    
    return positionHasChanged({x: this.circle.x, y: this.circle.y}, this.lastStateSnapshot.circlePos) ||
           positionHasChanged({x: this.redCircle.x, y: this.redCircle.y}, this.lastStateSnapshot.redCirclePos) ||
           positionHasChanged({x: this.orangeCircleYellow.x, y: this.orangeCircleYellow.y}, this.lastStateSnapshot.orangePos) ||
           positionHasChanged({x: this.leftFoot.x, y: this.leftFoot.y}, this.lastStateSnapshot.leftFootPos) ||
           positionHasChanged({x: this.rightFoot.x, y: this.rightFoot.y}, this.lastStateSnapshot.rightFootPos) ||
           Math.abs(this.currentBend - this.lastStateSnapshot.currentBend) > 0.05 ||
           this.onFloor !== this.lastStateSnapshot.onFloor ||
           this.landingAnimationActive !== this.lastStateSnapshot.landingAnimationActive ||
           this.transitionActive !== this.lastStateSnapshot.transitionActive;
  }
  
  // Schedule the next animation frame only if needed
  scheduleNextUpdate() {
    // Instead of setting a new timer directly, use requestAnimationFrame
    // which will sync properly with the browser's rendering cycle
    this.animationFrameRequest = requestAnimationFrame(() => this.update());
  }
  
  // Update method replaces animate - separates state updates from rendering
  update() {
    // Cancel any existing animation frame request to prevent duplicates
    if (this.animationFrameRequest) {
      cancelAnimationFrame(this.animationFrameRequest);
      this.animationFrameRequest = null;
    }
    
    const currentTime = performance.now();
    const deltaTime = currentTime - (this.lastUpdateTime || currentTime);
    this.lastUpdateTime = currentTime;
    
    // Limit the effective delta time to avoid huge jumps
    // but DON'T hardcode timing based on 60fps
    const maxDelta = 100; // max 100ms (prevents huge jumps after tab switching, etc)
    const effectiveDelta = Math.min(deltaTime, maxDelta);
    
    // Update animations with the deltaTime
    this.updateTransitions(currentTime);
    this.updateBendState();
    this.updateFeetPositions(effectiveDelta);
    this.updateOrangeCirclePosition(effectiveDelta);
    this.checkFloorCollision();
    this.updateLandingAnimation();
    
    // Only redraw if needed
    if (this.needsUpdate || this.hasStateChanged()) {
      this.updateStateSnapshot();
      this.draw();
      this.needsUpdate = false;
    }
    
    // Schedule the next update
    this.scheduleNextUpdate();
  }
  
  // Calculate regions that need to be redrawn
  calculateDirtyRegions() {
    this.dirtyRegions = [];
    const padding = 30; // Padding around elements to ensure full coverage
    
    // Add character and its movement area
    this.addDirtyRegion(
      Math.min(this.circle.x, this.lastStateSnapshot.circlePos.x) - padding,
      Math.min(this.circle.y, this.lastStateSnapshot.circlePos.y) - padding,
      Math.abs(this.circle.x - this.lastStateSnapshot.circlePos.x) + padding * 2,
      Math.abs(this.circle.y - this.lastStateSnapshot.circlePos.y) + padding * 2
    );
    
    // Add red circle area
    this.addDirtyRegion(
      Math.min(this.redCircle.x, this.lastStateSnapshot.redCirclePos.x) - padding,
      Math.min(this.redCircle.y, this.lastStateSnapshot.redCirclePos.y) - padding,
      Math.abs(this.redCircle.x - this.lastStateSnapshot.redCirclePos.x) + padding * 2,
      Math.abs(this.redCircle.y - this.lastStateSnapshot.redCirclePos.y) + padding * 2
    );
    
    // Add orange circle area
    this.addDirtyRegion(
      Math.min(this.orangeCircleYellow.x, this.lastStateSnapshot.orangePos.x) - padding,
      Math.min(this.orangeCircleYellow.y, this.lastStateSnapshot.orangePos.y) - padding,
      Math.abs(this.orangeCircleYellow.x - this.lastStateSnapshot.orangePos.x) + padding * 2,
      Math.abs(this.orangeCircleYellow.y - this.lastStateSnapshot.orangePos.y) + padding * 2
    );
    
    // Add feet areas
    this.addDirtyRegion(
      Math.min(this.leftFoot.x, this.lastStateSnapshot.leftFootPos.x) - padding,
      Math.min(this.leftFoot.y, this.lastStateSnapshot.leftFootPos.y) - padding,
      Math.abs(this.leftFoot.x - this.lastStateSnapshot.leftFootPos.x) + padding * 2,
      Math.abs(this.leftFoot.y - this.lastStateSnapshot.leftFootPos.y) + padding * 2
    );
    
    this.addDirtyRegion(
      Math.min(this.rightFoot.x, this.lastStateSnapshot.rightFootPos.x) - padding,
      Math.min(this.rightFoot.y, this.lastStateSnapshot.rightFootPos.y) - padding,
      Math.abs(this.rightFoot.x - this.lastStateSnapshot.rightFootPos.x) + padding * 2,
      Math.abs(this.rightFoot.y - this.lastStateSnapshot.rightFootPos.y) + padding * 2
    );
    
    // If landing animation is active, add the whole character area
    if (this.landingAnimationActive) {
      const radius = this.blackCircleRadius + padding * 2;
      this.addDirtyRegion(
        this.circle.x - radius,
        this.circle.y - radius,
        radius * 2,
        radius * 2
      );
    }
  }
  
  // Add a dirty region, ensuring it's within canvas bounds
  addDirtyRegion(x, y, width, height) {
    // Ensure region is within canvas bounds
    x = Math.max(0, Math.min(x, this.canvas.width));
    y = Math.max(0, Math.min(y, this.canvas.height));
    width = Math.min(width, this.canvas.width - x);
    height = Math.min(height, this.canvas.height - y);
    
    // Only add valid regions
    if (width > 0 && height > 0) {
      this.dirtyRegions.push({x, y, width, height});
    }
  }
  
  // Separate bend update logic
  updateBendState() {
    var targetBend;
    if (this.transitionActive) {
      var overallDx = this.targetPos.x - this.startPos.x;
      targetBend = overallDx > 0 ? -1 : 1;
    } else {
      var dx = this.circle.x - this.lastCircleX;
      targetBend = Math.abs(dx) > 0.1 ? (dx > 0 ? -1 : 1) : this.currentBend;
    }
    this.currentBend += (targetBend - this.currentBend) * 0.05;
  }
  
  // Separate transition update logic
  updateTransitions(currentTime) {
    if (this.transitionActive) {
      var elapsed = currentTime - this.transitionStartTime;
      var t = Math.min(elapsed / this.currentTransitionDuration, 1);
      var easedT = this.easeInOut(t);
      
      const dx = this.targetPos.x - this.startPos.x;
      const dy = this.targetPos.y - this.startPos.y;
      
      this.circle.x = this.startPos.x + dx * easedT;
      this.circle.y = this.startPos.y + dy * easedT;
      
      if (t === 1) { 
        this.transitionActive = false; 
        this.targetCircle = null; 
      }
    } else if (this.gravityOn && !this.dragging) {
      this.circle.velocityY += this.gravityAcceleration;
      this.circle.y += this.circle.velocityY;
    }
  }
  
  // Separate feet position update logic
  updateFeetPositions(deltaTime) {
    const { updatedLastSteppedFoot } = this.beeLegs.updateFeet(
      this.circle, 
      this.leftFoot, 
      this.rightFoot, 
      this.dot, 
      this.stepThreshold, 
      this.stepHeight, 
      this.lastCircleX, 
      this.onFloor,
      this.lastSteppedFoot
    );
    
    if (updatedLastSteppedFoot) {
      this.lastSteppedFoot = updatedLastSteppedFoot;
    }
    
    this.beeLegs.updateFootStep(this.leftFoot, this.stepHeight, deltaTime, this.stepSpeed);
    this.beeLegs.updateFootStep(this.rightFoot, this.stepHeight, deltaTime, this.stepSpeed);
    
    if (!this.leftFoot.isStepping) this.leftFoot.y = this.circle.y + this.dot.offsetY;
    if (!this.rightFoot.isStepping) this.rightFoot.y = this.circle.y + this.dot.offsetY;

    this.beeLegs.updateRedCircle(this.redCircle, this.redCircleVelocity, this.circle, this.bounce, this.onFloor);
  }
  
  // Draw method - handles actual rendering instead of animate
  draw() {
    // Clear only the dirty regions
    this.clearDirtyRegions();
    
    // Redraw the character
    this.drawBreathingCharacter();
    this.drawYellowCharacter();
  }
  
  // Clear only the regions that need updating
  clearDirtyRegions() {
    if (this.dirtyRegions.length === 0) {
      // If no dirty regions, do a focused clear around the character
      this.optimizedClear();
      return;
    }
    
    // Clear each dirty region
    this.dirtyRegions.forEach(region => {
      this.ctx.clearRect(region.x, region.y, region.width, region.height);
    });
  }

  // Replace the animate method with optimized version
  animate() {
    // Start the animation loop if not already running
    this.lastUpdateTime = performance.now();
    this.scheduleNextUpdate();
  }
  
  // Force a full redraw when needed (e.g., after resize)
  forceFullRedraw() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.draw();
  }
  
  // Optimize canvas clearing by only clearing the affected areas
  optimizedClear() {
    // Calculate the bounding box of all animated elements
    const padding = 20; // Add some padding around elements
    
    let minX = Math.min(this.circle.x, this.redCircle.x, this.orangeCircleYellow.x) - this.blackCircleRadius - padding;
    let minY = Math.min(this.circle.y, this.redCircle.y, this.orangeCircleYellow.y) - this.blackCircleRadius - padding;
    let maxX = Math.max(this.circle.x, this.redCircle.x, this.orangeCircleYellow.x) + this.blackCircleRadius + padding;
    let maxY = Math.max(this.circle.y, this.redCircle.y, this.orangeCircleYellow.y) + this.blackCircleRadius + padding;
    
    // Include feet positions
    minX = Math.min(minX, this.leftFoot.x - padding, this.rightFoot.x - padding);
    minY = Math.min(minY, this.leftFoot.y - padding, this.rightFoot.y - padding);
    maxX = Math.max(maxX, this.leftFoot.x + padding, this.rightFoot.x + padding);
    maxY = Math.max(maxY, this.leftFoot.y + padding, this.rightFoot.y + padding);
    
    // Ensure we're within canvas bounds
    minX = Math.max(0, minX);
    minY = Math.max(0, minY);
    maxX = Math.min(this.canvas.width, maxX);
    maxY = Math.min(this.canvas.height, maxY);
    
    // Only clear the bounding box of animated elements
    const width = maxX - minX;
    const height = maxY - minY;
    
    // Only perform clearing if we have a valid area
    if (width > 0 && height > 0) {
      this.ctx.clearRect(minX, minY, width, height);
    } else {
      // Fallback to clearing the entire canvas if calculations went wrong
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }
  
  // Update eye configuration based on current config values
  updateEyeConfig() {
    if (!this.config) return;
    
    // Clear any pending timeout
    if (this.configUpdateTimeout) {
      clearTimeout(this.configUpdateTimeout);
    }
    
    // Set a new timeout to update the config after a short delay
    this.configUpdateTimeout = setTimeout(() => {
      // Map the 0-100 range to appropriate multipliers (1-5)
      window.beeEyeConfig = {
        topEyeSquareHeight: 1 + (this.config.topEyeSquareHeight / 25), // 0-100 → 1-5
        bottomEyeSquareHeight: 1 + (this.config.bottomEyeSquareHeight / 25), // 0-100 → 1-5
        topEyeCircleHeight: 1 + (this.config.topEyeCircleHeight / 25), // 0-100 → 1-5
        bottomEyeCircleHeight: 1 + (this.config.bottomEyeCircleHeight / 25), // 0-100 → 1-5
      };
    }, 50); // 50ms debounce
  }
  
  // Easing function for animations
  easeInOut(t) {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  }
  
  // Check for floor collision
  checkFloorCollision() {
    const floorY = this.canvas.height - this.floor.height;
    const feetY = this.circle.y + this.dot.offsetY;
    if (feetY + this.dot.radius > floorY) {
      this.onFloor = true;
      this.circle.y = this.floorFootY - this.dot.offsetY;
      this.circle.velocityY = 0;
    } else {
      this.onFloor = false;
    }
  }
  
  // Trigger landing animation when landing on floor
  triggerLandingAnimation() {
    if (this.onFloor && !this.wasOnFloor && !this.dragging) {
      this.landingAnimationActive = true;
      this.landingAnimationProgress = 0;
    }
  }
  
  // Update landing animation state
  updateLandingAnimation() {
    if (this.landingAnimationActive) {
      this.landingAnimationProgress++;
      var t = this.landingAnimationProgress / this.landingAnimationDuration;
      this.bounce = this.maxBounce * Math.sin(Math.PI * t);
      if (this.landingAnimationProgress >= this.landingAnimationDuration) {
        this.landingAnimationActive = false;
        this.bounce = 0;
      }
    }
  }
  
  // Draw the main breathing character (red circle, legs, feet)
  drawBreathingCharacter() {
    // Clear with a larger area than the canvas to allow overflow
    this.ctx.clearRect(-1000, -1000, this.canvas.width + 2000, this.canvas.height + 2000);
    
    // Draw floor
    this.ctx.fillStyle = '#333333'; // Dark grey
    this.ctx.fillRect(-1000, this.canvas.height - this.floor.height, this.canvas.width + 2000, this.floor.height);

    // Draw the red circle (with damping) more visibly
    this.ctx.beginPath();
    this.ctx.arc(this.redCircle.x, this.redCircle.y, 25, 0, Math.PI * 2); // Increased radius
    this.ctx.fillStyle = "rgba(255, 0, 0, 0.7)"; // Semi-transparent fill
    this.ctx.fill();
    this.ctx.lineWidth = 4;
    this.ctx.strokeStyle = "darkred"; // Add a contrasting stroke
    this.ctx.stroke();
    this.ctx.closePath();

    // Draw legs and feet
    this.beeLegs.drawLegs(this.ctx, this.redCircle, this.leftFoot, this.rightFoot, this.currentBend);
    
    // Draw blue target marker if active
    if (this.targetCircle) {
      this.ctx.beginPath();
      this.ctx.arc(this.targetCircle.x, this.targetCircle.y, 20, 0, Math.PI * 2); // Increased radius
      this.ctx.fillStyle = "rgba(0, 0, 255, 0.3)"; // Semi-transparent fill
      this.ctx.strokeStyle = "blue"; // Blue outline
      this.ctx.lineWidth = 4;
      this.ctx.fill();
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }
  
  // Draw a vertical pill shape (helper for the bee body)
  drawVerticalPill(x, y, width, height) {
    const radius = width / 2;
    this.ctx.beginPath();
    this.ctx.arc(x + width / 2, y + radius, radius, Math.PI, 0, false);
    this.ctx.lineTo(x + width, y + height - radius);
    this.ctx.arc(x + width / 2, y + height - radius, radius, 0, Math.PI, false);
    this.ctx.closePath();
  }
  
  // Draw the yellow bee character
  drawYellowCharacter() {
    // Update rectangle position to follow red circle
    if (!this.rectangle.dragging) {
      this.rectangle.x = this.redCircle.x - 30 * this.compositeScale;
      this.rectangle.y = this.redCircle.y - 35 * this.compositeScale;
    }

    // Calculate dimensions and positions
    const pullWidth = this.rectangle.width * 1.2 * 0.9;
    const pullHeight = 120 * this.compositeScale;
    const pullX = this.rectangle.x + this.rectangle.width / 2 - pullWidth / 2;
    const pullY = this.rectangle.y - pullHeight / 2 + 20 * this.compositeScale;
    const centerX = this.rectangle.x + this.rectangle.width / 2;
    const centerY = pullY + pullHeight / 2;

    // Calculate angle between orangeCircleYellow and center
    const angle = Math.atan2(this.orangeCircleYellow.y - centerY, this.orangeCircleYellow.x - centerX);
    let degrees = Math.round(angle * (180 / Math.PI) + 90);
    if (degrees < 0) degrees += 360;
    if (degrees >= 360) degrees -= 360;

    // Compute interpolation factors and positions
    const circleRadius = 5 * this.compositeScale;
    const leftBaseX = pullX;
    const rightBaseX = pullX + pullWidth;
    const lowerY = centerY;
    const upperY = centerY - pullHeight * 0.35;
    
    let interpolationFactor = degrees <= 180 ? (180 - degrees) / 180 : (degrees - 180) / 180;
    let redDegrees = degrees - 40; if (redDegrees < 0) redDegrees += 360;
    let redInterpolationFactor = redDegrees <= 180 ? (180 - redDegrees) / 180 : (redDegrees - 180) / 180;
    let whiteDegrees = degrees + 220; if (whiteDegrees >= 360) whiteDegrees -= 360;
    let whiteInterpolationFactor = whiteDegrees <= 180 ? (180 - whiteDegrees) / 180 : (whiteDegrees - 180) / 180;
    let brownDegrees = degrees - 40; if (brownDegrees < 0) brownDegrees += 360;
    let brownInterpolationFactor = brownDegrees <= 180 ? (180 - brownDegrees) / 180 : (brownDegrees - 180) / 180;
    let greyDegrees = degrees + 220; if (greyDegrees >= 360) greyDegrees -= 360;
    let greyInterpolationFactor = greyDegrees <= 180 ? (180 - greyDegrees) / 180 : (greyDegrees - 180) / 180;
    
    // Add the missing pinkFactor definition
    let pinkDegrees = degrees - 20; if (pinkDegrees < 0) pinkDegrees += 360;
    let pinkFactor = pinkDegrees <= 180 ? (180 - pinkDegrees) / 180 : (pinkDegrees - 180) / 180;

    const leftX = leftBaseX + (rightBaseX - leftBaseX) * interpolationFactor;
    const rightX = rightBaseX - (rightBaseX - leftBaseX) * interpolationFactor;
    const redX = leftBaseX + (rightBaseX - leftBaseX) * redInterpolationFactor;
    const whiteX = leftBaseX + (rightBaseX - leftBaseX) * whiteInterpolationFactor;
    const brownX = leftBaseX + (rightBaseX - leftBaseX) * brownInterpolationFactor;
    const greyX = leftBaseX + (rightBaseX - leftBaseX) * greyInterpolationFactor;

    // Upper set (red and white) - eyes
    const eyeSpacingFactor = 1.2; // Reduced to bring eyes closer together

    // Calculate additional offset based on angle
    const angleOffsetFactor = Math.abs(Math.sin(angle));

    // Combine the base spacing with the angle-based offset
    const effectiveSpacingFactor = eyeSpacingFactor * (1 - 0.5 * angleOffsetFactor);

    // Define highlight variables
    const highlightRadius = circleRadius * 0.4;
    const highlightOffsetX = circleRadius * 0.2;
    const highlightOffsetY = -circleRadius * 0.2;

    // Apply sine wave easing to the interpolation factors
    const easeInterpolation = (factor) => {
      const angle = (factor - 0.5) * Math.PI;
      return (Math.sin(angle) + 1) / 2;
    };

    // Apply the easing to the interpolation factors
    const easedRedInterpolation = easeInterpolation(redInterpolationFactor);
    const easedWhiteInterpolation = easeInterpolation(whiteInterpolationFactor);

    // Adjust the positions with the effective spacing factor and eased interpolation
    const adjustedRedX = leftBaseX + (rightBaseX - leftBaseX) * (easedRedInterpolation * effectiveSpacingFactor + (1 - effectiveSpacingFactor) * 0.5);
    const adjustedWhiteX = leftBaseX + (rightBaseX - leftBaseX) * (easedWhiteInterpolation * effectiveSpacingFactor + (1 - effectiveSpacingFactor) * 0.5);

    // Calculate eye vertical offset
    const eyeVerticalOffset = circleRadius * 1.2;

    // Define eye radius for the bottom eye
    const eyeRadius = 6 * this.compositeScale;

    // Calculate positions for the foreground circles
    const middleY = centerY - pullHeight * 0.15;
    const uniformCircleSize = circleRadius * 1.2;

    // Calculate inverted positions using inverted factors
    const invertedBrownInterpolationFactor = 1 - brownInterpolationFactor;
    const invertedGreyInterpolationFactor = 1 - greyInterpolationFactor;
    const invertedBrownX = leftBaseX + (rightBaseX - leftBaseX) * invertedBrownInterpolationFactor;
    const invertedGreyX = leftBaseX + (rightBaseX - leftBaseX) * invertedGreyInterpolationFactor;

    // Calculate the black circle position
    const blackX = centerX - (this.rectangle.width / 2) * Math.sin(degrees * Math.PI / 180);
    const blackY = centerY + pullHeight * 0.3;

    // Calculate mouth parameters
    const mouthY = upperY + circleRadius * 4;
    const maxMouthWidth = circleRadius * 2.52;
    const mouthHeight = 4 * this.compositeScale;

    // Calculate offset factor
    const offsetFactor = Math.abs(Math.sin(degrees * Math.PI / 180));
    const adjustedMouthWidth = maxMouthWidth * (1 - offsetFactor * 0.4);

    // Center the mouth between the two eyes
    const eyesMidpoint = (adjustedRedX + adjustedWhiteX) / 2;

    // Calculate mouth position with smooth transition
    const angleRadians = degrees * Math.PI / 180;
    const directionFactor = Math.sin(angleRadians);
    const enhancedOffset = Math.sign(directionFactor) * Math.pow(Math.abs(directionFactor), 0.7) * 10;
    const mouthCenterX = eyesMidpoint + enhancedOffset;
    const mouthX = mouthCenterX - adjustedMouthWidth/2;
    const smileCurvature = mouthHeight * 2;

    // Draw the eyes using the BeeEyes module
    this.beeEyes.drawBeeEyePair(
      this.ctx, 
      adjustedRedX, 
      adjustedWhiteX, 
      upperY + eyeVerticalOffset, 
      circleRadius * 1.5, 
      degrees, 
      "black", 
      "blue"
    );

    // DRAW BACKGROUND ELEMENTS FIRST
    // These are the copies of foreground elements that are always visible behind the body
    
    // Draw copies of the foreground circles that are always visible
    this.ctx.beginPath();
    this.ctx.arc(invertedBrownX, middleY, uniformCircleSize, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();
    
    this.ctx.beginPath();
    this.ctx.arc(invertedGreyX, middleY, uniformCircleSize, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();
    
    // Draw the black circle (so it appears behind everything)
    this.ctx.beginPath();
    this.ctx.arc(blackX, blackY, eyeRadius, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();
    this.ctx.strokeStyle = "black";
    this.ctx.lineWidth = 2;
    this.ctx.stroke();

    // Define antenna variables
    const topY = centerY - pullHeight * 0.45;
    const antennaHeight = 20 * this.compositeScale;
    const antennaWidth = 4 * this.compositeScale;
    const antennaTopRadius = 5 * this.compositeScale;
    
    // Calculate the center midpoint of the character
    const characterMidpointX = centerX;
    
    // Position antennas above the eyes with offset toward center
    const leftEyeX = adjustedRedX;
    const leftAntennaX = leftEyeX + (characterMidpointX - leftEyeX) * 0.3;
    
    // Calculate curve direction based on angle - INVERTED
    const leftAntennaCurveFactor = (degrees >= 0 && degrees < 180) ? 1 : -1;
    
    // Interpolate the curve strength based on angle
    const antennaAngleRadians = degrees * Math.PI / 180;
    const curveStrengthFactor = Math.abs(Math.sin(antennaAngleRadians));
    const maxCurveStrength = 8 * this.compositeScale;
    const leftAntennaCurveStrength = curveStrengthFactor * maxCurveStrength;
    
    // Right antenna position
    const rightEyeX = adjustedWhiteX;
    const rightAntennaX = rightEyeX + (characterMidpointX - rightEyeX) * 0.3;
    
    // Calculate curve for right antenna - INVERTED
    const rightAntennaCurveFactor = (degrees >= 0 && degrees < 180) ? 1 : -1;
    const rightAntennaCurveStrength = curveStrengthFactor * maxCurveStrength;

    // Draw duplicate antennas that are always visible behind the body
    // Left antenna duplicate
    this.ctx.beginPath();
    this.ctx.moveTo(leftAntennaX, topY);
    this.ctx.quadraticCurveTo(
      leftAntennaX + (leftAntennaCurveFactor * leftAntennaCurveStrength),
      topY - antennaHeight/2,
      leftAntennaX,
      topY - antennaHeight
    );
    this.ctx.lineWidth = 6 * this.compositeScale;
    this.ctx.strokeStyle = "black";
    this.ctx.stroke();
    
    // Draw antenna top circle
    this.ctx.beginPath();
    this.ctx.arc(leftAntennaX, topY - antennaHeight, antennaTopRadius, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();
    
    // Right antenna duplicate
    this.ctx.beginPath();
    this.ctx.moveTo(rightAntennaX, topY);
    this.ctx.quadraticCurveTo(
      rightAntennaX + (rightAntennaCurveFactor * rightAntennaCurveStrength),
      topY - antennaHeight/2,
      rightAntennaX,
      topY - antennaHeight
    );
    this.ctx.lineWidth = 6 * this.compositeScale;
    this.ctx.strokeStyle = "black";
    this.ctx.stroke();
    
    // Draw antenna top circle
    this.ctx.beginPath();
    this.ctx.arc(rightAntennaX, topY - antennaHeight, antennaTopRadius, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();

    // Draw a copy of the mouth that's always visible behind the character
    this.ctx.beginPath();
    this.ctx.moveTo(mouthX, mouthY);
    this.ctx.lineCap = "round";
    this.ctx.lineWidth = mouthHeight;
    this.ctx.strokeStyle = "black";
    this.ctx.quadraticCurveTo(
      mouthX + adjustedMouthWidth/2,
      mouthY + smileCurvature,
      mouthX + adjustedMouthWidth,
      mouthY
    );
    this.ctx.stroke();
    this.ctx.lineCap = "butt";

    // Draw the body connection points
    const purpleDotOnBody = {
      x: leftX,
      y: lowerY
    };

    const aquaDotOnBody = {
      x: rightX,
      y: lowerY
    };

    // Purple dot
    this.ctx.beginPath();
    this.ctx.arc(purpleDotOnBody.x, purpleDotOnBody.y, 6 * this.compositeScale, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();

    // Aqua dot
    this.ctx.beginPath();
    this.ctx.arc(aquaDotOnBody.x, aquaDotOnBody.y, 6 * this.compositeScale, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();

    // Calculate purple arm bend with smooth transition
    const purpleLineBendValue = 1;
    // Determine target bend direction
    if (this.purpleCircle.x < purpleDotOnBody.x) {
      this.targetPurpleBend = purpleLineBendValue * -1; // Target inverted bend
    } else {
      this.targetPurpleBend = purpleLineBendValue; // Target normal bend
    }
    // Smoothly transition to target bend
    this.currentPurpleBend += (this.targetPurpleBend - this.currentPurpleBend) * this.bendTransitionSpeed;
    let adjustedPurpleBend = this.currentPurpleBend;

    // Calculate aqua arm bend with smooth transition
    const aquaLineBendValue = -1;
    // Determine target bend direction
    if (this.aquaCircle.x > aquaDotOnBody.x) {
      this.targetAquaBend = aquaLineBendValue * -1; // Target inverted bend
    } else {
      this.targetAquaBend = aquaLineBendValue; // Target normal bend
    }
    // Smoothly transition to target bend
    this.currentAquaBend += (this.targetAquaBend - this.currentAquaBend) * this.bendTransitionSpeed;
    let adjustedAquaBend = this.currentAquaBend;

    // DRAW THE BLACK COPIES OF BOTH ARMS AND HANDS (behind the body)
    // Black copy of purple arm
    this.ctx.strokeStyle = "black";
    this.ctx.lineWidth = 6;
    this.beeLegs.drawLegLine(this.ctx, purpleDotOnBody.x, purpleDotOnBody.y, this.purpleCircle.x, this.purpleCircle.y, adjustedPurpleBend);

    // Black copy of purple hand (circle)
    this.ctx.beginPath();
    this.ctx.arc(this.purpleCircle.x, this.purpleCircle.y, this.purpleCircle.radius * 1.5, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();

    // Black copy of aqua arm
    this.ctx.strokeStyle = "black";
    this.ctx.lineWidth = 6;
    this.beeLegs.drawLegLine(this.ctx, aquaDotOnBody.x, aquaDotOnBody.y, this.aquaCircle.x, this.aquaCircle.y, adjustedAquaBend);

    // Black copy of aqua hand (circle)
    this.ctx.beginPath();
    this.ctx.arc(this.aquaCircle.x, this.aquaCircle.y, this.aquaCircle.radius * 1.5, 0, Math.PI * 2);
    this.ctx.fillStyle = "black";
    this.ctx.fill();

    // Define the body width multiplier
    const bodyWidthMultiplier = 1.0;
    
    // DRAW THE BEE PILL BODY (which will cover the background circles)
    // First, draw just the yellow base of the bee body without stripes
    this.ctx.save();
    this.drawVerticalPill(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), pullY, pullWidth * bodyWidthMultiplier, pullHeight);
    this.ctx.clip();
    
    // Base yellow color
    const baseYellow = "#FFDD00";
    
    // Draw the main body first (just the yellow part)
    const headSectionHeight = pullHeight * 0.45;
    const seamY = pullY + headSectionHeight;
    const headArc = headSectionHeight * 0.1;
    this.ctx.fillStyle = baseYellow;
    this.ctx.beginPath();
    this.ctx.moveTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), pullY);
    this.ctx.lineTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), seamY);
    this.ctx.quadraticCurveTo(
      pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier / 2, 
      seamY + headArc, 
      pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier, 
      seamY
    );
    this.ctx.lineTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier, pullY);
    this.ctx.closePath();
    this.ctx.fill();
    this.ctx.restore();

    // Store body dimensions for masking
    const bodyDimensions = {
      x: pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2),
      y: pullY,
      width: pullWidth * bodyWidthMultiplier,
      height: pullHeight
    };
    
    // Draw the front-facing eyes after the yellow base but before the stripes
    this.beeEyes.drawFrontEyePair(
      this.ctx, 
      adjustedRedX, 
      adjustedWhiteX, 
      upperY + eyeVerticalOffset, 
      circleRadius * 1.5, 
      degrees, 
      "black",
      bodyDimensions // Pass the body dimensions for masking
    );

    // Now draw the stripes on top of the eyes
    this.ctx.save();
    this.drawVerticalPill(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), pullY, pullWidth * bodyWidthMultiplier, pullHeight);
    this.ctx.clip();
    
    // Draw the stripes
    const numStripes = 5;
    const heightRemaining = (pullY + pullHeight) - seamY;
    const stripeHeight = heightRemaining / numStripes;
    const stripeColours = ["black", baseYellow, "black", baseYellow, "black"];
    for (let i = 0; i < numStripes; i++) {
      const stripeY = seamY + i * stripeHeight;
      const stripeArc = stripeHeight * 0.2;
      this.ctx.fillStyle = stripeColours[i];
      this.ctx.beginPath();
      this.ctx.moveTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), stripeY);
      this.ctx.quadraticCurveTo(
        pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier / 2, 
        stripeY + stripeArc, 
        pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier, 
        stripeY
      );
      this.ctx.lineTo(pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier, stripeY + stripeHeight);
      this.ctx.quadraticCurveTo(
        pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2) + pullWidth * bodyWidthMultiplier / 2, 
        stripeY + stripeHeight + stripeArc, 
        pullX - (pullWidth * (bodyWidthMultiplier - 1) / 2), 
        stripeY + stripeHeight
      );
      this.ctx.closePath();
      this.ctx.fill();
    }
    this.ctx.restore();

    // THEN DRAW THE FOREGROUND CIRCLES (now black instead of pink and blue)
    // Only draw first foreground circle when angle IS between 220 and 30 degrees
    if (degrees <= 30 || degrees >= 220) {
      this.ctx.beginPath();
      this.ctx.arc(invertedBrownX, middleY, uniformCircleSize, 0, Math.PI * 2);
      this.ctx.fillStyle = "black";
      this.ctx.fill();
    }

    // Draw second foreground circle - only when angle is greater than 320 or less than 140
    if (degrees < 140 || degrees > 320) {
      this.ctx.beginPath();
      this.ctx.arc(invertedGreyX, middleY, uniformCircleSize, 0, Math.PI * 2);
      this.ctx.fillStyle = "black";
      this.ctx.fill();
    }

    // Update purple circle position to follow the character
    if (!this.purpleCircle.dragging) {
      // Calculate the base position
      this.purpleCircle.baseY = centerY + 75 * this.compositeScale + this.purpleCircle.heightOffset + this.purpleCircle.customHeightOffset;
      this.purpleCircle.y = this.purpleCircle.baseY;
      
      // Calculate x position based on angle and distance from center
      let purpleX;
      if (degrees <= 180) {
        const progress = (180 - degrees) / 180;
        const targetX = centerX + (this.purpleCircle.distanceFromCenter * -1);
        purpleX = centerX + this.purpleCircle.distanceFromCenter + (targetX - (centerX + this.purpleCircle.distanceFromCenter)) * progress;
      } else {
        const progress = (degrees - 180) / 180;
        const targetX = centerX + (this.purpleCircle.distanceFromCenter * -1);
        purpleX = centerX + this.purpleCircle.distanceFromCenter + (targetX - (centerX + this.purpleCircle.distanceFromCenter)) * progress;
      }
      if (degrees === 90 || degrees === 270) { purpleX = centerX; }
      this.purpleCircle.x = purpleX;
    }
    
    // Update aqua circle position similarly
    if (!this.aquaCircle.dragging) {
      // Calculate the base position
      this.aquaCircle.baseY = centerY + 75 * this.compositeScale + this.aquaCircle.heightOffset + this.aquaCircle.customHeightOffset;
      this.aquaCircle.y = this.aquaCircle.baseY;
      
      // Calculate x position based on angle and distance from center
      let aquaX;
      if (degrees <= 180) {
        const progress = (180 - degrees) / 180;
        const targetX = centerX + (this.aquaCircle.distanceFromCenter * -1);
        aquaX = centerX + this.aquaCircle.distanceFromCenter + (targetX - (centerX + this.aquaCircle.distanceFromCenter)) * progress;
      } else {
        const progress = (degrees - 180) / 180;
        const targetX = centerX + (this.aquaCircle.distanceFromCenter * -1);
        aquaX = centerX + this.aquaCircle.distanceFromCenter + (targetX - (centerX + this.aquaCircle.distanceFromCenter)) * progress;
      }
      if (degrees === 90 || degrees === 270) { aquaX = centerX; }
      this.aquaCircle.x = aquaX;
    }

    // Draw draggable orange circle with improved visual feedback
    this.ctx.beginPath();
    this.ctx.arc(this.orangeCircleYellow.x, this.orangeCircleYellow.y, this.orangeCircleYellow.radius, 0, Math.PI * 2);
    
    // Add a glow effect when hovering or dragging
    if (this.orangeCircleYellow.dragging) {
      this.ctx.shadowColor = 'rgba(255, 255, 0, 0.8)';
      this.ctx.shadowBlur = 15;
      this.ctx.fillStyle = '#FF8C00'; // Darker orange when dragging
    } else {
      this.ctx.fillStyle = this.orangeCircleYellow.colour;
    }
    
    this.ctx.fill();
    
    // Add a border to make it more visible
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = 'black';
    this.ctx.stroke();
    
    // Reset shadow
    this.ctx.shadowColor = 'transparent';
    this.ctx.shadowBlur = 0;

    // Display current angle with better visibility
    this.ctx.fillStyle = 'white';
    this.ctx.font = 'bold 12px Arial';
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    this.ctx.fillText(`${degrees}°`, this.orangeCircleYellow.x, this.orangeCircleYellow.y);

    // Only draw left antenna when angle is between 70 and 220 degrees
    if (degrees >= 70 && degrees <= 220) {
      // Draw curved antenna line
      this.ctx.beginPath();
      this.ctx.moveTo(leftAntennaX, topY);
      this.ctx.quadraticCurveTo(
        leftAntennaX + (leftAntennaCurveFactor * leftAntennaCurveStrength),
        topY - antennaHeight/2,
        leftAntennaX,
        topY - antennaHeight
      );
      this.ctx.lineWidth = 6 * this.compositeScale;
      this.ctx.strokeStyle = "black";
      this.ctx.stroke();
      
      // Draw antenna top circle
      this.ctx.beginPath();
      this.ctx.arc(leftAntennaX, topY - antennaHeight, antennaTopRadius, 0, Math.PI * 2);
      this.ctx.fillStyle = "black";
      this.ctx.fill();
    }
    
    // Right antenna - only visible when angle is between 90 and 280 degrees
    if (degrees >= 90 && degrees <= 280) {
      // Draw curved antenna line
      this.ctx.beginPath();
      this.ctx.moveTo(rightAntennaX, topY);
      this.ctx.quadraticCurveTo(
        rightAntennaX + (rightAntennaCurveFactor * rightAntennaCurveStrength),
        topY - antennaHeight/2,
        rightAntennaX,
        topY - antennaHeight
      );
      this.ctx.lineWidth = 6 * this.compositeScale;
      this.ctx.strokeStyle = "black";
      this.ctx.stroke();
      
      // Draw antenna top circle
      this.ctx.beginPath();
      this.ctx.arc(rightAntennaX, topY - antennaHeight, antennaTopRadius, 0, Math.PI * 2);
      this.ctx.fillStyle = "black";
      this.ctx.fill();
    }

    // Draw the black mouth line after the body (so it appears on top)
    // Only draw the black mouth when angle is between 80 and 280 degrees
    if (degrees >= 90 && degrees <= 270) {
      this.ctx.beginPath();
      // Instead of drawing a straight line, use a quadratic curve for a smile
      this.ctx.moveTo(mouthX, mouthY);
      this.ctx.lineCap = "round"; // Round the ends of the line
      this.ctx.lineWidth = mouthHeight;
      this.ctx.strokeStyle = "black";
      // Flip the curve by changing the control point to be BELOW the line
      this.ctx.quadraticCurveTo(
        mouthX + adjustedMouthWidth/2, // Control point x (middle of the mouth)
        mouthY + smileCurvature,       // Control point y (BELOW the mouth line to curve upward)
        mouthX + adjustedMouthWidth,   // End point x
        mouthY                         // End point y
      );
      this.ctx.stroke();
      this.ctx.lineCap = "butt"; // Reset line cap
    }

    // After drawing the bee pill body, draw the front-facing arms when needed
    // Only draw the black right arm and hand when angle is between 0 and 180 degrees
    if (degrees > 0 && degrees <= 180) {
      // Draw the purple arm and hand on top
      this.ctx.strokeStyle = "black";
      this.ctx.lineWidth = 6;
      this.beeLegs.drawLegLine(this.ctx, purpleDotOnBody.x, purpleDotOnBody.y, this.purpleCircle.x, this.purpleCircle.y, adjustedPurpleBend);
      
      // Draw the purple hand (circle)
      this.ctx.beginPath();
      this.ctx.arc(this.purpleCircle.x, this.purpleCircle.y, this.purpleCircle.radius * 1.5, 0, Math.PI * 2);
      this.ctx.fillStyle = "black";
      this.ctx.fill();
    }

    // Only draw the black left arm and hand when angle is between 180 and 360 degrees
    if (degrees > 180 && degrees <= 360) {
      // Draw the aqua arm and hand on top
      this.ctx.strokeStyle = "black";
      this.ctx.lineWidth = 6;
      this.beeLegs.drawLegLine(this.ctx, aquaDotOnBody.x, aquaDotOnBody.y, this.aquaCircle.x, this.aquaCircle.y, adjustedAquaBend);
      
      // Draw the aqua hand (circle)
      this.ctx.beginPath();
      this.ctx.arc(this.aquaCircle.x, this.aquaCircle.y, this.aquaCircle.radius * 1.5, 0, Math.PI * 2);
      this.ctx.fillStyle = "black";
      this.ctx.fill();
    }

    // Another eye (purple circle) - only visible when angle is above 260 or below 90 (this is the tail for the bee)
    // This is drawn AFTER the bee body so it appears on top
    if (degrees < 90 || degrees > 260) {
      this.ctx.beginPath();
      this.ctx.arc(blackX, blackY, eyeRadius, 0, Math.PI * 2);
      this.ctx.fillStyle = "black";
      this.ctx.fill();
      this.ctx.strokeStyle = "black";
      this.ctx.lineWidth = 2;
      this.ctx.stroke();
    }
  }
  
  // Add this new method to update the orange circle position
  updateOrangeCirclePosition(deltaTime) {
    // Skip animation completely if manually positioned
    if (this.orangeCircleYellow.positionFixed) {
      return;
    }
    
    // Skip if currently being dragged
    if (this.orangeCircleYellow.dragging) {
      return;
    }
    
    // Ensure the angle property exists
    if (typeof this.orangeCircleYellow.angle !== 'number') {
      this.orangeCircleYellow.angle = 0;
    }
    
    // Normal rotation logic
    this.orangeCircleYellow.angle += this.orangeCircleYellow.rotationSpeed;
    
    // Keep angle between 0 and 2π
    if (this.orangeCircleYellow.angle > Math.PI * 2) {
      this.orangeCircleYellow.angle -= Math.PI * 2;
    }
    
    // Get the center of the bee character
    const centerX = this.rectangle.x + this.rectangle.width / 2;
    const centerY = this.rectangle.y + 75 * this.compositeScale;
    
    // Calculate new position based on angle and radius
    this.orangeCircleYellow.x = centerX + this.blackCircleRadius * Math.cos(this.orangeCircleYellow.angle);
    this.orangeCircleYellow.y = centerY + this.blackCircleRadius * Math.sin(this.orangeCircleYellow.angle);
  }
  
  // Get the canvas element for Three.js texture
  getCanvas() {
    return this.canvas;
  }
  
  // Update the configuration with debounce
  updateConfig(newConfig) {
    this.config = {
      ...this.config,
      ...newConfig
    };
    
    this.configNeedsUpdate = true;
    this.markNeedsUpdate();
  }
  
  // Move the character to a target position
  moveToPosition(x, y) {
    this.targetCircle = { x, y };
    this.transitionActive = true;
    this.transitionStartTime = performance.now();
    this.startPos = { x: this.circle.x, y: this.circle.y };
    this.targetPos = { x, y };
    
    // Calculate distance-based duration
    const dx = this.targetPos.x - this.startPos.x;
    const dy = this.targetPos.y - this.startPos.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const maxDistance = Math.sqrt(this.canvas.width * this.canvas.width + this.canvas.height * this.canvas.height);
    const maxDuration = 5000; // 5 seconds for max distance
    this.currentTransitionDuration = Math.max(
      this.transitionDuration, // Minimum duration (2000ms)
      this.transitionDuration + (maxDuration - this.transitionDuration) * (distance / maxDistance)
    );
    
    this.markNeedsUpdate();
  }
  
  // Toggle gravity
  toggleGravity() {
    this.gravityOn = !this.gravityOn;
    this.markNeedsUpdate();
    return this.gravityOn;
  }
  
  // Resize the canvas
  resize(width, height) {
    this.canvas.width = width;
    this.canvas.height = height;
    this.forceFullRedraw();
  }
  
  // Mark as needing update when properties change
  markNeedsUpdate() {
    this.needsUpdate = true;
    this.scheduleNextUpdate();
  }

  // Add these new methods for mouse interaction
  handleMouseDown(e) {
    console.log("Mouse down detected");
    const rect = this.canvas.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const clickY = e.clientY - rect.top;
    
    // Check if click is on the orange circle with a slightly larger hit area
    const dx = clickX - this.orangeCircleYellow.x;
    const dy = clickY - this.orangeCircleYellow.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const hitRadius = this.orangeCircleYellow.radius * 1.5; // 50% larger hit area
    
    console.log(`Click at (${clickX}, ${clickY}), circle at (${this.orangeCircleYellow.x}, ${this.orangeCircleYellow.y}), distance: ${distance}, radius: ${hitRadius}`);
    
    if (distance <= hitRadius) {
      console.log("Orange circle clicked!");
      this.orangeCircleYellow.dragging = true;
      this.orangeCircleYellow.positionFixed = true;
      this.markNeedsUpdate();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleMouseMove(e) {
    if (this.orangeCircleYellow.dragging) {
      console.log("Dragging orange circle");
      const rect = this.canvas.getBoundingClientRect();
      this.orangeCircleYellow.x = e.clientX - rect.left;
      this.orangeCircleYellow.y = e.clientY - rect.top;
      this.markNeedsUpdate();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleMouseUp(e) {
    if (this.orangeCircleYellow.dragging) {
      console.log("Released orange circle");
      this.orangeCircleYellow.dragging = false;
      // Keep positionFixed true so it stays where it was dropped
      this.markNeedsUpdate();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  // Add these methods for touch interaction (mobile)
  handleTouchStart(e) {
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      const rect = this.canvas.getBoundingClientRect();
      const touchX = touch.clientX - rect.left;
      const touchY = touch.clientY - rect.top;
      
      // Check if touch is on the orange circle
      const dx = touchX - this.orangeCircleYellow.x;
      const dy = touchY - this.orangeCircleYellow.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      if (distance <= this.orangeCircleYellow.radius) {
        this.orangeCircleYellow.dragging = true;
        this.orangeCircleYellow.positionFixed = true;
        this.markNeedsUpdate();
        e.preventDefault();
      }
    }
  }

  handleTouchMove(e) {
    if (this.orangeCircleYellow.dragging && e.touches.length === 1) {
      const touch = e.touches[0];
      const rect = this.canvas.getBoundingClientRect();
      this.orangeCircleYellow.x = touch.clientX - rect.left;
      this.orangeCircleYellow.y = touch.clientY - rect.top;
      this.markNeedsUpdate();
      e.preventDefault();
    }
  }

  handleTouchEnd(e) {
    this.orangeCircleYellow.dragging = false;
    // Keep positionFixed true so it stays where it was dropped
    this.markNeedsUpdate();
  }

  // Add a method to reset the orange circle to orbit mode
  resetOrangeCirclePosition() {
    this.orangeCircleYellow.positionFixed = false;
    this.markNeedsUpdate();
  }
}