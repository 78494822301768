// src/components/loginpage/QuickThinkersLandingPage.js

import React, { useState, Suspense, lazy, memo } from 'react';
import { Link } from 'react-router-dom';
import {
  Bell,
  Book,
  Rocket,
  Users,
  BarChart,
  Lock,
  CheckCircle,
} from 'lucide-react';
import { Helmet } from 'react-helmet-async';

// Import Swiper components and styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';

// Import Assets
import heroImage from '../assets/heroimage.png';
import placeholder1 from '../assets/placeholder1.jpg';
import placeholder2 from '../assets/placeholder2.jpg';
import placeholder3 from '../assets/placeholder3.jpg';
import placeholder4 from '../assets/placeholder4.jpg';
import placeholder5 from '../assets/placeholder5.jpg';
import placeholder6 from '../assets/placeholder6.jpg';
import placeholder7 from '../assets/placeholder7.jpg';
import placeholder8 from '../assets/placeholder8.jpg';
import placeholder9 from '../assets/placeholder9.jpg';
import placeholder11 from '../assets/placeholder11.jpg';
import placeholder12 from '../assets/placeholder12.jpg';

// Import CSS
import './QuickThinkersLandingPage.css';

// Lazy load components
const Layout = lazy(() => import('./HeaderFooterLayoutBees/Layout'));
const Bee = lazy(() => import('./HeaderFooterLayoutBees/Bee'));
const SolutionButton = lazy(() => import('./SolutionButton'));

// =========================
// Reusable Components
// =========================

// Memoized FeatureItem Component with Animation
const FeatureItem = memo(({ text }) => (
  <div className="flex items-center p-4 transition-transform duration-300 transform bg-white rounded-lg shadow hover:translate-x-2 animate-fadeIn">
    <CheckCircle className="w-6 h-6 text-green-500 animate-pulse" />
    <span className="ml-3 text-gray-700">{text}</span>
  </div>
));

// Memoized FeatureCard Component with Popup Functionality
const FeatureCard = memo(
  ({
    id,
    icon,
    title,
    description,
    detail,
    placeholderImage,
    activeFeature,
    setActiveFeature,
  }) => {
    const isActive = activeFeature === id;

    const handleCardClick = () => {
      setActiveFeature(isActive ? null : id);
    };

    return (
      <div
        className="relative p-8 text-center transition-transform duration-300 transform bg-white rounded-lg shadow-lg cursor-pointer hover:scale-105"
        onClick={handleCardClick}
      >
        <div className="flex justify-center mb-6">{icon}</div>
        <h3 className="mb-4 text-2xl font-bold text-yellow-900 animate-fadeIn">
          {title}
        </h3>
        <p className="text-gray-600 delay-200 animate-fadeIn">{description}</p>

        {/* Popup Content */}
        {isActive && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Popup Overlay */}
            <div
              className="absolute inset-0 bg-black bg-opacity-25"
              onClick={() => setActiveFeature(null)}
            ></div>

            {/* Popup Box */}
            <div className="relative z-10 max-w-md p-6 bg-white rounded-lg shadow-lg">
              {/* Placeholder Image */}
              <img
                src={placeholderImage}
                alt={`${title} Placeholder`}
                className="object-cover w-full h-48 mb-4 rounded"
                loading="lazy"
              />

              {/* Popup Content */}
              <h4 className="mb-2 text-xl font-bold text-yellow-900">
                {title} Details
              </h4>
              <p className="mb-4 text-gray-700">{detail}</p>

              {/* Close Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering parent onClick
                  setActiveFeature(null);
                }}
                className="px-4 py-2 text-sm font-semibold text-yellow-600 transition-colors bg-white border-2 border-yellow-600 rounded-full hover:bg-yellow-600 hover:text-white"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
);

// =========================
// Main Component
// =========================

const QuickThinkersLandingPage = () => {
  // State to track the active SolutionButton
  const [activeButton, setActiveButton] = useState(null);

  // State to track the active FeatureCard
  const [activeFeature, setActiveFeature] = useState(null);

  // Array of imported carousel images
  const carouselImages = [
    placeholder1,
    placeholder2,
    placeholder3,
    placeholder4,
    placeholder5,
    placeholder12,
    placeholder6,
    placeholder7,
    placeholder8,
    placeholder9,
    placeholder11,
    placeholder1,
    placeholder2,
    placeholder3,
    placeholder4,
    placeholder5,
    placeholder12,
    placeholder6,
    placeholder7,
    placeholder8,
    placeholder9,
    placeholder11,
  ];

  // Define the number of bees
  const numberOfBees = 30;

  // Features Data
  const features = [
    {
      id: 'dynamic-classroom',
      icon: <Rocket className="w-16 h-16 text-yellow-600 animate-bounce" />,
      title: 'Dynamic Classroom',
      description:
        'Quickly adjust the classroom to the needs of the students.',
      detail:
        'Our Dynamic Classroom feature allows educators to seamlessly adapt the learning environment in real-time, ensuring that each student’s unique needs are met efficiently.',
      placeholderImage: placeholder1, // Placeholder image for this feature
    },
    {
      id: 'class-management',
      icon: (
        <Bell className="w-16 h-16 text-yellow-600 delay-200 animate-bounce" />
      ),
      title: 'Class Management',
      description: 'Monitor and control student learning in real-time.',
      detail:
        'With Class Management, teachers can oversee student progress, manage assignments, and maintain an organized classroom, all from a centralized dashboard.',
      placeholderImage: placeholder2,
    },
    {
      id: 'instant-data',
      icon: (
        <Book className="w-16 h-16 text-yellow-600 animate-bounce delay-400" />
      ),
      title: 'Instant Data',
      description:
        'Collect instant data aligned to the curriculum and standards.',
      detail:
        'Instant Data provides real-time analytics and insights, enabling educators to make informed decisions and tailor instruction to better meet educational standards.',
      placeholderImage: placeholder3,
    },
  ];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Layout>
        {/* SEO Optimization */}
        <Helmet>
          <title>Home | Bright Beehives</title>
          <meta
            name="description"
            content="Bright Beehives offers interactive and immersive teaching systems to enhance student engagement and make learning fun. Discover our tools designed for educators."
          />
          <meta
            name="keywords"
            content="Bright Beehives, education tools, student engagement, teaching systems, interactive learning, personalized learning"
          />
          <link rel="canonical" href="https://www.quickthinkers.com/" />
        </Helmet>

        {/* Hero Section with added padding */}
        <section className="relative px-4 py-10 overflow-hidden text-yellow-900 bg-yellow-100 lg:px-24 animate-fadeIn">
          <div className="flex flex-col-reverse items-center justify-between w-full lg:flex-row">
            {/* Text Content */}
            <div className="w-full pr-0 mb-12 lg:w-1/2 lg:pr-12 lg:mb-0">
              <h1 className="mt-12 mb-6 text-5xl font-bold leading-tight text-center animate-slideInLeft">
                Speed up learning with <br /> Bright Beehives
              </h1>
              <p className="mb-8 text-xl text-center text-yellow-700 delay-200 animate-fadeIn">
                Engage students in their learning with our interactive and
                immersive teaching system. Designed to be quick and easy to use,
                you can start teaching any topic in a matter of minutes.
              </p>
              <Link
                to="/teacherlogin"
                className="flex items-center justify-center px-8 py-3 text-xl font-semibold text-black transition-colors transition-transform duration-300 transform bg-white border-4 border-yellow-300 rounded-full shadow hover:bg-yellow-100 hover:scale-105 animate-slideInRight"
              >
                Explore Bright Beehives
              </Link>
            </div>

            {/* Updated Image Content with lazy loading */}
            <div className="hidden w-full max-w-xl px-8 lg:block lg:w-1/3 lg:px-0">
              <img
                src={heroImage}
                alt="Hero"
                className="object-contain w-full h-full rounded-lg animate-fadeIn"
                style={{ objectPosition: 'middle' }}
                loading="lazy"
              />
            </div>
          </div>

          {/* Animated Bees SVGs */}
          <Suspense fallback={<div>Loading Bees...</div>}>
            {Array.from({ length: numberOfBees }, (_, i) => (
              <Bee key={`bee-${i}`} id={i} />
            ))}
          </Suspense>
        </section>

        {/* Swiper Image Carousel with lazy loaded images */}
        <div className="px-4 my-20">
          <Swiper
            spaceBetween={12}
            slidesPerView="auto"
            loop={true}
            speed={12000}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
              reverseDirection: false,
            }}
            freeMode={{
              enabled: true,
              momentum: true,
              momentumRatio: 0.5,
              momentumVelocityRatio: 0.5,
            }}
            modules={[Autoplay, FreeMode]}
            className="mySwiper"
          >
            {carouselImages.map((image, index) => (
              <SwiperSlide
                key={`swiper-slide-${index}`}
                style={{ width: '400px', height: '400px' }}
              >
                <div className="w-full h-full overflow-hidden rounded-lg">
                  <img
                    src={image}
                    alt={`Carousel Slide ${index + 1}`}
                    className="object-cover w-full h-full"
                    loading="lazy"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Features Section with Popup Functionality */}
        <section className="px-24 py-20 bg-white">
          <h2 className="mb-16 text-4xl font-bold text-center text-yellow-900 animate-fadeIn">
            Tools Designed with Educators
          </h2>
          <div className="grid max-w-6xl grid-cols-1 gap-12 mx-auto md:grid-cols-3">
            {features.map((feature) => (
              <FeatureCard
                key={feature.id}
                id={feature.id}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                detail={feature.detail}
                placeholderImage={feature.placeholderImage}
                activeFeature={activeFeature}
                setActiveFeature={setActiveFeature}
              />
            ))}
          </div>
        </section>

        {/* Impact Section with animations */}
        <section className="px-5 py-10 text-gray-800 bg-blue-100 md:px-24 md:py-20">
          <div className="max-w-6xl mx-auto">
            <h2 className="mb-12 text-3xl font-bold text-center md:text-4xl animate-fadeIn">
              The Impact of Bright Beehives
            </h2>
            <div className="flex flex-col justify-around space-y-8 text-center md:flex-row md:space-y-0">
              <div className="animate-fadeIn">
                <div className="mb-2 text-4xl font-bold md:text-6xl">1M</div>
                <div className="text-lg md:text-xl">bees saved</div>
              </div>
              <div className="delay-200 animate-fadeIn">
                <div className="mb-2 text-4xl font-bold md:text-6xl">95.8%</div>
                <div className="text-lg md:text-xl">Satisfied teachers</div>
              </div>
              <div className="animate-fadeIn delay-400">
                <div className="mb-2 text-4xl font-bold md:text-6xl">Hours</div>
                <div className="text-lg md:text-xl">
                  of teacher time saved
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Solutions Section with animations */}
        <section className="relative px-24 py-20 bg-yellow-50">
          <h2 className="mb-16 text-4xl font-bold text-center text-yellow-900 animate-fadeIn">
            For every student, every day
          </h2>
          <div className="grid max-w-6xl grid-cols-2 gap-8 mx-auto md:grid-cols-4">
            <Suspense fallback={<div>Loading Solutions...</div>}>
              <SolutionButton
                id="student-engagement"
                icon={<Users className="w-8 h-8" />}
                text="Student Engagement"
                detail="Boost participation with our interactive lessons."
                image={placeholder1}
                activeButton={activeButton}
                setActiveButton={setActiveButton}
              />
              {/* Add other SolutionButtons similarly */}
            </Suspense>
          </div>
        </section>
      </Layout>
    </Suspense>
  );
};

export default QuickThinkersLandingPage;
