import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // For SEO
import { CheckCircle } from 'lucide-react'; // Import CheckCircle icon
import { motion } from 'framer-motion'; // For animations

// Import Layout
import Layout from '../HeaderFooterLayoutBees/Layout'; // Import the Layout component

// Updated data with only the specified subsections
const whyData = {
  'customer-stories': {
    title: 'Customer Stories',
    description:
      'Read about how educators and institutions have successfully implemented Bright Beehivesto enhance learning experiences.',
    features: [
      'Real-world examples of Bright Beehives in action.',
      'Success metrics and outcomes from various schools.',
      'Testimonials from satisfied educators and administrators.',
    ],
    benefits: [
      'Understand the tangible benefits of using Bright Beehives.',
      'Gain insights from real-world applications.',
      'Learn how Bright Beehives can be adapted to different educational settings.',
    ],
    testimonials: [
      {
        name: 'Amanda Green',
        role: 'Elementary School Teacher',
        feedback:
          'Bright Beehives has revolutionized the way I engage with my students. The interactive lessons are a hit!',
      },
      {
        name: 'Mark Johnson',
        role: 'High School Principal',
        feedback:
          'The data analytics provided by Bright Beehives have been instrumental in improving our teaching strategies.',
      },
    ],
  },
  'integration-partners': {
    title: 'Integration and Partners',
    description:
      'Discover our integration partners and how Bright Beehives seamlessly connects with your existing educational tools.',
    features: [
      'Seamless integration with popular Learning Management Systems (LMS).',
      'API support for custom integrations.',
      'Partnerships with leading educational technology providers.',
    ],
    benefits: [
      'Enhance your existing tech stack without disruption.',
      'Leverage combined functionalities for a better learning experience.',
      'Access to a broader range of educational tools and resources.',
    ],
    testimonials: [
      {
        name: 'Linda Thompson',
        role: 'IT Director',
        feedback:
          'Integrating Bright Beehives with our existing LMS was seamless. The support team was incredibly helpful throughout the process.',
      },
      {
        name: 'Robert Davis',
        role: 'Educational Technology Manager',
        feedback:
          'The partnerships Bright Beehives has established allow us to offer a more comprehensive set of tools to our educators.',
      },
    ],
  },
  efficacy: {
    title: 'Efficacy',
    description:
      'Learn about the data and models that demonstrate the effectiveness of Bright Beehives in educational settings.',
    features: [
      'Comprehensive studies on student engagement and performance.',
      'Data-driven insights supporting teaching methodologies.',
      'Continuous improvement based on feedback and analytics.',
    ],
    benefits: [
      'Trust in the proven effectiveness of our platform.',
      'Make informed decisions based on solid data.',
      'Ensure that your educational strategies are backed by research.',
    ],
    testimonials: [
      {
        name: 'Dr. Laura Martinez',
        role: 'University Research Coordinator',
        feedback:
          "Bright Beehives' research collaboration platform has significantly improved our ability to work with partners both locally and internationally. The data security measures give us peace of mind knowing our research data is protected.",
      },
      {
        name: 'Prof. Kevin Turner',
        role: 'Professor of Computer Science',
        feedback:
          'Integrating Bright Beehives with our existing systems was seamless. The virtual classroom solutions have been invaluable, especially during the shift to remote learning, ensuring our students continue to receive high-quality education.',
      },
    ],
  },
  privacy: {
    title: 'Privacy',
    description:
      'Understand how Bright Beehives safeguards personal and educational data to ensure privacy and security.',
    features: [
      'Compliance with all major data protection regulations.',
      'Robust encryption for data at rest and in transit.',
      'Regular security audits and updates.',
    ],
    benefits: [
      'Protect sensitive student and educator information.',
      'Build trust with stakeholders by ensuring data security.',
      'Mitigate risks associated with data breaches and leaks.',
    ],
    testimonials: [
      {
        name: 'Michael Brown',
        role: 'School Administrator',
        feedback:
          'Managing resources across multiple schools used to be a challenge. With Bright Beehives, we can allocate resources more effectively and ensure that each school has what it needs to succeed.',
      },
      {
        name: 'Emily Clark',
        role: 'Private Tutor',
        feedback:
          "Bright Beehives has revolutionized the way I conduct my tutoring sessions. The personalized session planning tools allow me to tailor each lesson to my students' needs, making my tutoring more effective.",
      },
    ],
  },
};

// FeatureItem Component
const FeatureItem = ({ text }) => (
  <motion.div
    className="flex items-start p-4 space-x-3 transition-shadow duration-300 bg-white rounded-lg shadow hover:shadow-lg"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <CheckCircle className="w-6 h-6 mt-1 text-green-500" />
    <p className="text-gray-700">{text}</p>
  </motion.div>
);

// TestimonialItem Component
const TestimonialItem = ({ testimonial }) => (
  <motion.div
    className="p-6 transition-shadow duration-300 bg-white rounded-lg shadow-md hover:shadow-xl"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <p className="mb-4 italic text-gray-600">"{testimonial.feedback}"</p>
    <div className="flex items-center">
      <div className="flex-shrink-0 w-12 h-12 mr-4 bg-gray-200 rounded-full"></div>
      <div>
        <h5 className="text-lg font-semibold text-gray-800">{testimonial.name}</h5>
        <p className="text-sm text-gray-500">{testimonial.role}</p>
      </div>
    </div>
  </motion.div>
);

// BenefitsSection Component
const BenefitsSection = ({ benefits }) => (
  <motion.div
    className="mt-12"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="mb-6 text-2xl font-bold text-yellow-900">Benefits</h3>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {benefits.map((benefit, index) => (
        <motion.div
          key={index}
          className="flex items-start p-4 space-x-3 bg-yellow-100 rounded-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
          <CheckCircle className="w-6 h-6 mt-1 text-yellow-600" />
          <p className="text-gray-700">{benefit}</p>
        </motion.div>
      ))}
    </div>
  </motion.div>
);

// TestimonialsSection Component
const TestimonialsSection = ({ testimonials }) => (
  <motion.div
    className="mt-12"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="mb-6 text-2xl font-bold text-yellow-900">What Our Clients Say</h3>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {testimonials.map((testimonial, index) => (
        <TestimonialItem key={index} testimonial={testimonial} />
      ))}
    </div>
  </motion.div>
);

const WhyQuickThinkers = () => {
  const location = useLocation();
  const subcategoryKey = location.hash ? location.hash.slice(1) : null;

  // Check if subcategoryKey exists in whyData
  const data = subcategoryKey && whyData[subcategoryKey] ? whyData[subcategoryKey] : null;

  return (
    <Layout>
      <div className="relative flex flex-col min-h-screen overflow-hidden font-sans bg-yellow-50">
        {/* SEO Optimization */}
        <Helmet>
          <title>Why Bright Beehives | Bright Beehives</title>
          <meta
            name="description"
            content="Discover why Bright Beehives is the preferred choice for educators and institutions seeking innovative educational solutions."
          />
        </Helmet>

        {/* Main Content */}
        <main className="z-10 flex-grow">
          <section className="relative px-6 py-12 bg-yellow-50">
            <div className="mx-auto max-w-7xl">
              {data ? (
                // Render only the selected subcategory
                <div id={subcategoryKey} className="p-8 mb-16 bg-white rounded-lg shadow-lg">
                  <div className="mb-10 text-center">
                    <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">
                      {data.title}
                    </h2>
                    <p className="text-lg text-gray-700 md:text-xl">{data.description}</p>
                  </div>

                  {/* Key Features */}
                  <div className="mb-12">
                    <h3 className="mb-6 text-2xl font-semibold text-yellow-900">Key Features</h3>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                      {data.features.map((feature, index) => (
                        <FeatureItem key={index} text={feature} />
                      ))}
                    </div>
                  </div>

                  {/* Benefits Section */}
                  <BenefitsSection benefits={data.benefits} />

                  {/* Testimonials Section */}
                  <TestimonialsSection testimonials={data.testimonials} />
                </div>
              ) : (
                // Render all subsections if no hash is present
                Object.keys(whyData).map((key) => {
                  const sectionData = whyData[key];
                  return (
                    <div key={key} id={key} className="p-8 mb-16 bg-white rounded-lg shadow-lg">
                      <div className="mb-10 text-center">
                        <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">
                          {sectionData.title}
                        </h2>
                        <p className="text-lg text-gray-700 md:text-xl">
                          {sectionData.description}
                        </p>
                      </div>

                      {/* Key Features */}
                      <div className="mb-12">
                        <h3 className="mb-6 text-2xl font-semibold text-yellow-900">
                          Key Features
                        </h3>
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                          {sectionData.features.map((feature, index) => (
                            <FeatureItem key={index} text={feature} />
                          ))}
                        </div>
                      </div>

                      {/* Benefits Section */}
                      <BenefitsSection benefits={sectionData.benefits} />

                      {/* Testimonials Section */}
                      <TestimonialsSection testimonials={sectionData.testimonials} />
                    </div>
                  );
                })
              )}
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default WhyQuickThinkers;
