import React from 'react';
// Import the banner image
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

// Import VoxelGame Component
import VoxelGame from '../../Teachers/Build/VoxelGame'; // Adjust the path based on your project structure

const StudentTests = ({ onClose, classId }) => {
  // Function to add ordinal suffix to the day
  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // Function to format the current date and time
  const getFormattedDate = () => {
    const now = new Date();
    const options = { weekday: 'long', month: 'long' };
    const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const day = getOrdinal(now.getDate());
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
    return `${weekday} the ${day} of ${month} ${formattedTime}`;
  };

  return (
    <div className="flex flex-col w-full h-screen overflow-hidden bg-white student-tests">
      
      {/* Combined Title and Banner Section - Adjusted z-index to ensure it doesn't overlap with modal close button */}
      <div 
        className="relative w-full mb-4 border-gray-700 border-t-5"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', // Increased shadow size and darkness
          zIndex: 10 // Ensure this is lower than the modal close button z-index
        }}
      >
        {/* Banner image as background */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
        
        {/* Title content overlaid on banner */}
        <div className="relative z-10 flex flex-col items-center justify-center w-full px-4 py-4">
          {/* Title */}
          <p className="text-3xl font-bold text-center text-white">Build</p>
          {/* Current Date and Time */}
          <div className="mt-2 text-xs font-bold text-white">
            {getFormattedDate()}
          </div>
        </div>
      </div>

      {/* Main Content - Added bottom padding and rounded corners */}
      <div className="flex-1 w-full px-4 pb-4 overflow-hidden md:px-8 md:pb-8">
        <div className="w-full h-full overflow-hidden rounded-2xl" 
             style={{ aspectRatio: '1/1', maxHeight: '100%' }}>
          <VoxelGame />
        </div>
      </div>
    </div>
  );
};

export default StudentTests;