// Points.js

import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Rnd } from 'react-rnd';
import { Minus, Plus, X } from 'lucide-react';
import { db, rtdb } from '../../../firebase';
import { ref, update } from 'firebase/database';

const MINIMIZED_SIZE = 60; // Define a constant for minimized size

const Points = React.memo(({ activeBees, onClose }) => {
  console.log("Points component rendering with activeBees:", activeBees);
  
  // State declarations
  const [dimensions, setDimensions] = useState({
    width: 400,
    height: 500,
    x: window.innerWidth / 2 - 200, // Center horizontally
    y: window.innerHeight / 2 - 250, // Center vertically
  });

  const prevDimensionsRef = useRef({
    width: 400,
    height: 500,
    x: window.innerWidth / 2 - 200,
    y: window.innerHeight / 2 - 250,
  });

  const [isMinimized, setIsMinimized] = useState(false);
  const [pointsToAdd, setPointsToAdd] = useState(10); // Default points value
  const [recentlyUpdated, setRecentlyUpdated] = useState({});

  // Refs for click vs drag detection
  const mouseDownTimeRef = useRef(0);
  const mouseDownPosRef = useRef({ x: 0, y: 0 });

  // Memoize styles for performance
  const styles = useMemo(() => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: isMinimized ? '#10b981' : 'rgba(255, 255, 255, 0.95)',
      borderRadius: isMinimized ? '50%' : '16px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
      justifyContent: 'space-between',
      overflow: 'hidden',
      boxSizing: 'border-box',
      transition: 'border-radius 0.5s ease, background-color 0.5s ease',
      position: 'relative',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 12px',
      backgroundColor: 'transparent',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      cursor: 'move',
      userSelect: 'none',
      flexShrink: 0,
    },
    title: {
      color: '#4b5563',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    controlButton: {
      background: 'none',
      border: 'none',
      color: '#4b5563',
      cursor: 'pointer',
      padding: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      transition: 'transform 0.2s, color 0.2s',
      borderRadius: '4px',
      '&:hover': {
        color: '#000',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1,
      padding: '16px',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
    pointsControls: {
      display: isMinimized ? 'none' : 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
      margin: '0 0 16px 0',
      padding: '8px',
      backgroundColor: '#f3f4f6',
      borderRadius: '8px',
    },
    pointsValue: {
      fontSize: '24px',
      fontWeight: 'bold',
      minWidth: '50px',
      textAlign: 'center',
    },
    pointsButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      border: 'none',
      backgroundColor: '#3b82f6',
      color: 'white',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#2563eb',
      },
    },
    studentList: {
      display: isMinimized ? 'none' : 'flex',
      flexDirection: 'column',
      gap: '8px',
      overflowY: 'auto',
      flex: 1,
    },
    studentItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px',
      backgroundColor: '#f9fafb',
      borderRadius: '8px',
      transition: 'background-color 0.3s ease',
    },
    studentName: {
      flex: 1,
      fontWeight: '500',
    },
    addPointsButton: {
      padding: '6px 12px',
      backgroundColor: '#10b981',
      color: 'white',
      border: 'none',
      borderRadius: '6px',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#059669',
      },
    },
    minimizedIcon: {
      display: isMinimized ? 'flex' : 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '24px',
      color: 'white',
    },
    statusText: {
      fontSize: '12px',
      color: '#10b981',
      marginLeft: '8px',
      opacity: 1,
      transition: 'opacity 1.5s ease',
    },
    noStudents: {
      textAlign: 'center',
      padding: '20px',
      color: '#6b7280',
      fontStyle: 'italic',
    }
  }), [isMinimized, pointsToAdd]);

  // Event handlers
  const addPoints = useCallback((classId, studentName) => {
    if (pointsToAdd <= 0) return;
    
    const beeRef = ref(rtdb, `activeclasses/${classId}/beeNames/${studentName}`);
    
    update(beeRef, {
      totalxp: rtdb.ServerValue.increment(pointsToAdd)
    })
    .then(() => {
      // Mark as recently updated
      setRecentlyUpdated(prev => ({
        ...prev,
        [`${classId}-${studentName}`]: true
      }));
      
      // Reset after animation
      setTimeout(() => {
        setRecentlyUpdated(prev => ({
          ...prev,
          [`${classId}-${studentName}`]: false
        }));
      }, 1500);
      
      console.log(`Added ${pointsToAdd} points to ${studentName} in class ${classId}`);
    })
    .catch(error => {
      console.error("Error adding points:", error);
    });
  }, [pointsToAdd]);

  const increasePoints = useCallback(() => {
    setPointsToAdd(prev => prev + 5);
  }, []);

  const decreasePoints = useCallback(() => {
    setPointsToAdd(prev => Math.max(5, prev - 5));
  }, []);

  const minimize = useCallback(() => {
    if (!isMinimized) {
      // Store current dimensions in ref
      prevDimensionsRef.current = { ...dimensions };

      // Calculate center point
      const centerX = dimensions.x + dimensions.width / 2;
      const centerY = dimensions.y + dimensions.height / 2;

      // Calculate new position to maintain center point
      const newX = centerX - MINIMIZED_SIZE / 2;
      const newY = centerY - MINIMIZED_SIZE / 2;

      setDimensions({
        width: MINIMIZED_SIZE,
        height: MINIMIZED_SIZE,
        x: newX,
        y: newY,
      });
      setIsMinimized(true);
    }
  }, [isMinimized, dimensions]);

  const restore = useCallback(() => {
    if (isMinimized) {
      const prev = prevDimensionsRef.current;
      
      // Calculate center point of minimized window
      const centerX = dimensions.x + dimensions.width / 2;
      const centerY = dimensions.y + dimensions.height / 2;
      
      // Calculate new position to maintain center point
      const newX = centerX - prev.width / 2;
      const newY = centerY - prev.height / 2;

      setDimensions({
        width: prev.width,
        height: prev.height,
        x: newX,
        y: newY,
      });
      setIsMinimized(false);
    }
  }, [isMinimized, dimensions]);

  // Handle Rnd resize stop to update dimensions
  const handleResizeStop = useCallback((e, direction, ref, delta, position) => {
    setDimensions({
      width: parseInt(ref.style.width, 10),
      height: parseInt(ref.style.height, 10),
      x: position.x,
      y: position.y,
    });
  }, []);

  // Handle Rnd drag stop to update position
  const handleDragStop = useCallback((e, d) => {
    setDimensions((prev) => ({
      ...prev,
      x: d.x,
      y: d.y,
    }));
  }, []);

  // Click vs Drag logic for minimized floating button
  const handleMouseDown = useCallback((e) => {
    mouseDownTimeRef.current = Date.now();
    mouseDownPosRef.current = { x: e.clientX, y: e.clientY };
  }, []);

  const handleMouseUp = useCallback((e) => {
    const clickThreshold = 200; // milliseconds
    const moveThreshold = 5; // pixels

    const mouseUpTime = Date.now();
    const timeDiff = mouseUpTime - mouseDownTimeRef.current;

    const deltaX = Math.abs(e.clientX - mouseDownPosRef.current.x);
    const deltaY = Math.abs(e.clientY - mouseDownPosRef.current.y);
    const moveDiff = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    if (timeDiff < clickThreshold && moveDiff < moveThreshold) {
      restore();
    }
  }, [restore]);

  return (
    <Rnd
      size={{ width: dimensions.width, height: dimensions.height }}
      position={{ x: dimensions.x, y: dimensions.y }}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
      minWidth={isMinimized ? MINIMIZED_SIZE : 300}
      minHeight={isMinimized ? MINIMIZED_SIZE : 300}
      bounds="window"
      aspectRatio={isMinimized ? 1 : undefined}
      style={{
        zIndex: 1000,
        overflow: 'hidden',
        cursor: isMinimized ? 'grab' : 'default',
      }}
      enableResizing={!isMinimized}
      disableDragging={false}
    >
      <div 
        style={styles.container}
        onMouseDown={isMinimized ? handleMouseDown : undefined}
        onMouseUp={isMinimized ? handleMouseUp : undefined}
      >
        {/* Header */}
        <div style={styles.header} className="drag-handle">
          {!isMinimized && <div style={styles.title}>Student Points</div>}
          <div style={styles.controls}>
            {!isMinimized && (
              <>
                <button
                  onClick={minimize}
                  style={styles.controlButton}
                  title="Minimize"
                >
                  <Minus size={16} />
                </button>
                <button
                  onClick={onClose}
                  style={styles.controlButton}
                  title="Close"
                >
                  <X size={16} />
                </button>
              </>
            )}
          </div>
        </div>

        {/* Body */}
        <div style={styles.body}>
          {!isMinimized && (
            <>
              {/* Points Control Section */}
              <div style={styles.pointsControls}>
                <button 
                  style={styles.pointsButton} 
                  onClick={decreasePoints}
                  disabled={pointsToAdd <= 5}
                  title="Decrease points"
                >
                  <Minus size={20} />
                </button>
                <div style={styles.pointsValue}>{pointsToAdd}</div>
                <button 
                  style={styles.pointsButton} 
                  onClick={increasePoints}
                  title="Increase points"
                >
                  <Plus size={20} />
                </button>
              </div>
              
              {/* Student List */}
              <div style={styles.studentList}>
                {activeBees && activeBees.length > 0 ? (
                  activeBees.map((bee) => (
                    <div 
                      key={`${bee.classId}-${bee.name}`} 
                      style={{
                        ...styles.studentItem,
                        backgroundColor: recentlyUpdated[`${bee.classId}-${bee.name}`] 
                          ? '#d1fae5' // Light green background when recently updated
                          : '#f9fafb'
                      }}
                    >
                      <div style={styles.studentName}>
                        {bee.name} 
                        {bee.isOnline ? ' (Online)' : ' (Offline)'}
                      </div>
                      <button 
                        style={styles.addPointsButton}
                        onClick={() => addPoints(bee.classId, bee.name)}
                      >
                        +{pointsToAdd} Points
                        {recentlyUpdated[`${bee.classId}-${bee.name}`] && 
                          <span style={styles.statusText}>Added!</span>
                        }
                      </button>
                    </div>
                  ))
                ) : (
                  <div style={styles.noStudents}>
                    No active students found
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {/* Minimized View */}
        {isMinimized && (
          <div style={styles.minimizedIcon}>
            🏆
          </div>
        )}
      </div>
    </Rnd>
  );
});

export default Points;

