import React, { useMemo } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

const Mercury = () => {
  // Mercury constants (no atmosphere needed)
  const mercuryRadius = 2439.7;

  // Mercury Surface Shader with updated Earth-style shadow gradient.
  const mercurySurfaceShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          // Apply Mercury's rotation pattern (rotated by 162.5 degrees)
          float angle = radians(162.5);
          mat3 rotationMatrix = mat3(
            1.0, 0.0, 0.0,
            0.0, cos(angle), -sin(angle),
            0.0, sin(angle), cos(angle)
          );
          
          vec3 rotatedPosition = rotationMatrix * position;
          vec3 rotatedNormal = rotationMatrix * normal;
          
          vUv = uv;
          vNormal = rotatedNormal;
          vPosition = rotatedPosition;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;
        const float PI = 3.14159265359;

        // Simple random and noise functions
        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - f);
          return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.65, 0.55, 0.45);
          
          // Create subtle waves across the surface
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 12.0 + time * 0.2) * 0.03;
          float wave2 = sin(angle * 7.0 - time * 0.15 + PI * 0.4) * 0.025;
          float wave3 = sin(angle * 4.0 + time * 0.1 + PI * 0.7) * 0.02;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          // Create a faint cloud/noise effect over Mercury's surface
          float clouds = noise(vec2(modifiedY * 4.0, time * 0.15)) * 0.7;
          clouds += noise(vec2(modifiedY * 10.0, time * 0.08)) * 0.3;
          
          vec3 bandColor;
          if (modifiedY > 0.8) {
              bandColor = mix(vec3(0.6, 0.4, 0.7), vec3(0.7, 0.5, 0.8), clouds);
          } else if (modifiedY > 0.6) {
              bandColor = mix(vec3(0.8, 0.7, 0.2), vec3(0.9, 0.8, 0.3), clouds);
          } else if (modifiedY > 0.4) {
              bandColor = mix(vec3(0.2, 0.3, 0.5), vec3(0.3, 0.4, 0.6), clouds);
          } else if (modifiedY > 0.2) {
              bandColor = mix(vec3(0.7, 0.6, 0.1), vec3(0.8, 0.7, 0.2), clouds);
          } else {
              bandColor = mix(vec3(0.5, 0.3, 0.6), vec3(0.6, 0.4, 0.7), clouds);
          }
          
          // Apply a subtle tint across the surface
          bandColor *= mix(
            vec3(1.3, 1.2, 1.1),
            vec3(0.8, 0.7, 0.9),
            sin(modifiedY * 9.0 + clouds * 3.0) * 0.5 + 0.5
          );
          
          vec3 finalColor = mix(bandColor, baseColor, 0.05);

          // Shadow logic (borrowed from Earth's implementation)
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct < 0.9) {
            float shadowAngle = acos(-dotProduct) * 1.8;
            if (shadowAngle < 3.15) {
              float shadowStrength = smoothstep(3.15, 2.8, shadowAngle);
              shadowStrength = pow(shadowStrength, 1.5);
              finalColor = mix(finalColor, vec3(0.0), 0.45 * shadowStrength);
            }
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  return (
    <group>
      {/* Mercury Surface Mesh Only */}
      <mesh>
        <sphereGeometry args={[mercuryRadius, 32, 32]} />
        <shaderMaterial
          attach="material"
          {...mercurySurfaceShader}
          side={THREE.FrontSide}
        />
      </mesh>
    </group>
  );
};

export default Mercury;
