import React, { useRef, useContext, useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { SunContext, sharedGeometries } from './Overlay1-Viewer';
import { SunContext2 } from './Overlay2-TV';

// Improved ModifiedCircleWithEdges component with instanced circle caps
const ModifiedCircleWithEdges = ({ 
  position, 
  radius, 
  color, 
  opacity, 
  renderOrder,
  contextType = "overlay1" // Default to overlay1
}) => {
  const circleRef = useRef();
  const lineGroupRef = useRef();
  
  // Choose the appropriate context based on contextType
  const context1 = useContext(SunContext);
  const context2 = useContext(SunContext2);
  const { sunContext } = contextType === "overlay2" ? context2 : context1;
  
  // References for instanced meshes
  const lightCapsRef = useRef();
  const darkCapsRef = useRef();
  
  // Track visibility states for each instance
  const capsVisibility = useRef({
    lightStart: false,
    lightEnd: false,
    darkStart: false,
    darkEnd: false
  });
  
  // Store dummy objects for matrix updates
  const dummyObj = useRef(new THREE.Object3D());
  
  // Used for detecting significant changes in sun position
  const lastSunPos = useRef({ x: 0, y: 0 });
  
  // Calculate light and shadow colors from base color
  const colors = useMemo(() => {
    let r, g, b;

    // Check for hex values (including shorthand) or rgb strings.
    if (typeof color === 'string' && color.startsWith('#')) {
      // If shorthand hex like "#555", expand it to "#555555"
      if (color.length === 4) {
        r = parseInt(color[1] + color[1], 16);
        g = parseInt(color[2] + color[2], 16);
        b = parseInt(color[3] + color[3], 16);
      } else {
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
      }
    } else if (typeof color === 'string' && color.startsWith('rgb')) {
      // Extract the number values from an rgb string like "rgb(85, 85, 85)"
      const result = color.match(/rgb\s*\(\s*(\d+)[,\s]+(\d+)[,\s]+(\d+)\s*\)/);
      if (result) {
        r = parseInt(result[1], 10);
        g = parseInt(result[2], 10);
        b = parseInt(result[3], 10);
      }
    }
    
    // Default to white if not recognized
    if (r === undefined || g === undefined || b === undefined) {
      r = g = b = 255;
    }
    
    // Factors for slight brightness adjustment (20% change)
    const lightenFactor = 0.2; // 20% brighter
    const darkenFactor  = 0.2; // 20% darker
    
    // Calculate new colors (ensure they remain within 0-255)
    const lightR = Math.min(255, Math.floor(r * (1 + lightenFactor)));
    const lightG = Math.min(255, Math.floor(g * (1 + lightenFactor)));
    const lightB = Math.min(255, Math.floor(b * (1 + lightenFactor)));
    
    const darkR = Math.max(0, Math.floor(r * (1 - darkenFactor)));
    const darkG = Math.max(0, Math.floor(g * (1 - darkenFactor)));
    const darkB = Math.max(0, Math.floor(b * (1 - darkenFactor)));
    
    return {
      light: new THREE.Color(`rgb(${lightR}, ${lightG}, ${lightB})`),
      dark: new THREE.Color(`rgb(${darkR}, ${darkG}, ${darkB})`)
    };
  }, [color]);
  
  // Set up the geometry and materials
  useEffect(() => {
    if (!lineGroupRef.current) return;
    
    // Clear existing children if any
    while (lineGroupRef.current.children.length > 0) {
      const child = lineGroupRef.current.children[0];
      lineGroupRef.current.remove(child);
    }
    
    // Create light and dark materials
    const lightMaterial = new THREE.MeshBasicMaterial({
      color: colors.light,
      transparent: true,
      opacity: opacity,
      side: THREE.DoubleSide,
      depthTest: false,
      depthWrite: false
    });
    
    const darkMaterial = new THREE.MeshBasicMaterial({
      color: colors.dark,
      transparent: true,
      opacity: opacity,
      side: THREE.DoubleSide,
      depthTest: false,
      depthWrite: false
    });
    
    // Create empty meshes for light and dark arcs
    const lightMesh = new THREE.Mesh(new THREE.BufferGeometry(), lightMaterial);
    const darkMesh = new THREE.Mesh(new THREE.BufferGeometry(), darkMaterial);
    
    // Set the render order
    lightMesh.renderOrder = renderOrder;
    darkMesh.renderOrder = renderOrder;
    
    // Add to group
    lineGroupRef.current.add(lightMesh);
    lineGroupRef.current.add(darkMesh);
    
    // Create instanced meshes for caps
    // 2 instances for light caps (start and end)
    const lightCaps = new THREE.InstancedMesh(
      sharedGeometries.circleGeometry,
      lightMaterial.clone(),
      2
    );
    lightCaps.renderOrder = renderOrder;
    lightCaps.count = 2;
    lineGroupRef.current.add(lightCaps);
    lightCapsRef.current = lightCaps;
    
    // 2 instances for dark caps (start and end)
    const darkCaps = new THREE.InstancedMesh(
      sharedGeometries.circleGeometry,
      darkMaterial.clone(),
      2
    );
    darkCaps.renderOrder = renderOrder;
    darkCaps.count = 2;
    lineGroupRef.current.add(darkCaps);
    darkCapsRef.current = darkCaps;
    
    // Initially hide all caps
    for (let i = 0; i < 2; i++) {
      dummyObj.current.scale.set(0, 0, 0); // Scale to 0 to hide
      dummyObj.current.updateMatrix();
      lightCaps.setMatrixAt(i, dummyObj.current.matrix);
      darkCaps.setMatrixAt(i, dummyObj.current.matrix);
    }
    lightCaps.instanceMatrix.needsUpdate = true;
    darkCaps.instanceMatrix.needsUpdate = true;
    
    // Clean up function
    return () => {
      lightMaterial.dispose();
      darkMaterial.dispose();
    };
  }, [radius, colors, opacity, renderOrder]);
  
  // Update the curve based on sun position
  const updateCurve = (sunPos) => {
    if (!lineGroupRef.current || lineGroupRef.current.children.length < 4) return;
    
    const lightMesh = lineGroupRef.current.children[0];
    const darkMesh = lineGroupRef.current.children[1];
    const lightCaps = lightCapsRef.current;
    const darkCaps = darkCapsRef.current;
    
    if (!lightCaps || !darkCaps) return;
    
    // Get circle position
    const circlePos = {
      x: position[0],
      y: position[1]
    };
    
    // Calculate angle from circle to sun in radians
    const angleToSun = Math.atan2(sunPos.y - circlePos.y, sunPos.x - circlePos.x);
    
    // Base thickness
    const baseThickness = radius * 0.15;
    
    // Inset factor - controls how far the edges are inset from the circle edge
    // Using just 3.75% inset from the edge (1/4 of the previous 15%)
    const insetFactor = 0.0375;
    
    // Create points for light arc (90 degrees facing the sun)
    const numPoints = 50;
    const arcLength = Math.PI / 2;
    
    // Light arc starts at angleToSun - arcLength/2
    const lightStartAngle = angleToSun - arcLength/2;
    const lightEndAngle = angleToSun + arcLength/2;
    
    // Adjusted radius for the arcs to be more inset
    const insetRadius = radius * (1 - insetFactor);
    
    // Create arc geometry using Three.js built-in curve
    const lightCurve = new THREE.EllipseCurve(
      0, 0,                         // Center
      insetRadius, insetRadius,     // X and Y radius - now using inset radius
      lightStartAngle, lightEndAngle, // Start and end angle
      false,                        // Clockwise
      0                            // Rotation
    );
    
    // Get points along the curve
    const lightPoints = lightCurve.getPoints(numPoints);
    const lightPositions = [];
    const lightIndices = [];
    
    // Create the thickness by offsetting points
    for (let i = 0; i < lightPoints.length; i++) {
      const point = lightPoints[i];
      const t = i / (lightPoints.length - 1);
      const bulgeFactor = Math.sin(t * Math.PI) * 0.5;
      const thickness = baseThickness * (1 + bulgeFactor);
      
      // Calculate normal at this point
      const normalX = point.x / radius;
      const normalY = point.y / radius;
      const normalLength = Math.sqrt(normalX * normalX + normalY * normalY);
      
      // Inner vertex
      lightPositions.push(
        point.x - (normalX / normalLength) * thickness,
        point.y - (normalY / normalLength) * thickness,
        0.05
      );
      
      // Outer vertex
      lightPositions.push(
        point.x,
        point.y,
        0.05
      );
      
      // Create triangles (except for last point)
      if (i < lightPoints.length - 1) {
        const baseIndex = i * 2;
        lightIndices.push(
          baseIndex, baseIndex + 1, baseIndex + 2,
          baseIndex + 1, baseIndex + 3, baseIndex + 2
        );
      }
    }
    
    // Update light mesh geometry
    const lightGeometry = new THREE.BufferGeometry();
    lightGeometry.setAttribute('position', new THREE.Float32BufferAttribute(lightPositions, 3));
    lightGeometry.setIndex(lightIndices);
    lightMesh.geometry.dispose();
    lightMesh.geometry = lightGeometry;
    
    // Create geometry for dark arc (90 degrees on the opposite side from the sun)
    const darkGeometry = new THREE.BufferGeometry();
    const darkPositionAttribute = new THREE.Float32BufferAttribute(new Float32Array(numPoints * 6), 3);
    darkGeometry.setAttribute('position', darkPositionAttribute);
    const darkIndices = [];
    
    // Dark arc starts at angleToSun + Math.PI - arcLength/2 to center it opposite to the sun
    const darkStartAngle = angleToSun + Math.PI - arcLength/2;
    const darkEndAngle = angleToSun + Math.PI + arcLength/2;
    
    for (let i = 0; i <= numPoints; i++) {
      const t = i / numPoints;
      const currentAngle = darkStartAngle + (t * arcLength);
      
      // Calculate bulge factor - more thickness in the middle
      const bulgeFactor = Math.sin(t * Math.PI) * 0.8; // 0.5 controls the amount of bulge
      
      // Calculate thickness with bulge (thicker in the middle)
      const thickness = baseThickness * (1 + bulgeFactor);
      
      // Inner vertex (closer to center)
      const innerRadius = radius - thickness;
      const innerX = Math.cos(currentAngle) * innerRadius;
      const innerY = Math.sin(currentAngle) * innerRadius;
      
      // Outer vertex (at the circle edge)
      const outerRadius = radius;
      const outerX = Math.cos(currentAngle) * outerRadius;
      const outerY = Math.sin(currentAngle) * outerRadius;
      
      darkPositionAttribute.array[i*6] = innerX;
      darkPositionAttribute.array[i*6+1] = innerY;
      darkPositionAttribute.array[i*6+2] = 0.05;
      darkPositionAttribute.array[i*6+3] = outerX;
      darkPositionAttribute.array[i*6+4] = outerY;
      darkPositionAttribute.array[i*6+5] = 0.05;
      
      // Add triangles (except for the first point)
      if (i > 0) {
        const baseIndex = (i - 1) * 2;
        // First triangle
        darkIndices.push(baseIndex, baseIndex + 1, baseIndex + 2);
        // Second triangle
        darkIndices.push(baseIndex + 1, baseIndex + 3, baseIndex + 2);
      }
    }
    
    darkGeometry.setIndex(darkIndices);
    darkMesh.geometry.dispose();
    darkMesh.geometry = darkGeometry;
    
    // Position and scale the end cap circles
    const capRadius = baseThickness / 2; // Use base thickness for caps
    
    // Light arc start cap - position at the middle of the edge with additional inset
    const lightStartInset = baseThickness * 0.3; // Additional inset for light start cap
    const lightStartX = Math.cos(lightStartAngle) * (radius - baseThickness/2 - lightStartInset);
    const lightStartY = Math.sin(lightStartAngle) * (radius - baseThickness/2 - lightStartInset);
    
    // Light arc end cap - position at the middle of the edge, not at inner radius
    const lightEndX = Math.cos(lightEndAngle) * (radius - baseThickness/2);
    const lightEndY = Math.sin(lightEndAngle) * (radius - baseThickness/2);
    
    // Dark arc start cap - position at the middle of the edge, not at inner radius
    const darkStartX = Math.cos(darkStartAngle) * (radius - baseThickness/2);
    const darkStartY = Math.sin(darkStartAngle) * (radius - baseThickness/2);
    
    // Dark arc end cap - position at the middle of the edge with additional inset
    const darkEndInset = baseThickness * 0.3; // Additional inset for dark end cap
    const darkEndX = Math.cos(darkEndAngle) * (radius - baseThickness/2 - darkEndInset);
    const darkEndY = Math.sin(darkEndAngle) * (radius - baseThickness/2 - darkEndInset);
    
    // Update light cap instances - circle type keeps caps
    dummyObj.current.position.set(lightStartX, lightStartY, 0.01);
    dummyObj.current.scale.set(capRadius, capRadius, 1);
    dummyObj.current.updateMatrix();
    lightCaps.setMatrixAt(0, dummyObj.current.matrix);
    
    dummyObj.current.position.set(lightEndX, lightEndY, 0.01);
    dummyObj.current.scale.set(capRadius, capRadius, 1);
    dummyObj.current.updateMatrix();
    lightCaps.setMatrixAt(1, dummyObj.current.matrix);
    
    // Update dark cap instances
    dummyObj.current.position.set(darkStartX, darkStartY, 0.01);
    dummyObj.current.scale.set(capRadius, capRadius, 1);
    dummyObj.current.updateMatrix();
    darkCaps.setMatrixAt(0, dummyObj.current.matrix);
    
    dummyObj.current.position.set(darkEndX, darkEndY, 0.01);
    dummyObj.current.scale.set(capRadius, capRadius, 1);
    dummyObj.current.updateMatrix();
    darkCaps.setMatrixAt(1, dummyObj.current.matrix);
    
    // Update instance matrices
    lightCaps.instanceMatrix.needsUpdate = true;
    darkCaps.instanceMatrix.needsUpdate = true;
    
    // Update visibility states
    capsVisibility.current = {
      lightStart: true,
      lightEnd: true,
      darkStart: true,
      darkEnd: true
    };
  };
  
  // Update curves on each frame
  useFrame(() => {
    if (!sunContext.current) return;
    
    // Only update if sun moved enough
    const dx = sunContext.current.x - lastSunPos.current.x;
    const dy = sunContext.current.y - lastSunPos.current.y;
    if (Math.sqrt(dx*dx + dy*dy) > 0.01) {
      updateCurve(sunContext.current);
      lastSunPos.current = {...sunContext.current};
    }
  });
  
  useEffect(() => {
    // Explicitly disable frustum culling for all meshes in the group
    if (lineGroupRef.current) {
      lineGroupRef.current.traverse(object => {
        if (object.isMesh || object.isLine) {
          object.frustumCulled = false;
        }
      });
    }
    
    // Also disable for the main circle
    if (circleRef.current) {
      circleRef.current.frustumCulled = false;
    }
  }, []);
  
  return (
    <group position={position}>
      {/* Main circle - shifted slightly to the left */}
      <mesh 
        ref={circleRef}
        renderOrder={renderOrder}
        position={[-0.001, 0, 0]} // Added slight leftward offset
      >
        <circleGeometry args={[radius, 32]} />
        <meshBasicMaterial 
          color={color} 
          transparent={true}
          opacity={opacity}
          side={THREE.DoubleSide}
          depthTest={false}
          depthWrite={false}
        />
      </mesh>
      
      {/* Light and shadow curves group */}
      <group ref={lineGroupRef} />
    </group>
  );
};

export default ModifiedCircleWithEdges;