// src/components/Students/apps/StudentTests.js

import React from 'react';
import PuzzleBanner from '../../../components/banners/animatedsvg copy 6.svg'; // Adjust the path if necessary

const StudentGames = () => {
  // Function to add ordinal suffix to the day
  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // Function to format the current date and time
  const getFormattedDate = () => {
    const now = new Date();
    const options = { weekday: 'long', month: 'long' };
    const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const day = getOrdinal(now.getDate());
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
    return `${weekday} the ${day} of ${month} ${formattedTime}`;
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-white student-tests">
      
      {/* Title Section */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4">
        {/* Title */}
        <p className="text-3xl font-bold text-center">Read</p>
        {/* Current Date and Time */}
        <div className="mt-2 text-xs font-bold text-gray-600">
          {getFormattedDate()}
        </div>
      </div>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full border-gray-700 border-t-5"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', // Increased shadow size and darkness
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ height: '96px', width: '100%', objectFit: 'cover' }}
        />
      </div>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-start flex-grow p-6 overflow-auto">
        <p className="mt-6 text-lg text-gray-700">
          This is where the modal will go
        </p>
        {/* Add any additional content or functionality here */}
      </div>
    </div>
  );
};

export default StudentGames;
