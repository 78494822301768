import React, { useState, useEffect, useRef } from 'react';
import { rtdb } from '../../../firebase'; // Ensure rtdb is correctly exported
import { ref, onValue, off, update, get } from 'firebase/database'; // Added 'update' for XP increment
import { useAuth } from '../../../contexts/AuthContext';
import PropTypes from 'prop-types';
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported
import { useDataController, MODULE_STATES } from '../../../contexts/datacontroller';
import OpenAI from 'openai'; // You'll need to install this package

// Import banner image similar to Whiteboard
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

// Import Layout
import Layout from '../HeaderFooterLayoutBees/Layout'; // Import the Layout component

// Update the OpenAI client initialization
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const SlideComponents = ({ onClose, classID }) => {
  const { currentUser } = useAuth();
  const [bees, setBees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBee, setSelectedBee] = useState(null);

  // Add new state for new member name input
  const [newMemberName, setNewMemberName] = useState('');

  // Add new state for timer
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timerActive, setTimerActive] = useState(false);

  // Create a ref where the external JS animation will attach its container.
  const animationContainerRef = useRef(null);

  // Add new state variables for the lesson grid
  const [cellContents, setCellContents] = useState(Array(20).fill(''));
  const [iDoContents, setIDoContents] = useState(Array(20).fill(''));
  const [weDoContents, setWeDoContents] = useState(Array(20).fill(''));
  const [youDoContents, setYouDoContents] = useState(Array(20).fill(''));
  const [reflectionContents, setReflectionContents] = useState(Array(20).fill(''));
  
  // Add selection state
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);

  // Add new state for tracking current active cell
  const [activeInputIndex, setActiveInputIndex] = useState(0);

  // Add paste handler function for bulk paste
  const handleBulkPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    
    // Split the pasted content by newlines to get individual cell values
    const rows = pastedData.split(/\r?\n/).filter(row => row.trim() !== '');
    
    if (rows.length > 1) {
      // This is a multi-line paste, update multiple cells
      const startIndex = activeInputIndex;
      const newCellContents = [...cellContents];
      
      rows.forEach((value, index) => {
        const targetIndex = startIndex + index;
        if (targetIndex < newCellContents.length) {
          newCellContents[targetIndex] = value.trim();
        }
      });
      
      setCellContents(newCellContents);
    }
  };

  // Add timer utility function
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Add timer effect
  useEffect(() => {
    let interval;
    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining(prev => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setTimerActive(false);
      // Optional: Add any logic for when timer ends
    }
    return () => clearInterval(interval);
  }, [timerActive, timeRemaining]);

  // Fetch bees' names from RTDB where logged is "yes"
  useEffect(() => {
    if (!classID) {
      console.error('Missing classID.');
      setError('Invalid class ID.');
      setLoading(false);
      return;
    }

    const beesRef = ref(rtdb, `activeclasses/${classID}/beeNames`);
    console.log('Listening to RTDB path:', `activeclasses/${classID}/beeNames`); // Debug log

    const handleData = (snapshot) => {
      const data = snapshot.val();
      console.log('Fetched data from RTDB:', data); // Debug log

      if (data) {
        console.log(`Data retrieved for classID ${classID}:`, data);
        const beesArray = Object.keys(data)
          .filter((name) => {
            const isLogged = data[name].logged === 'yes'; // Ensure case matches
            if (!isLogged) {
              console.log(`Bee "${name}" is not logged.`);
            }
            return isLogged;
          })
          .map((name) => ({
            name: name.trim(),
            totalxp: data[name].totalxp || 0,
            classId: classID,
          }));
        console.log('Parsed bees array:', beesArray); // Debug log
        setBees(beesArray);
      } else {
        console.warn(`No data found at path activeclasses/${classID}/beeNames.`);
        setBees([]);
      }
      setLoading(false);
    };

    onValue(beesRef, handleData, (err) => {
      console.error('Error fetching bees:', err);
      setError('Failed to load bees. Please try again.');
      setLoading(false);
    });

    console.log(`Listener attached to activeclasses/${classID}/beeNames`);

    // Cleanup listener on unmount
    return () => {
      off(beesRef, 'value', handleData);
      console.log(`Detached listener from activeclasses/${classID}/beeNames`);
    };
  }, [classID]);

  // Function to handle XP increment
  const handleAddXp = (beeName) => {
    const beeIndex = bees.findIndex((bee) => bee.name === beeName);
    if (beeIndex !== -1) {
      const updatedBees = [...bees];
      updatedBees[beeIndex].totalxp += 1;
      setBees(updatedBees);

      // Update in RTDB
      const beeRef = ref(rtdb, `activeclasses/${classID}/beeNames/${beeName}/totalxp`);
      update(beeRef, updatedBees[beeIndex].totalxp)
        .then(() => {
          console.log(`XP updated for ${beeName}`);
        })
        .catch((err) => {
          console.error('Error updating XP:', err);
        });
    }
  };

  // JSX for a single bee's card
  const BeeCard = ({ bee }) => {
    console.log('Rendering BeeCard for:', bee.name); // Debug log

    return (
      <div className="flex flex-col items-center">
        {/* A4 Page Shape */}
        <div
          className="relative transition-transform bg-white rounded-lg shadow-md cursor-pointer hover:scale-105"
          style={{
            width: '150px', // Adjusted width for screen
            height: '212px', // Height maintaining A4 aspect ratio (150 * 297 / 210 ≈ 212)
            maxWidth: '150px',
            maxHeight: '212px',
          }}
          onClick={() => setSelectedBee(bee)}
        >
          {/* Placeholder for typing content */}
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-400">Typing Area</p>
          </div>

          {/* XP Button */}
          <button
            className="absolute p-1 text-lg transition-opacity bg-blue-500 rounded-full shadow opacity-0 bottom-2 right-2 hover:opacity-100"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the card click
              handleAddXp(bee.name);
            }}
            aria-label="Add XP"
            title="Add XP"
          >
            ❤️
          </button>
        </div>

        {/* Bee Name */}
        <div className="mt-2 text-sm font-medium text-center text-gray-700">
          {bee.name}
        </div>
      </div>
    );
  };

  BeeCard.propTypes = {
    bee: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number,
      classId: PropTypes.string.isRequired,
    }).isRequired,
  };

  // List of bee cards with adjusted grid layout
  const beeList = (
    <div className="grid justify-center grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
      {bees.map((bee) => (
        <BeeCard key={`${bee.classId}-${bee.name}`} bee={bee} />
      ))}
    </div>
  );

  // Modal view for selected bee
  const BeeModal = ({ bee, onClose }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
        <div
          className="relative bg-white rounded-lg shadow-xl"
          style={{
            width: '90%',
            height: '80%',
            maxWidth: '800px',
            maxHeight: '1100px',
            overflow: 'hidden', // Remove scrollbars
          }}
          onClick={onClose}
        >
          <button
            onClick={onClose}
            className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
            aria-label="Close Modal"
            onMouseDown={(e) => e.stopPropagation()} // Prevent closing when clicking the button
          >
            <FaTimes />
          </button>
          <div className="flex flex-col h-full p-12">
            {/* Enlarge the BeeCard inside the modal */}
            <div className="flex items-center justify-center flex-grow">
              <div
                className="relative bg-white rounded-lg shadow-md cursor-default"
                style={{
                  width: '100%', // Make it responsive within the modal
                  height: 'auto',
                  paddingTop: '141.42%', // 210/297 ≈ 0.707, so padding-top: 70.7% to maintain aspect ratio
                  maxWidth: '800px',
                }}
              >
                <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-between h-full p-8">
                  <h2 className="mb-4 text-2xl font-bold text-center">{bee.name}</h2>
                  {/* Additional detailed content can be added here */}
                  <div className="mt-4 text-center">
                    {/* Placeholder for detailed typing content */}
                    <p className="text-gray-400">Detailed Typing Area (To be implemented)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  BeeModal.propTypes = {
    bee: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number,
      classId: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  // Add handler functions
  const handleCellChange = (index, value) => {
    const newCellContents = [...cellContents];
    newCellContents[index] = value;
    setCellContents(newCellContents);
  };

  // Add this new utility function near the top of your component
  const ensureProperFormatting = (text) => {
    if (!text) return '';

    // First, remove any existing formatting for these specific terms
    let formatted = text
      .replace(/<strong>Enable:<\/strong>/gi, 'Enable:')
      .replace(/<strong>At:<\/strong>/gi, 'At:')
      .replace(/<strong>Extend:<\/strong>/gi, 'Extend:')
      .replace(/\*\*Enable:\*\*/gi, 'Enable:')
      .replace(/\*\*At:\*\*/gi, 'At:')
      .replace(/\*\*Extend:\*\*/gi, 'Extend:')
      .replace(/<strong>Enable<\/strong>:/gi, 'Enable:')
      .replace(/<strong>At<\/strong>:/gi, 'At:')
      .replace(/<strong>Extend<\/strong>:/gi, 'Extend:');

    // Split into lines and process each line
    const lines = formatted.split('\n');
    
    const processedLines = lines.map(line => {
      // First, handle any remaining markdown bold
      line = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

      // Then process our specific keywords
      // Look for the keywords with their colons, including possible spaces
      line = line.replace(/\b(Enable)\s*:/gi, '<strong>Enable:</strong>');
      line = line.replace(/\b(At)\s*:/gi, '<strong>At:</strong>');
      line = line.replace(/\b(Extend)\s*:/gi, '<strong>Extend:</strong>');

      return line;
    });

    return processedLines.join('\n');
  };

  // Update the handleGenerate function to use the new formatting
  const handleGenerate = async () => {
    try {
      // Filter out empty cells and process each non-empty cell
      const nonEmptyCells = cellContents.filter(content => content.trim() !== '');
      
      // Create copies of the current state arrays
      const newIDoContents = [...iDoContents];
      const newWeDoContents = [...weDoContents];
      const newYouDoContents = [...youDoContents];
      const newReflectionContents = [...reflectionContents];
      
      for (let i = 0; i < nonEmptyCells.length; i++) {
        const content = nonEmptyCells[i];
        const originalIndex = cellContents.indexOf(content);
        
        // Skip if this cell already has content in all fields
        if (iDoContents[originalIndex] && 
            weDoContents[originalIndex] && 
            youDoContents[originalIndex] && 
            reflectionContents[originalIndex]) {
          console.log(`Skipping generation for cell ${originalIndex + 1} as it already has content`);
          continue;
        }
        
        // Generate I Do content
        const iDoPrompt = `Your task is to use the following Learning Intention and Success Criteria and create teacher instruction for the teacher instruction part of lesson.

This should consist of teacher instruction to the students i.e explaining the learning content. It should use language like "Teacher explains..." 

The facts/things that the teacher does in this part should be in dot points.

Ensure all your responses use British/Australian english. i.e the -Ize suffix should be -ise. 

Examples:
- "Teacher draws .... on the whiteboard" 
- "Teacher models solving...." 

Your response should have at least 3 subheadings and each one should ahve at least 3 dot points.

In the dot points, provide actual examples of the learning. 

Your response should not include any resources or explanation of the activity. The response is only explain the actual learning of the content.

Ensure you include specify what to search for on Google Images using the format: [SEARCH: your search terms]. For example: [SEARCH: multiplication array diagram]. Do not restate the LI and SC. Put new lines of text on a new row. Each dot point should also be on a new row. Make subheadings bold. Do not label "teacher instruction". Just return the actual content. 

LI and SC: ${content}`;

        // Generate We Do content
        const weDoPrompt = `Your task is to use the following Learning Intention and Success Criteria and create teacher instruction for student peer discussion activity. 

the students have already recieved the learning content. it is now time for them to participate in a peer discussion/activity. 

This should consist of teacher instruction to the students i.e explaining the learning content. It should use language like "Teacher tells students to..." 

Ensure all your responses use British/Australian english. i.e the -Ize suffix should be -ise. 

Examples:
- "Teacher tells student to turn and talk to the student next to them, and discuss their thoughts or ideas from the lesson learning. " 
- "Teacher tells students to get into a group of 4 students and brainstorm...." 


Your response should not include any resources or explanation of the activity. The response is only explain the actual learning of the content.

Your response should always start with: "We Do Activity:" in bold letters. then the activity should be on a new line. 

It should only be 1 we do activity. Not multiple. 

LI and SC: ${content}`;

        // Make the first two API calls
        const [iDoCompletion, weDoCompletion] = await Promise.all([
          openai.chat.completions.create({
            messages: [{ role: "user", content: iDoPrompt }],
            model: "gpt-3.5-turbo",
          }),
          openai.chat.completions.create({
            messages: [{ role: "user", content: weDoPrompt }],
            model: "gpt-3.5-turbo",
          })
        ]);

        // Process the I Do response
        let processedIDoResponse = iDoCompletion.choices[0].message.content;
        const searchPattern = /\[SEARCH:\s*(.*?)\]/g;
        processedIDoResponse = processedIDoResponse.replace(searchPattern, (match, searchTerms) => {
          const encodedSearch = encodeURIComponent(searchTerms);
          const googleImagesUrl = `https://www.google.com/search?q=${encodedSearch}&tbm=isch`;
          return `<a href="${googleImagesUrl}" target="_blank" class="text-blue-500 hover:underline">[View Image: ${searchTerms}]</a>`;
        });

        // Process the We Do response - ensure "We Do Activity:" is bold
        let processedWeDoResponse = weDoCompletion.choices[0].message.content;
        
        // If the response doesn't already have "We Do Activity:" in bold, add it
        if (!processedWeDoResponse.includes("<strong>We Do Activity:</strong>") && 
            !processedWeDoResponse.includes("**We Do Activity:**")) {
          processedWeDoResponse = "<strong>We Do Activity:</strong>\n" + processedWeDoResponse;
        } else {
          // Replace markdown bold with HTML bold if it exists
          processedWeDoResponse = processedWeDoResponse.replace(/\*\*We Do Activity:\*\*/g, "<strong>We Do Activity:</strong>");
        }

        // Update the temporary arrays instead of setting state
        newIDoContents[originalIndex] = processedIDoResponse;
        newWeDoContents[originalIndex] = processedWeDoResponse;

        // Now generate You Do content using the processed I Do and We Do content
        const youDoPrompt = `Your task is to use the following Learning Intention and Success Criteria and create an activity for the lesson. 

the students have already recieved the learning content. it is now time for them to do an activity. 

This should consist of instruction for the activity example: "Activity: The students..." followed with the activity description. 

the activity shouldnt require any resources beyond: pen and paper in their workbooks, drawing, conversations, roleplay, etc (things that can be done with general classroom supplies).

Ensure all your responses use British/Australian english. i.e the -Ize suffix should be -ise. 

It is only 1 activity not multiple. 

You need to include an Enable at extend after the activity description. 

It should look like this:

"Enable: [plan an enable activity for students who fidn the main activity too difficult and need extra support/easier tasks. Start with "Enable students will.. ](1 stentence)
At: Students complete the main activity. (Do not change)
Extend: [plan an enable activity for students who fidn the main activity too difficult and need extra support/easier tasks. Start with "Extend students will..  "] (1 stentence)


Your response should always start with: "You Do Activity:" in bold letters. then the activity should be on a new line. 

The start of the lesson consistented of the following information: ${processedIDoResponse.replace(/<[^>]*>/g, '')} and the we do activity was: ${processedWeDoResponse.replace(/<[^>]*>/g, '')}.

LI and SC: ${content}`;

        // Make the third API call
        const youDoCompletion = await openai.chat.completions.create({
          messages: [{ role: "user", content: youDoPrompt }],
          model: "gpt-3.5-turbo",
        });

        // Process the You Do response
        let processedYouDoResponse = youDoCompletion.choices[0].message.content;
        
        // Use the new formatting function
        processedYouDoResponse = ensureProperFormatting(processedYouDoResponse);
        
        // Ensure "You Do Activity:" is properly formatted
        if (!processedYouDoResponse.includes("<strong>You Do Activity:</strong>")) {
          processedYouDoResponse = "<strong>You Do Activity:</strong>\n" + processedYouDoResponse;
        }

        // Update the temporary array
        newYouDoContents[originalIndex] = processedYouDoResponse;
        
        // Add a random reflection activity
        const reflectionActivities = [
          "10 Second Summary: Students have 10 seconds to summarise the main point of the lesson.",
          "3-2-1 Countdown: Students share 3 things they learned, 2 things they found interesting, and 1 question they still have.",
          "30 Second Advertisement: Students create a 30-second advertisement selling the concept they learned during the lesson.",
          "5-4-3-2-1 Reflection: Students list 5 words related to the lesson, 4 things they learned, 3 applications of the lesson, 2 questions they still have, and 1 thing they'll tell someone about the lesson.",
          "A-Ha Moment: Students share the moment from the lesson when something 'clicked' for them.",
          "ABC Summary: Students summarise the lesson by assigning a word that starts with each letter of the alphabet related to the lesson.",
          "Acrostic Summary: Students create an acrostic poem using a word or concept from the lesson, where each line represents a concept or idea from the lesson.",
          "Adjective Adventure: Students think of an adjective that describes the lesson and explain their choice.",
          "Analogue Clock Reflection: Ask students to relate the hour hand (what they learned), minute hand (how they felt) and the second hand (what they still have questions about) to their experience of the lesson.",
          "Back to the Future: Students imagine they can travel five years into the future. They write about how they might apply what they learned from the lesson then.",
          "Before and After: Students share what they knew about the topic before the lesson and what they've learned after.",
          "Biggest Challenge: Students discuss the biggest challenge they faced during the lesson and how they overcame it.",
          "Biggest Surprise: Students share what surprised them most during the lesson.",
          "Bridge to Tomorrow: Students discuss how they can apply what they learned in today's lesson to tomorrow's lesson.",
          "Building Blocks: Students discuss what the foundational concepts (building blocks) of the lesson were and how they build upon each other.",
          "Celebrity Guest: Students imagine a celebrity joined the lesson. They discuss what that person might have learned or found interesting.",
          "Chalk Talk: Students silently write a word or phrase on the board that represents their thoughts about the lesson.",
          "Change One Thing: Students discuss one thing they would change about the lesson if they could.",
          "Circle of Appreciation: In a circle, students share something they appreciated about a peer during the lesson.",
          "Class Exit Ticket: Have students summarise one thing they've learned from the lesson and one question they still have.",
          "Classroom Graffiti Wall: Students pretend to graffiti a sentence or a phrase onto an imaginary classroom wall that reflects a key learning from the day.",
          "Classroom Timeline: Students collaboratively create a timeline of the lesson's main events or concepts.","Clay Modelling: Ask students to imagine they're shaping clay to represent their understanding of the lesson and then describe it.",
          "Colour Palette: Students associate different aspects of the lesson with colours and explain why.",
          "Comic Strip: Students outline a short comic strip that tells the story of the lesson.","Connect Four: Students make four connections between the lesson and their own lives.",
          "Connect with Personal Life: Students share a connection between the day's lesson and something in their own life.",
          "Diagram Decoder: Students draw a diagram that represents the main concepts from the lesson and explain it to a partner.",
          "Drama Replay: Students act out a key moment from the lesson.",
          "Draw Your Understanding: Allow students to draw a picture of what they learned, then explain their drawing to a peer.",
          "Echo the Echidna: Students pretend to be 'Echo the Echidna', who can only communicate by repeating what it has heard. They echo back the main points from the lesson.",
          "Emoji Emotions: Students select emojis that represent how they felt during the lesson and explain why.","Emotion Check-In: Students share how they feel about their understanding of the lesson, using specific emotions.",
          "Fast Forward: Students imagine it's a week later and they've completely forgotten the lesson. They then write down what they think will be most memorable.",
          "Feeling Thermometer: Students draw a thermometer to depict how well they understood the lesson, with 'cold' being not very well and 'hot' being a solid understanding.",
          "Fill in the Blank: The teacher starts a sentence related to the lesson and the students complete it.","Fishbowl: One student speaks about the lesson while the others listen, then rotate.",
          "Fist to Five: Students rate their understanding of the lesson from zero (closed fist) to five (all fingers up).",
          "Flash Debate: Divide the class into two. Each side quickly debates from differing perspectives related to the lesson.",
          "Future Application: Students brainstorm ways they might apply what they've learned to real-world situations in the future.",
          "Future Forecast: Based on today's lesson, students make a prediction about what they will learn next or how they can use the knowledge in the future.",
          "Gestures Recap: Assign each key learning point a gesture. Students repeat the sequence of gestures to recap the lesson.",
          "Gold Medal Moment: Students share a 'gold medal' moment from the lesson, something they feel they did really well.",
          "Golden Nugget: Students identify the 'golden nugget', the most valuable thing they've learned from the lesson.",
          "Guess Who: Students write down a description of a concept or character from the lesson and have others guess what it is.",
          "Hashtag Highlight: Students create a hashtag that summarises the main point or theme of the lesson.",
          "Headline News: Students create a headline for a newspaper article about the lesson.",
          "Highs and Lows: Students share their high point (what they found easy or enjoyed) and low point (what they found challenging) of the lesson.",
          "In Their Shoes: Students explain a concept from the lesson as if they were teaching it to a younger sibling or a grandparent.",
          "Inside the Crystal Ball: Students predict what the next lesson might involve based on what they learned today.",
          "Instagram Post: Students describe an 'Instagram post' about the lesson, including a description of the 'image' and a fitting caption.",
          "Invisible Friend: Students pretend they have to explain the lesson to an invisible friend who knows nothing about the topic.",
          "Invisible Ink: Students 'write' in the air using their finger, summarizing a key point from the lesson, and their peers guess what they're writing.",
          "Invisible Interview: Students pair up, one interviews the other about the lesson, then they swap roles. The catch is, the interviewer pretends to hold a mic and the interviewee responds as if they're an expert on a talk show.",
          "Jigsaw Puzzle: Students describe how different parts of the lesson fit together like a jigsaw puzzle to form the whole lesson.",
          "Journalist for a Day: Students imagine they're a journalist and write a headline for a news article that covers the lesson's main point.",
          "Key Character: In the context of the lesson, students describe a character they identified with and explain why.",
          "Knowledge Knapsack: Students discuss what 'items' (knowledge or skills) from the lesson they would pack in their 'knowledge knapsack' and why.",
          "Knowledge Tree: Students draw a tree, with the trunk representing the main idea of the lesson, the branches representing sub-topics, and leaves as specific details or facts.",
          "Lesson Anthem: Students come up with lyrics to a national anthem based on the lesson.",
          "Lesson App: Students design a mobile app that encapsulates the lesson. They describe its features and how it would be helpful.",
          "Lesson Billboard: Students design a billboard advertisement that highlights the main concept of the lesson.",
          "Lesson Billboard: Students design an imaginary billboard advertising the lesson's key points.",
          "Lesson Haiku: Students write a haiku poem (a three-line poem with seventeen syllables, written in a 5/7/5 syllable count) that encapsulates the main points of the lesson.",
          "Lesson in a Nutshell: Students pretend they're in an elevator with someone who knows nothing about the lesson, and they have to summarise it before the elevator reaches their floor.",
          "Lesson in a Tweet: Students formulate a tweet (short sentence) that encapsulates the main lesson.",
          "Lesson Journal: Students write a short journal entry about the lesson, focusing on what they learned and how they felt.",
          "Lesson Ladder: Students draw a ladder and write a different point they learned on each rung.",
          "Lesson Map: Students sketch a map that shows the path they took to learn during the lesson, highlighting key milestones along the way.",
          "Lesson MVP: Students nominate an 'MVP' (Most Valuable Point) from the lesson.",
          "Lesson Playlist: Students pick a song that they think fits with the theme of the lesson and explain why.",
          "Lesson Recipes: Students write a 'recipe' for the lesson, including 'ingredients' (the things they needed to understand the lesson) and 'method' (how they went about learning).",
          "Lesson Selfie: Students describe a 'selfie' picture they would take that captures their understanding or a memorable moment from the lesson.",
          "Lesson Soundtrack: Students think of a song that could be a soundtrack to the lesson and explain why.",
          "Lesson's Quote: Students come up with a quote that summarises the essence of the lesson.",
          "Letter to My Future Self: Students write a short letter to their future selves about what they learned and how it can be applied.",
          "Life Application: Students discuss how the knowledge or skills from the lesson could be applied in daily life.",
          "Living Statue: Students strike a pose that represents their feeling or understanding of the lesson.",
          "Lost at Sea: Students choose one aspect of the lesson they would want to have with them if they were lost at sea, and explain why.",
          "Magic Mic: Pass an imaginary microphone around the class. The student with the 'mic' shares something they learned.",
          "Memory Minute: Students close their eyes and have one minute to remember as much as they can from the lesson. Then they share with a partner or the class.",
          "Memory Mirror: Students 'reflect' on what they learned and share their reflections like they are looking into a mirror.",
          "Metaphor Magic: Students create a metaphor or simile that relates to the lesson.",
          "Mind Map: Students quickly draw a mind map of the main ideas or steps involved in the lesson.",
          "Mixtape: Students choose a song that would fit well on a mixtape for the lesson, and explain why.",
          "Musical Reflection: Students write lyrics for a song or a rap that summarises the main points of the lesson.",
          "Mystery Bag: Students imagine they have a mystery bag. They 'pull out' the most important idea from the lesson and describe it.",
          "Newscaster's Report: Students pretend they're newscasters delivering the main points of the lesson on a news program.",
          "One Change: Students identify one thing that they would change about the lesson if they were the teacher.",
          "One Sentence Summary: Students summarise the entire lesson in just one sentence.",
          "One Word Reflection: Students share one word that encapsulates their feelings or understanding of the lesson and explain why they chose it.",
          "One Word Summary: Students summarise the lesson in one word and explain their choice.",
          "Pass the Baton: Students write down a piece of advice for students who will learn this lesson in the future.",
          "Personal Dictionary: Students choose a new word or concept from the lesson and create their own dictionary definition.",
          "Personal Progress: Students reflect on how they have improved during the lesson and what they want to improve next time.",
          "Photo Caption: Students think of a photograph that could represent the lesson and then create a caption for it.",
          "Physical Charades: Students mime something they've learned, while others guess.","Picture Predictions: Based on what they learned today, students draw a prediction about what they might learn in the next lesson.",
          "Popcorn Share: One student shares a thought about the lesson, then 'pops' the share to another student by saying their name.",
          "Positivity Pebbles: Students think of a positive contribution they or a classmate made during the lesson.",
          "Postcard to a Friend: Students describe what they learned as if they're writing a postcard to a friend who was absent.",
          "Powerful Picture: Students draw a powerful picture that symbolises the main concept of the lesson.",
          "Pretend Podcast: Students imagine they're recording a podcast about the lesson and discuss the key points.","Pretend Text: Students write a pretend text message to a friend summarising the lesson.",
          "Problem Solver: Students discuss a problem they encountered during the lesson and how they solved it.",
          "Pros and Cons: Students make a pros and cons list for a specific idea or concept they learned during the lesson.",
          "Question Master: Students write down a question they have about the lesson and swap it with a peer to answer.",
          "Question Master: Students write down one thought-provoking question about the lesson and then pose it to the class.",
          "Quick Quiz: Students create one multiple choice question based on the lesson and exchange it with a partner.",
          "Reality Check: Students discuss how today's lesson applies to real-life situations.",
          "Role-Play Recap: In pairs, students role-play a conversation about what they've learned in the lesson.",
          "Sensory Reflection: Students reflect on what they saw, heard, touched, tasted, or smelled during the lesson.",
          "Silent Line Up: Students line up based on their understanding of the lesson, from high to low (without speaking), and share why they placed themselves there.",
          "Six Word Summary: Students must condense their understanding of the lesson into exactly six words.",
          "Skyscraper Summary: Students imagine their summary of the lesson is a floor in a skyscraper. They explain what level their understanding is on and why.",
          "Smiley Scale: Students draw a smiley face representing how they feel about their understanding of the lesson – a big smile means they understand fully, a straight line means they're not sure, and a frown means they're struggling.",
          "Snapshots: Students describe three 'snapshots' that would illustrate the main events of the lesson.",
          "Snowball: Students write down a key point from the lesson, crumple it up, and toss it in the middle of the room. Each student picks up a \"snowball\" and reads it aloud.",
          "Sports Commentary: Students narrate a highlight of the lesson as if they're sports commentators.",
          "Star Rating: Students give the lesson a 'star rating' out of five and explain their reasoning.",
          "Step Inside: Students 'step inside' a character or concept from the lesson and describe how they see the lesson from that perspective.",
          "Sticky Situation: Students describe a sticky note with a phrase or picture representing the lesson, then explain it to a partner or group.","Story Sequencer: Students sequence the key events or concepts of the lesson as if they are creating a story plot.",
          "Story String: One student starts a story string by stating a fact from the lesson. Each student then adds to the string, building on the story with another lesson fact.",
          "Superhero Symbol: Students imagine they are superheroes whose powers are based on what they learned in the lesson. They describe their superhero symbol and powers.",
          "T-Shirt Slogan: Students come up with a catchy slogan or phrase for a T-shirt that represents the main concept or topic of the lesson.","Take a Stand: Pose a debatable question related to the lesson. Students then 'take a stand' and justify their position.",
          "Take-Home Message: Students discuss what the 'take-home message' from the lesson is.",
          "Teaching the Air: Students pretend they're teachers, explaining the day's lesson to the classroom (which is empty except for their fellow students).",
          "Text to Self: Students discuss how the lesson reminded them of something in their own lives.","The 5 Ws: Students write down Who, What, When, Where, Why, and How about the lesson.",
          "The Crystal Ball: Students pretend to look into a crystal ball and make a prediction based on what they learned in the lesson.","The Director's Chair: Students imagine they are directing a movie about the lesson, describing the key scenes they would include.",
          "The Director's Cut: Students imagine they are directing a film about the lesson. They summarise what will be in the 'trailer'.",
          "The Elevator Pitch: Students craft a brief, persuasive speech selling the importance of what they learned.",
          "The Helicopter View: Students pretend they're looking down at the lesson from a helicopter and describe what they see, focusing on the 'big picture'.",
          "The Island: Students think of one thing from the lesson they would take to a deserted island and explain why.",
          "The Pitch: Students imagine they have to persuade someone of the importance of the lesson's topic in one minute or less.",
          "Three Bridges: Students identify three connections (or 'bridges') they can make between what they learned in the lesson and the world around them.",
          "Three Facts and a Fib: Students write down three true statements and one false statement about the lesson. Other students must identify the 'fib'.",
          "Time Capsule: Students describe an item related to the lesson that they'd put in a time capsule and explain why.",
          "Time Traveller: Students imagine they can go back in time to the start of the lesson, and share one piece of advice with their past self.",
          "Top Three List: Students list their top three takeaways from the lesson.","Top Three Takeaways: Students write down their top three takeaways from the lesson.",
          "Traffic Light: Students indicate their understanding using 'traffic light' colors: green (I've got it), yellow (I'm unsure), and red (I don't understand).",
          "Travel Review: Students write a 'travel review' about their journey through the lesson, highlighting key points of interest.",
          "Twitter Tweet: Students write a brief 'tweet' about what they learned from the lesson.",
          "Two Stars and a Wish: Ask students to identify two things they learned or did well in the lesson (two stars) and one area where they wish to improve (a wish).",
          "Unanswered Questions: Students share a question about the lesson that they still don't know the answer to.",
          "Venn Diagrams: Students draw a Venn diagram to compare and contrast two different elements or concepts from the lesson.",
          "Virtual Postcard: Students create a virtual postcard to send to a friend describing one thing they learned during the lesson.",
          "Virtual Tour: Students give a 'virtual tour' of the lesson as if they were a tour guide.",
          "Weather Report: Students describe the 'weather' of the lesson (sunny if they understood everything, cloudy if some parts were unclear, stormy if they found it tough).",
          "What if...?: Ask students to think of a \"what if\" scenario related to the lesson. What if we had no numbers in maths? What if the character in the story made a different decision?",
          "What Stuck With You?: Students share one thing from the lesson that stuck with them.","What's Your Tagline?: Students create a tagline or slogan that captures the essence of the lesson.",
          "What's in Your Backpack?: Students think about which 'tools' (skills) from the lesson they will take with them in their learning 'backpack'.",
          "What's Missing?: Students think of something that could have improved the lesson.",
          "Wisdom to Share: Students write down one piece of advice they would give to someone who is learning about this lesson's topic for the first time.",
          "Word Cloud: Students shout out words related to the lesson, and one student writes them on the board creating a word cloud.",
          "Knowledge Capsule: Students draw a capsule and fill it with key learnings from the lesson.",
          "Simile Summary: Students create a simile that describes the lesson (e.g., \"This lesson was like a puzzle because...\").",
          "Who Would You Teach?: Students choose a person they would like to teach the lesson to and explain why they chose that person.",
          "Mental Movie: Students close their eyes and create a 'mental movie' of the lesson, then share a scene from their movie.",
          "Lessons Learned Letter: Students write a letter to their future selves about what they learned and how it might be useful in the future.","If I Were the Teacher: Students explain a concept from the lesson as if they were the teacher.",
          "Metaphor Magic: Students come up with a metaphor that captures the essence of the lesson.","Key Moment: Students share a key moment from the lesson that stood out to them.",
          "In Other Words: Students rephrase the lesson's objective in their own words.","Emotional Check-In: Students share how they felt at the beginning, middle, and end of the lesson.",
          "Lesson Bookmark: Students create a bookmark that represents the lesson.","The Highlight Reel: Students describe three highlights from the lesson.",
          "What Would You Ask?: Students write down one question they would ask the teacher about the lesson if they had no fear of being judged.","Lesson as a Book: Students imagine the lesson is a book. They describe the cover and write a brief blurb.",
          "Key Takeaways: Students draw a key and label it with their main takeaways from the lesson.",
          "Lesson Review: Students write a brief review of the lesson as if it were a movie or book.",
          "Unforgettable Fact: Students share the most unforgettable fact or point from the lesson.",
          "Imagine This: Students use their imagination to apply the lesson to an imaginary scenario.",
          "Peer Teaching: In pairs, students take turns teaching each other something they learned during the lesson.",
          "Lesson Time Capsule: Students write down one thing they want to remember from this lesson in a year and why.",
          "Lesson's Motto: Students come up with a motto that encapsulates the essence of the lesson.",
          "Emoji Reaction: Students choose an emoji that represents their feelings about the lesson and explain why they chose it.",
          "Lesson Selfie: Students imagine they're taking a 'selfie' that represents the lesson, and describe what's in the photo.",
          "Lesson's Hashtag: Students create a hashtag that summarises the key takeaway from the lesson.",
          "Advice to a Friend: Students write down advice they'd give to a friend about the lesson.",
          "Step by Step: Students sequence the steps or key points of the lesson, explaining why they've put them in that order.",
          "Teach a Toy: Students pretend they have to teach a favourite toy what they learned during the lesson.",
          "Lesson's Symbol: Students draw a symbol that represents the lesson and explain why they chose it.",
          "KWL Chart: Students fill out a KWL (What I Know, What I Want to Know, What I Learned) chart about the lesson.",
          "Lesson's Superhero: Students invent a superhero character whose powers are related to the key skills or knowledge learned in the lesson.",
          "Brainstorm a Sequel: Students brainstorm what a 'sequel' to the lesson might involve.",
          "Traffic Light: Students use the 'traffic light' system to reflect on their learning - green for what they understood, yellow for what they're unsure about, and red for what they didn't understand.",
          "Lesson's Word Cloud: Students create a word cloud, listing words that come to mind when they think about the lesson.","Talk Show Host: Students pretend they're a talk show host discussing the main topics of the lesson.",
          "Lesson's Meme: Students describe a meme that represents the lesson.","Newly Coined Term: Students coin a new term based on what they learned and define it.","Visual Mind Map: Students draw a mind map of the main points from the lesson.",
          "Lesson in a Tweet: Students summarise the lesson in 280 characters or less.","Fast Forward: Students predict what they will learn in the next lesson based on what they learned today.",
          "Exit Slip: Before leaving class, students write down one thing they learned and one question they still have."
        ];
        
        // Update the random reflection activity selection to make the title bold
        const randomIndex = Math.floor(Math.random() * reflectionActivities.length);
        const selectedReflection = reflectionActivities[randomIndex];

        // Process the reflection activity to make the title bold
        // Find the first colon and make everything up to that point bold
        const colonIndex = selectedReflection.indexOf(':');
        let processedReflection = selectedReflection;
        if (colonIndex > 0) {
          const title = selectedReflection.substring(0, colonIndex + 1);
          const rest = selectedReflection.substring(colonIndex + 1);
          processedReflection = `<strong>${title}</strong>${rest}`;
        }

        // Update the temporary array
        newReflectionContents[originalIndex] = processedReflection;
      }
      
      // Set all states at once after the loop is complete
      setIDoContents(newIDoContents);
      setWeDoContents(newWeDoContents);
      setYouDoContents(newYouDoContents);
      setReflectionContents(newReflectionContents);
    } catch (error) {
      console.error('Error generating content:', error);
    }
  };

  // Update the renderContent function to handle HTML content
  const renderContent = (content) => {
    if (!content) return '';
    
    // Convert markdown-style bold text (between **) to HTML bold tags
    let formattedContent = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Convert dashes at the start of lines to bullet points
    formattedContent = formattedContent.replace(/^- /gm, '• ');
    
    // Make "Enable:", "At:", and "Extend:" bold with case-insensitive matching
    formattedContent = formattedContent.replace(/\b(enable:)/gi, '<strong>$1</strong>');
    formattedContent = formattedContent.replace(/\b(at:)/gi, '<strong>$1</strong>');
    formattedContent = formattedContent.replace(/\b(extend:)/gi, '<strong>$1</strong>');
    
    // Convert newlines to <br> tags
    formattedContent = formattedContent.replace(/\n/g, '<br>');
    
    return formattedContent;
  };

  const isSelected = (row, col) => {
    if (!selectionStart || !selectionEnd) return false;

    const startRow = Math.min(selectionStart.row, selectionEnd.row);
    const endRow = Math.max(selectionStart.row, selectionEnd.row);
    const startCol = Math.min(selectionStart.col, selectionEnd.col);
    const endCol = Math.max(selectionStart.col, selectionEnd.col);

    return row >= startRow && row <= endRow && col >= startCol && col <= endCol;
  };

  const getSelectedCells = () => {
    if (!selectionStart || !selectionEnd) return null;

    const startRow = Math.min(selectionStart.row, selectionEnd.row);
    const endRow = Math.max(selectionStart.row, selectionEnd.row);
    const startCol = Math.min(selectionStart.col, selectionEnd.col);
    const endCol = Math.max(selectionStart.col, selectionEnd.col);

    // Create a 2D array of the selected cells
    const selectedData = [];
    
    if (isFlipped) {
      // In flipped mode, we need to create rows based on the lesson components
      // First row is headers (if needed)
      const headerRow = [''];
      for (let col = startCol; col <= endCol; col++) {
        headerRow.push(`Lesson ${col + 1}`);
      }
      
      // Only add header row if we're selecting multiple columns
      if (endCol - startCol > 0) {
        selectedData.push(headerRow);
      }
      
      // Now add component rows
      const componentLabels = ['I Do', 'We Do', 'You Do', 'Reflection'];
      for (let row = startRow; row <= endRow; row++) {
        const rowData = [componentLabels[row]]; // Start with component label
        
        for (let col = startCol; col <= endCol; col++) {
          // Get content based on row (which is the component type) and column (which is the lesson number)
          let cellContent = '';
          switch (row) {
            case 0: cellContent = formatForClipboard(iDoContents[col]) || ''; break;
            case 1: cellContent = formatForClipboard(weDoContents[col]) || ''; break;
            case 2: cellContent = formatForClipboard(youDoContents[col]) || ''; break;
            case 3: cellContent = formatForClipboard(reflectionContents[col]) || ''; break;
            default: cellContent = '';
          }
          rowData.push(cellContent);
        }
        selectedData.push(rowData);
      }
    } else {
      // Original mode - rows are lessons, columns are components
      for (let row = startRow; row <= endRow; row++) {
        const rowData = [];
        for (let col = startCol; col <= endCol; col++) {
          // Get content based on column, use placeholder if empty
          let cellContent = '';
          switch (col) {
            case 0: cellContent = formatForClipboard(iDoContents[row]) || ''; break;
            case 1: cellContent = formatForClipboard(weDoContents[row]) || ''; break;
            case 2: cellContent = formatForClipboard(youDoContents[row]) || ''; break;
            case 3: cellContent = formatForClipboard(reflectionContents[row]) || ''; break;
            default: cellContent = '';
          }
          rowData.push(cellContent);
        }
        selectedData.push(rowData);
      }
    }
    
    return selectedData;
  };

  // Update the formatForClipboard function to use the same formatting logic
  const formatForClipboard = (content) => {
    if (!content) return '';
    
    // Use the same formatting function for consistency
    let formattedContent = ensureProperFormatting(content);
    
    // Convert HTML to markdown for clipboard
    formattedContent = formattedContent
      .replace(/<strong>(.*?)<\/strong>/g, '**$1**')
      .replace(/•/g, '- ')
      .replace(/<br>/g, '\n');
    
    return formattedContent;
  };

  const handleCopy = async () => {
    const selectedData = getSelectedCells();
    let data;
    
    if (!selectedData) {
      // If nothing is selected, copy all cells
      if (isFlipped) {
        // In flipped mode, create a table with components as rows and lessons as columns
        data = [
          ['I Do'].concat(Array(20).fill().map((_, i) => formatForClipboard(iDoContents[i]) || '')),
          ['We Do'].concat(Array(20).fill().map((_, i) => formatForClipboard(weDoContents[i]) || '')),
          ['You Do'].concat(Array(20).fill().map((_, i) => formatForClipboard(youDoContents[i]) || '')),
          ['Reflection'].concat(Array(20).fill().map((_, i) => formatForClipboard(reflectionContents[i]) || ''))
        ];
      } else {
        // Original mode
        data = Array(20).fill().map((_, row) => [
          iDoContents[row] || `Step ${row + 1}`,
          weDoContents[row] || `Step ${row + 1}`,
          youDoContents[row] || `Step ${row + 1}`,
          reflectionContents[row] || `Step ${row + 1}`
        ]);
      }
    } else {
      data = selectedData;
    }

    const plainText = data.map(row => 
      row.map(cell => cell.replace(/\n/g, ' ')).join('\t')
    ).join('\n');

    const htmlContent = data.map(row => 
      row.map(cell => {
        const htmlCell = cell
          .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
          .replace(/^- /gm, '• ')
          .replace(/\n/g, '<br>');
        return `<td style="vertical-align: top; white-space: pre-wrap; padding: 2px; border: 1px solid black;">${htmlCell}</td>`;
      }).join('')
    ).map(row => `<tr>${row}</tr>`).join('');

    const htmlDocument = `
      <html>
        <body>
          <table style="border-collapse: collapse;">${htmlContent}</table>
        </body>
      </html>
    `;

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([plainText], { type: 'text/plain' }),
          'text/html': new Blob([htmlDocument], { type: 'text/html' })
        })
      ]);
    } catch (err) {
      await navigator.clipboard.writeText(plainText);
      console.error('Failed to copy with formatting:', err);
    }
  };

  // Add this debug function to help verify the selection
  const logSelection = () => {
    if (selectionStart && selectionEnd) {
      console.log('Selection:', {
        start: selectionStart,
        end: selectionEnd,
        rows: Math.abs(selectionEnd.row - selectionStart.row) + 1,
        columns: Math.abs(selectionEnd.col - selectionStart.col) + 1
      });
    }
  };

  // Update the mouseup handler to include logging
  useEffect(() => {
    const handleMouseUp = () => {
      if (isSelecting) {
        setIsSelecting(false);
        logSelection(); // Add logging
        handleCopy();
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, [isSelecting]);

  // Add new function to handle flipping the table
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  // Add new function to handle clearing all content
  const handleClearAll = () => {
    setCellContents(Array(20).fill(''));
    setIDoContents(Array(20).fill(''));
    setWeDoContents(Array(20).fill(''));
    setYouDoContents(Array(20).fill(''));
    setReflectionContents(Array(20).fill(''));
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-white">
      {/* Header Section with SVG Banner and Date/Time */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4 shadow-md">
        {/* Title */}
        <h1 className="text-3xl font-bold text-center">Lesson Creator</h1>
        {/* Current Date and Time */}
        <div className="mt-2 text-xs font-bold text-gray-600">
          {new Date().toLocaleString()}
        </div>
      </div>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ height: '96px', width: '100%', objectFit: 'cover' }}
        />
      </div>

      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute text-3xl text-gray-500 top-4 right-4 hover:text-gray-700"
        aria-label="Close Workbook"
      >
        <FaTimes />
      </button>

      {/* Main Content Area */}
      <div className="flex-grow p-6 mt-4 overflow-auto bg-gray-100">
        {/* Add a centering container */}
        <div className="flex justify-center">
          <div className="flex items-start gap-4 max-w-[1400px]">
            {/* First Column */}
            <div className="max-w-md bg-white rounded-lg shadow">
              <div className="p-4">
                <div className="h-[72px]"> {/* Match height of right column headers */}
                  <h2 className="text-xl font-bold text-center">Paste LI and SC</h2>
                </div>
                <div className="space-y-1"> {/* Changed from space-y-2 to space-y-1 to match table */}
                  {cellContents.map((content, index) => (
                    <div key={index} className="flex items-center h-8"> {/* Added h-8 to match table cell height */}
                      <div className="w-8 mr-2 text-sm text-gray-500">{index + 1}</div>
                      <input
                        type="text"
                        value={content}
                        onChange={(e) => handleCellChange(index, e.target.value)}
                        onFocus={() => setActiveInputIndex(index)}
                        onPaste={(e) => handleBulkPaste(e)}
                        className="w-full h-8 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder={`Cell ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Generate Lessons Button */}
            <div className="flex flex-col gap-2">
              {/* Clear Button - new */}
              <button
                onClick={handleClearAll}
                className="flex items-center justify-center h-10 gap-2 px-4 text-white transition-colors duration-200 bg-red-500 rounded-full shadow-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                title="Clear All Content"
              >
                <svg 
                  className="w-4 h-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                  />
                </svg>
                <span className="text-sm font-medium">Clear All</span>
              </button>
              
              {/* Generate Button - existing */}
              <button
                onClick={handleGenerate}
                className="flex items-center justify-center h-10 gap-2 px-4 text-white transition-colors duration-200 bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                title="Generate Lessons"
              >
                <svg 
                  className="w-4 h-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
                <span className="text-sm font-medium">Generate</span>
              </button>
            </div>

            {/* Second Column with table */}
            <div className="flex gap-2">
              {/* Table container */}
              <div className="flex-1 bg-white rounded-lg shadow" style={{ maxWidth: '800px' }}>
                <div className="p-4">
                  <div className="h-[72px]">
                    <h2 className="text-xl font-bold text-center">Lesson Components</h2>
                  </div>
                  
                  {isFlipped ? (
                    // Flipped table layout with fixed cell sizes
                    <div className="overflow-x-auto">
                      <table className="w-full table-fixed">
                        {/* Add table header */}
                        <thead>
                          <tr>
                            <th className="w-24"></th> {/* Empty cell for row headers */}
                            {Array(20).fill('').map((_, index) => (
                              <th key={index} className="w-[120px] p-1 text-center font-medium text-gray-700">
                                Lesson {index + 1}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {/* I Do Row */}
                          <tr className="border-b">
                            <td className="w-24 py-2 pr-4 font-semibold text-blue-600">I Do</td>
                            {Array(20).fill('').map((_, index) => (
                              <td key={index} className="w-[120px] p-1 max-w-[120px]">
                                <div
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                    setIsSelecting(true);
                                    setSelectionStart({ row: 0, col: index });
                                    setSelectionEnd({ row: 0, col: index });
                                  }}
                                  onMouseEnter={() => {
                                    if (isSelecting) {
                                      setSelectionEnd({ row: 0, col: index });
                                    }
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: iDoContents[index] ? 
                                      renderContent(iDoContents[index]) : 
                                      '<span class="text-gray-400">-</span>'
                                  }}
                                  className={`w-full px-2 py-1 text-xs border border-gray-300 rounded h-8 overflow-hidden whitespace-nowrap text-ellipsis cursor-default max-w-full block ${isSelected(0, index) ? 'bg-blue-100' : ''}`}
                                  style={{ width: '120px', height: '32px' }} // Match input height
                                />
                              </td>
                            ))}
                          </tr>
                          
                          {/* We Do Row */}
                          <tr className="border-b">
                            <td className="w-24 py-2 pr-4 font-semibold text-green-600">We Do</td>
                            {Array(20).fill('').map((_, index) => (
                              <td key={index} className="w-[120px] p-1 max-w-[120px]">
                                <div
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                    setIsSelecting(true);
                                    setSelectionStart({ row: 1, col: index });
                                    setSelectionEnd({ row: 1, col: index });
                                  }}
                                  onMouseEnter={() => {
                                    if (isSelecting) {
                                      setSelectionEnd({ row: 1, col: index });
                                    }
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: weDoContents[index] ? 
                                      renderContent(weDoContents[index]) : 
                                      '<span class="text-gray-400">-</span>'
                                  }}
                                  className={`w-full px-2 py-1 text-xs border border-gray-300 rounded h-8 overflow-hidden whitespace-nowrap text-ellipsis cursor-default max-w-full block ${isSelected(1, index) ? 'bg-green-100' : ''}`}
                                  style={{ width: '120px', height: '32px' }} // Match input height
                                />
                              </td>
                            ))}
                          </tr>

                          {/* You Do Row */}
                          <tr className="border-b">
                            <td className="w-24 py-2 pr-4 font-semibold text-purple-600">You Do</td>
                            {Array(20).fill('').map((_, index) => (
                              <td key={index} className="w-[120px] p-1 max-w-[120px]">
                                <div
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                    setIsSelecting(true);
                                    setSelectionStart({ row: 2, col: index });
                                    setSelectionEnd({ row: 2, col: index });
                                  }}
                                  onMouseEnter={() => {
                                    if (isSelecting) {
                                      setSelectionEnd({ row: 2, col: index });
                                    }
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: youDoContents[index] ? 
                                      renderContent(youDoContents[index]) : 
                                      '<span class="text-gray-400">-</span>'
                                  }}
                                  className={`w-full px-2 py-1 text-xs border border-gray-300 rounded h-8 overflow-hidden whitespace-nowrap text-ellipsis cursor-default max-w-full block ${isSelected(2, index) ? 'bg-purple-100' : ''}`}
                                  style={{ width: '120px', height: '32px' }} // Match input height
                                />
                              </td>
                            ))}
                          </tr>

                          {/* Reflection Row */}
                          <tr>
                            <td className="w-24 py-2 pr-4 font-semibold text-orange-600">Reflection</td>
                            {Array(20).fill('').map((_, index) => (
                              <td key={index} className="w-[120px] p-1 max-w-[120px]">
                                <div
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                    setIsSelecting(true);
                                    setSelectionStart({ row: 3, col: index });
                                    setSelectionEnd({ row: 3, col: index });
                                  }}
                                  onMouseEnter={() => {
                                    if (isSelecting) {
                                      setSelectionEnd({ row: 3, col: index });
                                    }
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: reflectionContents[index] ? 
                                      renderContent(reflectionContents[index]) : 
                                      '<span class="text-gray-400">-</span>'
                                  }}
                                  className={`w-full px-2 py-1 text-xs border border-gray-300 rounded h-8 overflow-hidden whitespace-nowrap text-ellipsis cursor-default max-w-full block ${isSelected(3, index) ? 'bg-orange-100' : ''}`}
                                  style={{ width: '120px', height: '32px' }} // Match input height
                                />
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    // Original table layout - keep existing code
                    <div className="grid grid-cols-4 gap-2">
                      {/* I Do Column */}
                      <div className="space-y-1">
                        {Array(20).fill('').map((_, rowIndex) => (
                          <div
                            key={`ido-${rowIndex}`}
                            dangerouslySetInnerHTML={{ 
                              __html: iDoContents[rowIndex] ? 
                                renderContent(iDoContents[rowIndex]) : 
                                '<span class="text-gray-400">I Do</span>' 
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              setIsSelecting(true);
                              setSelectionStart({ row: rowIndex, col: 0 });
                              setSelectionEnd({ row: rowIndex, col: 0 });
                            }}
                            onMouseEnter={() => {
                              if (isSelecting) {
                                setSelectionEnd({ row: rowIndex, col: 0 });
                              }
                            }}
                            className={`w-full h-8 px-2 py-1 text-xs border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500 cursor-default overflow-hidden whitespace-nowrap text-ellipsis ${isSelected(rowIndex, 0) ? 'bg-blue-100' : ''}`}
                          />
                        ))}
                      </div>

                      {/* We Do Column */}
                      <div className="space-y-1">
                        {Array(20).fill('').map((_, rowIndex) => (
                          <div
                            key={`wedo-${rowIndex}`}
                            dangerouslySetInnerHTML={{ 
                              __html: weDoContents[rowIndex] ? 
                                renderContent(weDoContents[rowIndex]) : 
                                '<span class="text-gray-400">We Do</span>' 
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              setIsSelecting(true);
                              setSelectionStart({ row: rowIndex, col: 1 });
                              setSelectionEnd({ row: rowIndex, col: 1 });
                            }}
                            onMouseEnter={() => {
                              if (isSelecting) {
                                setSelectionEnd({ row: rowIndex, col: 1 });
                              }
                            }}
                            className={`w-full h-8 px-2 py-1 text-xs border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-green-500 cursor-default overflow-hidden whitespace-nowrap text-ellipsis ${isSelected(rowIndex, 1) ? 'bg-green-100' : ''}`}
                          />
                        ))}
                      </div>

                      {/* You Do Column */}
                      <div className="space-y-1">
                        {Array(20).fill('').map((_, rowIndex) => (
                          <div
                            key={`youdo-${rowIndex}`}
                            dangerouslySetInnerHTML={{ 
                              __html: youDoContents[rowIndex] ? 
                                renderContent(youDoContents[rowIndex]) : 
                                '<span class="text-gray-400">You Do</span>' 
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              setIsSelecting(true);
                              setSelectionStart({ row: rowIndex, col: 2 });
                              setSelectionEnd({ row: rowIndex, col: 2 });
                            }}
                            onMouseEnter={() => {
                              if (isSelecting) {
                                setSelectionEnd({ row: rowIndex, col: 2 });
                              }
                            }}
                            className={`w-full h-8 px-2 py-1 text-xs border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-purple-500 cursor-default overflow-hidden whitespace-nowrap text-ellipsis ${isSelected(rowIndex, 2) ? 'bg-purple-100' : ''}`}
                          />
                        ))}
                      </div>

                      {/* Reflection Column - Update to use div with dangerouslySetInnerHTML instead of input */}
                      <div className="space-y-1">
                        {Array(20).fill('').map((_, rowIndex) => (
                          <div
                            key={`reflection-${rowIndex}`}
                            dangerouslySetInnerHTML={{ 
                              __html: reflectionContents[rowIndex] ? 
                                renderContent(reflectionContents[rowIndex]) : 
                                '<span class="text-gray-400">Reflection</span>' 
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              setIsSelecting(true);
                              setSelectionStart({ row: rowIndex, col: 3 });
                              setSelectionEnd({ row: rowIndex, col: 3 });
                            }}
                            onMouseEnter={() => {
                              if (isSelecting) {
                                setSelectionEnd({ row: rowIndex, col: 3 });
                              }
                            }}
                            className={`w-full h-8 px-2 py-1 text-xs border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-orange-500 cursor-default overflow-hidden whitespace-nowrap text-ellipsis ${isSelected(rowIndex, 3) ? 'bg-orange-100' : ''}`}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Buttons Column */}
              <div className="flex flex-col gap-2">
                {/* Copy Button - existing code */}
                <button
                  onClick={handleCopy}
                  className="flex items-center justify-center h-10 gap-2 px-4 text-white transition-colors duration-200 bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  title="Copy Selected Cells"
                >
                  <svg 
                    className="w-4 h-4" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                    />
                  </svg>
                  <span className="text-sm font-medium">Copy</span>
                </button>

                {/* Flip Button */}
                <button
                  onClick={handleFlip}
                  className="flex items-center justify-center h-10 gap-2 px-4 text-white transition-colors duration-200 bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  title="Flip Table Layout"
                >
                  <svg 
                    className="w-4 h-4" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                    />
                  </svg>
                  <span className="text-sm font-medium">Flip</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bee Modal */}
      {selectedBee && <BeeModal bee={selectedBee} onClose={() => setSelectedBee(null)} />}
    </div>
  );
};

// PropTypes for validation
SlideComponents.propTypes = {
  onClose: PropTypes.func.isRequired,
  classID: PropTypes.string.isRequired,
};

// Create a wrapper component that uses Layout
const Planning = () => {
  // You can add any state or logic needed for the Planning page here
  const handleClose = () => {
    // Handle close action - perhaps navigate back
    window.history.back();
  };

  return (
    <Layout>
      <SlideComponents onClose={handleClose} classID="demo-class-id" />
    </Layout>
  );
};

export default Planning;