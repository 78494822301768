import React, { useRef, useContext, useMemo, useCallback, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { SunContext, sharedGeometries } from './Overlay1-Viewer';
import { SunContext2 } from './Overlay2-TV';

// Add this function at the top level, before the ModifiedRectWithEdges component
const createRoundedRectGeometry = (width, height, radius) => {
  // Use Three.js built-in shape with rounded corners
  const shape = new THREE.Shape();
  
  const actualWidth = Math.abs(width);
  const actualHeight = Math.abs(height);
  const actualRadius = Math.min(radius, Math.min(actualWidth, actualHeight) / 2);
  
  shape.moveTo(-actualWidth/2 + actualRadius, -actualHeight/2);
  shape.lineTo(actualWidth/2 - actualRadius, -actualHeight/2);
  shape.quadraticCurveTo(actualWidth/2, -actualHeight/2, actualWidth/2, -actualHeight/2 + actualRadius);
  shape.lineTo(actualWidth/2, actualHeight/2 - actualRadius);
  shape.quadraticCurveTo(actualWidth/2, actualHeight/2, actualWidth/2 - actualRadius, actualHeight/2);
  shape.lineTo(-actualWidth/2 + actualRadius, actualHeight/2);
  shape.quadraticCurveTo(-actualWidth/2, actualHeight/2, -actualWidth/2, actualHeight/2 - actualRadius);
  shape.lineTo(-actualWidth/2, -actualHeight/2 + actualRadius);
  shape.quadraticCurveTo(-actualWidth/2, -actualHeight/2, -actualWidth/2 + actualRadius, -actualHeight/2);
  
  return new THREE.ShapeGeometry(shape);
};

// Add this HSL to RGB conversion function
const hslToRgb = (h, s, l) => {
  h /= 360;
  s /= 100;
  l /= 100;
  
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return [
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255)
  ];
};

// New component for rectangles with edge lines and instanced cap meshes
const ModifiedRectWithEdges = ({ 
  position, 
  scale, 
  color, 
  opacity, 
  renderOrder,
  contextType = "overlay1" // Default to overlay1
}) => {
  const rectRef = useRef();
  const lineGroupRef = useRef();
  
  // Choose the appropriate context based on contextType
  const context1 = useContext(SunContext);
  const context2 = useContext(SunContext2);
  const { sunContext } = contextType === "overlay2" ? context2 : context1;
  
  // References for instanced cap meshes
  const lightCapsRef = useRef();
  const darkCapsRef = useRef();
  
  // Store dummy objects for matrix updates
  const dummyObj = useRef(new THREE.Object3D());
  
  // Track visibility states for each instance (8 pairs of caps)
  const capsVisibility = useRef({
    topLight: { start: false, end: false },
    topDark: { start: false, end: false },
    bottomLight: { start: false, end: false },
    bottomDark: { start: false, end: false },
    rightLight: { start: false, end: false },
    rightDark: { start: false, end: false },
    leftLight: { start: false, end: false },
    leftDark: { start: false, end: false }
  });
  
  // Pre-allocate buffers and geometries for reuse
  const geometriesRef = useRef({
    initialized: false,
    topLight: null,
    topDark: null,
    bottomLight: null,
    bottomDark: null,
    rightLight: null,
    rightDark: null,
    leftLight: null,
    leftDark: null
  });
  
  // Add the missing lastSunPos ref
  const lastSunPos = useRef({ x: 0, y: 0 });
  
  // Use a throttled update to reduce frame rate impact
  const lastUpdateTime = useRef(0);
  const updateThreshold = 16; // ms (roughly 60fps)
  
  // Calculate light and shadow colors from base color
  const colors = useMemo(() => {
    let r, g, b;

    // Check for hex values
    if (typeof color === 'string' && color.startsWith('#')) {
      // If shorthand hex like "#555", expand it to "#555555"
      if (color.length === 4) {
        r = parseInt(color[1] + color[1], 16);
        g = parseInt(color[2] + color[2], 16);
        b = parseInt(color[3] + color[3], 16);
      } else {
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
      }
    } else if (typeof color === 'string' && color.startsWith('rgb')) {
      // Extract the number values from an rgb string like "rgb(85, 85, 85)"
      const result = color.match(/rgb\s*\(\s*(\d+)[,\s]+(\d+)[,\s]+(\d+)\s*\)/);
      if (result) {
        r = parseInt(result[1], 10);
        g = parseInt(result[2], 10);
        b = parseInt(result[3], 10);
      }
    } else if (typeof color === 'string' && color.startsWith('hsl')) {
      // Parse HSL color values
      const hslRegex = /hsl\s*\(\s*(\d+)[,\s]+(\d+)%[,\s]+(\d+)%\s*\)/;
      const match = color.match(hslRegex);
      
      if (match) {
        const h = parseInt(match[1], 10);
        const s = parseInt(match[2], 10);
        const l = parseInt(match[3], 10);
        
        // Convert HSL to RGB
        const rgb = hslToRgb(h, s, l);
        r = rgb[0];
        g = rgb[1];
        b = rgb[2];
      }
    }

    // If no valid values were parsed, default to white
    if (r === undefined || g === undefined || b === undefined) {
      r = g = b = 255;
    }

    // Define factors for lightening and darkening (20% change in brightness)
    const lightenFactor = 0.2;
    const darkenFactor = 0.2;

    // Calculate new colors (clamped between 0 and 255)
    const lightR = Math.min(255, Math.floor(r * (1 + lightenFactor)));
    const lightG = Math.min(255, Math.floor(g * (1 + lightenFactor)));
    const lightB = Math.min(255, Math.floor(b * (1 + lightenFactor)));

    const darkR = Math.max(0, Math.floor(r * (1 - darkenFactor)));
    const darkG = Math.max(0, Math.floor(g * (1 - darkenFactor)));
    const darkB = Math.max(0, Math.floor(b * (1 - darkenFactor)));

    return {
      light: new THREE.Color(`rgb(${lightR}, ${lightG}, ${lightB})`),
      dark: new THREE.Color(`rgb(${darkR}, ${darkG}, ${darkB})`)
    };
  }, [color]);
  
  // Set up the geometry and materials
  useEffect(() => {
    if (!lineGroupRef.current) return;
    
    // Clear existing children if any
    while (lineGroupRef.current.children.length > 0) {
      const child = lineGroupRef.current.children[0];
      if (child.geometry) child.geometry.dispose();
      if (child.material) child.material.dispose();
      lineGroupRef.current.remove(child);
    }
    
    // Enable stencil buffer for this scene
    const renderer = THREE.WebGLRenderer.instance;
    if (renderer) {
      renderer.state.buffers.stencil.setTest(true);
    }
    
    // Create light and dark materials with clipping enabled
    const lightMaterial = new THREE.MeshBasicMaterial({
      color: colors.light,
      transparent: true,
      opacity: opacity,
      side: THREE.DoubleSide,
      depthTest: false,
      depthWrite: false,
      clippingPlanes: [], // Will be set from userData
      clipIntersection: false
    });
    
    const darkMaterial = new THREE.MeshBasicMaterial({
      color: colors.dark,
      transparent: true,
      opacity: opacity,
      side: THREE.DoubleSide,
      depthTest: false,
      depthWrite: false,
      clippingPlanes: [], // Will be set from userData
      clipIntersection: false
    });
    
    // Create reusable buffer geometries
    if (!geometriesRef.current.initialized) {
      // Create reusable geometries with pre-allocated buffers
      const createGeometry = () => {
        const geometry = new THREE.BufferGeometry();
        // Pre-allocate buffers for 4 vertices (2 triangles)
        geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(12), 3));
        geometry.setIndex([0, 1, 2, 2, 1, 3]);
        return geometry;
      };
      
      geometriesRef.current.topLight = createGeometry();
      geometriesRef.current.topDark = createGeometry();
      geometriesRef.current.bottomLight = createGeometry();
      geometriesRef.current.bottomDark = createGeometry();
      geometriesRef.current.rightLight = createGeometry();
      geometriesRef.current.rightDark = createGeometry();
      geometriesRef.current.leftLight = createGeometry();
      geometriesRef.current.leftDark = createGeometry();
      geometriesRef.current.initialized = true;
    }
    
    // Create eight line meshes (light and dark versions for each edge)
    const topLightEdge = new THREE.Mesh(geometriesRef.current.topLight, lightMaterial.clone());
    const rightLightEdge = new THREE.Mesh(geometriesRef.current.rightLight, lightMaterial.clone());
    const bottomLightEdge = new THREE.Mesh(geometriesRef.current.bottomLight, lightMaterial.clone());
    const leftLightEdge = new THREE.Mesh(geometriesRef.current.leftLight, lightMaterial.clone());
    
    const topDarkEdge = new THREE.Mesh(geometriesRef.current.topDark, lightMaterial.clone());
    const rightDarkEdge = new THREE.Mesh(geometriesRef.current.rightDark, darkMaterial.clone());
    const bottomDarkEdge = new THREE.Mesh(geometriesRef.current.bottomDark, darkMaterial.clone());
    const leftDarkEdge = new THREE.Mesh(geometriesRef.current.leftDark, darkMaterial.clone());
    
    // Set render order
    [topLightEdge, rightLightEdge, bottomLightEdge, leftLightEdge,
     topDarkEdge, rightDarkEdge, bottomDarkEdge, leftDarkEdge].forEach(edge => {
      edge.renderOrder = renderOrder;
      // Explicitly disable frustum culling for all edge objects
      edge.frustumCulled = false;
    });
    
    // Add to group
    lineGroupRef.current.add(topLightEdge);
    lineGroupRef.current.add(rightLightEdge);
    lineGroupRef.current.add(bottomLightEdge);
    lineGroupRef.current.add(leftLightEdge);
    lineGroupRef.current.add(topDarkEdge);
    lineGroupRef.current.add(rightDarkEdge);
    lineGroupRef.current.add(bottomDarkEdge);
    lineGroupRef.current.add(leftDarkEdge);
    
    // Create instanced meshes for caps - 16 light caps and 16 dark caps
    // We need one instance for each start/end of each edge type
    const lightCaps = new THREE.InstancedMesh(
      sharedGeometries.circleGeometry,
      lightMaterial.clone(),
      16
    );
    lightCaps.renderOrder = renderOrder;
    lightCaps.count = 16;
    // Explicitly disable frustum culling for light caps
    lightCaps.frustumCulled = false;
    lineGroupRef.current.add(lightCaps);
    lightCapsRef.current = lightCaps;
    
    // Ensure stencil settings are properly configured
    lightCaps.material.stencilWrite = false;
    lightCaps.material.stencilFunc = THREE.EqualStencilFunc;
    lightCaps.material.stencilRef = 1;
    
    const darkCaps = new THREE.InstancedMesh(
      sharedGeometries.circleGeometry,
      darkMaterial.clone(),
      16
    );
    darkCaps.renderOrder = renderOrder;
    darkCaps.count = 16;
    // Explicitly disable frustum culling for dark caps
    darkCaps.frustumCulled = false;
    lineGroupRef.current.add(darkCaps);
    darkCapsRef.current = darkCaps;
    
    // Ensure stencil settings are properly configured
    darkCaps.material.stencilWrite = false;
    darkCaps.material.stencilFunc = THREE.EqualStencilFunc;
    darkCaps.material.stencilRef = 1;
    
    // Initially hide all caps by scaling to 0
    for (let i = 0; i < 16; i++) {
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      lightCaps.setMatrixAt(i, dummyObj.current.matrix);
      darkCaps.setMatrixAt(i, dummyObj.current.matrix);
    }
    lightCaps.instanceMatrix.needsUpdate = true;
    darkCaps.instanceMatrix.needsUpdate = true;
    
    // Clean up function
    return () => {
      // Dispose of all materials
      lightMaterial.dispose();
      darkMaterial.dispose();
    };
  }, [scale, colors, opacity, renderOrder]);
  
  // Modify the useEffect where the rectangle geometry is created
  useEffect(() => {
    if (!rectRef.current) return;
    
    // Calculate dimensions and radius
    const minDimension = Math.min(Math.abs(scale[0]), Math.abs(scale[1]));
    const cornerRadius = minDimension * 0.07; // 15% of minimum dimension
    
    // Create geometry with actual dimensions
    const roundedRectGeometry = createRoundedRectGeometry(scale[0], scale[1], cornerRadius);
    
    // Update main rectangle geometry
    if (rectRef.current.geometry) rectRef.current.geometry.dispose();
    rectRef.current.geometry = roundedRectGeometry;
    
    // Important: Set scale to 1 since we're using actual dimensions
    rectRef.current.scale.set(1, 1, 1);
    
    // Create stencil mesh
    const stencilMaterial = new THREE.MeshBasicMaterial({
      colorWrite: false,
      depthWrite: false,
      depthTest: false,
      transparent: true,
      opacity: 0,
      stencilWrite: true,
      stencilRef: 1,
      stencilFunc: THREE.AlwaysStencilFunc,
      stencilZPass: THREE.ReplaceStencilOp,
      stencilZFail: THREE.KeepStencilOp,
      stencilFail: THREE.KeepStencilOp
    });
    
    const stencilMesh = new THREE.Mesh(
      roundedRectGeometry,
      stencilMaterial
    );
    
    // Important: Set scale to 1 since we're using actual dimensions
    stencilMesh.scale.set(1, 1, 1);
    stencilMesh.renderOrder = renderOrder - 1;
    
    // Add to scene at the same position as our rectangle
    rectRef.current.parent.add(stencilMesh);
    
    // Configure all caps to only render where stencil value matches the reference
    if (lightCapsRef.current && darkCapsRef.current) {
      [lightCapsRef.current.material, darkCapsRef.current.material].forEach(material => {
        material.stencilWrite = false;      // Don't write to stencil buffer
        material.stencilRef = 1;            // Reference value to test against
        material.stencilFunc = THREE.EqualStencilFunc; // Only pass where stencil equals ref value
        material.stencilFail = THREE.ZeroStencilOp;    // If stencil test fails, do nothing
        material.stencilZFail = THREE.ZeroStencilOp;   // If depth test fails, do nothing
        material.stencilZPass = THREE.KeepStencilOp;   // If both pass, keep stencil value
      });
    }
    
    // Make sure edge lines also respect the stencil
    for (let i = 0; i < 8; i++) {
      if (lineGroupRef.current?.children[i]?.material) {
        const material = lineGroupRef.current.children[i].material;
        material.stencilWrite = false;      // Don't write to stencil buffer
        material.stencilRef = 1;            // Reference value to test against
        material.stencilFunc = THREE.EqualStencilFunc; // Only pass where stencil equals ref value
        material.stencilFail = THREE.ZeroStencilOp;    // If stencil test fails, do nothing
        material.stencilZFail = THREE.ZeroStencilOp;   // If depth test fails, do nothing
        material.stencilZPass = THREE.KeepStencilOp;   // If both pass, keep stencil value
      }
    }
    
    return () => {
      if (stencilMesh.parent) {
        stencilMesh.parent.remove(stencilMesh);
      }
      stencilMaterial.dispose();
      roundedRectGeometry.dispose();
    };
  }, [scale, renderOrder]);
  
  // Also disable frustum culling on the main rectangle and line group
  useEffect(() => {
    if (rectRef.current) {
      rectRef.current.frustumCulled = false;
    }
    
    if (lineGroupRef.current) {
      lineGroupRef.current.frustumCulled = false;
      
      // Also disable on the group itself to ensure child inheritance
      lineGroupRef.current.traverse(child => {
        if (child.frustumCulled !== undefined) {
          child.frustumCulled = false;
        }
      });
    }
  }, []);
  
  // Memoize the edge angles calculation to avoid recalculating when not needed
  const calculateEdgeAngles = useCallback((sunPos) => {
    // Rectangle dimensions
    const halfWidth = scale[0] / 2;
    const halfHeight = scale[1] / 2;
    
    // Rectangle center position
    const rectCenter = {
      x: position[0],
      y: position[1]
    };
    
    // Calculate edge midpoints and normals
    const edges = {
      top: {
        midpoint: { x: rectCenter.x, y: rectCenter.y + halfHeight },
        normal: { x: 0, y: 1 }
      },
      right: {
        midpoint: { x: rectCenter.x + halfWidth, y: rectCenter.y },
        normal: { x: 1, y: 0 }
      },
      bottom: {
        midpoint: { x: rectCenter.x, y: rectCenter.y - halfHeight },
        normal: { x: 0, y: -1 }
      },
      left: {
        midpoint: { x: rectCenter.x - halfWidth, y: rectCenter.y },
        normal: { x: -1, y: 0 }
      }
    };
    
    // Calculate angles for each edge
    const edgeAngles = {};
    
    for (const [edge, data] of Object.entries(edges)) {
      // Vector from edge midpoint to sun
      const vx = sunPos.x - data.midpoint.x;
      const vy = sunPos.y - data.midpoint.y;
      
      // Calculate angle between normal and sun vector
      let angle = (Math.atan2(vy, vx) - Math.atan2(data.normal.y, data.normal.x)) * 180/Math.PI;
      angle = (angle + 360) % 360;
      
      // Normalize to -90 to +90 range
      if (angle > 180) {
        angle = 360 - angle;
        angle = -angle;
      }
      
      // Clamp to -90 to +90 range
      edgeAngles[edge] = Math.max(-90, Math.min(90, angle));
    }
    
    return edgeAngles;
  }, [position, scale]);
  
  // Add refs to track animation state for edges
  const edgeAnimations = useRef({
    topLight: { active: false, progress: 0 },
    topDark: { active: false, progress: 0 },
    bottomLight: { active: false, progress: 0 },
    bottomDark: { active: false, progress: 0 },
    rightLight: { active: false, progress: 0 },
    rightDark: { active: false, progress: 0 },
    leftLight: { active: false, progress: 0 },
    leftDark: { active: false, progress: 0 }
  });
  
  // Animation duration in seconds
  const animationDuration = 1.0;
  
  // Update the edge lines based on sun position
  const updateEdgeLines = useCallback((sunPos, deltaTime) => {
    if (!lineGroupRef.current || lineGroupRef.current.children.length < 10) return;
    
    // Get edge meshes
    const topLightEdge = lineGroupRef.current.children[0];
    const rightLightEdge = lineGroupRef.current.children[1];
    const bottomLightEdge = lineGroupRef.current.children[2];
    const leftLightEdge = lineGroupRef.current.children[3];
    const topDarkEdge = lineGroupRef.current.children[4];
    const rightDarkEdge = lineGroupRef.current.children[5];
    const bottomDarkEdge = lineGroupRef.current.children[6];
    const leftDarkEdge = lineGroupRef.current.children[7];
    const lightCaps = lightCapsRef.current;
    const darkCaps = darkCapsRef.current;
    
    if (!lightCaps || !darkCaps) return;
    
    // Get rectangle dimensions
    const halfWidth = scale[0] / 2;
    const halfHeight = scale[1] / 2;
    
    // Calculate edge angles
    const edgeAngles = calculateEdgeAngles(sunPos);

    // Base line thickness calculation with maximum cap
    const minDimension = Math.min(halfWidth, halfHeight);
    // Use square root scaling for more controlled growth and cap the maximum size
    const maxThickness = 3; // Maximum thickness allowed
    const baseThickness = Math.min(maxThickness, Math.sqrt(minDimension) * 0.02);
    const capRadius = baseThickness / 2; // Half the thickness for the cap radius
    const edgeInset = baseThickness * 0.75; // Inset by 0.75x the thickness
    
    // Visibility threshold (percentage of full length)
    const threshold = 0.05; // 5% of full length
    
    // Helper function to update edge animation state
    const updateEdgeAnimation = (edgeType, shouldBeVisible, deltaTime) => {
      const animation = edgeAnimations.current[edgeType];
      
      // If visibility state is changing, start animation
      if (shouldBeVisible !== animation.active) {
        animation.active = shouldBeVisible;
        // If becoming visible, reset progress to 0 (will animate up)
        if (shouldBeVisible) {
          animation.progress = 0;
        }
      }
      
      // Update animation progress
      if (shouldBeVisible && animation.progress < 1) {
        // Animate in - TWICE AS FAST (2x multiplier)
        animation.progress = Math.min(1, animation.progress + (deltaTime * 2) / animationDuration);
      } else if (!shouldBeVisible && animation.progress > 0) {
        // Animate out - NORMAL SPEED (unchanged)
        animation.progress = Math.max(0, animation.progress - deltaTime / animationDuration);
      }
      
      return animation.progress;
    };
    
    // Calculate line lengths and positions for each edge
    // TOP EDGE - bring in the start/end points by edgeInset and offset inward by half thickness
    let topX1 = -halfWidth + edgeInset;
    let topX2 = halfWidth - edgeInset;
    const topY = halfHeight - baseThickness/2; // Moved inward by half thickness
    
    // Apply angle-based length adjustment for top edge
    const topAngle = edgeAngles.top;
    if (topAngle > 0) {
      // Edge facing toward sun - end point moves inward from right
      const fraction = Math.min(topAngle, 90) / 90;
      topX2 = (halfWidth - edgeInset) - (2 * (halfWidth - edgeInset)) * fraction;
    } else if (topAngle < 0) {
      // Edge facing away from sun - start point moves inward from left
      const fraction = Math.min(Math.abs(topAngle), 90) / 90;
      topX1 = (-halfWidth + edgeInset) + (2 * (halfWidth - edgeInset)) * fraction;
    }
    
    // Calculate inverse for top edge (uses bottom edge angle)
    let topInvX1 = -halfWidth;
    let topInvX2 = halfWidth;
    const bottomAngle = edgeAngles.bottom;
    if (bottomAngle > 0) {
      const fraction = Math.min(bottomAngle, 90) / 90;
      topInvX1 = -halfWidth + (2 * halfWidth) * fraction;
    } else if (bottomAngle < 0) {
      const fraction = Math.min(Math.abs(bottomAngle), 90) / 90;
      topInvX2 = halfWidth - (2 * halfWidth) * fraction;
    }
    
    // BOTTOM EDGE - bring in the start/end points by edgeInset and offset inward by half thickness
    let bottomX1 = -halfWidth + edgeInset;
    let bottomX2 = halfWidth - edgeInset;
    const bottomY = -halfHeight + baseThickness/2; // Moved inward by half thickness
    
    // Apply angle-based length adjustment for bottom edge
    if (bottomAngle > 0) {
      const fraction = Math.min(bottomAngle, 90) / 90;
      bottomX1 = (-halfWidth + edgeInset) + (2 * (halfWidth - edgeInset)) * fraction;
    } else if (bottomAngle < 0) {
      const fraction = Math.min(Math.abs(bottomAngle), 90) / 90;
      bottomX2 = (halfWidth - edgeInset) - (2 * (halfWidth - edgeInset)) * fraction;
    }
    
    // Calculate inverse for bottom edge (uses top edge angle)
    let bottomInvX1 = -halfWidth + edgeInset;
    let bottomInvX2 = halfWidth - edgeInset;
    if (topAngle > 0) {
      const fraction = Math.min(topAngle, 90) / 90;
      bottomInvX1 = (-halfWidth + edgeInset) + (2 * (halfWidth - edgeInset)) * fraction;
    } else if (topAngle < 0) {
      const fraction = Math.min(Math.abs(topAngle), 90) / 90;
      bottomInvX2 = (halfWidth - edgeInset) - (2 * (halfWidth - edgeInset)) * fraction;
    }
    
    // RIGHT EDGE - bring in the start/end points by edgeInset and offset inward by half thickness
    let rightY1 = -halfHeight + edgeInset;
    let rightY2 = halfHeight - edgeInset;
    const rightX = halfWidth - baseThickness/2; // Moved inward by half thickness
    
    // Apply angle-based length adjustment for right edge
    const rightAngle = edgeAngles.right;
    if (rightAngle > 0) {
      const fraction = Math.min(rightAngle, 90) / 90;
      rightY1 = (-halfHeight + edgeInset) + (2 * (halfHeight - edgeInset)) * fraction;
    } else if (rightAngle < 0) {
      const fraction = Math.min(Math.abs(rightAngle), 90) / 90;
      rightY2 = (halfHeight - edgeInset) - (2 * (halfHeight - edgeInset)) * fraction;
    }
    
    // Calculate inverse for right edge (uses left edge angle)
    let rightInvY1 = -halfHeight + edgeInset;
    let rightInvY2 = halfHeight - edgeInset;
    const leftAngle = edgeAngles.left;
    if (leftAngle > 0) {
      const fraction = Math.min(leftAngle, 90) / 90;
      rightInvY1 = (-halfHeight + edgeInset) + (2 * (halfHeight - edgeInset)) * fraction;
    } else if (leftAngle < 0) {
      const fraction = Math.min(Math.abs(leftAngle), 90) / 90;
      rightInvY2 = (halfHeight - edgeInset) - (2 * (halfHeight - edgeInset)) * fraction;
    }
    
    // LEFT EDGE - bring in the start/end points by edgeInset and offset inward by half thickness
    let leftY1 = -halfHeight + edgeInset;
    let leftY2 = halfHeight - edgeInset;
    const leftX = -halfWidth + baseThickness/2; // Moved inward by half thickness
    
    // Apply angle-based length adjustment for left edge
    if (leftAngle > 0) {
      const fraction = Math.min(leftAngle, 90) / 90;
      leftY2 = (halfHeight - edgeInset) - (2 * (halfHeight - edgeInset)) * fraction;
    } else if (leftAngle < 0) {
      const fraction = Math.min(Math.abs(leftAngle), 90) / 90;
      leftY1 = (-halfHeight + edgeInset) + (2 * (halfHeight - edgeInset)) * fraction;
    }
    
    // Calculate inverse for left edge (uses right edge angle)
    let leftInvY1 = -halfHeight + edgeInset;
    let leftInvY2 = halfHeight - edgeInset;
    if (rightAngle > 0) {
      const fraction = Math.min(rightAngle, 90) / 90;
      leftInvY2 = (halfHeight - edgeInset) - (2 * (halfHeight - edgeInset)) * fraction;
    } else if (rightAngle < 0) {
      const fraction = Math.min(Math.abs(rightAngle), 90) / 90;
      leftInvY1 = (-halfHeight + edgeInset) + (2 * (halfHeight - edgeInset)) * fraction;
    }
    
    // Precompute bounds for cap positioning
    const bounds = {
      minX: position[0] - halfWidth,
      maxX: position[0] + halfWidth,
      minY: position[1] - halfHeight,
      maxY: position[1] + halfHeight,
      halfWidth,
      halfHeight
    };
    
    // Check if lines should be visible based on length
    const topLength = Math.abs(topX2 - topX1);
    const topInvLength = Math.abs(topInvX2 - topInvX1);
    const bottomLength = Math.abs(bottomX2 - bottomX1);
    const bottomInvLength = Math.abs(bottomInvX2 - bottomInvX1);
    const rightLength = Math.abs(rightY2 - rightY1);
    const rightInvLength = Math.abs(rightInvY2 - rightInvY1);
    const leftLength = Math.abs(leftY2 - leftY1);
    const leftInvLength = Math.abs(leftInvY2 - leftInvY1);
    
    // TOP LIGHT EDGE
    const shouldShowTopLight = topLength > threshold * 2 * halfWidth;
    const topLightProgress = updateEdgeAnimation('topLight', shouldShowTopLight, deltaTime);
    
    if (topLightProgress > 0) {
      // Apply animation to edge thickness
      const animatedThickness = baseThickness * topLightProgress;
      
      const positionAttribute = topLightEdge.geometry.attributes.position;
      const array = positionAttribute.array;
      
      array[0] = topX1; array[1] = topY + animatedThickness/2; array[2] = 0.01;
      array[3] = topX2; array[4] = topY + animatedThickness/2; array[5] = 0.01;
      array[6] = topX1; array[7] = topY - animatedThickness/2; array[8] = 0.01;
      array[9] = topX2; array[10] = topY - animatedThickness/2; array[11] = 0.01;
      
      positionAttribute.needsUpdate = true;
      topLightEdge.visible = true;
      
      // Update cap animations with the same progress value
      const capScale = capRadius * topLightProgress;
      
      // Use the same animation progress for caps
      if (capScale > 0) {
        // Start cap
        dummyObj.current.position.set(topX1, topY, 0.01);
        dummyObj.current.scale.set(capScale, capScale, 1);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(0, dummyObj.current.matrix);
        
        // End cap
        dummyObj.current.position.set(topX2, topY, 0.01);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(1, dummyObj.current.matrix);
        
        capsVisibility.current.topLight.start = true;
        capsVisibility.current.topLight.end = true;
      } else {
        // Hide caps completely when scale reaches 0
        dummyObj.current.scale.set(0, 0, 0);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(0, dummyObj.current.matrix);
        lightCaps.setMatrixAt(1, dummyObj.current.matrix);
        
        capsVisibility.current.topLight.start = false;
        capsVisibility.current.topLight.end = false;
      }
    } else {
      topLightEdge.visible = false;
      
      // Hide caps completely
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      lightCaps.setMatrixAt(0, dummyObj.current.matrix);
      lightCaps.setMatrixAt(1, dummyObj.current.matrix);
      
      capsVisibility.current.topLight.start = false;
      capsVisibility.current.topLight.end = false;
    }
    
    // TOP DARK EDGE (INVERSE)
    const shouldShowTopDark = topInvLength > threshold * 2 * halfWidth;
    const topDarkProgress = updateEdgeAnimation('topDark', shouldShowTopDark, deltaTime);
    
    if (topDarkProgress > 0) {
      // Apply animation to edge thickness
      const animatedThickness = baseThickness * topDarkProgress;
      
      const positionAttribute = topDarkEdge.geometry.attributes.position;
      const array = positionAttribute.array;
      
      array[0] = topInvX1; array[1] = topY + animatedThickness/2; array[2] = 0.01;
      array[3] = topInvX2; array[4] = topY + animatedThickness/2; array[5] = 0.01;
      array[6] = topInvX1; array[7] = topY - animatedThickness/2; array[8] = 0.01;
      array[9] = topInvX2; array[10] = topY - animatedThickness/2; array[11] = 0.01;
      
      positionAttribute.needsUpdate = true;
      topDarkEdge.visible = true;
      
      // Update cap animations with the same progress value
      const capScale = capRadius * topDarkProgress;
      
      // Use the same animation progress for caps
      if (capScale > 0) {
        let clampedInvX1 = Math.max(bounds.minX + capScale, Math.min(bounds.maxX - capScale, position[0] + topInvX1));
        let clampedInvX2 = Math.max(bounds.minX + capScale, Math.min(bounds.maxX - capScale, position[0] + topInvX2));
        
        // Start cap
        dummyObj.current.position.set(clampedInvX1 - position[0], topY, 0.01);
        dummyObj.current.scale.set(capScale, capScale, 1);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(2, dummyObj.current.matrix);
        
        // End cap
        dummyObj.current.position.set(clampedInvX2 - position[0], topY, 0.01);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(3, dummyObj.current.matrix);
        
        capsVisibility.current.topDark.start = true;
        capsVisibility.current.topDark.end = true;
      } else {
        // Hide caps completely when scale reaches 0
        dummyObj.current.scale.set(0, 0, 0);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(2, dummyObj.current.matrix);
        lightCaps.setMatrixAt(3, dummyObj.current.matrix);
        
        capsVisibility.current.topDark.start = false;
        capsVisibility.current.topDark.end = false;
      }
    } else {
      topDarkEdge.visible = false;
      
      // Hide caps completely
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      lightCaps.setMatrixAt(2, dummyObj.current.matrix);
      lightCaps.setMatrixAt(3, dummyObj.current.matrix);
      
      capsVisibility.current.topDark.start = false;
      capsVisibility.current.topDark.end = false;
    }
    
    // BOTTOM LIGHT EDGE
    const shouldShowBottomLight = bottomLength > threshold * 2 * halfWidth;
    const bottomLightProgress = updateEdgeAnimation('bottomLight', shouldShowBottomLight, deltaTime);
    
    if (bottomLightProgress > 0) {
      // Apply animation to edge thickness
      const animatedThickness = baseThickness * bottomLightProgress;
      
      const positionAttribute = bottomLightEdge.geometry.attributes.position;
      const array = positionAttribute.array;
      
      array[0] = bottomX1; array[1] = bottomY + animatedThickness/2; array[2] = 0.01;
      array[3] = bottomX2; array[4] = bottomY + animatedThickness/2; array[5] = 0.01;
      array[6] = bottomX1; array[7] = bottomY - animatedThickness/2; array[8] = 0.01;
      array[9] = bottomX2; array[10] = bottomY - animatedThickness/2; array[11] = 0.01;
      
      positionAttribute.needsUpdate = true;
      bottomLightEdge.visible = true;
      
      // Update cap animations with the same progress value
      const capScale = capRadius * bottomLightProgress;
      
      // Use the same animation progress for caps
      if (capScale > 0) {
        // Start cap
        dummyObj.current.position.set(bottomX1, bottomY, 0.01);
        dummyObj.current.scale.set(capScale, capScale, 1);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(4, dummyObj.current.matrix);
        
        // End cap
        dummyObj.current.position.set(bottomX2, bottomY, 0.01);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(5, dummyObj.current.matrix);
        
        capsVisibility.current.bottomLight.start = true;
        capsVisibility.current.bottomLight.end = true;
      } else {
        // Hide caps completely when scale reaches 0
        dummyObj.current.scale.set(0, 0, 0);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(4, dummyObj.current.matrix);
        lightCaps.setMatrixAt(5, dummyObj.current.matrix);
        
        capsVisibility.current.bottomLight.start = false;
        capsVisibility.current.bottomLight.end = false;
      }
    } else {
      bottomLightEdge.visible = false;
      
      // Hide caps completely
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      lightCaps.setMatrixAt(4, dummyObj.current.matrix);
      lightCaps.setMatrixAt(5, dummyObj.current.matrix);
      
      capsVisibility.current.bottomLight.start = false;
      capsVisibility.current.bottomLight.end = false;
    }
    
    // BOTTOM DARK EDGE (INVERSE)
    const shouldShowBottomDark = bottomInvLength > threshold * 2 * halfWidth;
    const bottomDarkProgress = updateEdgeAnimation('bottomDark', shouldShowBottomDark, deltaTime);
    
    if (bottomDarkProgress > 0) {
      // Apply animation to edge thickness
      const animatedThickness = baseThickness * bottomDarkProgress;
      
      const positionAttribute = bottomDarkEdge.geometry.attributes.position;
      const array = positionAttribute.array;
      
      array[0] = bottomInvX1; array[1] = bottomY + animatedThickness/2; array[2] = 0.01;
      array[3] = bottomInvX2; array[4] = bottomY + animatedThickness/2; array[5] = 0.01;
      array[6] = bottomInvX1; array[7] = bottomY - animatedThickness/2; array[8] = 0.01;
      array[9] = bottomInvX2; array[10] = bottomY - animatedThickness/2; array[11] = 0.01;
      
      positionAttribute.needsUpdate = true;
      bottomDarkEdge.visible = true;
      
      // Update cap animations with the same progress value
      const capScale = capRadius * bottomDarkProgress;
      
      // Use the same animation progress for caps
      if (capScale > 0) {
        let clampedBottomInvX1 = Math.max(bounds.minX + capScale, Math.min(bounds.maxX - capScale, position[0] + bottomInvX1));
        let clampedBottomInvX2 = Math.max(bounds.minX + capScale, Math.min(bounds.maxX - capScale, position[0] + bottomInvX2));
        
        // Start cap
        dummyObj.current.position.set(clampedBottomInvX1 - position[0], bottomY, 0.01);
        dummyObj.current.scale.set(capScale, capScale, 1);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(0, dummyObj.current.matrix);
        
        // End cap
        dummyObj.current.position.set(clampedBottomInvX2 - position[0], bottomY, 0.01);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(1, dummyObj.current.matrix);
        
        capsVisibility.current.bottomDark.start = true;
        capsVisibility.current.bottomDark.end = true;
      } else {
        // Hide caps completely when scale reaches 0
        dummyObj.current.scale.set(0, 0, 0);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(0, dummyObj.current.matrix);
        darkCaps.setMatrixAt(1, dummyObj.current.matrix);
        
        capsVisibility.current.bottomDark.start = false;
        capsVisibility.current.bottomDark.end = false;
      }
    } else {
      bottomDarkEdge.visible = false;
      
      // Hide caps completely
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      darkCaps.setMatrixAt(0, dummyObj.current.matrix);
      darkCaps.setMatrixAt(1, dummyObj.current.matrix);
      
      capsVisibility.current.bottomDark.start = false;
      capsVisibility.current.bottomDark.end = false;
    }
    
    // RIGHT LIGHT EDGE
    const shouldShowRightLight = rightLength > threshold * 2 * halfHeight;
    const rightLightProgress = updateEdgeAnimation('rightLight', shouldShowRightLight, deltaTime);
    
    if (rightLightProgress > 0) {
      // Apply animation to edge thickness
      const animatedThickness = baseThickness * rightLightProgress;
      
      const positionAttribute = rightLightEdge.geometry.attributes.position;
      const array = positionAttribute.array;
      
      array[0] = rightX + animatedThickness/2; array[1] = rightY1; array[2] = 0.01;
      array[3] = rightX + animatedThickness/2; array[4] = rightY2; array[5] = 0.01;
      array[6] = rightX - animatedThickness/2; array[7] = rightY1; array[8] = 0.01;
      array[9] = rightX - animatedThickness/2; array[10] = rightY2; array[11] = 0.01;
      
      positionAttribute.needsUpdate = true;
      rightLightEdge.visible = true;
      
      // Update cap animations with the same progress value
      const capScale = capRadius * rightLightProgress;
      
      // Use the same animation progress for caps
      if (capScale > 0) {
        // Start cap
        dummyObj.current.position.set(rightX, rightY1, 0.01);
        dummyObj.current.scale.set(capScale, capScale, 1);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(6, dummyObj.current.matrix);
        
        // End cap
        dummyObj.current.position.set(rightX, rightY2, 0.01);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(7, dummyObj.current.matrix);
        
        capsVisibility.current.rightLight.start = true;
        capsVisibility.current.rightLight.end = true;
      } else {
        // Hide caps completely when scale reaches 0
        dummyObj.current.scale.set(0, 0, 0);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(6, dummyObj.current.matrix);
        lightCaps.setMatrixAt(7, dummyObj.current.matrix);
        
        capsVisibility.current.rightLight.start = false;
        capsVisibility.current.rightLight.end = false;
      }
    } else {
      rightLightEdge.visible = false;
      
      // Hide caps completely
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      lightCaps.setMatrixAt(6, dummyObj.current.matrix);
      lightCaps.setMatrixAt(7, dummyObj.current.matrix);
      
      capsVisibility.current.rightLight.start = false;
      capsVisibility.current.rightLight.end = false;
    }
    
    // RIGHT DARK EDGE (INVERSE)
    const shouldShowRightDark = rightInvLength > threshold * 2 * halfHeight;
    const rightDarkProgress = updateEdgeAnimation('rightDark', shouldShowRightDark, deltaTime);
    
    if (rightDarkProgress > 0) {
      // Apply animation to edge thickness
      const animatedThickness = baseThickness * rightDarkProgress;
      
      const positionAttribute = rightDarkEdge.geometry.attributes.position;
      const array = positionAttribute.array;
      
      array[0] = rightX + animatedThickness/2; array[1] = rightInvY1; array[2] = 0.01;
      array[3] = rightX + animatedThickness/2; array[4] = rightInvY2; array[5] = 0.01;
      array[6] = rightX - animatedThickness/2; array[7] = rightInvY1; array[8] = 0.01;
      array[9] = rightX - animatedThickness/2; array[10] = rightInvY2; array[11] = 0.01;
      
      positionAttribute.needsUpdate = true;
      rightDarkEdge.visible = true;
      
      // Update cap animations with the same progress value
      const capScale = capRadius * rightDarkProgress;
      
      // Use the same animation progress for caps
      if (capScale > 0) {
        let clampedRightInvY1 = Math.max(bounds.minY + capScale, Math.min(bounds.maxY - capScale, position[1] + rightInvY1));
        let clampedRightInvY2 = Math.max(bounds.minY + capScale, Math.min(bounds.maxY - capScale, position[1] + rightInvY2));
        
        // Start cap
        dummyObj.current.position.set(rightX, clampedRightInvY1 - position[1], 0.01);
        dummyObj.current.scale.set(capScale, capScale, 1);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(2, dummyObj.current.matrix);
        
        // End cap
        dummyObj.current.position.set(rightX, clampedRightInvY2 - position[1], 0.01);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(3, dummyObj.current.matrix);
        
        capsVisibility.current.rightDark.start = true;
        capsVisibility.current.rightDark.end = true;
      } else {
        // Hide caps completely when scale reaches 0
        dummyObj.current.scale.set(0, 0, 0);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(2, dummyObj.current.matrix);
        darkCaps.setMatrixAt(3, dummyObj.current.matrix);
        
        capsVisibility.current.rightDark.start = false;
        capsVisibility.current.rightDark.end = false;
      }
    } else {
      rightDarkEdge.visible = false;
      
      // Hide caps completely
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      darkCaps.setMatrixAt(2, dummyObj.current.matrix);
      darkCaps.setMatrixAt(3, dummyObj.current.matrix);
      
      capsVisibility.current.rightDark.start = false;
      capsVisibility.current.rightDark.end = false;
    }
    
    // LEFT LIGHT EDGE
    const shouldShowLeftLight = leftLength > threshold * 2 * halfHeight;
    const leftLightProgress = updateEdgeAnimation('leftLight', shouldShowLeftLight, deltaTime);
    
    if (leftLightProgress > 0) {
      // Apply animation to edge thickness
      const animatedThickness = baseThickness * leftLightProgress;
      
      const positionAttribute = leftLightEdge.geometry.attributes.position;
      const array = positionAttribute.array;
      
      array[0] = leftX + animatedThickness/2; array[1] = leftY1; array[2] = 0.01;
      array[3] = leftX + animatedThickness/2; array[4] = leftY2; array[5] = 0.01;
      array[6] = leftX - animatedThickness/2; array[7] = leftY1; array[8] = 0.01;
      array[9] = leftX - animatedThickness/2; array[10] = leftY2; array[11] = 0.01;
      
      positionAttribute.needsUpdate = true;
      leftLightEdge.visible = true;
      
      // Update cap animations with the same progress value
      const capScale = capRadius * leftLightProgress;
      
      // Use the same animation progress for caps
      if (capScale > 0) {
        // Start cap
        dummyObj.current.position.set(leftX, leftY1, 0.01);
        dummyObj.current.scale.set(capScale, capScale, 1);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(8, dummyObj.current.matrix);
        
        // End cap
        dummyObj.current.position.set(leftX, leftY2, 0.01);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(9, dummyObj.current.matrix);
        
        capsVisibility.current.leftLight.start = true;
        capsVisibility.current.leftLight.end = true;
      } else {
        // Hide caps completely when scale reaches 0
        dummyObj.current.scale.set(0, 0, 0);
        dummyObj.current.updateMatrix();
        lightCaps.setMatrixAt(8, dummyObj.current.matrix);
        lightCaps.setMatrixAt(9, dummyObj.current.matrix);
        
        capsVisibility.current.leftLight.start = false;
        capsVisibility.current.leftLight.end = false;
      }
    } else {
      leftLightEdge.visible = false;
      
      // Hide caps completely
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      lightCaps.setMatrixAt(8, dummyObj.current.matrix);
      lightCaps.setMatrixAt(9, dummyObj.current.matrix);
      
      capsVisibility.current.leftLight.start = false;
      capsVisibility.current.leftLight.end = false;
    }
    
    // LEFT DARK EDGE (INVERSE)
    const shouldShowLeftDark = leftInvLength > threshold * 2 * halfHeight;
    const leftDarkProgress = updateEdgeAnimation('leftDark', shouldShowLeftDark, deltaTime);
    
    if (leftDarkProgress > 0) {
      // Apply animation to edge thickness
      const animatedThickness = baseThickness * leftDarkProgress;
      
      const positionAttribute = leftDarkEdge.geometry.attributes.position;
      const array = positionAttribute.array;
      
      array[0] = leftX + animatedThickness/2; array[1] = leftInvY1; array[2] = 0.01;
      array[3] = leftX + animatedThickness/2; array[4] = leftInvY2; array[5] = 0.01;
      array[6] = leftX - animatedThickness/2; array[7] = leftInvY1; array[8] = 0.01;
      array[9] = leftX - animatedThickness/2; array[10] = leftInvY2; array[11] = 0.01;
      
      positionAttribute.needsUpdate = true;
      leftDarkEdge.visible = true;
      
      // Update cap animations with the same progress value
      const capScale = capRadius * leftDarkProgress;
      
      // Use the same animation progress for caps
      if (capScale > 0) {
        let clampedLeftInvY1 = Math.max(bounds.minY + capScale, Math.min(bounds.maxY - capScale, position[1] + leftInvY1));
        let clampedLeftInvY2 = Math.max(bounds.minY + capScale, Math.min(bounds.maxY - capScale, position[1] + leftInvY2));
        
        // Start cap
        dummyObj.current.position.set(leftX, clampedLeftInvY1 - position[1], 0.01);
        dummyObj.current.scale.set(capScale, capScale, 1);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(4, dummyObj.current.matrix);
        
        // End cap
        dummyObj.current.position.set(leftX, clampedLeftInvY2 - position[1], 0.01);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(5, dummyObj.current.matrix);
        
        capsVisibility.current.leftDark.start = true;
        capsVisibility.current.leftDark.end = true;
      } else {
        // Hide caps completely when scale reaches 0
        dummyObj.current.scale.set(0, 0, 0);
        dummyObj.current.updateMatrix();
        darkCaps.setMatrixAt(4, dummyObj.current.matrix);
        darkCaps.setMatrixAt(5, dummyObj.current.matrix);
        
        capsVisibility.current.leftDark.start = false;
        capsVisibility.current.leftDark.end = false;
      }
    } else {
      leftDarkEdge.visible = false;
      
      // Hide caps completely
      dummyObj.current.scale.set(0, 0, 0);
      dummyObj.current.updateMatrix();
      darkCaps.setMatrixAt(4, dummyObj.current.matrix);
      darkCaps.setMatrixAt(5, dummyObj.current.matrix);
      
      capsVisibility.current.leftDark.start = false;
      capsVisibility.current.leftDark.end = false;
    }
    
    // Update instance matrices for both cap types
    lightCaps.instanceMatrix.needsUpdate = true;
    darkCaps.instanceMatrix.needsUpdate = true;
  }, [scale, calculateEdgeAngles]);
  
  // Precompute corner coordinates as a reference for clamping cap positions
  const bounds = useMemo(() => {
    const halfWidth = scale[0] / 2;
    const halfHeight = scale[1] / 2;
    
    return {
      minX: position[0] - halfWidth,
      maxX: position[0] + halfWidth,
      minY: position[1] - halfHeight,
      maxY: position[1] + halfHeight,
      halfWidth,
      halfHeight
    };
  }, [position, scale]);
  
  // Update on each frame with throttling
  useFrame(({ clock, deltaTime }) => {
    if (!sunContext.current) return;
    
    const currentTime = clock.getElapsedTime() * 1000; // Convert to ms
    
    // Only update if enough time has passed or sun moved significantly
    const dx = sunContext.current.x - lastSunPos.current.x;
    const dy = sunContext.current.y - lastSunPos.current.y;
    const sunMovedEnough = Math.sqrt(dx*dx + dy*dy) > 0.01;
    const timePassedEnough = currentTime - lastUpdateTime.current > updateThreshold;
    
    // Always update if any animations are in progress
    const hasActiveAnimations = Object.entries(edgeAnimations.current).some(
      ([edge, anim]) => anim.active && anim.progress < 1
    );
    
    if (sunMovedEnough || timePassedEnough || hasActiveAnimations) {
      updateEdgeLines(sunContext.current, deltaTime || 0.016); // Pass deltaTime for animations
      lastSunPos.current = {...sunContext.current};
      lastUpdateTime.current = currentTime;
    }
  });
  
  return (
    <group position={position}>
      {/* Main rectangle */}
      <mesh 
        ref={rectRef}
        renderOrder={renderOrder}
        scale={scale}
      >
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial 
          color={color} 
          transparent={true}
          opacity={opacity}
          side={THREE.DoubleSide}
          depthTest={false}
          depthWrite={false}
          // Make the rectangle itself also respect its own stencil mask
          stencilWrite={true}
          stencilRef={1}
          stencilFunc={THREE.AlwaysStencilFunc}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      
      {/* Edge lines group */}
      <group ref={lineGroupRef} />
    </group>
  );
};

export default ModifiedRectWithEdges;