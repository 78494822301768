// src/components/Teachers/Slideshow/SlideCreator.js

import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import Button from './Button'; // Adjust the path based on your project structure
import * as THREE from 'three';
import { FaTimes } from '../../assets/icons';
import PuzzleBanner from '../../../components/banners/animatedsvg.svg';

// Modify the lazy loading of BookScene
const BookScene = lazy(() => import('./BookScene').then(module => {
  // Reduced delay to 1 second
  return new Promise(resolve => 
    setTimeout(() => resolve(module), 5)
  );
}));

// Add this new Slider component near the top
const Slider = ({ label, value, onChange, min = 0, max = 10, step = 0.5 }) => (
  <div className="flex items-center mb-1">
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
      className="w-24"
    />
    <span className="ml-1 text-[10px]">{label}: {value}</span>
  </div>
);

// Add these near the top of the file, before the SlideCreator component
const bookGenres = {
  'Picture Books': { x: 0, y: 150, z: -1000 },
  'Fantasy': { x: 0, y: 150, z: -1000 },
  'Adventure': { x: 0, y: 150, z: -1000 },
  'Science': { x: 0, y: 150, z: -1000 },
  'History': { x: 0, y: 150, z: -1000 },
  'Mystery': { x: 0, y: 150, z: -1000 },
  'Folk Tales': { x: 0, y: 150, z: -1000 },
  'Poetry': { x: 0, y: 150, z: -1000 },
  'Biography': { x: 0, y: 150, z: -1000 }
};

const ageRanges = [
  '1-4 years',
  '3-5 years', 
  '4-6 years',
  '5-7 years',
  '6-8 years',
  '7-9 years',
  '8-12 years'
];

// Add this near other constants at the top of the file
const genreColors = {
  'Picture Books': '#FF6B6B',
  'Fantasy': '#4ECDC4',
  'Adventure': '#45B7D1',
  'Science': '#96CEB4',
  'History': '#FFEEAD',
  'Mystery': '#D4A5A5',
  'Folk Tales': '#9B786F',
  'Poetry': '#A8E6CF',
  'Biography': '#FFD3B6'
};

const genreEmojis = {
  'Picture Books': '📚',
  'Fantasy': '🐉',
  'Adventure': '🗺️',
  'Science': '🔬',
  'History': '⏳',
  'Mystery': '🔍',
  'Folk Tales': '🧚',
  'Poetry': '📝',
  'Biography': '👤'
};

const getGenreColor = (genre) => {
  return genreColors[genre] || '#6c757d'; // Default color if genre not found
};

const SlideCreator = ({ onClose, onPopSlide }) => {
  // Replace the static genres array with actual book genres
  const genres = [
    'Picture Books',
    'Fantasy',
    'Adventure',
    'Science',
    'History',
    'Mystery',
    'Folk Tales',
    'Poetry',
    'Biography'
  ];

  // Placeholder subtitles with empty strings
  const placeholderSubtitles = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ];

  // Modified function to generate initial slides with just genres
  const generateInitialSlides = () => {
    return genres.map((genre, index) => ({
      id: Date.now() + index,
      title: genre,
      subtitle: "",
      theme: genre,
      x: 0,
      y: 0,
      z: 0,
      location: 'Near'
    }));
  };

  // Initialize slides with genres
  const [slides, setSlides] = useState(generateInitialSlides());

  // State to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // State to track navigation direction for animation
  const [navigationDirection, setNavigationDirection] = useState(0); // 1 for next, -1 for previous, 0 for direct navigation

  // New state to track if a transition is in progress
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Make "show/hide matrix" hidden by default
  const [showMatrix, setShowMatrix] = useState(false);

  // Add this new state near other state declarations
  const [showText, setShowText] = useState(true);

  const currentSlide = slides[currentSlideIndex];

  // Function to handle animation completion
  const handleAnimationComplete = () => {
    setIsTransitioning(false);
  };

  // Handlers for navigating slides
  const handleNext = () => {
    if (currentSlideIndex >= slides.length - 1 || isTransitioning) return;
    setIsTransitioning(true);
    setShowText(false);
    
    // Move camera to next aisle
    if (sceneRef.current) {
      sceneRef.current.moveToNextAisle();
    }
    
    // Update slide index after transition duration
    setTimeout(() => {
      setNavigationDirection(1);
      setCurrentSlideIndex(currentSlideIndex + 1);
      setShowText(true);
      setIsTransitioning(false);
    }, 2000); // Match this with the transition duration in BookScene
  };

  const handlePrevious = () => {
    if (currentSlideIndex === 0 || isTransitioning) return;
    setIsTransitioning(true);
    setShowText(false);
    
    // Move camera to previous aisle
    if (sceneRef.current) {
      sceneRef.current.moveToPreviousAisle();
    }
    
    // Update slide index after transition duration
    setTimeout(() => {
      setNavigationDirection(-1);
      setCurrentSlideIndex(currentSlideIndex - 1);
      setShowText(true);
      setIsTransitioning(false);
    }, 2000); // Match this with the transition duration in BookScene
  };

  // Handler for clicking on a slide preview
  const handleSlideClick = (index) => {
    if (index === currentSlideIndex || isTransitioning) return;
    setIsTransitioning(true);
    setShowText(false);
    
    // Move camera directly to target aisle
    if (sceneRef.current) {
        sceneRef.current.moveToAisle(index);
    }
    
    // Update slide index after transition completes
    setTimeout(() => {
        setNavigationDirection(index > currentSlideIndex ? 1 : -1);
        setCurrentSlideIndex(index);
        setShowText(true);
        setIsTransitioning(false);
    }, 2000); // Match this with the transition duration in BookScene
  };

  // Simplified handleThemeChange
  const handleThemeChange = (e) => {
    const newTheme = e.target.value;
    
    const updatedSlides = [...slides];
    updatedSlides[currentSlideIndex] = {
      ...slides[currentSlideIndex],
      theme: newTheme,
      title: newTheme
    };

    setSlides(updatedSlides);
  };

  // Add handler for deleting slides
  const handleDeleteSlide = (index, e) => {
    e.stopPropagation();
    
    const updatedSlides = slides.filter((_, i) => i !== index);
    
    setSlides(updatedSlides);
    
    if (index <= currentSlideIndex) {
      setCurrentSlideIndex(Math.max(0, currentSlideIndex - 1));
    }
  };

  // Add function to get highest theme number
  const getHighestThemeNumber = () => {
    if (!slides.length) return 1;
    return Math.max(...slides.map(slide => slide.themeNumber));
  };

  // Add coordinate change handler
  const handleCoordinateChange = (coord, value) => {
    const updatedSlides = [...slides];
    updatedSlides[currentSlideIndex] = {
      ...slides[currentSlideIndex],
      [coord]: Number(value)
    };
    setSlides(updatedSlides);
  };

  // Simplified handleLocationChange
  const handleLocationChange = (e) => {
    const location = e.target.value;
    const coordinates = bookGenres[location];
    
    if (coordinates) {
      const updatedSlides = [...slides];
      updatedSlides[currentSlideIndex] = {
        ...slides[currentSlideIndex],
        x: coordinates.x,
        y: coordinates.y,
        z: coordinates.z,
        location: location,
        title: currentSlide.theme
      };
      setSlides(updatedSlides);
    }
  };

  // Add this near the top of the component with other state
  const canvasRef = useRef(null);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const containerRef = useRef(null);

  // Modify the Three.js useEffect
  useEffect(() => {
    if (!canvasRef.current || !containerRef.current) return;

    // Import BookScene dynamically when needed
    import('./BookScene').then(module => {
      // Cleanup previous scene if it exists
      if (sceneRef.current) {
          sceneRef.current.dispose();
          sceneRef.current = null;
      }

      // Initialize scene with the BookScene class
      const BookSceneClass = module.default;
      const bookScene = new BookSceneClass(canvasRef.current);
      sceneRef.current = bookScene;

      // Function to update canvas size
      const updateCanvasSize = () => {
          const container = containerRef.current;
          if (container && bookScene) {
              const width = container.clientWidth;
              const height = container.clientHeight;
              bookScene.renderer.setSize(width, height);
              bookScene.camera.aspect = width / height;
              bookScene.camera.updateProjectionMatrix();
          }
      };

      // Initial size update
      updateCanvasSize();

      // Handle window resize
      const handleResize = () => {
          updateCanvasSize();
      };
      window.addEventListener('resize', handleResize);

      // Create ResizeObserver to watch container size changes
      const resizeObserver = new ResizeObserver(updateCanvasSize);
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      // Cleanup
      return () => {
          window.removeEventListener('resize', handleResize);
          resizeObserver.disconnect();
          if (sceneRef.current) {
              sceneRef.current.dispose();
              sceneRef.current = null;
          }
      };
    });
  }, []); // Remove isLoading from dependencies

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-white">
      {/* Header Section with SVG Banner and Date/Time */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4 shadow-md">
        {/* Title */}
        <h1 className="text-3xl font-bold text-center">Library</h1>
        {/* Current Date and Time */}
        <div className="mt-2 text-xs font-bold text-gray-600">
          {new Date().toLocaleString()}
        </div>
      </div>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ height: '96px', width: '100%', objectFit: 'cover' }}
        />
      </div>

      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute text-3xl text-gray-500 top-4 right-4 hover:text-gray-700"
        aria-label="Close Library"
      >
        <FaTimes />
      </button>

      {/* Main Content Area */}
      <div className="flex flex-1 overflow-hidden">
        {/* Left Panel (Genres List) */}
        <div className="flex flex-col w-48 h-full p-4 overflow-y-auto border-r">
          <div className="mb-4">
            <h4 className="text-lg font-medium text-center">Genres</h4>
          </div>
          <ul className="flex-1 space-y-4">
            {slides.map((slide, index) => (
              <li
                key={slide.id}
                onClick={() => handleSlideClick(index)}
                className={`cursor-pointer rounded border relative px-2 ${
                  currentSlideIndex === index
                    ? 'border-blue-500'
                    : 'border-gray-200 hover:opacity-90'
                }`}
                style={{
                  backgroundColor: getGenreColor(slide.title),
                  color: '#fff'
                }}
              >
                <div className="flex items-center p-2">
                  <div className="flex-1">
                    <div className="flex items-center justify-center w-full overflow-hidden rounded">
                      <span className="mr-2">{genreEmojis[slide.title]}</span>
                      <p className="text-sm text-center">
                        {slide.title.length > 20
                          ? `${slide.title.substring(0, 20)}...`
                          : slide.title}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Right Panel (Canvas Area) */}
        <div className="flex flex-col flex-1 h-full">
          {currentSlide && (
            <div className="flex flex-col flex-1 p-6">
              {/* Header with Title and Controls */}
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-4">
                  <h3 className="text-xl font-semibold">
                    {currentSlide ? `Slide - ${currentSlide.title}` : 'Select a Genre'}
                  </h3>
                  <select 
                    className="p-2 text-sm text-gray-700 border rounded-md"
                    value={currentSlide?.theme}
                    onChange={handleThemeChange}
                  >
                    {ageRanges.map(range => (
                      <option key={range} value={range}>
                        {range}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Canvas Container */}
              <div 
                ref={containerRef}
                className="relative flex-1 overflow-hidden bg-gray-100 border rounded-md"
              >
                <canvas
                  ref={canvasRef}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                />
                
                {/* Navigation buttons */}
                <div className="absolute left-0 right-0 z-10 flex justify-center space-x-4 bottom-4">
                  <button
                    onClick={handlePrevious}
                    disabled={currentSlideIndex === 0 || isTransitioning}
                    className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                      currentSlideIndex === 0 || isTransitioning ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNext}
                    disabled={currentSlideIndex === slides.length - 1 || isTransitioning}
                    className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                      currentSlideIndex === slides.length - 1 || isTransitioning ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SlideCreator.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPopSlide: PropTypes.func,
};

export default SlideCreator;
