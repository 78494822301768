import React from 'react';
import * as THREE from 'three';

export function Atmosphere() {
  return (
    <mesh renderOrder={3} scale={[1.05, 1.05, 1.05]}>
      <sphereGeometry args={[6371, 64, 32]} />
      <meshBasicMaterial 
        color="#87CEEB"
        transparent={true}
        opacity={0}
        side={THREE.BackSide}
        depthWrite={true}
        depthTest={true}
        blending={THREE.AdditiveBlending}
      />
    </mesh>
  );
}