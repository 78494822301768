// src/App.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

// Import your components with correct casing
import LoginPage from './components/loginpage/loginpage';
import TeacherLoginPage from './components/loginpage/TeacherLoginPage';
import TeacherHomepage from './components/Teachers/Homepage/teacherhomepage';
import StudentHomepage from './components/Students/studenthomepage';
import About from './components/loginpage/PortalPages/about';
import QuickThinkersLandingPage from './components/loginpage/QuickThinkersLandingPage';
import Pricing from './components/loginpage/PortalPages/Pricing';
import Solutions from './components/loginpage/PortalPages/Solutions';
import WhyQuickThinkers from './components/loginpage/PortalPages/WhyQuickThinkers';
import Resources from './components/loginpage/PortalPages/Resources';
import DemoPage from './components/loginpage/Demo/DemoPage';
import Planning from './components/loginpage/PortalPages/Planning';

// Import Context Providers
import { DataControllerProvider } from './contexts/datacontroller';
import { StudentManagerProvider } from './contexts/Studentmanager'; // Import StudentManagerProvider

/**
 * PrivateRoute Component
 * Restricts access to routes based on authentication and user role.
 * @param {ReactNode} children - The component to render if access is granted.
 * @param {string} role - The required role for access (e.g., 'teacher', 'student').
 */
const PrivateRoute = ({ children, role }) => {
  const { currentUser, userRole } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (role && userRole !== role) {
    return <Navigate to="/" replace />;
  }

  return children;
};

/**
 * App Component
 * Defines the routing structure of the application.
 */
function App() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<QuickThinkersLandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/teacherlogin" element={<TeacherLoginPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/solutions" element={<Solutions />} />
      <Route path="/why-quick-thinkers" element={<WhyQuickThinkers />} />
      <Route path="/resources" element={<Resources />} />
      <Route path="/planning" element={<Planning />} />

      {/* Protected Routes for Teachers */}
      <Route
        path="/teacher"
        element={
          <PrivateRoute role="teacher">
            <DataControllerProvider>
              <TeacherHomepage />
            </DataControllerProvider>
          </PrivateRoute>
        }
      />

      {/* Protected Routes for Students */}
      <Route
        path="/student"
        element={
          <PrivateRoute role="student">
            <StudentManagerProvider>
              <StudentHomepage />
            </StudentManagerProvider>
          </PrivateRoute>
        }
      />

      {/* Fallback Route for 404 Not Found */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
