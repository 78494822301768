import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';

const Neptune = () => {
  const { camera } = useThree();
  const neptuneGroupRef = useRef();

  // Define Neptune radii (in km)
  const surfaceRadius = 24622;
  const innerAtmosphereRadius = 25000;
  const outerAtmosphereRadius = 25800;

  // Neptune Surface Shader with updated Earth-style shadow gradient.
  const neptuneSurfaceShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          vUv = uv;
          vNormal = normal;
          vPosition = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        // Random and noise functions
        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
        }

        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (3.0 - 2.0 * f);
          return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.0, 0.35, 0.85); // Deep blue base for Neptune
          
          // Create seamless waves via the angle around the sphere
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 10.0 + time * 0.4) * 0.04;
          float wave2 = sin(angle * 8.0 + time * 0.3 + 3.14159 * 1.5) * 0.03;
          float wave3 = sin(angle * 6.0 - time * 0.5 + 3.14159 * 0.8) * 0.02;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          // Enhanced stripe system using noise
          float bands = noise(vec2(modifiedY * 1.0, time * 0.1)) * 0.7;
          bands += noise(vec2(modifiedY * 30.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          if (modifiedY > 0.8) {
              bandColor = mix(
                  vec3(0.0, 0.5, 0.7),
                  vec3(0.0, 0.6, 0.8),
                  bands
              );
          } else if (modifiedY > 0.6) {
              bandColor = mix(
                  vec3(0.0, 0.6, 0.5),
                  vec3(0.0, 0.7, 0.6),
                  bands
              );
          } else if (modifiedY > 0.4) {
              bandColor = mix(
                  vec3(0.1, 0.5, 0.5),
                  vec3(0.1, 0.6, 0.6),
                  bands
              );
          } else if (modifiedY > 0.2) {
              bandColor = mix(
                  vec3(0.0, 0.4, 0.7),
                  vec3(0.0, 0.5, 0.8),
                  bands
              );
          } else {
              bandColor = mix(
                  vec3(0.0, 0.45, 0.4),
                  vec3(0.0, 0.55, 0.5),
                  bands
              );
          }
          
          // Blend the bands with the base color
          vec3 finalColor = mix(baseColor, bandColor, 0.8);

          // NEW Shadow logic (borrowed from Earth's implementation)
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct < 0.9) {
              float shadowAngle = acos(-dotProduct) * 1.8;
              if (shadowAngle < 3.15) {
                  float shadowStrength = smoothstep(3.15, 2.8, shadowAngle);
                  shadowStrength = pow(shadowStrength, 1.5);
                  finalColor = mix(finalColor, vec3(0.0), 0.45 * shadowStrength);
              }
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  // Neptune Atmosphere Shader (copied from Earth's atmosphere logic)
  const neptuneAtmosphereShader = useMemo(() => {
    return {
      uniforms: {
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec3 vPosition;
        varying vec3 vNormal;
        
        void main() {
          vPosition = position;
          vNormal = normal;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec3 vPosition;
        varying vec3 vNormal;
        uniform mat4 modelMatrix;
        
        float easeInOutPower(float x) {
          return x < 0.5 ? 4.0 * x * x * x : 1.0 - pow(-2.0 * x + 2.0, 3.0) / 2.0;
        }
        
        void main() {
          vec4 skyColor = vec4(0.53, 0.81, 0.92, 0.0);
          vec4 finalColor = skyColor;
          
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          float dotProduct = dot(worldNormal, toSun);
          if (dotProduct > -0.9) {
            float angle = acos(dotProduct) * 2.0;
            if (angle < 3.0) {
              finalColor = vec4(0.25, 0.61, 0.85, 0.5);
            } else if (angle < 3.9) {
              float gradient = smoothstep(3.9, 3.0, angle);
              gradient = easeInOutPower(gradient);
              finalColor = mix(skyColor, vec4(0.25, 0.61, 0.85, 0.5), gradient);
            }
          }
          
          gl_FragColor = finalColor;
        }
      `
    };
  }, []);

  // Distance-check logic: only display atmosphere meshes when the camera is close enough.
  useFrame(() => {
    if (neptuneGroupRef.current) {
      const neptunePos = new THREE.Vector3();
      neptuneGroupRef.current.getWorldPosition(neptunePos);
      const distance = camera.position.distanceTo(neptunePos);
      neptuneGroupRef.current.children.forEach(child => {
        if (child.geometry instanceof THREE.SphereGeometry) {
          const r = child.geometry.parameters.radius;
          if (r === outerAtmosphereRadius || r === innerAtmosphereRadius) {
            child.visible = distance <= r * 2.5;
          }
        }
      });
    }
  });

  return (
    <group ref={neptuneGroupRef}>
      {/* Neptune Surface Mesh */}
      <mesh renderOrder={2}>
        <sphereGeometry args={[surfaceRadius, 32, 32]} />
        <shaderMaterial 
          attach="material"
          {...neptuneSurfaceShader}
          side={THREE.FrontSide}
        />
      </mesh>

      {/* Outer atmosphere layer */}
      <mesh renderOrder={1}>
        <sphereGeometry args={[outerAtmosphereRadius, 128, 640]} />
        <shaderMaterial 
          attach="material"
          {...neptuneAtmosphereShader}
          side={THREE.BackSide}
          transparent={true}
          depthWrite={false}
          blending={THREE.CustomBlending}
          blendEquation={THREE.AddEquation}
          blendSrc={THREE.SrcAlphaFactor}
          blendDst={THREE.OneMinusSrcAlphaFactor}
        />
      </mesh>

      {/* Inner atmosphere layer */}
      <mesh renderOrder={-1}>
        <sphereGeometry args={[innerAtmosphereRadius, 128, 640]} />
        <shaderMaterial 
          attach="material"
          {...neptuneAtmosphereShader}
          side={THREE.BackSide}
          transparent={true}
          depthWrite={false}
          blending={THREE.CustomBlending}
          blendEquation={THREE.AddEquation}
          blendSrc={THREE.SrcAlphaFactor}
          blendDst={THREE.OneMinusSrcAlphaFactor}
        />
      </mesh>
    </group>
  );
};

export default Neptune;
