import React, { useRef, useContext, useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { SunContext, sharedGeometries } from './Overlay1-Viewer';
import { SunContext2 } from './Overlay2-TV';

// Ellipse with dynamic edges based on light position
const ModifiedEllipseWithEdges = ({ 
  position, 
  radiusX,
  radiusY, 
  color, 
  opacity, 
  renderOrder,
  contextType = "overlay1" // Default to overlay1
}) => {
  const ellipseRef = useRef();
  const lineGroupRef = useRef();
  
  // Choose the appropriate context based on contextType
  const context1 = useContext(SunContext);
  const context2 = useContext(SunContext2);
  const { sunContext } = contextType === "overlay2" ? context2 : context1;
  
  // References for instanced meshes
  const lightCapsRef = useRef();
  const darkCapsRef = useRef();
  
  // Track visibility states for each instance
  const capsVisibility = useRef({
    lightStart: false,
    lightEnd: false,
    darkStart: false,
    darkEnd: false
  });
  
  // Store dummy objects for matrix updates
  const dummyObj = useRef(new THREE.Object3D());
  
  // Used for detecting significant changes in sun position
  const lastSunPos = useRef({ x: 0, y: 0 });
  
  // Calculate light and shadow colors from base color
  const colors = useMemo(() => {
    let r, g, b;

    // Check for hex values (including shorthand) or rgb strings.
    if (typeof color === 'string' && color.startsWith('#')) {
      // If shorthand hex like "#555", expand it to "#555555"
      if (color.length === 4) {
        r = parseInt(color[1] + color[1], 16);
        g = parseInt(color[2] + color[2], 16);
        b = parseInt(color[3] + color[3], 16);
      } else {
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
      }
    } else if (typeof color === 'string' && color.startsWith('rgb')) {
      // Extract the number values from an rgb string like "rgb(85, 85, 85)"
      const result = color.match(/rgb\s*\(\s*(\d+)[,\s]+(\d+)[,\s]+(\d+)\s*\)/);
      if (result) {
        r = parseInt(result[1], 10);
        g = parseInt(result[2], 10);
        b = parseInt(result[3], 10);
      }
    }
    
    // Default to white if not recognized
    if (r === undefined || g === undefined || b === undefined) {
      r = g = b = 255;
    }
    
    // Factors for slight brightness adjustment (20% change)
    const lightenFactor = 0.2; // 20% brighter
    const darkenFactor  = 0.2; // 20% darker
    
    // Calculate new colors (ensure they remain within 0-255)
    const lightR = Math.min(255, Math.floor(r * (1 + lightenFactor)));
    const lightG = Math.min(255, Math.floor(g * (1 + lightenFactor)));
    const lightB = Math.min(255, Math.floor(b * (1 + lightenFactor)));
    
    const darkR = Math.max(0, Math.floor(r * (1 - darkenFactor)));
    const darkG = Math.max(0, Math.floor(g * (1 - darkenFactor)));
    const darkB = Math.max(0, Math.floor(b * (1 - darkenFactor)));
    
    return {
      light: new THREE.Color(`rgb(${lightR}, ${lightG}, ${lightB})`),
      dark: new THREE.Color(`rgb(${darkR}, ${darkG}, ${darkB})`)
    };
  }, [color]);
  
  // Create ellipse geometry
  const ellipseGeometry = useMemo(() => {
    const segments = 64; // Higher number for smoother ellipse
    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    
    for (let i = 0; i <= segments; i++) {
      const angle = (i / segments) * Math.PI * 2;
      const x = Math.cos(angle) * radiusX;
      const y = Math.sin(angle) * radiusY;
      vertices.push(x, y, 0);
    }
    
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    
    // Create indices for triangulation (fan triangulation from center)
    const indices = [];
    // Add center point
    vertices.push(0, 0, 0);
    const centerIndex = vertices.length / 3 - 1;
    
    for (let i = 0; i < segments; i++) {
      indices.push(centerIndex, i, i + 1);
    }
    // Close the loop
    indices.push(centerIndex, segments, 0);
    
    geometry.setIndex(indices);
    geometry.computeVertexNormals();
    
    return geometry;
  }, [radiusX, radiusY]);
  
  // Set up the geometry and materials
  useEffect(() => {
    if (!lineGroupRef.current) return;
    
    // Clear existing children if any
    while (lineGroupRef.current.children.length > 0) {
      const child = lineGroupRef.current.children[0];
      lineGroupRef.current.remove(child);
    }
    
    // Create light and dark materials
    const lightMaterial = new THREE.MeshBasicMaterial({
      color: colors.light,
      transparent: true,
      opacity: opacity,
      side: THREE.DoubleSide,
      depthTest: false,
      depthWrite: false
    });
    
    const darkMaterial = new THREE.MeshBasicMaterial({
      color: colors.dark,
      transparent: true,
      opacity: opacity,
      side: THREE.DoubleSide,
      depthTest: false,
      depthWrite: false
    });
    
    // Create empty meshes for light and dark arcs
    const lightMesh = new THREE.Mesh(new THREE.BufferGeometry(), lightMaterial);
    const darkMesh = new THREE.Mesh(new THREE.BufferGeometry(), darkMaterial);
    
    // Set the render order
    lightMesh.renderOrder = renderOrder;
    darkMesh.renderOrder = renderOrder;
    
    // Add to group
    lineGroupRef.current.add(lightMesh);
    lineGroupRef.current.add(darkMesh);
    
    // Create instanced meshes for caps
    // 2 instances for light caps (start and end)
    const lightCaps = new THREE.InstancedMesh(
      sharedGeometries.circleGeometry,
      lightMaterial.clone(),
      2
    );
    lightCaps.renderOrder = renderOrder;
    lightCaps.count = 2;
    lineGroupRef.current.add(lightCaps);
    lightCapsRef.current = lightCaps;
    
    // 2 instances for dark caps (start and end)
    const darkCaps = new THREE.InstancedMesh(
      sharedGeometries.circleGeometry,
      darkMaterial.clone(),
      2
    );
    darkCaps.renderOrder = renderOrder;
    darkCaps.count = 2;
    lineGroupRef.current.add(darkCaps);
    darkCapsRef.current = darkCaps;
    
    // Initially hide all caps
    for (let i = 0; i < 2; i++) {
      dummyObj.current.scale.set(0, 0, 0); // Scale to 0 to hide
      dummyObj.current.updateMatrix();
      lightCaps.setMatrixAt(i, dummyObj.current.matrix);
      darkCaps.setMatrixAt(i, dummyObj.current.matrix);
    }
    lightCaps.instanceMatrix.needsUpdate = true;
    darkCaps.instanceMatrix.needsUpdate = true;
    
    // Clean up function
    return () => {
      lightMaterial.dispose();
      darkMaterial.dispose();
    };
  }, [radiusX, radiusY, colors, opacity, renderOrder]);
  
  // Update the curve based on sun position
  const updateCurve = (sunPos) => {
    if (!lineGroupRef.current || lineGroupRef.current.children.length < 4) return;
    
    const lightMesh = lineGroupRef.current.children[0];
    const darkMesh = lineGroupRef.current.children[1];
    const lightCaps = lightCapsRef.current;
    const darkCaps = darkCapsRef.current;
    
    if (!lightCaps || !darkCaps) return;
    
    // Get ellipse position
    const ellipsePos = {
      x: position[0],
      y: position[1]
    };
    
    // Calculate angle from ellipse to sun in radians
    const angleToSun = Math.atan2(sunPos.y - ellipsePos.y, sunPos.x - ellipsePos.x);
    
    // Base thickness - use the smaller radius for proportional thickness
    const baseThickness = Math.min(radiusX, radiusY) * 0.15;
    
    // Create points for light arc (90 degrees facing the sun)
    const numPoints = 50;
    const arcLength = Math.PI / 2; // 90 degrees
    
    // Light arc starts at angleToSun - arcLength/2 to center it facing the sun
    const lightStartAngle = angleToSun - arcLength/2;
    const lightEndAngle = angleToSun + arcLength/2;
    
    // Create geometry for light arc
    const lightGeometry = new THREE.BufferGeometry();
    const lightPositions = [];
    const lightIndices = [];
    
    // Create two separate arrays for inner and outer vertices
    const innerVertices = [];
    const outerVertices = [];
    
    for (let i = 0; i <= numPoints; i++) {
      const t = i / numPoints;
      const currentAngle = lightStartAngle + (t * arcLength);
      
      // Calculate bulge factor - more thickness in the middle
      // Using sine wave that peaks in the middle (at t=0.5)
      const bulgeFactor = Math.sin(t * Math.PI) * 0.5; // 0.5 controls the amount of bulge
      
      // Calculate thickness with bulge (thicker in the middle)
      const thickness = baseThickness * (1 + bulgeFactor);
      
      // Calculate ellipse points at this angle
      const outerX = Math.cos(currentAngle) * radiusX;
      const outerY = Math.sin(currentAngle) * radiusY;
      
      // Calculate normal at this point on the ellipse
      const nx = outerX / (radiusX * radiusX);
      const ny = outerY / (radiusY * radiusY);
      const normalLength = Math.sqrt(nx * nx + ny * ny);
      const normalizedNx = nx / normalLength;
      const normalizedNy = ny / normalLength;
      
      // Inner vertex (closer to center)
      const innerX = outerX - normalizedNx * thickness;
      const innerY = outerY - normalizedNy * thickness;
      
      // Store vertices in arrays
      innerVertices.push(innerX, innerY, 0.01);
      outerVertices.push(outerX, outerY, 0.01);
    }
    
    // Combine inner and outer vertices in the correct order for proper triangulation
    // First add all inner vertices
    for (let i = 0; i < innerVertices.length; i++) {
      lightPositions.push(innerVertices[i]);
    }
    
    // Then add all outer vertices
    for (let i = 0; i < outerVertices.length; i++) {
      lightPositions.push(outerVertices[i]);
    }
    
    // Create indices for triangulation
    const innerOffset = 0;
    const outerOffset = innerVertices.length / 3;
    
    for (let i = 0; i < numPoints; i++) {
      // Connect inner vertices to outer vertices
      lightIndices.push(
        innerOffset + i,
        innerOffset + i + 1,
        outerOffset + i
      );
      
      lightIndices.push(
        innerOffset + i + 1,
        outerOffset + i + 1,
        outerOffset + i
      );
    }
    
    lightGeometry.setAttribute('position', new THREE.Float32BufferAttribute(lightPositions, 3));
    lightGeometry.setIndex(lightIndices);
    lightMesh.geometry.dispose();
    lightMesh.geometry = lightGeometry;
    
    // Create geometry for dark arc (90 degrees on the opposite side from the sun)
    const darkGeometry = new THREE.BufferGeometry();
    const darkPositions = [];
    const darkIndices = [];
    
    // Create two separate arrays for inner and outer vertices
    const darkInnerVertices = [];
    const darkOuterVertices = [];
    
    // Dark arc starts at angleToSun + Math.PI - arcLength/2 to center it opposite to the sun
    const darkStartAngle = angleToSun + Math.PI - arcLength/2;
    const darkEndAngle = angleToSun + Math.PI + arcLength/2;
    
    for (let i = 0; i <= numPoints; i++) {
      const t = i / numPoints;
      const currentAngle = darkStartAngle + (t * arcLength);
      
      // Calculate bulge factor - more thickness in the middle
      const bulgeFactor = Math.sin(t * Math.PI) * 0.8; // 0.8 controls the amount of bulge
      
      // Calculate thickness with bulge (thicker in the middle)
      const thickness = baseThickness * (1 + bulgeFactor);
      
      // Calculate ellipse points at this angle
      const outerX = Math.cos(currentAngle) * radiusX;
      const outerY = Math.sin(currentAngle) * radiusY;
      
      // Calculate normal at this point on the ellipse
      const nx = outerX / (radiusX * radiusX);
      const ny = outerY / (radiusY * radiusY);
      const normalLength = Math.sqrt(nx * nx + ny * ny);
      const normalizedNx = nx / normalLength;
      const normalizedNy = ny / normalLength;
      
      // Inner vertex (closer to center)
      const innerX = outerX - normalizedNx * thickness;
      const innerY = outerY - normalizedNy * thickness;
      
      // Store vertices in arrays
      darkInnerVertices.push(innerX, innerY, 0.01);
      darkOuterVertices.push(outerX, outerY, 0.01);
    }
    
    // Combine inner and outer vertices in the correct order for proper triangulation
    // First add all inner vertices
    for (let i = 0; i < darkInnerVertices.length; i++) {
      darkPositions.push(darkInnerVertices[i]);
    }
    
    // Then add all outer vertices
    for (let i = 0; i < darkOuterVertices.length; i++) {
      darkPositions.push(darkOuterVertices[i]);
    }
    
    // Create indices for triangulation
    const darkInnerOffset = 0;
    const darkOuterOffset = darkInnerVertices.length / 3;
    
    for (let i = 0; i < numPoints; i++) {
      // Connect inner vertices to outer vertices
      darkIndices.push(
        darkInnerOffset + i,
        darkInnerOffset + i + 1,
        darkOuterOffset + i
      );
      
      darkIndices.push(
        darkInnerOffset + i + 1,
        darkOuterOffset + i + 1,
        darkOuterOffset + i
      );
    }
    
    darkGeometry.setAttribute('position', new THREE.Float32BufferAttribute(darkPositions, 3));
    darkGeometry.setIndex(darkIndices);
    darkMesh.geometry.dispose();
    darkMesh.geometry = darkGeometry;
    
    // Position and scale the end cap circles
    const capRadius = baseThickness / 2; // Use base thickness for caps
    
    // Calculate cap positions at the middle of the edge thickness
    // Light arc start cap
    const lightStartOuterX = Math.cos(lightStartAngle) * radiusX;
    const lightStartOuterY = Math.sin(lightStartAngle) * radiusY;
    const lightStartNx = lightStartOuterX / (radiusX * radiusX);
    const lightStartNy = lightStartOuterY / (radiusY * radiusY);
    const lightStartNormalLength = Math.sqrt(lightStartNx * lightStartNx + lightStartNy * lightStartNy);
    const lightStartNormalizedNx = lightStartNx / lightStartNormalLength;
    const lightStartNormalizedNy = lightStartNy / lightStartNormalLength;
    const lightStartX = lightStartOuterX - lightStartNormalizedNx * baseThickness/2;
    const lightStartY = lightStartOuterY - lightStartNormalizedNy * baseThickness/2;
    
    // Light arc end cap
    const lightEndOuterX = Math.cos(lightEndAngle) * radiusX;
    const lightEndOuterY = Math.sin(lightEndAngle) * radiusY;
    const lightEndNx = lightEndOuterX / (radiusX * radiusX);
    const lightEndNy = lightEndOuterY / (radiusY * radiusY);
    const lightEndNormalLength = Math.sqrt(lightEndNx * lightEndNx + lightEndNy * lightEndNy);
    const lightEndNormalizedNx = lightEndNx / lightEndNormalLength;
    const lightEndNormalizedNy = lightEndNy / lightEndNormalLength;
    const lightEndX = lightEndOuterX - lightEndNormalizedNx * baseThickness/2;
    const lightEndY = lightEndOuterY - lightEndNormalizedNy * baseThickness/2;
    
    // Dark arc start cap
    const darkStartOuterX = Math.cos(darkStartAngle) * radiusX;
    const darkStartOuterY = Math.sin(darkStartAngle) * radiusY;
    const darkStartNx = darkStartOuterX / (radiusX * radiusX);
    const darkStartNy = darkStartOuterY / (radiusY * radiusY);
    const darkStartNormalLength = Math.sqrt(darkStartNx * darkStartNx + darkStartNy * darkStartNy);
    const darkStartNormalizedNx = darkStartNx / darkStartNormalLength;
    const darkStartNormalizedNy = darkStartNy / darkStartNormalLength;
    const darkStartX = darkStartOuterX - darkStartNormalizedNx * baseThickness/2;
    const darkStartY = darkStartOuterY - darkStartNormalizedNy * baseThickness/2;
    
    // Dark arc end cap
    const darkEndOuterX = Math.cos(darkEndAngle) * radiusX;
    const darkEndOuterY = Math.sin(darkEndAngle) * radiusY;
    const darkEndNx = darkEndOuterX / (radiusX * radiusX);
    const darkEndNy = darkEndOuterY / (radiusY * radiusY);
    const darkEndNormalLength = Math.sqrt(darkEndNx * darkEndNx + darkEndNy * darkEndNy);
    const darkEndNormalizedNx = darkEndNx / darkEndNormalLength;
    const darkEndNormalizedNy = darkEndNy / darkEndNormalLength;
    const darkEndX = darkEndOuterX - darkEndNormalizedNx * baseThickness/2;
    const darkEndY = darkEndOuterY - darkEndNormalizedNy * baseThickness/2;
    
    // Update light cap instances
    dummyObj.current.position.set(lightStartX, lightStartY, 0.01);
    dummyObj.current.scale.set(capRadius, capRadius, 1);
    dummyObj.current.updateMatrix();
    lightCaps.setMatrixAt(0, dummyObj.current.matrix);
    
    dummyObj.current.position.set(lightEndX, lightEndY, 0.01);
    dummyObj.current.scale.set(capRadius, capRadius, 1);
    dummyObj.current.updateMatrix();
    lightCaps.setMatrixAt(1, dummyObj.current.matrix);
    
    // Update dark cap instances
    dummyObj.current.position.set(darkStartX, darkStartY, 0.01);
    dummyObj.current.scale.set(capRadius, capRadius, 1);
    dummyObj.current.updateMatrix();
    darkCaps.setMatrixAt(0, dummyObj.current.matrix);
    
    dummyObj.current.position.set(darkEndX, darkEndY, 0.01);
    dummyObj.current.scale.set(capRadius, capRadius, 1);
    dummyObj.current.updateMatrix();
    darkCaps.setMatrixAt(1, dummyObj.current.matrix);
    
    // Update instance matrices
    lightCaps.instanceMatrix.needsUpdate = true;
    darkCaps.instanceMatrix.needsUpdate = true;
    
    // Update visibility states
    capsVisibility.current = {
      lightStart: true,
      lightEnd: true,
      darkStart: true,
      darkEnd: true
    };
  };
  
  // Update curves on each frame
  useFrame(() => {
    if (!sunContext.current) return;
    
    // Only update if sun moved enough
    const dx = sunContext.current.x - lastSunPos.current.x;
    const dy = sunContext.current.y - lastSunPos.current.y;
    if (Math.sqrt(dx*dx + dy*dy) > 0.01) {
      updateCurve(sunContext.current);
      lastSunPos.current = {...sunContext.current};
    }
  });
  
  useEffect(() => {
    // Explicitly disable frustum culling for all meshes in the group
    if (lineGroupRef.current) {
      lineGroupRef.current.traverse(object => {
        if (object.isMesh || object.isLine) {
          object.frustumCulled = false;
        }
      });
    }
    
    // Also disable for the main ellipse
    if (ellipseRef.current) {
      ellipseRef.current.frustumCulled = false;
    }
  }, []);
  
  // Create a material for the ellipse
  const ellipseMaterial = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: opacity,
      side: THREE.DoubleSide,
      depthTest: false,
      depthWrite: false
    });
  }, [color, opacity]);
  
  // Create the ellipse mesh directly in useEffect
  useEffect(() => {
    if (!ellipseRef.current) return;
    
    // Create a circle and scale it to make an ellipse
    const circleGeometry = new THREE.CircleGeometry(1, 64);
    
    // Apply the geometry to the mesh
    ellipseRef.current.geometry = circleGeometry;
    
    // Scale the mesh to create an ellipse
    ellipseRef.current.scale.set(radiusX, radiusY, 1);
    
    // Apply the material
    ellipseRef.current.material = ellipseMaterial;
    
    // Set render order
    ellipseRef.current.renderOrder = renderOrder;
    
    // Disable frustum culling
    ellipseRef.current.frustumCulled = false;
    
    return () => {
      if (circleGeometry) circleGeometry.dispose();
    };
  }, [radiusX, radiusY, ellipseMaterial, renderOrder]);
  
  return (
    <group position={position}>
      {/* Main ellipse - just a placeholder, we'll set it up in useEffect */}
      <mesh ref={ellipseRef} />
      
      {/* Light and shadow curves group */}
      <group ref={lineGroupRef} />
    </group>
  );
};

export default ModifiedEllipseWithEdges;