import React, { useMemo, useRef, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

const Moon = () => {
  const moonRef = useRef();
  const materialRef = useRef();
  
  const moonShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          // Apply 98-degree rotation around X axis (different from Jupiter's 30 degrees)
          float angle = radians(98.0);
          mat3 rotationMatrix = mat3(
            1.0, 0.0, 0.0,
            0.0, cos(angle), -sin(angle),
            0.0, sin(angle), cos(angle)
          );
          
          vec3 rotatedPosition = rotationMatrix * position;
          vec3 rotatedNormal = rotationMatrix * normal;
          
          vUv = uv;
          vNormal = rotatedNormal;
          vPosition = rotatedPosition;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }

        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (3.0 - 2.0 * f);
          return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.7); // Mid gray base
          
          // Calculate angle for position-based features (removed time dependency)
          float angle = atan(vPosition.z, vPosition.x);
          // Removed time-based animation from waves
          float wave1 = sin(angle * 10.0) * 0.04;
          float wave2 = sin(angle * 8.0 + 3.14159 * 1.5) * 0.03;
          float wave3 = sin(angle * 6.0 + 3.14159 * 0.8) * 0.02;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          // Band system - removed time dependency
          float bands = noise(vec2(modifiedY * 1.0, 0.5)) * 0.7;
          bands += noise(vec2(modifiedY * 30.0, 0.25)) * 0.3;
          
          vec3 bandColor;
          
          // Create distinct gray bands
          if (modifiedY > 0.8) {
              bandColor = mix(
                  vec3(0.75),    // Light gray
                  vec3(0.8),     // Lighter gray
                  bands
              );
          } else if (modifiedY > 0.6) {
              bandColor = mix(
                  vec3(0.65),    // Mid-light gray
                  vec3(0.7),     // Slightly lighter
                  bands
              );
          } else if (modifiedY > 0.4) {
              bandColor = mix(
                  vec3(0.6),     // Mid gray
                  vec3(0.65),    // Slightly lighter
                  bands
              );
          } else if (modifiedY > 0.2) {
              bandColor = mix(
                  vec3(0.55),    // Mid-dark gray
                  vec3(0.6),     // Slightly lighter
                  bands
              );
          } else {
              bandColor = mix(
                  vec3(0.5),     // Dark gray
                  vec3(0.55),    // Slightly lighter
                  bands
              );
          }
          
          vec3 finalColor = mix(baseColor, bandColor, 0.8);
          
          // Calculate spherical coordinates for spots
          float phi = atan(vPosition.z, vPosition.x);
          float theta = acos(vPosition.y / length(vPosition));
          
          for(int i = 0; i < 168; i++) {
              float randPhi = random(vec2(float(i) * 0.1567, 0.3291)) * 2.0 * 3.14159;
              float randTheta = random(vec2(float(i) * 0.7823, 0.9451)) * 3.14159;
              
              float angularDist = acos(
                sin(theta) * sin(randTheta) * cos(phi - randPhi) + 
                cos(theta) * cos(randTheta)
              );
              
              float size = (0.15 + 0.25 * random(vec2(float(i) * 0.789, 0.32))) * 0.5;
              
              float spotOuter = step(angularDist, size);
              float spotInner = step(angularDist, size * 0.7);
              
              float colorChoice = random(vec2(float(i) * 0.345, 0.678));
              vec3 outerColor, innerColor;
              
              // Grayscale variations for spots
              if (colorChoice < 0.2) {
                  outerColor = vec3(0.75);    // Light gray
                  innerColor = vec3(0.8);     // Lighter gray
              } else if (colorChoice < 0.4) {
                  outerColor = vec3(0.65);    // Mid-light gray
                  innerColor = vec3(0.7);     // Slightly lighter
              } else if (colorChoice < 0.6) {
                  outerColor = vec3(0.6);     // Mid gray
                  innerColor = vec3(0.65);    // Slightly lighter
              } else if (colorChoice < 0.8) {
                  outerColor = vec3(0.55);    // Mid-dark gray
                  innerColor = vec3(0.6);     // Slightly lighter
              } else {
                  outerColor = vec3(0.5);     // Dark gray
                  innerColor = vec3(0.55);    // Slightly lighter
              }
              
              finalColor = mix(finalColor, outerColor, spotOuter * 1.0);
              finalColor = mix(finalColor, innerColor, spotInner * 1.0);
          }
          
          // --- Modified Shadow Logic ---
          // Compute world space position and normal, then determine how dark the shadow should be.
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          float dotProduct = dot(worldNormal, toSun);
          float alpha = 1.0;
          
          if (dotProduct < 0.9) {
            float shadowAngle = acos(-dotProduct) * 1.8;
            if (shadowAngle < 3.15) {
              float shadowStrength = smoothstep(3.15, 2.8, shadowAngle);
              shadowStrength = pow(shadowStrength, 1.5);
              finalColor = mix(finalColor, vec3(0.0), 0.45 * shadowStrength);
            }
          }
          // --- End Modified Shadow Logic ---

          gl_FragColor = vec4(finalColor, alpha);
        }
      `
    };
  }, []);

  // Update the model matrix on each frame to ensure correct shadow calculations
  useFrame(() => {
    if (moonRef.current && materialRef.current) {
      // Update the model matrix uniform with the current world matrix
      moonRef.current.updateWorldMatrix(true, false);
      materialRef.current.uniforms.modelMatrix.value.copy(moonRef.current.matrixWorld);
    }
  });

  return (
    <group>
      <mesh ref={moonRef} renderOrder={6}>
        <sphereGeometry args={[1737.4, 32, 32]} />
        <shaderMaterial 
          ref={materialRef}
          attach="material"
          {...moonShader}
          side={THREE.FrontSide}
          depthWrite={true}
          depthTest={true}
          transparent={false}
          alphaTest={0.01}
        />
      </mesh>

      {/* Thin atmosphere effect */}
      <mesh renderOrder={5}>
        <sphereGeometry args={[1738.4, 64, 32]} />
        <meshBasicMaterial 
          color="#404040"
          transparent={true}
          opacity={0.1}
          side={THREE.BackSide}
          depthWrite={false}
          depthTest={true}
        />
      </mesh>
    </group>
  );
};

export default Moon;
