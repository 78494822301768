// src/components/Teachers/games/Read.js

import React, { useState, useEffect, useRef } from 'react';
import Button from '../ui/Button';
import QuizCreator from './SlideCreator'; // Import the QuizCreator component
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported
import MoviePicker from './MoviePicker'; // Import the MoviePicker component
import { useDataController } from '../../../contexts/datacontroller'; // Import the useDataController hook
import SlideComponents from './BeeCharacter/SlideComponents'; // Import the SlideComponents component
import LessonGenerator from './LessonGenerator'; // Import the LessonGenerator component
import SlideConverter from './SlideCoverter'; // Import the SlideConverter component

// Function to add ordinal suffix to the day
const getOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

// Function to format the current date and time
const getFormattedDate = () => {
  const now = new Date();
  const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
  const month = now.toLocaleDateString('en-US', { month: 'long' });
  const day = getOrdinal(now.getDate());
  let hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
  return `${weekday} the ${day} of ${month} ${formattedTime}`;
};

// Example quiz data (without questions)
const initialQuizzes = [
  {
    id: 1,
    title: 'Math Basics',
    description: 'A quiz on basic mathematics concepts.',
  },
  {
    id: 2,
    title: 'Science Fundamentals',
    description: 'Test your knowledge on basic science topics.',
  },
  // Add more quiz objects as needed
];

// Define the SlideshowApp component
const SlideshowApp = ({ onClose }) => {
  const { activeClasses } = useDataController(); // Add this
  
  // Get the current active class ID
  const activeClassIds = Object.keys(activeClasses);
  const currentClassId = activeClassIds.length > 0 ? activeClassIds[0] : null;

  // Fullscreen State
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isQuizCreatorOpen, setIsQuizCreatorOpen] = useState(false); // State for QuizCreator
  const [isMoviePickerOpen, setIsMoviePickerOpen] = useState(false); // State for MoviePicker
  const [isSlideComponentsOpen, setIsSlideComponentsOpen] = useState(false); // Add this state
  const [isLessonGeneratorOpen, setIsLessonGeneratorOpen] = useState(false); // Add new state for LessonGenerator
  const [isSlideConverterOpen, setIsSlideConverterOpen] = useState(false); // Add new state for SlideConverter
  const slideshowRef = useRef(null);

  // Quizzes State
  const [quizzes, setQuizzes] = useState(initialQuizzes);

  // Function to toggle fullscreen
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (slideshowRef.current.requestFullscreen) {
        slideshowRef.current.requestFullscreen();
      } else if (slideshowRef.current.webkitRequestFullscreen) {
        /* Safari */
        slideshowRef.current.webkitRequestFullscreen();
      } else if (slideshowRef.current.msRequestFullscreen) {
        /* IE11 */
        slideshowRef.current.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  // Handle fullscreen change events
  useEffect(() => {
    const handleFullscreenChange = () => {
      const fullscreenElement =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;
      setIsFullscreen(!!fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Handlers for QuizCreator Modal
  const openQuizCreator = () => {
    setIsQuizCreatorOpen(true);
  };

  const closeQuizCreator = () => {
    setIsQuizCreatorOpen(false);
  };

  // Handlers for Movie Picker Modal
  const openMoviePicker = () => {
    setIsMoviePickerOpen(true);
  };

  const closeMoviePicker = () => {
    setIsMoviePickerOpen(false);
  };

  // Add these handlers
  const openSlideComponents = () => {
    setIsSlideComponentsOpen(true);
  };

  const closeSlideComponents = () => {
    setIsSlideComponentsOpen(false);
  };

  // Add new handlers for LessonGenerator
  const openLessonGenerator = () => {
    setIsLessonGeneratorOpen(true);
  };

  const closeLessonGenerator = () => {
    setIsLessonGeneratorOpen(false);
  };

  // Add new handlers for SlideConverter
  const openSlideConverter = () => {
    setIsSlideConverterOpen(true);
  };

  const closeSlideConverter = () => {
    setIsSlideConverterOpen(false);
  };

  // Handler for Search Quiz (You can implement the search functionality as needed)
  const handleSearchQuiz = () => {
    // Implement search functionality here
    console.log('Search Quiz Clicked');
  };

  // Handler for Movie Picker
  const handleMoviePicker = () => {
    // Implement movie picker functionality here
    console.log('Movie Picker Clicked');
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-black bg-opacity-50">
      {/* **Modal Container with Fullscreen Dimensions** */}
      <div
        ref={slideshowRef}
        className="flex flex-col w-full h-full bg-white rounded-none shadow-lg"
      >
        {/* **Header** */}
        <header className="relative flex flex-col items-center justify-center flex-shrink-0 p-4 border-b">
          {/* Title */}
          <h2 className="text-2xl font-bold">Read</h2>
          {/* Current Date and Time */}
          <div className="mt-2 text-sm font-medium text-gray-600">
            {getFormattedDate()}
          </div>
          {/* Close Button */}
          <Button
            onClick={onClose}
            variant="ghost"
            className="absolute text-2xl text-gray-600 top-4 right-4 hover:text-gray-800"
            aria-label="Close Slideshow App"
          >
            <FaTimes />
          </Button>
          {/* Fullscreen Toggle Button */}
          <Button
            onClick={toggleFullscreen}
            variant="ghost"
            className="absolute text-lg text-gray-600 top-4 left-4 hover:text-gray-800"
            aria-label="Toggle Fullscreen"
          >
            {isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
          </Button>
        </header>

        {/* **Banner Area** */}
        <div
          className="w-full"
          style={{
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // Adjusted shadow for consistency
          }}
        >
          {/* Banner image with fixed height */}
          <img
            src={PuzzleBanner}
            alt="Banner"
            className="object-cover w-full h-24"
          />
        </div>

        {/* **Main Content Area** */}
        <div className="flex-1 overflow-auto">
          {/* **Quiz Management Sections** */}
          <div className="flex flex-col h-full">
            {/* **Horizontal Bar with Tiles** */}
            <div className="flex justify-start p-4 bg-white shadow-md">
              {/* Library Button */}
              <button
                onClick={openQuizCreator}
                className="flex items-center justify-center flex-1 h-40 m-2 text-blue-500 transition duration-200 bg-white rounded-lg shadow-md hover:bg-gray-100"
              >
                <span className="text-xl font-semibold">Library</span>
              </button>
              {/* Movie Picker Button */}
              <button
                onClick={openMoviePicker}
                className="flex items-center justify-center flex-1 h-40 m-2 text-red-500 transition duration-200 bg-white rounded-lg shadow-md hover:bg-gray-100"
              >
                <span className="text-xl font-semibold">Movie Picker</span>
              </button>
              {/* Slide Components Button */}
              <button
                onClick={openSlideComponents}
                className="flex items-center justify-center flex-1 h-40 m-2 text-green-500 transition duration-200 bg-white rounded-lg shadow-md hover:bg-gray-100"
              >
                <span className="text-xl font-semibold">Slide Components</span>
              </button>
              {/* Lesson Generator Button */}
              <button
                onClick={openLessonGenerator}
                className="flex items-center justify-center flex-1 h-40 m-2 text-purple-500 transition duration-200 bg-white rounded-lg shadow-md hover:bg-gray-100"
              >
                <span className="text-xl font-semibold">Lesson Generator</span>
              </button>
              {/* Slide Converter Button */}
              <button
                onClick={openSlideConverter}
                className="flex items-center justify-center flex-1 h-40 m-2 text-orange-500 transition duration-200 bg-white rounded-lg shadow-md hover:bg-gray-100"
              >
                <span className="text-xl font-semibold">Slide Converter</span>
              </button>
            </div>
          </div>
        </div>

        {/* **QuizCreator Modal** */}
        {isQuizCreatorOpen && <QuizCreator onClose={closeQuizCreator} />}

        {/* **MoviePicker Modal** */}
        {isMoviePickerOpen && (
          <MoviePicker 
            onClose={closeMoviePicker} 
            classID={currentClassId}
          />
        )}

        {/* **SlideComponents Modal** */}
        {isSlideComponentsOpen && (
          <SlideComponents 
            onClose={closeSlideComponents}
            classID={currentClassId}
          />
        )}

        {/* **LessonGenerator Modal** */}
        {isLessonGeneratorOpen && (
          <LessonGenerator 
            onClose={closeLessonGenerator}
            classID={currentClassId}
          />
        )}

        {/* **SlideConverter Modal** */}
        {isSlideConverterOpen && (
          <SlideConverter 
            onClose={closeSlideConverter}
            classID={currentClassId}
          />
        )}
      </div>
    </div>
  );
};

export default SlideshowApp;
