import React from 'react';

const PersonWatchingTV2 = () => {
  return (
    <div className="flex items-center justify-center bg-gray-100 h-96">
      <svg viewBox="0 0 500 350" xmlns="http://www.w3.org/2000/svg">
<rect x="145" y="231.875" width="204.375" height="33.125" fill="hsl(189, 70%, 80%)" />
<rect x="126.875" y="255.625" width="244.375" height="90.625" fill="hsl(189, 70%,50%)" />
</svg>
    </div>
  );
};

export default PersonWatchingTV2;