// src/components/Students/apps/StudentSites.js

import React, { useState, useEffect, useRef } from 'react';
import PuzzleBanner from '../../../components/banners/animatedsvg copy 5.svg'; // Adjust the path if necessary

const StudentSites = () => {
  const [url, setUrl] = useState('https://www.nationalgeographic.com'); // Default URL
  const [iframeError, setIframeError] = useState(false);
  const iframeRef = useRef(null);
  const loadTimeoutRef = useRef(null);
  const TIMEOUT_DURATION = 10000;

  // Function to add ordinal suffix to the day
  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // Function to format the current date and time
  const getFormattedDate = () => {
    const now = new Date();
    const options = { weekday: 'long', month: 'long' };
    const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const day = getOrdinal(now.getDate());
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
    return `${weekday} the ${day} of ${month} ${formattedTime}`;
  };

  // Handle iframe loading/error management
  useEffect(() => {
    setIframeError(false);
    if (loadTimeoutRef.current) {
      clearTimeout(loadTimeoutRef.current);
    }
    loadTimeoutRef.current = setTimeout(() => {
      setIframeError(true);
    }, TIMEOUT_DURATION);
    return () => {
      clearTimeout(loadTimeoutRef.current);
    };
  }, [url]);

  const handleIframeLoad = () => {
    clearTimeout(loadTimeoutRef.current);
    setIframeError(false);
  };

  const handleIframeError = () => {
    clearTimeout(loadTimeoutRef.current);
    setIframeError(true);
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-white student-tests">
      
      {/* Title Section */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4">
        {/* Title */}
        <p className="text-3xl font-bold text-center">Browse</p>
        {/* Current Date and Time */}
        <div className="mt-2 text-xs font-bold text-gray-600">
          {getFormattedDate()}
        </div>
      </div>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full border-gray-700 border-t-5"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', // Increased shadow size and darkness
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ height: '96px', width: '100%', objectFit: 'cover' }}
        />
      </div>

      {/* Iframe Display */}
      <div className="relative flex-1 p-4 overflow-hidden">
        <iframe
          ref={iframeRef}
          src={url}
          title="Website Viewer"
          className="w-full h-[calc(100vh-240px)] border rounded"
          sandbox="allow-scripts allow-same-origin allow-forms"
          allowFullScreen
          onLoad={handleIframeLoad}
          onError={handleIframeError}
        ></iframe>
        {iframeError && (
          <div className="absolute inset-0 z-20 flex items-center justify-center bg-white bg-opacity-80">
            <p className="text-xl text-gray-700">
              Sorry, this website is being blocked by your internet provider
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentSites;
