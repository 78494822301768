// src/components/Teachers/HexagonButton1.js

import React from 'react';
import './HexagonButton1.css';
import SVGhexagon from '../../assets/SVGhexagon.svg'; // Import the SVG hexagon

// HexagonButton Component
const HexagonButton1 = ({ label, onClick, children, disabled }) => {
  const handleClick = (e) => {
    if (disabled) return; // Prevent action if disabled
    onClick && onClick(e);
  };

  return (
    <div
      className={`hexagon-button1 ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      role="button"
      aria-label={label}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !disabled) onClick();
      }}
    >
      {/* Use the imported SVG as an image */}
      <img
        src={SVGhexagon}
        alt=""
        className="hexagon-svg1"
        aria-hidden="true"
      />
      <div className="hexagon-label1">{label}</div>
      {/* Render any additional children */}
      {children}
    </div>
  );
};

// Hexagons Component with Spacer
const Hexagons1 = ({
  handleStartSlides,
  handleStartWhiteboard,
  handleStartYourHive,
  handleStartSites,
  handleStartTests,
  handleStartJournal,
  handleStartGames,
  moduleStatuses, // Ensure moduleStatuses is passed as a prop
}) => {
  const hexagons1 = [
    {
      label: 'Browse',
      onClick: handleStartSites,
      position: 'row-start-1 col-start-1',
      translateX: '104%',
      translateY: '54%',
      zIndex: 1,
      moduleKey: 'sitesactive', // Corresponding module status key
    },
    {
      label: 'Slides',
      onClick: handleStartSlides,
      position: 'row-start-1 col-start-1',
      translateX: '0%',
      translateY: '54%',
      zIndex: 1,
      moduleKey: 'slidesactive',
    },
    {
      label: 'Draw',
      onClick: handleStartWhiteboard,
      position: 'row-start-1 col-start-1',
      translateX: '52%',
      translateY: '132%',
      zIndex: 1,
      moduleKey: 'whiteboardactive',
    },
    {
      label: 'Assess',
      onClick: handleStartYourHive,
      position: 'row-start-1 col-start-1',
      translateX: '-52%',
      translateY: '132%',
      zIndex: 1,
      isProgress: true,
      moduleKey: 'progressactive',
    },
    {
      label: 'Build',
      onClick: handleStartTests,
      position: 'row-start-1 col-start-1',
      translateX: '-52%',
      translateY: '-24%',
      zIndex: 1,
      moduleKey: 'buildactive',
    },
    {
      label: 'Write',
      onClick: handleStartJournal,
      position: 'row-start-1 col-start-1',
      translateX: '-104%',
      translateY: '54%',
      zIndex: 1,
      moduleKey: 'journalactive',
    },
    {
      label: 'Read',
      onClick: handleStartGames,
      position: 'row-start-1 col-start-1',
      translateX: '52%',
      translateY: '-24%',
      zIndex: 1,
      moduleKey: 'readactive',
    },
    // Removed duplicate 'Games' entry
  ];

  return (
    <>
      <div className="grid items-center max-w-5xl grid-cols-1 grid-rows-1 gap-20 mx-auto mb-20 justify-items-center">
        {hexagons1.map((hex, index) => {
          // Determine if the hexagon should be disabled
          const isDisabled =
            hex.moduleKey &&
            moduleStatuses &&
            moduleStatuses[hex.moduleKey] !== 'active' &&
            moduleStatuses[hex.moduleKey] !== 'locked';

          return (
            <div
              key={index}
              className={`transition-transform duration-300 ${
                isDisabled ? '' : 'hover:scale-105'
              }`}
              style={{
                gridRowStart: hex.position.split(' ')[0].split('-')[2],
                gridColumnStart: hex.position.split(' ')[1].split('-')[2],
                transform: `translate(${hex.translateX}, ${hex.translateY})`,
                /* Ensure the hexagon shape via clipping */
                clipPath: 'polygon(100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%, 50% 0%)',
                WebkitClipPath: 'polygon(100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%, 50% 0%)',
                overflow: 'hidden', /* Ensure child elements are clipped */
                zIndex: hex.zIndex,
                opacity: isDisabled ? 0.5 : 1, // Optional: Adjust opacity if disabled
              }}
            >
              <HexagonButton1
                label={hex.label}
                onClick={hex.onClick}
                disabled={isDisabled}
              />
            </div>
          );
        })}
      </div>
      <div className="h-[100px]"></div>
    </>
  );
};

export { HexagonButton1, Hexagons1 };
