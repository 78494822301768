// src/components/Teachers/Tests/Tests.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import { FaTimes } from '../../assets/icons';

// Import Banner Image (Ensure you have a banner image in the specified path)
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

// Import VoxelGame Component
import VoxelGame from './VoxelGame'; // Adjust the path based on your project structure

// Function to add ordinal suffix to the day
const getOrdinal = (n) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

// Function to format the current date and time
const getFormattedDate = () => {
  const now = new Date();
  const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
  const month = now.toLocaleDateString('en-US', { month: 'long' });
  const day = getOrdinal(now.getDate());
  let hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
  return `${weekday} the ${day} of ${month} ${formattedTime}`;
};

const Tests = ({ onClose }) => {
  // State for current date and time
  const [currentDate, setCurrentDate] = useState(getFormattedDate());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(getFormattedDate());
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex flex-col w-full h-full bg-white">
      {/* Header Section with Title and Date/Time */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4 bg-gray-100 shadow-md">
        {/* Title */}
        <h2 className="text-3xl font-bold text-center">Build</h2>
        {/* Current Date and Time */}
        <div className="mt-2 text-sm font-medium text-gray-600">
          {currentDate}
        </div>
      </div>

      {/* Banner Area */}
      <div
        className="w-full"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // Adjusted shadow for consistency
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Banner"
          className="object-cover w-full h-24"
        />
      </div>

      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute text-3xl text-gray-500 top-4 right-4 hover:text-gray-700"
        aria-label="Close Build"
      >
        <FaTimes />
      </button>

      {/* Main Content Area */}
      <div className="flex flex-col flex-grow p-6 overflow-auto">
        {/* VoxelGame Component */}
        <VoxelGame />

        {/* Placeholder Content */}
        {/* If you still want to keep the placeholder, you can leave it or remove it */}
        {/* <div className="flex items-center justify-center h-full">
          <p className="text-gray-500">No content available.</p>
        </div> */}
      </div>
    </div>
  );
};

// PropTypes for validation
Tests.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Tests;
