// src/components/ViewSites/ViewSites.js

import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import PuzzleBanner from '../../../components/banners/animatedsvg.svg';
import { FaTimes } from '../../assets/icons';

const ViewSites = ({ show, handleClose }) => {
  // Function to add ordinal suffix to the day
  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  // Function to format the current date and time
  const getFormattedDate = () => {
    const now = new Date();
    const weekday = now.toLocaleDateString('en-US', { weekday: 'long' });
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const day = getOrdinal(now.getDate());
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;
    return `${weekday} the ${day} of ${month} ${formattedTime}`;
  };

  return (
    <CSSTransition in={show} timeout={300} classNames="modal" unmountOnExit>
      <div className="fixed inset-0 z-50 flex flex-col bg-white">
        {/* Header Section with Title and Date/Time */}
        <div className="flex flex-col items-center justify-center w-full px-4 py-4 bg-gray-100 shadow-md">
          {/* Title */}
          <h2 className="text-3xl font-bold text-center">Assess</h2>
          {/* Current Date and Time */}
          <div className="mt-2 text-sm font-medium text-gray-600">
            {getFormattedDate()}
          </div>
        </div>

        {/* Banner Area */}
        <div
          className="w-full"
          style={{
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
          }}
        >
          <img
            src={PuzzleBanner}
            alt="Banner"
            className="object-cover w-full h-24"
          />
        </div>

        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
          aria-label="Close Modal"
          onMouseDown={(e) => e.stopPropagation()}
        >
          <FaTimes />
        </button>
      </div>
    </CSSTransition>
  );
};

ViewSites.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ViewSites;
