// src/components/Teachers/Build/HexagonalBase.jsx

import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { useFrame } from '@react-three/fiber';

const HexagonalBase = ({ 
  position = [0, -2, 0], 
  radius = 10, // Reduced from 25 to make the hexagon smaller
  height = 1, 
  color = '#ffffff',
  segments = 6,
  rotation = [0, 0, 0],
  glowColor = '#ffffff',
  glowIntensity = 0.5,
  wallHeight = 2, // New prop for the height of the outer wall
  gridSize = 40, // New prop for the size of the grid/cavity
  borderColor = '#FFD700', // Gold color for the raised border
  bottomOffset = 0 // Added bottomOffset prop with default value of 0
}) => {
  // Create refs for the meshes
  const mainMeshRef = useRef();
  const patternMeshRef = useRef();
  const wallMeshRef = useRef();
  const borderMeshRef = useRef();
  const bottomExtensionRefs = useRef([]);
  
  // Create hexagonal prism geometry with a hexagonal hole
  const geometry = useMemo(() => {
    // Create a hexagonal shape
    const shape = new THREE.Shape();
    const angleStep = Math.PI * 2 / segments;
    
    // Start at the first vertex
    shape.moveTo(radius * Math.cos(0), radius * Math.sin(0));
    
    // Draw the hexagon
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      shape.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
    }
    
    // Create a hexagonal hole in the center that's almost as large as the outer hexagon
    const holeRadius = radius * 0.85; // Increased from gridSize/2*0.9 to 85% of outer radius
    const hole = new THREE.Path();
    
    // Draw the hexagonal hole
    hole.moveTo(holeRadius * Math.cos(0), holeRadius * Math.sin(0));
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      hole.lineTo(holeRadius * Math.cos(angle), holeRadius * Math.sin(angle));
    }
    
    // Add the hole to the shape
    shape.holes.push(hole);
    
    // Extrude settings
    const extrudeSettings = {
      steps: 1,
      depth: height,
      bevelEnabled: false
    };
    
    // Create the extruded geometry
    return new THREE.ExtrudeGeometry(shape, extrudeSettings);
  }, [radius, height, segments]);

  // Create a material with subtle texture - updated to match bottom extension brightness
  const material = useMemo(() => {
    return new THREE.MeshStandardMaterial({
      color: color,
      roughness: 0.4,
      metalness: 0.2,
      emissive: glowColor,
      emissiveIntensity: 0.08,
      flatShading: false,
      side: THREE.DoubleSide
    });
  }, [color, glowColor]);

  // Create bottom extension geometries if bottomOffset > 0
  const bottomExtensionGeometries = useMemo(() => {
    if (bottomOffset <= 0) return null;
    
    const geometries = [];
    const angleStep = Math.PI * 2 / segments;
    const innerRadius = radius * 0.9;
    
    // Create a separate geometry for each side of the hexagon
    for (let i = 0; i < segments; i++) {
      const startAngle = angleStep * i;
      const endAngle = angleStep * (i + 1);
      
      // Create a shape for this side
      const sideShape = new THREE.Shape();
      
      // Outer edge
      sideShape.moveTo(radius * Math.cos(startAngle), radius * Math.sin(startAngle));
      sideShape.lineTo(radius * Math.cos(endAngle), radius * Math.sin(endAngle));
      
      // Inner edge
      sideShape.lineTo(innerRadius * Math.cos(endAngle), innerRadius * Math.sin(endAngle));
      sideShape.lineTo(innerRadius * Math.cos(startAngle), innerRadius * Math.sin(startAngle));
      
      // Close the shape
      sideShape.lineTo(radius * Math.cos(startAngle), radius * Math.sin(startAngle));
      
      // Extrude settings for the side
      const extrudeSettings = {
        steps: 1,
        depth: bottomOffset,
        bevelEnabled: false
      };
      
      // Create the extruded geometry for this side
      const sideGeometry = new THREE.ExtrudeGeometry(sideShape, extrudeSettings);
      geometries.push(sideGeometry);
    }
    
    // Create top and bottom caps if needed
    const topCapShape = new THREE.Shape();
    const bottomCapShape = new THREE.Shape();
    
    // Draw the outer hexagon for both caps
    topCapShape.moveTo(radius * Math.cos(0), radius * Math.sin(0));
    bottomCapShape.moveTo(radius * Math.cos(0), radius * Math.sin(0));
    
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      topCapShape.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
      bottomCapShape.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
    }
    
    // Create the inner hole for both caps
    const topHole = new THREE.Path();
    const bottomHole = new THREE.Path();
    
    topHole.moveTo(innerRadius * Math.cos(0), innerRadius * Math.sin(0));
    bottomHole.moveTo(innerRadius * Math.cos(0), innerRadius * Math.sin(0));
    
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      topHole.lineTo(innerRadius * Math.cos(angle), innerRadius * Math.sin(angle));
      bottomHole.lineTo(innerRadius * Math.cos(angle), innerRadius * Math.sin(angle));
    }
    
    // Add the holes to the shapes
    topCapShape.holes.push(topHole);
    bottomCapShape.holes.push(bottomHole);
    
    // Create cap geometries
    const topCapGeometry = new THREE.ShapeGeometry(topCapShape);
    const bottomCapGeometry = new THREE.ShapeGeometry(bottomCapShape);
    
    // Add caps to the geometries array
    geometries.push(topCapGeometry);
    geometries.push(bottomCapGeometry);
    
    return geometries;
  }, [radius, segments, bottomOffset]);
  
  // Create materials for the bottom extension with different shades
  const bottomExtensionMaterials = useMemo(() => {
    if (bottomOffset <= 0) return null;
    
    const materials = [];
    
    // Base color (convert to THREE.Color to manipulate)
    const baseColor = new THREE.Color(color);
    
    // Create materials with different shades for each side of the hexagon
    for (let i = 0; i < segments; i++) {
      // Calculate shade factor based on side position
      // This creates a gradient effect around the hexagon
      const angle = (i / segments) * Math.PI * 2;
      // Use cosine to create a natural light falloff effect
      // Significantly increased the base brightness from 0.8 to 0.9 and reduced contrast even more
      const shadeFactor = 0.9 + 0.1 * Math.cos(angle);
      
      // Create a new color with the shade applied
      const sideColor = new THREE.Color().copy(baseColor).multiplyScalar(shadeFactor);
      
      materials.push(
        new THREE.MeshStandardMaterial({
          color: sideColor,
          roughness: 0.4, // Further reduced roughness for a shinier appearance
          metalness: 0.2, // Further increased metalness
          emissive: glowColor,
          emissiveIntensity: 0.08, // Significantly increased from 0.05
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.98 // Increased from 0.95
        })
      );
    }
    
    // Top cap material (even brighter)
    const topColor = new THREE.Color().copy(baseColor).multiplyScalar(1.3); // Increased from 1.2
    materials.push(
      new THREE.MeshStandardMaterial({
        color: topColor,
        roughness: 0.3, // Further reduced from 0.4
        metalness: 0.25, // Further increased from 0.15
        emissive: glowColor,
        emissiveIntensity: 0.1, // Doubled from 0.05
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.98 // Increased from 0.95
      })
    );
    
    // Bottom cap material (much less dark)
    const bottomColor = new THREE.Color().copy(baseColor).multiplyScalar(0.85); // Increased from 0.7
    materials.push(
      new THREE.MeshStandardMaterial({
        color: bottomColor,
        roughness: 0.5, // Further reduced from 0.6
        metalness: 0.2, // Further increased from 0.15
        emissive: glowColor,
        emissiveIntensity: 0.07, // Increased from 0.04
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.98 // Increased from 0.95
      })
    );
    
    return materials;
  }, [color, glowColor, bottomOffset, segments]);

  // Create pattern geometry for the top surface
  const patternGeometry = useMemo(() => {
    // Create a smaller hexagonal shape for the pattern
    const patternShape = new THREE.Shape();
    const angleStep = Math.PI * 2 / segments;
    const patternRadius = radius * 0.9; // Smaller than the main hexagon
    
    patternShape.moveTo(patternRadius * Math.cos(0), patternRadius * Math.sin(0));
    
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      patternShape.lineTo(patternRadius * Math.cos(angle), patternRadius * Math.sin(angle));
    }
    
    // Create a hexagonal hole with larger radius
    const holeRadius = radius * 0.85; // Match the main hole size
    const hole = new THREE.Path();
    
    // Draw the hexagonal hole
    hole.moveTo(holeRadius * Math.cos(0), holeRadius * Math.sin(0));
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      hole.lineTo(holeRadius * Math.cos(angle), holeRadius * Math.sin(angle));
    }
    
    // Add the hole to the shape
    patternShape.holes.push(hole);
    
    // Create a thin extrusion for the pattern
    const patternExtrudeSettings = {
      steps: 1,
      depth: 0.05,
      bevelEnabled: false
    };
    
    return new THREE.ExtrudeGeometry(patternShape, patternExtrudeSettings);
  }, [radius, segments]);

  // Create pattern material - updated to match bottom extension brightness
  const patternMaterial = useMemo(() => {
    // Create a brighter version of the base color
    const brightColor = new THREE.Color(color).multiplyScalar(1.3);
    
    return new THREE.MeshStandardMaterial({
      color: brightColor, // Use brighter color for the pattern
      roughness: 0.3,
      metalness: 0.25,
      emissive: glowColor,
      emissiveIntensity: 0.1,
      side: THREE.DoubleSide
    });
  }, [color, glowColor]);

  // Create wall geometry for the raised edge
  const wallGeometry = useMemo(() => {
    const wallShape = new THREE.Shape();
    const angleStep = Math.PI * 2 / segments;
    const holeRadius = radius * 0.85; // Match the main hole size
    
    // Start with the outer hexagon perimeter
    wallShape.moveTo(radius * Math.cos(0), radius * Math.sin(0));
    
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      wallShape.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
    }
    
    // Create a hexagonal hole for the inner cavity
    const innerPath = new THREE.Path();
    
    // Draw the hexagonal hole
    innerPath.moveTo(holeRadius * Math.cos(0), holeRadius * Math.sin(0));
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      innerPath.lineTo(holeRadius * Math.cos(angle), holeRadius * Math.sin(angle));
    }
    
    // Add the inner path as a hole
    wallShape.holes.push(innerPath);
    
    // Extrude settings for the wall
    const wallExtrudeSettings = {
      steps: 2,
      depth: wallHeight,
      bevelEnabled: true,
      bevelThickness: 0.2,
      bevelSize: 0.1,
      bevelOffset: 0,
      bevelSegments: 3
    };
    
    return new THREE.ExtrudeGeometry(wallShape, wallExtrudeSettings);
  }, [radius, wallHeight, segments]);

  // Create wall material - updated to match bottom extension brightness
  const wallMaterial = useMemo(() => {
    // Create materials with different shades for each side of the hexagon wall
    const materials = [];
    const baseColor = new THREE.Color(color);
    
    // Create a separate material for each side of the hexagon wall
    for (let i = 0; i < segments; i++) {
      const angle = (i / segments) * Math.PI * 2;
      // Use cosine to create a natural light falloff effect
      const shadeFactor = 0.9 + 0.1 * Math.cos(angle);
      
      // Create a new color with the shade applied
      const sideColor = new THREE.Color().copy(baseColor).multiplyScalar(shadeFactor);
      
      materials.push(
        new THREE.MeshStandardMaterial({
          color: sideColor,
          roughness: 0.4,
          metalness: 0.2,
          emissive: glowColor,
          emissiveIntensity: 0.08,
          side: THREE.DoubleSide
        })
      );
    }
    
    return materials;
  }, [color, glowColor, segments]);

  // Create inner wall geometry for the hexagonal cavity
  const innerWallGeometry = useMemo(() => {
    const holeRadius = radius * 0.85; // Match the main hole size
    const innerHexRadius = radius * 0.9; // Inner radius of the hexagon
    const angleStep = Math.PI * 2 / segments;
    
    // Create a shape for the inner wall
    const shape = new THREE.Shape();
    
    // First, add the inner hexagon vertices
    shape.moveTo(innerHexRadius * Math.cos(0), innerHexRadius * Math.sin(0));
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      shape.lineTo(innerHexRadius * Math.cos(angle), innerHexRadius * Math.sin(angle));
    }
    
    // Create the inner hexagonal hole
    const hole = new THREE.Path();
    hole.moveTo(holeRadius * Math.cos(0), holeRadius * Math.sin(0));
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      hole.lineTo(holeRadius * Math.cos(angle), holeRadius * Math.sin(angle));
    }
    
    // Add the inner hexagon as a hole
    shape.holes.push(hole);
    
    // Extrude settings for the inner wall with bevel for smoother edges
    const wallExtrudeSettings = {
      steps: 2,
      depth: wallHeight,
      bevelEnabled: true,
      bevelThickness: 0.2,
      bevelSize: 0.1,
      bevelOffset: 0,
      bevelSegments: 3
    };
    
    return new THREE.ExtrudeGeometry(shape, wallExtrudeSettings);
  }, [radius, wallHeight, segments]);

  // Create inner wall material - updated to match bottom extension brightness
  const innerWallMaterial = useMemo(() => {
    // Create a slightly darker version of the base color
    const darkerColor = new THREE.Color(color).multiplyScalar(0.85);
    
    return new THREE.MeshStandardMaterial({
      color: darkerColor,
      roughness: 0.5,
      metalness: 0.2,
      emissive: glowColor,
      emissiveIntensity: 0.07,
      side: THREE.DoubleSide
    });
  }, [color, glowColor]);

  // Create a raised border geometry for the outer edge of the hexagon
  const borderGeometry = useMemo(() => {
    const shape = new THREE.Shape();
    const angleStep = Math.PI * 2 / segments;
    
    // Start at the first vertex
    shape.moveTo(radius * Math.cos(0), radius * Math.sin(0));
    
    // Draw the hexagon
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      shape.lineTo(radius * Math.cos(angle), radius * Math.sin(angle));
    }
    
    // Create a slightly smaller hexagon for the inner hole
    const innerRadius = radius * 0.9;
    const hole = new THREE.Path();
    
    hole.moveTo(innerRadius * Math.cos(0), innerRadius * Math.sin(0));
    
    for (let i = 1; i <= segments; i++) {
      const angle = angleStep * i;
      hole.lineTo(innerRadius * Math.cos(angle), innerRadius * Math.sin(angle));
    }
    
    // Add the hole to the shape
    shape.holes.push(hole);
    
    // Extrude settings for the border
    const extrudeSettings = {
      steps: 1,
      depth: 0.3, // Height of the raised border
      bevelEnabled: true,
      bevelThickness: 0.2,
      bevelSize: 0.1,
      bevelOffset: 0,
      bevelSegments: 3
    };
    
    return new THREE.ExtrudeGeometry(shape, extrudeSettings);
  }, [radius, segments]);

  // Create border material (gold color)
  const borderMaterial = useMemo(() => {
    return new THREE.MeshStandardMaterial({
      color: borderColor,
      roughness: 0.2,
      metalness: 0.8,
      emissive: '#FFD700',
      emissiveIntensity: 0.3, // Increased to a constant bright value
      flatShading: false
    });
  }, [borderColor]);

  // Create a connecting wall geometry to join the hexagonal cavity to the bottom cap
  const connectingWallGeometry = useMemo(() => {
    if (bottomOffset <= 0) return null;
    
    const holeRadius = radius * 0.85; // Match the main hole size
    const innerRadius = radius * 0.9; // Inner radius of the hexagon
    const angleStep = Math.PI * 2 / segments;
    
    // Create geometries for connecting walls
    const wallGeometries = [];
    
    // For each segment of the hexagon
    for (let i = 0; i < segments; i++) {
      const startAngle = angleStep * i;
      const endAngle = angleStep * (i + 1);
      
      // Create points for the current segment
      const outerStart = [
        holeRadius * Math.cos(startAngle),
        holeRadius * Math.sin(startAngle)
      ];
      const outerEnd = [
        holeRadius * Math.cos(endAngle),
        holeRadius * Math.sin(endAngle)
      ];
      
      // Create a shape for this connecting wall
      const wallShape = new THREE.Shape();
      
      // Define the wall segment
      wallShape.moveTo(outerStart[0], outerStart[1]);
      wallShape.lineTo(outerEnd[0], outerEnd[1]);
      
      // Extrude down
      const extrudeSettings = {
        steps: 1,
        depth: bottomOffset,
        bevelEnabled: false
      };
      
      const wallGeometry = new THREE.ExtrudeGeometry(wallShape, extrudeSettings);
      wallGeometries.push(wallGeometry);
    }
    
    return wallGeometries;
  }, [radius, segments, bottomOffset]);

  // Create material for the connecting walls
  const connectingWallMaterial = useMemo(() => {
    if (bottomOffset <= 0) return null;
    
    // Use a slightly darker shade than the main color
    const wallColor = new THREE.Color(color).multiplyScalar(0.8);
    
    return new THREE.MeshStandardMaterial({
      color: wallColor,
      roughness: 0.5,
      metalness: 0.2,
      emissive: glowColor,
      emissiveIntensity: 0.07,
      side: THREE.DoubleSide
    });
  }, [color, glowColor, bottomOffset]);

  // Add subtle noise to the geometry vertices for a more natural look
  useMemo(() => {
    if (geometry) {
      const positionAttribute = geometry.attributes.position;
      const vertex = new THREE.Vector3();
      
      for (let i = 0; i < positionAttribute.count; i++) {
        vertex.fromBufferAttribute(positionAttribute, i);
        
        // Only add noise to the top face
        if (Math.abs(vertex.z - height) < 0.01) {
          vertex.z += (Math.random() - 0.5) * 0.05;
        }
        
        positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
      }
      
      positionAttribute.needsUpdate = true;
      geometry.computeVertexNormals();
    }
  }, [geometry, height]);

  // Animation for the glow effect - updated for all components
  useFrame((state) => {
    if (patternMeshRef.current) {
      const time = state.clock.getElapsedTime();
      patternMeshRef.current.material.emissiveIntensity = 0.15 + Math.sin(time * 0.5) * 0.05;
    }
    
    if (wallMeshRef.current && wallMeshRef.current.material) {
      const time = state.clock.getElapsedTime();
      // If material is an array, animate each material
      if (Array.isArray(wallMeshRef.current.material)) {
        wallMeshRef.current.material.forEach((mat, index) => {
          mat.emissiveIntensity = 0.12 + Math.sin(time * 0.3 + index * 0.1) * 0.05;
        });
      } else {
        // Otherwise animate the single material
        wallMeshRef.current.material.emissiveIntensity = 0.12 + Math.sin(time * 0.3) * 0.05;
      }
    }
    
    // Add animation for bottom extension meshes if they exist
    if (bottomExtensionRefs.current.length > 0) {
      const time = state.clock.getElapsedTime();
      // Animate each mesh's material with significantly increased brightness
      bottomExtensionRefs.current.forEach((mesh, index) => {
        if (mesh && mesh.material) {
          mesh.material.emissiveIntensity = 0.08 + Math.sin(time * 0.2 + index * 0.1) * 0.04;
        }
      });
    }
  });

  return (
    <group position={position} rotation={rotation}>
      {/* Main hexagonal base */}
      
      {/* Pattern on top - positioned exactly at height to avoid z-fighting */}
      <mesh 
        ref={patternMeshRef}
        geometry={patternGeometry} 
        material={patternMaterial}
        position={[0, 0, height]} // Exactly at the top of the main surface
        renderOrder={2}
      />
      
      {/* Raised gold border on the outer edge of the hexagon */}
      <mesh 
        ref={borderMeshRef}
        geometry={borderGeometry} 
        material={borderMaterial}
        position={[0, 0, height + wallHeight - 0.05]} // Position just below the top of the wall
        renderOrder={5}
      />
      
      {/* Inner wall - for the hexagonal cavity */}
      <mesh 
        geometry={innerWallGeometry} 
        material={innerWallMaterial}
        position={[0, 0, height]} // Start at the top of the base
        renderOrder={4}
      />
      
      {/* Bottom extension - only rendered if bottomOffset > 0 */}
      {bottomOffset > 0 && bottomExtensionGeometries && bottomExtensionMaterials && (
        <>
          {/* Render each side of the hexagonal tower with its own material */}
          {bottomExtensionGeometries.slice(0, segments).map((geometry, index) => (
            <mesh
              key={`side-${index}`}
              ref={el => {
                if (!bottomExtensionRefs.current[index]) {
                  bottomExtensionRefs.current[index] = el;
                }
              }}
              geometry={geometry}
              material={bottomExtensionMaterials[index]}
              position={[0, 0, -bottomOffset + 24]} // Position at the bottom
              renderOrder={0}
              castShadow
            />
          ))}
          
          {/* Top cap */}
          <mesh
            ref={el => {
              if (!bottomExtensionRefs.current[segments]) {
                bottomExtensionRefs.current[segments] = el;
              }
            }}
            geometry={bottomExtensionGeometries[segments]}
            material={bottomExtensionMaterials[segments]}
            position={[0, 0, 20]} // Position at the top of the tower
            renderOrder={0}
            castShadow
          />
          
          {/* Bottom cap */}
          <mesh
            ref={el => {
              if (!bottomExtensionRefs.current[segments + 1]) {
                bottomExtensionRefs.current[segments + 1] = el;
              }
            }}
            geometry={bottomExtensionGeometries[segments + 1]}
            material={bottomExtensionMaterials[segments + 1]}
            position={[0, 0, -bottomOffset + 20]} // Position at the bottom of the tower
            rotation={[Math.PI, 0, 0]} // Flip to face downward
            renderOrder={0}
            castShadow
          />
          
          {/* Connecting walls from hexagonal cavity to hexagon */}
          {connectingWallGeometry && connectingWallGeometry.map((geometry, index) => (
            <mesh
              key={`connecting-wall-${index}`}
              geometry={geometry}
              material={connectingWallMaterial}
              position={[0, 0, -bottomOffset + 24]} // Align with the bottom of the structure
              renderOrder={1}
              castShadow
            />
          ))}
        </>
      )}
    </group>
  );
};

HexagonalBase.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number),
  radius: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  segments: PropTypes.number,
  rotation: PropTypes.arrayOf(PropTypes.number),
  glowColor: PropTypes.string,
  glowIntensity: PropTypes.number,
  wallHeight: PropTypes.number,
  gridSize: PropTypes.number,
  borderColor: PropTypes.string,
  bottomOffset: PropTypes.number // Added prop type for bottomOffset
};

export default HexagonalBase; 