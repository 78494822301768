// JoinCode.js

import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Rnd } from 'react-rnd';
import { Minus, Copy, X } from 'lucide-react';

const MINIMIZED_SIZE = 60; // Define a constant for minimized size

const JoinCode = React.memo(({ classId, classCode, onClose }) => {
  console.log("JoinCode component rendering with classCode:", classCode);
  
  // State declarations
  const [dimensions, setDimensions] = useState({
    width: 300,
    height: 300,
    x: window.innerWidth / 2 - 150, // Center horizontally
    y: window.innerHeight / 2 - 150, // Center vertically
  });

  const prevDimensionsRef = useRef({
    width: 300,
    height: 300,
    x: window.innerWidth / 2 - 150,
    y: window.innerHeight / 2 - 150,
  });

  const [isMinimized, setIsMinimized] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // Refs for click vs drag detection
  const mouseDownTimeRef = useRef(0);
  const mouseDownPosRef = useRef({ x: 0, y: 0 });

  // Memoize styles for performance
  const styles = useMemo(() => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: isMinimized ? '#10b981' : 'rgba(255, 255, 255, 0.95)',
      borderRadius: isMinimized ? '50%' : '16px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
      justifyContent: 'space-between',
      overflow: 'hidden',
      boxSizing: 'border-box',
      transition: 'border-radius 0.5s ease, background-color 0.5s ease',
      position: 'relative',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between', // Changed to space-between for close button
      alignItems: 'center',
      padding: '8px 12px',
      backgroundColor: 'transparent',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      cursor: 'move',
      userSelect: 'none',
      flexShrink: 0,
    },
    title: {
      color: '#4b5563',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    controlButton: {
      background: 'none',
      border: 'none',
      color: '#4b5563',
      cursor: 'pointer',
      padding: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      transition: 'transform 0.2s, color 0.2s',
      borderRadius: '4px',
      '&:hover': {
        color: '#000',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      padding: '16px',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
    codeDisplay: {
      fontSize: '42px',
      fontFamily: 'monospace',
      fontWeight: 'bold',
      color: '#1f2937',
      letterSpacing: '0.2em',
      textAlign: 'center',
      marginBottom: '16px',
      userSelect: 'all',
      display: isMinimized ? 'none' : 'block',
    },
    buttons: {
      display: isMinimized ? 'none' : 'flex',
      gap: '8px',
      marginTop: '16px',
      width: '100%',
      justifyContent: 'center',
    },
    copyButton: {
      padding: '10px 16px',
      backgroundColor: '#3b82f6',
      color: 'white',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '16px',
      fontWeight: '500',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#2563eb',
      },
    },
    minimizedIcon: {
      display: isMinimized ? 'flex' : 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '24px',
      color: 'white',
    },
    copyStatus: {
      marginTop: '8px',
      fontSize: '14px',
      color: '#10b981',
      opacity: isCopied ? 1 : 0,
      transition: 'opacity 0.3s ease',
    }
  }), [isMinimized, isCopied]);

  // Event handlers
  const copyToClipboard = useCallback(() => {
    if (classCode) {
      navigator.clipboard.writeText(classCode).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    }
  }, [classCode]);

  const minimize = useCallback(() => {
    if (!isMinimized) {
      // Store current dimensions in ref
      prevDimensionsRef.current = { ...dimensions };

      // Calculate center point
      const centerX = dimensions.x + dimensions.width / 2;
      const centerY = dimensions.y + dimensions.height / 2;

      // Calculate new position to maintain center point
      const newX = centerX - MINIMIZED_SIZE / 2;
      const newY = centerY - MINIMIZED_SIZE / 2;

      setDimensions({
        width: MINIMIZED_SIZE,
        height: MINIMIZED_SIZE,
        x: newX,
        y: newY,
      });
      setIsMinimized(true);
    }
  }, [isMinimized, dimensions]);

  const restore = useCallback(() => {
    if (isMinimized) {
      const prev = prevDimensionsRef.current;
      
      // Calculate center point of minimized window
      const centerX = dimensions.x + dimensions.width / 2;
      const centerY = dimensions.y + dimensions.height / 2;
      
      // Calculate new position to maintain center point
      const newX = centerX - prev.width / 2;
      const newY = centerY - prev.height / 2;

      setDimensions({
        width: prev.width,
        height: prev.height,
        x: newX,
        y: newY,
      });
      setIsMinimized(false);
    }
  }, [isMinimized, dimensions]);

  // Handle Rnd resize stop to update dimensions
  const handleResizeStop = useCallback((e, direction, ref, delta, position) => {
    setDimensions({
      width: parseInt(ref.style.width, 10),
      height: parseInt(ref.style.height, 10),
      x: position.x,
      y: position.y,
    });
  }, []);

  // Handle Rnd drag stop to update position
  const handleDragStop = useCallback((e, d) => {
    setDimensions((prev) => ({
      ...prev,
      x: d.x,
      y: d.y,
    }));
  }, []);

  // Click vs Drag logic for minimized floating button
  const handleMouseDown = useCallback((e) => {
    mouseDownTimeRef.current = Date.now();
    mouseDownPosRef.current = { x: e.clientX, y: e.clientY };
  }, []);

  const handleMouseUp = useCallback((e) => {
    const clickThreshold = 200; // milliseconds
    const moveThreshold = 5; // pixels

    const mouseUpTime = Date.now();
    const timeDiff = mouseUpTime - mouseDownTimeRef.current;

    const deltaX = Math.abs(e.clientX - mouseDownPosRef.current.x);
    const deltaY = Math.abs(e.clientY - mouseDownPosRef.current.y);
    const moveDiff = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    if (timeDiff < clickThreshold && moveDiff < moveThreshold) {
      restore();
    }
  }, [restore]);

  return (
    <Rnd
      size={{ width: dimensions.width, height: dimensions.height }}
      position={{ x: dimensions.x, y: dimensions.y }}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
      minWidth={isMinimized ? MINIMIZED_SIZE : 200}
      minHeight={isMinimized ? MINIMIZED_SIZE : 200}
      bounds="window"
      aspectRatio={isMinimized ? 1 : undefined}
      style={{
        zIndex: 1000,
        overflow: 'hidden',
        cursor: isMinimized ? 'grab' : 'default',
      }}
      enableResizing={!isMinimized}
      disableDragging={false}
      dragHandleClassName={isMinimized ? undefined : "drag-handle"}
    >
      <div 
        style={styles.container}
        onMouseDown={isMinimized ? handleMouseDown : undefined}
        onMouseUp={isMinimized ? handleMouseUp : undefined}
      >
        {/* Header */}
        <div style={styles.header} className="drag-handle">
          {!isMinimized && <div style={styles.title}>Class Join Code</div>}
          <div style={styles.controls}>
            {!isMinimized && (
              <>
                <button
                  onClick={minimize}
                  style={styles.controlButton}
                  title="Minimize"
                >
                  <Minus size={16} />
                </button>
                <button
                  onClick={onClose}
                  style={styles.controlButton}
                  title="Close"
                >
                  <X size={16} />
                </button>
              </>
            )}
          </div>
        </div>

        {/* Body */}
        <div style={styles.body}>
          {!isMinimized && (
            <>
              <div style={styles.codeDisplay}>
                {classCode || 'No code'}
              </div>
              <div style={styles.copyStatus}>
                {isCopied ? 'Copied!' : ''}
              </div>
              <button 
                style={styles.copyButton} 
                onClick={copyToClipboard}
                disabled={!classCode}
              >
                <Copy size={16} />
                <span>Copy Code</span>
              </button>
            </>
          )}
        </div>

        {/* Minimized View */}
        {isMinimized && (
          <div style={styles.minimizedIcon}>
            🔑
          </div>
        )}
      </div>
    </Rnd>
  );
});

export default JoinCode;

