/* src/components/Header.js */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, Menu } from 'lucide-react';
import brightbeehiveslogo from '../../assets/BrightBeehives/BightBeehivesLogo.png'; // Corrected path

const Header = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openMobileSubmenus, setOpenMobileSubmenus] = useState({
    solutions: false,
    why: false,
    resources: false,
  });

  const toggleDropdown = (menu) => {
    setOpenDropdown((prev) => (prev === menu ? null : menu));
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const toggleMobileSubmenu = (menu) => {
    setOpenMobileSubmenus((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest('.dropdown') &&
        !event.target.closest('.mobile-menu-button')
      ) {
        setOpenDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="relative z-30 p-3 bg-white shadow-md animate-fadeIn">
      <div className="flex flex-col items-center xl:flex-row xl:justify-between xl:items-center">
        {/* Logo */}
        <div className="flex-shrink-0 mb-4 xl:mb-0">
          <Link to="/">
            <img
              src={brightbeehiveslogo}
              alt="Quick Thinkers"
              className="w-[200px] md:w-[250px] lg:w-[300px] h-auto"
            />
          </Link>
        </div>

        {/* Navigation and Hamburger Menu */}
        <div className="flex items-center justify-center xl:justify-start xl:space-x-4">
          {/* Hamburger Menu Button (Visible on Mobile) */}
          <div className="xl:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-gray-800 mobile-menu-button focus:outline-none"
              aria-label="Toggle menu"
            >
              {isMobileMenuOpen ? (
                <ChevronUp className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden xl:flex xl:space-x-6">
            {/* Solutions Dropdown */}
            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown('solutions')}
                className="flex items-center text-xl font-bold text-gray-800 transition-colors hover:text-yellow-600 focus:outline-none whitespace-nowrap"
                aria-haspopup="true"
                aria-expanded={openDropdown === 'solutions'}
              >
                Solutions
                {openDropdown === 'solutions' ? (
                  <ChevronUp className="w-4 h-4 ml-1 transition-transform duration-300 transform rotate-180" />
                ) : (
                  <ChevronDown className="w-4 h-4 ml-1 transition-transform duration-300" />
                )}
              </button>
              {openDropdown === 'solutions' && (
                <div className="absolute left-0 z-50 px-4 py-4 mt-2 bg-white rounded-lg shadow-lg w-80 animate-slideDown">
                  <div className="px-4 py-2">
                    <Link
                      to="/solutions#educators"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Educators
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Spend less time administrating and more time engaging students.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/solutions#schools-districts"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Schools &amp; Districts
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Empower teachers with the tools and data to enrich the student experience.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/solutions#tutors"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Tutors
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Build better connections with students.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/solutions#higher-ed"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Higher Ed
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Supplement on-campus solutions with targeted academic support.
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Why Quick Thinkers Dropdown */}
            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown('why')}
                className="flex items-center text-xl font-bold text-gray-800 transition-colors hover:text-yellow-600 focus:outline-none whitespace-nowrap"
                aria-haspopup="true"
                aria-expanded={openDropdown === 'why'}
              >
                Why Bright Beehives
                {openDropdown === 'why' ? (
                  <ChevronUp className="w-4 h-4 ml-1 transition-transform duration-300 transform rotate-180" />
                ) : (
                  <ChevronDown className="w-4 h-4 ml-1 transition-transform duration-300" />
                )}
              </button>
              {openDropdown === 'why' && (
                <div className="absolute left-0 z-50 px-4 py-4 mt-2 bg-white rounded-lg shadow-lg w-80 animate-slideDown">
                  <div className="px-4 py-2">
                    <Link
                      to="/why-quick-thinkers#customer-stories"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Customer Stories
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Stories of school, district, and classroom success.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/why-quick-thinkers#integration-partners"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Integration and Partners
                    </Link>
                    <p className="mt-1 text-gray-600">
                      The key connections that make our platform powerful.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/why-quick-thinkers#efficacy"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Efficacy
                    </Link>
                    <p className="mt-1 text-gray-600">
                      The data and models behind our platform and tools.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/why-quick-thinkers#privacy"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Privacy
                    </Link>
                    <p className="mt-1 text-gray-600">
                      How we safeguard personal and educational data.
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Resources Dropdown */}
            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown('resources')}
                className="flex items-center text-xl font-bold text-gray-800 transition-colors hover:text-yellow-600 focus:outline-none whitespace-nowrap"
                aria-haspopup="true"
                aria-expanded={openDropdown === 'resources'}
              >
                Resources
                {openDropdown === 'resources' ? (
                  <ChevronUp className="w-4 h-4 ml-1 transition-transform duration-300 transform rotate-180" />
                ) : (
                  <ChevronDown className="w-4 h-4 ml-1 transition-transform duration-300" />
                )}
              </button>
              {openDropdown === 'resources' && (
                <div className="absolute left-0 z-50 py-2 mt-2 bg-white rounded-lg shadow-lg w-72 animate-slideDown">
                  <Link
                    to="/resources#blog"
                    className="block px-4 py-2 text-gray-700 transition-colors duration-300 hover:bg-yellow-100"
                    onClick={() => setOpenDropdown(null)}
                  >
                    Blog
                  </Link>
                  <Link
                    to="/resources#help-center"
                    className="block px-4 py-2 text-gray-700 transition-colors duration-300 hover:bg-yellow-100"
                    onClick={() => setOpenDropdown(null)}
                  >
                    Help Center
                  </Link>
                  <Link
                    to="/resources#webinars"
                    className="block px-4 py-2 text-gray-700 transition-colors duration-300 hover:bg-yellow-100"
                    onClick={() => setOpenDropdown(null)}
                  >
                    Webinars
                  </Link>
                </div>
              )}
            </div>

            {/* About Link */}
            <Link
              to="/about"
              className="text-xl font-bold text-gray-800 transition-colors hover:text-yellow-600 whitespace-nowrap"
            >
              About
            </Link>

            {/* Pricing Link */}
            <Link
              to="/pricing"
              className="text-xl font-bold text-gray-800 transition-colors hover:text-yellow-600 whitespace-nowrap"
            >
              Pricing
            </Link>

            {/* Planning Link */}
            <Link
              to="/planning"
              className="text-xl font-bold text-gray-800 transition-colors hover:text-yellow-600 whitespace-nowrap"
            >
              Planning
            </Link>
          </nav>
        </div>

        {/* Login Buttons */}
        <div className="hidden xl:flex xl:items-center xl:space-x-4">
          <Link
            to="/login"
            className="px-4 py-2 text-center text-white transition-all duration-300 transform bg-yellow-500 rounded-full shadow-md whitespace-nowrap hover:bg-yellow-600"
          >
            Student Login
          </Link>
          <Link
            to="/teacherlogin"
            className="px-4 py-2 text-center text-white transition-all duration-300 transform bg-yellow-500 rounded-full shadow-md whitespace-nowrap hover:bg-yellow-600"
          >
            Teacher Login
          </Link>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {isMobileMenuOpen && (
        <div className="absolute left-0 z-40 w-full bg-white shadow-md top-full xl:hidden">
          <nav className="flex flex-col p-4 space-y-4">
            {/* Solutions Mobile Submenu */}
            <div className="flex flex-col">
              <button
                onClick={() => toggleMobileSubmenu('solutions')}
                className="flex items-center justify-between w-full text-xl font-bold text-gray-800 hover:text-yellow-600 focus:outline-none"
              >
                <span>Solutions</span>
                {openMobileSubmenus.solutions ? (
                  <ChevronUp className="w-4 h-4" />
                ) : (
                  <ChevronDown className="w-4 h-4" />
                )}
              </button>
              {openMobileSubmenus.solutions && (
                <div className="mt-2 ml-4 space-y-2">
                  <Link
                    to="/solutions#educators"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Educators
                  </Link>
                  <Link
                    to="/solutions#schools-districts"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Schools &amp; Districts
                  </Link>
                  <Link
                    to="/solutions#tutors"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Tutors
                  </Link>
                  <Link
                    to="/solutions#higher-ed"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Higher Ed
                  </Link>
                </div>
              )}
            </div>

            {/* Why Quick Thinkers Mobile Submenu */}
            <div className="flex flex-col">
              <button
                onClick={() => toggleMobileSubmenu('why')}
                className="flex items-center justify-between w-full text-xl font-bold text-gray-800 hover:text-yellow-600 focus:outline-none"
              >
                <span>Why Quick Thinkers</span>
                {openMobileSubmenus.why ? (
                  <ChevronUp className="w-4 h-4" />
                ) : (
                  <ChevronDown className="w-4 h-4" />
                )}
              </button>
              {openMobileSubmenus.why && (
                <div className="mt-2 ml-4 space-y-2">
                  <Link
                    to="/why-quick-thinkers#customer-stories"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Customer Stories
                  </Link>
                  <Link
                    to="/why-quick-thinkers#integration-partners"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Integration and Partners
                  </Link>
                  <Link
                    to="/why-quick-thinkers#efficacy"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Efficacy
                  </Link>
                  <Link
                    to="/why-quick-thinkers#privacy"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Privacy
                  </Link>
                </div>
              )}
            </div>

            {/* Resources Mobile Submenu */}
            <div className="flex flex-col">
              <button
                onClick={() => toggleMobileSubmenu('resources')}
                className="flex items-center justify-between w-full text-xl font-bold text-gray-800 hover:text-yellow-600 focus:outline-none"
              >
                <span>Resources</span>
                {openMobileSubmenus.resources ? (
                  <ChevronUp className="w-4 h-4" />
                ) : (
                  <ChevronDown className="w-4 h-4" />
                )}
              </button>
              {openMobileSubmenus.resources && (
                <div className="mt-2 ml-4 space-y-2">
                  <Link
                    to="/resources#blog"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Blog
                  </Link>
                  <Link
                    to="/resources#help-center"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Help Center
                  </Link>
                  <Link
                    to="/resources#webinars"
                    className="block text-gray-700 hover:text-yellow-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Webinars
                  </Link>
                </div>
              )}
            </div>

            {/* About Link */}
            <Link
              to="/about"
              className="text-xl font-bold text-gray-800 hover:text-yellow-600"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              About
            </Link>

            {/* Pricing Link */}
            <Link
              to="/pricing"
              className="text-xl font-bold text-gray-800 hover:text-yellow-600"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Pricing
            </Link>

            {/* Planning Link */}
            <Link
              to="/planning"
              className="text-xl font-bold text-gray-800 hover:text-yellow-600"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Planning
            </Link>

            {/* Login Buttons (Moved Inside Mobile Menu) */}
            <div className="flex flex-col mt-4 space-y-4">
              <Link
                to="/login"
                className="px-4 py-2 text-center text-white bg-yellow-500 rounded-full shadow-md hover:bg-yellow-600"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Student Login
              </Link>
              <Link
                to="/teacherlogin"
                className="px-4 py-2 text-center text-white bg-yellow-500 rounded-full shadow-md hover:bg-yellow-600"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Teacher Login
              </Link>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
