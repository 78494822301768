// BeeEyes.js - Module for eye animation and appearance

export class BeeEyes {
  constructor() {
    // Default eye configuration
    this.config = {
      topEyeSquareHeight: 50,
      bottomEyeSquareHeight: 50,
      topEyeCircleHeight: 50,
      bottomEyeCircleHeight: 50,
      pupilSize: 0.4,
      eyeSpacing: 90,
      eyeY: 380
    };
    
    // Animation state to track current and target values
    this.animationState = {
      current: {
        topEyeSquareHeight: 3,
        bottomEyeSquareHeight: 1,
        topEyeCircleHeight: 3,
        bottomEyeCircleHeight: 1
      },
      target: {
        topEyeSquareHeight: 3,
        bottomEyeSquareHeight: 1,
        topEyeCircleHeight: 3,
        bottomEyeCircleHeight: 1
      },
      // Animation settings
      duration: 500, // milliseconds
      startTime: null,
      animating: false
    };
  }
  
  // Update eye configuration
  updateConfig(newConfig) {
    this.config = { ...this.config, ...newConfig };
    
    // Check for config changes
    if (window.beeEyeConfig) {
      this.animateToNewValues({
        topEyeSquareHeight: window.beeEyeConfig.topEyeSquareHeight,
        bottomEyeSquareHeight: window.beeEyeConfig.bottomEyeSquareHeight,
        topEyeCircleHeight: window.beeEyeConfig.topEyeCircleHeight,
        bottomEyeCircleHeight: window.beeEyeConfig.bottomEyeCircleHeight
      });
    }
  }
  
  // Function to start animation when target values change
  animateToNewValues(newValues) {
    // Update target values
    Object.keys(newValues).forEach(key => {
      if (this.animationState.target[key] !== newValues[key]) {
        this.animationState.target[key] = newValues[key];
        
        // Start animation if not already running
        if (!this.animationState.animating) {
          this.animationState.animating = true;
          this.animationState.startTime = performance.now();
          requestAnimationFrame(() => this.updateAnimationValues());
        }
      }
    });
  }
  
  // Function to update animation values on each frame
  updateAnimationValues() {
    if (!this.animationState.animating) return;
    
    const now = performance.now();
    const elapsed = now - this.animationState.startTime;
    const progress = Math.min(elapsed / this.animationState.duration, 1);
    
    if (progress < 1) {
      // Calculate eased progress
      const easedProgress = this.easeInOutQuad(progress);
      
      // Update current values based on eased progress
      Object.keys(this.animationState.target).forEach(key => {
        const start = this.animationState.current[key];
        const end = this.animationState.target[key];
        this.animationState.current[key] = start + (end - start) * easedProgress;
      });
      
      // Request next frame
      requestAnimationFrame(() => this.updateAnimationValues());
    } else {
      // Animation complete, set final values
      Object.keys(this.animationState.target).forEach(key => {
        this.animationState.current[key] = this.animationState.target[key];
      });
      this.animationState.animating = false;
    }
  }
  
  // Easing function
  easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
  }
  
  // Draw a vertical pill shape (helper for masking)
  drawVerticalPill(ctx, x, y, width, height) {
    const radius = width / 2;
    ctx.beginPath();
    ctx.arc(x + width / 2, y + radius, radius, Math.PI, 0, false);
    ctx.lineTo(x + width, y + height - radius);
    ctx.arc(x + width / 2, y + height - radius, radius, 0, Math.PI, false);
    ctx.closePath();
  }
  
  // Draw a single bee eye
  drawBeeEye(ctx, x, y, radius, fillColor = "black", includeStroke = false, strokeColor = "black") {
    // Save the current context state
    ctx.save();
    
    // Create clipping path for the eye circle
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2);
    ctx.clip();
    
    // Draw the main eye circle
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2);
    ctx.fillStyle = fillColor;
    ctx.fill();
    
    if (includeStroke) {
      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = 1;
      ctx.stroke();
    }
    
    // Draw the highlight (white circle slightly offset) inside the clipped area
    const highlightRadius = radius * 0.4;
    const highlightOffsetX = radius * 0.2;
    const highlightOffsetY = -radius * 0.2;
    
    ctx.beginPath();
    ctx.arc(x + highlightOffsetX, y + highlightOffsetY, highlightRadius, 0, Math.PI * 2);
    ctx.fillStyle = "white";
    ctx.fill();
    
    // Draw the yellow squares and circles (will only be visible inside the clipped eye area)
    // These are now drawn AFTER the white highlight so they appear on top
    const squareSize = radius * 2 * 1.05; // 5% larger
    const squareOffsetAbove = radius * this.animationState.current.topEyeSquareHeight;
    const squareOffsetBelow = radius * this.animationState.current.bottomEyeSquareHeight;
    
    // Draw yellow squares
    ctx.fillStyle = "#FFDD00";
    // Above
    ctx.fillRect(x - squareSize/2, y - squareOffsetAbove, squareSize, squareSize);
    // Below
    ctx.fillRect(x - squareSize/2, y + squareOffsetBelow, squareSize, squareSize);
    
    // Draw yellow circles
    ctx.beginPath();
    ctx.arc(x, y - (radius * this.animationState.current.topEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
    ctx.fill();
    ctx.beginPath();
    ctx.arc(x, y + (radius * this.animationState.current.bottomEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
    ctx.fill();
    
    // Restore context (removes clipping)
    ctx.restore();
  }
  
  // This is the method that BeeAnimation is looking for - matching the expected parameters
  drawFrontEyePair(ctx, leftEyeX, rightEyeX, eyeY, radius, degrees, frontColor = "black", bodyDimensions = null) {
    // If we have body dimensions, draw the eyes with masking
    if (bodyDimensions) {
      // Save the current context state
      ctx.save();
      
      // Create a clipping path using the bee body shape
      this.drawVerticalPill(ctx, bodyDimensions.x, bodyDimensions.y, bodyDimensions.width, bodyDimensions.height);
      ctx.clip();
      
      // Now draw the purple squares and green circles - they'll only be visible inside the clipping path
      const squareSize = radius * 2 * 1.05; // 5% larger
      const squareOffsetAbove = radius * this.animationState.current.topEyeSquareHeight;
      const squareOffsetBelow = radius * this.animationState.current.bottomEyeSquareHeight;
      
      // Draw for left eye if visible
      if (degrees >= 70 && degrees <= 270) {
        ctx.fillStyle = "#FFDD00";
        // Above
        ctx.fillRect(leftEyeX - squareSize/2, eyeY - squareOffsetAbove, squareSize, squareSize);
        // Below
        ctx.fillRect(leftEyeX - squareSize/2, eyeY + squareOffsetBelow, squareSize, squareSize);
        
        // Yellow circles
        ctx.fillStyle = "#FFDD00";
        ctx.beginPath();
        ctx.arc(leftEyeX, eyeY - (radius * this.animationState.current.topEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
        ctx.fill();
        ctx.beginPath();
        ctx.arc(leftEyeX, eyeY + (radius * this.animationState.current.bottomEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
        ctx.fill();
      }
      
      // Draw for right eye if visible
      if (degrees >= 100 && degrees <= 290) {
        ctx.fillStyle = "#FFDD00";
        // Above
        ctx.fillRect(rightEyeX - squareSize/2, eyeY - squareOffsetAbove, squareSize, squareSize);
        // Below
        ctx.fillRect(rightEyeX - squareSize/2, eyeY + squareOffsetBelow, squareSize, squareSize);
        
        // Yellow circles
        ctx.fillStyle = "#FFDD00";
        ctx.beginPath();
        ctx.arc(rightEyeX, eyeY - (radius * this.animationState.current.topEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
        ctx.fill();
        ctx.beginPath();
        ctx.arc(rightEyeX, eyeY + (radius * this.animationState.current.bottomEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
        ctx.fill();
      }
      
      // Restore the context to remove the clipping path
      ctx.restore();
    }
    
    // Draw front-facing eyes on top with angle-based visibility
    // Change rendering order based on position of orange circle (degrees)
    if (degrees > 180 && degrees < 360) {
      // Orange circle is to the left of the center, draw left eye first then right eye
      // Left eye - only draw when angle is between 70 and 270 degrees
      if (degrees >= 70 && degrees <= 250) {
        this.drawBeeEye(ctx, leftEyeX, eyeY, radius, frontColor);
      }
      
      // Right eye - only draw when angle is between 100 and 290 degrees
      if (degrees >= 110 && degrees <= 290) {
        this.drawBeeEye(ctx, rightEyeX, eyeY, radius, frontColor, true, "black");
      }
    } else {
      // Orange circle is to the right of the center, draw right eye first then left eye
      // Right eye - only draw when angle is between 100 and 290 degrees
      if (degrees >= 110 && degrees <= 290) {
        this.drawBeeEye(ctx, rightEyeX, eyeY, radius, frontColor, true, "black");
      }
      
      // Left eye - only draw when angle is between 70 and 270 degrees
      if (degrees >= 70 && degrees <= 250) {
        this.drawBeeEye(ctx, leftEyeX, eyeY, radius, frontColor);
      }
    }
  }
  
  // Add drawBeeEyePair as an alias for backwards compatibility
  drawBeeEyePair(ctx, leftEyeX, rightEyeX, eyeY, radius, degrees, frontColor = "black", backColor = "blue") {
    // Draw back-facing eyes first (so they appear behind)
    // Left eye - always visible with black color
    this.drawBeeEye(ctx, leftEyeX, eyeY, radius, "black");
    
    // Right eye - always visible with black color
    this.drawBeeEye(ctx, rightEyeX, eyeY, radius, "black", true, "black");
    
    // The front-facing colored parts will be drawn in drawFrontEyePair after the body
  }
  
  // Draw eye highlights
  drawEyeHighlights(ctx, leftEyeX, rightEyeX, eyeY) {
    ctx.fillStyle = "rgba(255, 255, 255, 0.6)";
    
    // Left eye highlight
    ctx.beginPath();
    ctx.arc(leftEyeX - 10, eyeY - 10, 10, 0, Math.PI * 2);
    ctx.fill();
    
    // Right eye highlight
    ctx.beginPath();
    ctx.arc(rightEyeX - 10, eyeY - 10, 10, 0, Math.PI * 2);
    ctx.fill();
  }

  // Draw the black eye at the bottom (tail)
  drawBottomEye(ctx, x, y, radius, degrees) {
    // Only visible when angle is above 260 or below 90
    if (degrees < 90 || degrees > 260) {
      // Draw the eye first
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;
      ctx.stroke();
      
      // Draw the highlight
      const highlightRadius = radius * 0.4;
      const highlightOffsetX = radius * 0.2;
      const highlightOffsetY = -radius * 0.2;
      
      ctx.beginPath();
      ctx.arc(x + highlightOffsetX, y + highlightOffsetY, highlightRadius, 0, Math.PI * 2);
      ctx.fillStyle = "white";
      ctx.fill();
      
      // Draw yellow squares and circles AFTER the highlight so they appear on top
      const squareSize = radius * 2 * 1.05; // 5% larger
      const squareOffsetAbove = radius * this.animationState.current.topEyeSquareHeight;
      const squareOffsetBelow = radius * this.animationState.current.bottomEyeSquareHeight;
      
      ctx.fillStyle = "#FFDD00";
      // Above
      ctx.fillRect(x - squareSize/2, y - squareOffsetAbove, squareSize, squareSize);
      // Below
      ctx.fillRect(x - squareSize/2, y + squareOffsetBelow, squareSize, squareSize);
      
      // Yellow circles
      ctx.fillStyle = "#FFDD00";
      ctx.beginPath();
      ctx.arc(x, y - (radius * this.animationState.current.topEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
      ctx.fill();
      ctx.beginPath();
      ctx.arc(x, y + (radius * this.animationState.current.bottomEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
      ctx.fill();
    }
  }
}
