import React from 'react';

const PersonWatchingTV1 = () => {
  return (
    <div className="flex items-center justify-center bg-gray-100 h-96">
      <svg viewBox="0 0 500 350" xmlns="http://www.w3.org/2000/svg">
<polygon points="0.625,-14.375 500,-14.375 500,80 0.625,80" fill="#E6D7C3" />
<polygon points="-2.5,75 202.5,75 202.5,129.375 -2.5,129.375" fill="#E6D7C3" />
<polygon points="290.625,80 500.625,80 500.625,130 290.625,130" fill="#E6D7C3" />
<polygon points="1.25,125 501.59747314453125,125 501.59747314453125,340.8172607421875 1.25,340.8172607421875" fill="#E6D7C3" />
<polygon points="410,70 390,90 430,90" fill="#FFD700" />
<rect x="92.50000762939453" y="155.625" width="40" height="41.25" fill="#8000ff" />
<rect x="150" y="40" width="40" height="40" fill="#F5F5DC" />
<rect x="92.50000762939453" y="152.5" width="40" height="3.75" fill="hsl(263, 100%, 20%)" />
<rect x="104.37500762939453" y="137.5" width="10" height="15.625" fill="hsl(102, 100%, 15%)" />
<rect x="113.12500762939453" y="125" width="7.5" height="27.5" fill="hsl(102, 100%, 20%)" />
<rect x="97.50000762939453" y="129.375" width="7.5" height="23.75" fill="hsl(102, 100%, 10%)" />
<rect x="118.12500762939453" y="138.125" width="7.5" height="14.375" fill="hsl(102, 100%, 30%)" />
<polygon points="185,60 182.99038105676658,67.5 177.5,72.99038105676658 170,75 162.5,72.99038105676658 157.00961894323342,67.5 155,60 157.00961894323342,52.5 162.5,47.00961894323342 170,45 177.5,47.00961894323342 182.99038105676658,52.49999999999999" fill="#FF6347" />
<rect x="369.5780487060547" y="100.90692138671875" width="80" height="90" fill="#A0522D" />
<rect x="170.82803344726562" y="140.90692138671875" width="7.5" height="78.75" fill="#A0522D" />
<rect x="317.7030487060547" y="140.28192138671875" width="7.5" height="79.375" fill="#A0522D" />
<rect x="170.8280487060547" y="137.78192138671875" width="154.37501525878906" height="6.875" fill="#A0522D" />
<rect x="374.5780487060547" y="105.90692138671875" width="70" height="25" fill="#8B4513" />
<rect x="374.5780487060547" y="135.90692138671875" width="70" height="25" fill="#8B4513" />
<rect x="374.5780487060547" y="165.90692138671875" width="70" height="20" fill="#8B4513" />
<rect x="170.82803344726562" y="142.78192138671875" width="154.37501525878906" height="6.25" fill="#8B4513" />
<rect x="378.3280487060547" y="109.03192138671875" width="10" height="20" fill="#FF0000" />
<rect x="175.82803344726562" y="120.90692138671875" width="10" height="20" fill="#FF0000" />
<rect x="303.3280487060547" y="135.90692138671875" width="12.5" height="5.625" fill="#888888" />
<rect x="175.82803344726562" y="118.40692138671875" width="10" height="2.5" fill="#cccccc" />
<rect x="432.7030487060547" y="139.03192138671875" width="10" height="20" fill="#FF0000" />
<rect x="388.3280487060547" y="109.03192138671875" width="10" height="20" fill="#0000FF" />
<rect x="398.3280487060547" y="109.03192138671875" width="10" height="20" fill="#008000" />
<rect x="408.9530487060547" y="109.03192138671875" width="15" height="20" fill="#FFFF00" />
<rect x="378.3280487060547" y="139.03192138671875" width="15" height="20" fill="#800080" />
<rect x="393.3280487060547" y="139.03192138671875" width="10" height="20" fill="#FFA500" />
<rect x="403.3280487060547" y="139.03192138671875" width="10" height="20" fill="#FF00FF" />
<rect x="412.7030487060547" y="139.03192138671875" width="10" height="20" fill="#00FFFF" />
<polygon points="85.625,30 138.125,30 138.125,121.25 85.625,121.25" fill="#87CEEB" />
<rect x="-0.625" y="190.625" width="498.75000762939453" height="143.125" fill="#ff8000" />
<rect x="91.875" y="34.375" width="40.625" height="81.25" fill="#ADD8E6" />
<rect x="110.625" y="36.25" width="3.75" height="78.75" fill="#FFFFFF" />
<rect x="92.5" y="76.25" width="40" height="3.125" fill="#FFFFFF" />
<rect x="386.875" y="66.25000381469727" width="46.25" height="26.874996185302734" fill="#F5F5DC" />
<rect x="390" y="70" width="40" height="20" fill="#87CEFA" />
<rect x="200.625" y="75" width="91.875" height="5" fill="#333" />
<rect x="200.625" y="78.75" width="4.375" height="46.875" fill="#333" />
<rect x="290" y="75" width="5.625" height="48.75" fill="#333" />
<rect x="201.25" y="121.25" width="94.16738891601562" height="4.4471282958984375" fill="#333" />
<rect x="242.5" y="125.625" width="11.25" height="14.375" fill="#555" />
<rect x="209.375" y="128.75" width="80" height="10" fill="#777" />
<rect x="200.625" y="136.25" width="98.75" height="4.375" fill="#555" />
<rect x="215" y="131.25" width="14.375" height="3.75" fill="#444" />
<rect x="272.5" y="129.375" width="11.875" height="5.625" fill="#444" />
<polygon points="410,70 390,90 430,90" fill="#FFD700" />
</svg>
    </div>
  );
};

export default PersonWatchingTV1;