// src/components/Teachers/Slideshow/SlidesCreatorControls.js

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Template Selector Modal Component
 */
export const TemplateSelectorModal = ({ 
  activeTab, 
  setActiveTab, 
  handleAddSlide, 
  onClose, 
  slideTemplates, 
  getGroupedTemplates, 
  tabColors, 
  textColors 
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[90vw] h-[80vh] bg-white rounded-lg shadow-xl flex flex-col">
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="text-2xl font-semibold">Choose a Template</h3>
          <button 
            onClick={onClose}
            className="text-2xl text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>
        
        {/* Tabs */}
        <div className="flex p-2 space-x-1 overflow-x-auto bg-gray-100">
          {Object.keys(slideTemplates).map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colours
                ${activeTab === tab 
                  ? 'bg-white text-blue-600 shadow' 
                  : 'text-gray-600 hover:bg-white hover:text-blue-600'
                }`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Template Grid */}
        <div className="flex-1 p-6 overflow-y-auto">
          <div className="grid grid-cols-6 gap-4">
            {activeTab === 'Screens' ? (
              // Special handling for Screens tab
              Object.entries(getGroupedTemplates(slideTemplates[activeTab])).map(([groupName, templates]) => (
                <div 
                  key={groupName} 
                  className="flex flex-col gap-2"
                >
                  {templates.map((template) => (
                    <button
                      key={template.id}
                      onClick={() => handleAddSlide(template)}
                      className="flex flex-col items-center p-2 space-y-1 transition-all border rounded-lg hover:scale-105 hover:shadow-lg"
                      style={{ 
                        backgroundColor: template.color,
                        color: textColors[activeTab],
                        minHeight: '80px'
                      }}
                    >
                      <span className="text-xl">{template.preview}</span>
                      <span className="w-full text-xs font-medium text-center truncate">
                        {template.name.includes(':') ? template.name.split(':')[1].trim() : template.name}
                      </span>
                    </button>
                  ))}
                </div>
              ))
            ) : (
              // Regular grid layout for other tabs
              slideTemplates[activeTab].map((template) => (
                <button
                  key={template.id}
                  onClick={() => handleAddSlide(template)}
                  className="flex flex-col items-center p-2 space-y-1 transition-all border rounded-lg hover:scale-105 hover:shadow-lg"
                  style={{ 
                    backgroundColor: tabColors[activeTab],
                    color: textColors[activeTab],
                    minHeight: '80px'
                  }}
                >
                  <span className="text-xl">{template.preview}</span>
                  <span className="w-full text-xs font-medium text-center truncate">{template.name}</span>
                </button>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * HUD Controls Component
 */
export const HudControls = ({ controlsState }) => {
  const {
    speed, setSpeed,
    orbitLock, setOrbitLock,
    guidanceSystem, setGuidanceSystem,
    timelapse, setTimelapse,
    viewLock, setViewLock,
    selectedCelestialBody, setSelectedCelestialBody,
    showMoons, setShowMoons,
    selectedMoon, setSelectedMoon,
    gravity, setGravity,
    gravityAssist, setGravityAssist,
    land, setLand,
    zoom, setZoom,
    axisD, setAxisD,
    universeScale, setUniverseScale,
    universeDistance, setUniverseDistance,
    axisZoom, setAxisZoom,
    vertical, setVertical,
    horizontal, setHorizontal,
    cameraOrbitalSpeed, setCameraOrbitalSpeed,
    showHud, setShowHud,
    simulationDate
  } = controlsState;

  return (
    <div className="absolute z-50 bottom-4 right-4">
      {showHud && (
        <div className="w-[500px] p-4 font-mono text-green-400 bg-black rounded-lg bg-opacity-40 backdrop-blur-sm">
          {/* Close button */}
          <div className="flex items-center justify-between mb-2">
            {/* Add date display */}
            <div className="text-sm">
              {new Date(simulationDate).toLocaleString()}
            </div>
            <button
              onClick={() => setShowHud(false)}
              className="text-green-400 hover:text-green-300"
            >
              ✕
            </button>
          </div>

          <div className="flex gap-4">
            {/* Left Column - Buttons */}
            <div className="w-1/2">
              {/* New Quick Control Buttons */}
              <div className="grid grid-cols-2 gap-2 mb-4">
                <button
                  onClick={() => {
                    setVertical(90);
                    setTimeout(() => {
                      setAxisZoom(10.9);
                    }, 100);
                  }}
                  className="px-3 py-1 border border-green-400 rounded hover:bg-green-400 hover:bg-opacity-20"
                >
                  <div className="text-xs">LAND</div>
                  <div className="text-sm font-bold">VIEW</div>
                </button>

                <button
                  onClick={() => {
                    setVertical(0);
                    setTimeout(() => {
                      setAxisZoom(3);
                    }, 100);
                  }}
                  className="px-3 py-1 border border-green-400 rounded hover:bg-green-400 hover:bg-opacity-20"
                >
                  <div className="text-xs">COUNTRY</div>
                  <div className="text-sm font-bold">VIEW</div>
                </button>

                <button
                  onClick={() => {
                    setVertical(0);
                    setTimeout(() => {
                      setAxisZoom(1);
                    }, 100);
                  }}
                  className="px-3 py-1 border border-green-400 rounded hover:bg-green-400 hover:bg-opacity-20"
                >
                  <div className="text-xs">CONTINENT</div>
                  <div className="text-sm font-bold">VIEW</div>
                </button>
              </div>

              {/* Existing Control Buttons */}
              <div className="grid grid-cols-2 gap-2 mb-4">
                <button
                  onClick={() => setGravity(!gravity)}
                  className={`px-3 py-1 border rounded ${
                    gravity 
                      ? 'border-green-400 bg-green-400 bg-opacity-20' 
                      : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                  }`}
                >
                  <div className="text-xs">SOLAR SYSTEM</div>
                  <div className="text-sm font-bold">SIZE</div>
                </button>

                <button
                  onClick={() => setGravityAssist(!gravityAssist)}
                  className={`px-3 py-1 border rounded ${
                    gravityAssist 
                      ? 'border-green-400 bg-green-400 bg-opacity-20' 
                      : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                  }`}
                >
                  <div className="text-xs">SOLAR SYSTEM</div>
                  <div className="text-sm font-bold">DISTANCE</div>
                </button>
              </div>

              {/* Planet Selection Buttons */}
              <div className="mb-4">
                <div className="flex flex-col gap-1">
                  <button
                    onClick={() => {
                      setSelectedCelestialBody('Sun');
                      setShowMoons(false);
                      setSelectedMoon('');
                    }}
                    className={`px-2 py-1 text-xs border rounded w-20 ${
                      selectedCelestialBody === 'Sun'
                        ? 'border-yellow-400 bg-yellow-400 bg-opacity-20' 
                        : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                    }`}
                  >
                    Sun
                  </button>
                  {['Mercury', 'Venus'].map((planet) => (
                    <div key={planet} className="flex flex-wrap items-center gap-1">
                      <button
                        onClick={() => {
                          setSelectedCelestialBody(planet);
                          setSelectedMoon('');
                        }}
                        className={`px-2 py-1 text-xs border rounded w-20 ${
                          selectedCelestialBody === planet
                            ? 'border-green-400 bg-green-400 bg-opacity-20' 
                            : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                        }`}
                      >
                        {planet}
                      </button>
                    </div>
                  ))}
                  {/* Earth and Moon buttons */}
                  <div className="flex flex-wrap items-center gap-1">
                    <button
                      onClick={() => {
                        setSelectedCelestialBody('Earth');
                        setSelectedMoon('');
                      }}
                      className={`px-2 py-1 text-xs border rounded w-20 ${
                        selectedCelestialBody === 'Earth' && !selectedMoon
                          ? 'border-green-400 bg-green-400 bg-opacity-20' 
                          : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                      }`}
                    >
                      Earth
                    </button>
                    <button
                      onClick={() => {
                        setSelectedCelestialBody('Moon');
                        setSelectedMoon('');
                      }}
                      className={`px-2 py-1 text-xs border rounded w-20 ${
                        selectedCelestialBody === 'Moon'
                          ? 'border-blue-400 bg-blue-400 bg-opacity-20' 
                          : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                      }`}
                    >
                      Moon
                    </button>
                  </div>
                  {/* Remaining planets */}
                  {['Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'].map((planet) => (
                    <div key={planet} className="flex flex-wrap items-center gap-1">
                      <button
                        onClick={() => {
                          setSelectedCelestialBody(planet);
                          setSelectedMoon('');
                        }}
                        className={`px-2 py-1 text-xs border rounded w-20 ${
                          selectedCelestialBody === planet
                            ? 'border-green-400 bg-green-400 bg-opacity-20' 
                            : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                        }`}
                      >
                        {planet}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Right Column - Dials */}
            <div className="w-1/2 space-y-4">
              {/* Camera Orbital Speed Dial (New) */}
              <div className="relative">
                <div className="flex items-center justify-between">
                  <span className="text-xs">CAMERA ORBITAL SPEED</span>
                  <span className="text-lg">{cameraOrbitalSpeed}</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="5000"
                  step=".01"
                  value={cameraOrbitalSpeed}
                  onChange={(e) => setCameraOrbitalSpeed(Number(e.target.value))}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>

              {/* Timelapse Control */}
              <div className="relative">
                <div className="flex items-center justify-between">
                  <span className="text-xs">TIMELAPSE</span>
                  <span className="text-lg">
                    {timelapse > 0 ? '+' : ''}{timelapse.toFixed(0)}
                  </span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="5000"
                  step="1"
                  value={timelapse}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    const deadzoneThreshold = 1;
                    setTimelapse(Math.abs(value) < deadzoneThreshold ? 0 : value);
                  }}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>

              {/* Axis R Dial */}
              <div className="relative mt-4">
                <div className="flex items-center justify-between">
                  <span className="text-xs">AXIS R</span>
                  <span className="text-lg">{zoom.toFixed(1)}</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="360"
                  step="1"
                  value={zoom}
                  onChange={(e) => setZoom(parseFloat(e.target.value))}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>

              {/* Axis D Dial */}
              <div className="relative mt-4">
                <div className="flex items-center justify-between">
                  <span className="text-xs">AXIS D</span>
                  <span className="text-lg">{axisD.toFixed(1)}</span>
                </div>
                <input
                  type="range"
                  min="-89"
                  max="89"
                  step="1"
                  value={axisD || 0}
                  onChange={(e) => setAxisD(Number(e.target.value))}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>

              {/* Axis Zoom Dial */}
              <div className="relative mt-4">
                <div className="flex items-center justify-between">
                  <span className="text-xs">AXIS ZOOM</span>
                  <span className="text-lg">{axisZoom.toFixed(1)}</span>
                </div>
                <input
                  type="range"
                  min=".1"
                  max="50"
                  step=".01"
                  value={axisZoom}
                  onChange={(e) => setAxisZoom(Number(e.target.value))}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>

              {/* Vertical Dial */}
              <div className="relative mt-4">
                <div className="flex items-center justify-between">
                  <span className="text-xs">VERTICAL</span>
                  <span className="text-lg">{vertical.toFixed(1)}</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="90"
                  step="1"
                  value={vertical}
                  onChange={(e) => setVertical(Number(e.target.value))}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>

              {/* Horizontal Dial */}
              <div className="relative mt-4">
                <div className="flex items-center justify-between">
                  <span className="text-xs">HORIZONTAL</span>
                  <span className="text-lg">{horizontal.toFixed(1)}</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="90"
                  step="1"
                  value={horizontal}
                  onChange={(e) => setHorizontal(Number(e.target.value))}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>

              {/* Universe Scale Dial */}
              <div className="relative mt-4">
                <div className="flex items-center justify-between">
                  <span className="text-xs">UNIVERSE SCALE</span>
                  <span className="text-lg">{universeScale.toFixed(1)}</span>
                </div>
                <input
                  type="range"
                  min="1"
                  max="1000"
                  step="1"
                  value={universeScale}
                  onChange={(e) => setUniverseScale(parseFloat(e.target.value))}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>

              {/* Universe Distance Dial */}
              <div className="relative mt-4">
                <div className="flex items-center justify-between">
                  <span className="text-xs">UNIVERSE DISTANCE</span>
                  <span className="text-lg">{universeDistance.toFixed(1)}</span>
                </div>
                <input
                  type="range"
                  min="-100"
                  max="1"
                  step="0.1"
                  value={universeDistance}
                  onChange={(e) => setUniverseDistance(parseFloat(e.target.value))}
                  className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// PropTypes for the components
TemplateSelectorModal.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  handleAddSlide: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  slideTemplates: PropTypes.object.isRequired,
  getGroupedTemplates: PropTypes.func.isRequired,
  tabColors: PropTypes.object.isRequired,
  textColors: PropTypes.object.isRequired
};

HudControls.propTypes = {
  controlsState: PropTypes.object.isRequired
};