// src/components/Teachers/Slideshow/SlidesCreator.js

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from './Button'; // Adjust the path based on your project structure
import PixiCanvas from '../../../pixi/OverlayAnimated/ThreeJS'; // Adjust the path based on your project structure
import {
  slideTemplates,
  locationCoordinates,
  planetLabels,
  hasMoons,
  getMoonsForPlanet,
  generateInitialSlides,
  generateInitialLocationTextMatrix,
  slideSettings,
  getGroupedTemplates,
  tabColors,
  textColors
} from './SlideCreatorData';
import { HudControls, TemplateSelectorModal } from './SlidesCreatorControls';
import gsap from 'gsap';

const CameraController = ({ targetFOV, camera }) => {
  const currentFOV = useRef(camera.fov);
  const tweenRef = useRef(null);
  const isInitialRender = useRef(true);
  
  useEffect(() => {
    // On initial render, set FOV immediately without animation
    if (isInitialRender.current) {
      camera.fov = targetFOV;
      camera.updateProjectionMatrix();
      currentFOV.current = targetFOV;
      isInitialRender.current = false;
      return;
    }
    
    // Kill any existing tween
    if (tweenRef.current) {
      tweenRef.current.kill();
    }
    
    // Create a new tween with exactly 2 seconds duration
    tweenRef.current = gsap.to(currentFOV, {
      current: targetFOV,
      duration: 2,
      ease: "power2.inout",
      onUpdate: () => {
        camera.fov = currentFOV.current;
        camera.updateProjectionMatrix();
      }
    });
    
    // Cleanup on unmount
    return () => {
      if (tweenRef.current) {
        tweenRef.current.kill();
      }
    };
  }, [targetFOV, camera]);
  
  return null;
};

const SlideCreator = ({ onClose, onPopSlide }) => {
  // Replace the static genres array with a simpler one
  const genres = ['Blank'];

  // Placeholder subtitles with empty strings
  const placeholderSubtitles = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ];

  // Change default active tab to Space
  const [activeTab, setActiveTab] = useState('Space');

  // State declarations
  const [slides, setSlides] = useState(() => {
    // These initial slides come from the Continents group.
    const initialContinents = [
      { theme: 'oceania', preview: '🌏', name: 'Oceania' },
      { theme: 'africa', preview: '🌍', name: 'Africa' },
      { theme: 'saturn-orbit', preview: '🪐', name: 'Saturn Orbit' },
      { theme: 'venus-orbit', preview: '🌖', name: 'Venus Orbit' },
      { theme: 'mercury-orbit', preview: '🟤', name: 'Mercury Orbit' },
      { theme: 'venus-orbit', preview: '🌖', name: 'Venus Orbit' },
      { theme: 'mars-orbit', preview: '🔴', name: 'Mars Orbit' },
      { theme: 'jupiter-orbit', preview: '🟢', name: 'Jupiter Orbit' },
      { theme: 'neptune-orbit', preview: '🟠', name: 'Neptune Orbit' },
      { theme: 'uranus-orbit', preview: '🔵', name: 'Uranus Orbit' },
      { theme: 'moon-orbit', preview: '🌜', name: 'Earth - Moon' },
      { theme: 'oceania', preview: '🌏', name: 'Oceania' },
      { theme: 'australia', preview: '🇦🇺', name: 'Australia' },
      { theme: 'conversation', preview: '💬', name: 'Conversation' },
    ];
    
    return initialContinents.map((continent, index) => ({
      id: Date.now() + index,
      subtitle: "",
      x: 0,
      y: 0,
      z: 0,
      location: 'Field',
      theme: continent.theme,
      // Instead of cycling through an array, use the continent group color
      color: tabColors["Continents"],
      preview: continent.preview,
      name: continent.name,
      themeNumber: 1,
      sequenceNumber: index + 1
    }));
  });
  const [locationTextMatrix, setLocationTextMatrix] = useState(generateInitialLocationTextMatrix());

  // State to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // State to track navigation direction for animation
  const [navigationDirection, setNavigationDirection] = useState(0); // 1 for next, -1 for previous, 0 for direct navigation

  // New state to track if a transition is in progress
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Add this new state near the top of the component with other state declarations
  const [showCoordinates, setShowCoordinates] = useState(false);

  // Make "show/hide matrix" hidden by default
  const [showMatrix, setShowMatrix] = useState(false);

  // Add this new state near other state declarations
  const [showText, setShowText] = useState(true);

  // Add new state for HUD controls
  const [speed, setSpeed] = useState(50);
  const [orbitLock, setOrbitLock] = useState(false);
  const [guidanceSystem, setGuidanceSystem] = useState(false);
  const [timelapse, setTimelapse] = useState(0);

  // Add new state for View Lock
  const [viewLock, setViewLock] = useState(false);

  // Add new state for selected celestial body
  const [selectedCelestialBody, setSelectedCelestialBody] = useState('Sun');

  // Add new state for showing moons
  const [showMoons, setShowMoons] = useState(false);

  // Add new state for selected moon
  const [selectedMoon, setSelectedMoon] = useState('');

  // Add ref for scene initialization status
  const [sceneInitialized, setSceneInitialized] = useState(false);

  // Add new state for the flight sim controls
  const [gravity, setGravity] = useState(true);
  const [gravityAssist, setGravityAssist] = useState(false);
  const [land, setLand] = useState(false);

  // Add new state for zoom control near other state declarations
  const [zoom, setZoom] = useState(0);

  // Add new state for axis D control near other state declarations
  const [axisD, setAxisD] = useState(0);

  // Add new state for template selector
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);

  // Change default state for HUD visibility to false
  const [showHud, setShowHud] = useState(false);

  // Add new state declarations near the other state declarations
  const [universeScale, setUniverseScale] = useState(1);
  const [universeDistance, setUniverseDistance] = useState(0);

  // Add new state declarations near the other state declarations
  const [axisZoom, setAxisZoom] = useState(.7);
  const [vertical, setVertical] = useState(0);

  // Add new state for horizontal control near other state declarations
  const [horizontal, setHorizontal] = useState(0);

  // Add new state for camera orbital speed
  const [cameraOrbitalSpeed, setCameraOrbitalSpeed] = useState(0);

  // Add new state for camera distance
  const [cameraDistance, setCameraDistance] = useState(0);

  // Set the starting date to the current user's date and time
  const simulationStartDate = React.useMemo(() => new Date(), []); // Uses the current time instead of Jan 1, 2000
  const [simulationElapsedDays, setSimulationElapsedDays] = useState(0);
  const [simulationDate, setSimulationDate] = useState(simulationStartDate);
  const lastSimTimeRef = useRef(Date.now());

  // Update the initial value to default to 10.0x
  const [sceneCameraControl, setSceneCameraControl] = useState(81); // Default to the minimum (which will show 10.0x)
  const targetFOV = useMemo(() => {
    // Recalculate to properly map 81-105 to a FOV that gives 1.0x-10.0x zoom
    // FOV 25 corresponds to 1.0x zoom, FOV 2.5 corresponds to 10.0x zoom
    // Linear interpolation: FOV = 25 - (sceneCameraControl - 81) * (25 - 2.5) / (105 - 81)
    return 25 - ((sceneCameraControl - 81) / 24) * 22.5;
  }, [sceneCameraControl]);

  const currentSlide = slides[currentSlideIndex];

  // Function to handle animation completion
  const handleAnimationComplete = () => {
    setIsTransitioning(false);
  };

  // Updated handlers for navigating slides
  const handleNext = () => {
    if (currentSlideIndex >= slides.length - 1) return;
    const nextSlide = slides[currentSlideIndex + 1];
    applySlideSettings(nextSlide);
    setCurrentSlideIndex(currentSlideIndex + 1);
  };

  const handlePrevious = () => {
    if (currentSlideIndex === 0) return;
    const prevSlide = slides[currentSlideIndex - 1];
    applySlideSettings(prevSlide);
    setCurrentSlideIndex(currentSlideIndex - 1);
  };

  // Update handleSlideClick to also handle static/orbit views.
  const handleSlideClick = (index) => {
    if (index === currentSlideIndex) return;
    const targetSlide = slides[index];
    applySlideSettings(targetSlide);
    setCurrentSlideIndex(index);
  };

  // Modify handleAddSlide to apply settings for new slides
  const handleAddSlide = (template) => {
    const newSlide = {
      id: Date.now(),
      subtitle: "",
      x: 0,
      y: 0,
      z: 0,
      location: 'Field',
      theme: template.theme,
      settings: slideSettings[template.theme] || {},
      color: tabColors[activeTab],
      textColor: textColors[activeTab],
      preview: template.preview,
      name: template.name
    };

    const updatedSlides = [...slides, newSlide];
    setSlides(updatedSlides);
    
    // Update current slide index to the new slide
    const newIndex = updatedSlides.length - 1;
    setCurrentSlideIndex(newIndex);
    
    // Apply the slide settings immediately
    const settings = slideSettings[template.theme];
    if (settings) {
      // Apply all settings at once
      setSelectedCelestialBody(settings.selectedCelestialBody);
      setZoom(settings.zoom ?? 0);
      setAxisD(settings.axisD ?? 0);
      setAxisZoom(settings.axisZoom ?? 0.1);
      setTimelapse(settings.timelapse ?? 0);
      if (settings.showMoons !== undefined) setShowMoons(settings.showMoons);
      if (settings.selectedMoon !== undefined) setSelectedMoon(settings.selectedMoon);
      if (settings.vertical !== undefined) setVertical(settings.vertical);
    }
  };

  // Simplify handleDeleteSlide by removing title recalculation
  const handleDeleteSlide = (index, e) => {
    e.stopPropagation(); // Prevent slide selection when clicking delete
    
    const updatedSlides = slides.filter((_, i) => i !== index);
    setSlides(updatedSlides);
    
    // Adjust current slide index if necessary
    if (index <= currentSlideIndex) {
      setCurrentSlideIndex(Math.max(0, currentSlideIndex - 1));
    }
  };

  // Add function to get highest theme number
  const getHighestThemeNumber = () => {
    if (!slides.length) return 1;
    return Math.max(...slides.map(slide => slide.themeNumber));
  };

  // Add coordinate change handler
  const handleCoordinateChange = (coord, value) => {
    const updatedSlides = [...slides];
    updatedSlides[currentSlideIndex] = {
      ...slides[currentSlideIndex],
      [coord]: Number(value),
      axisZoom: axisZoom
    };
    setSlides(updatedSlides);
  };

  // Add this handler near other handlers
  const handleLocationChange = (e) => {
    const location = e.target.value;
    const coordinates = locationCoordinates[location];
    
    if (coordinates) {
      const updatedSlides = [...slides];
      updatedSlides[currentSlideIndex] = {
        ...slides[currentSlideIndex],
        x: coordinates.x,
        y: coordinates.y,
        z: coordinates.z,
        axisZoom: axisZoom,
        location: location,
        title: `${location} ${currentSlide.themeNumber}.${currentSlide.sequenceNumber} ${currentSlide.theme}`
      };
      setSlides(updatedSlides);
      setLocationTextMatrix(generateInitialLocationTextMatrix());
    }
  };

  // Add this useEffect to handle key events
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent default behaviour for WASD and Space keys
      if (['w', 'a', 's', 'd', ' '].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }

      // Handle speed adjustments
      if (e.key === '-' || e.key === '_') {
        setSpeed(prevSpeed => Math.max(0, prevSpeed - 5));
      }
      if (e.key === '+' || e.key === '=') {
        setSpeed(prevSpeed => Math.min(100, prevSpeed + 5));
      }
    };

    // Add the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array since we don't need any dependencies

  useEffect(() => {
    // Remove all console.logs
  }, [
    guidanceSystem,
    orbitLock,
    viewLock,
    gravity,
    gravityAssist,
    land,
    selectedCelestialBody,
    speed,
    timelapse,
    zoom,
    axisD,
    axisZoom
  ]);

  // Add this useEffect near the other useEffect hooks
  useEffect(() => {
    if (slides.length > 0) {
      const initialSlide = {
        ...slides[0],
        axisZoom: slides[0].axisZoom || 0.1
      };
      applySlideSettings(initialSlide);
    }
  }, []); // Empty dependency array means this runs once when component mounts

  // This effect integrates real-time elapsed time (dt in seconds) multiplied 
  // by our effective simulation rate ((timelapse * speed) / 86400)
  // so that one full Earth rotation (i.e. 86400 real seconds at rate 1)
  // corresponds to 1 simulated day.
  useEffect(() => {
    let animationFrameId;
    const updateSimulationTime = () => {
      const now = Date.now();
      const dt = (now - lastSimTimeRef.current) / 1000; // dt in seconds
      lastSimTimeRef.current = now;
      setSimulationElapsedDays(prev => {
        // Here we add the fraction of day elapsed since the last update.
        const deltaDays = (dt * timelapse * speed) / 86400; 
        const newElapsedDays = prev + deltaDays;
        const newDate = new Date(simulationStartDate.getTime() + newElapsedDays * 86400 * 1000);
        setSimulationDate(newDate);
        return newElapsedDays;
      });
      animationFrameId = requestAnimationFrame(updateSimulationTime);
    };

    animationFrameId = requestAnimationFrame(updateSimulationTime);
    return () => cancelAnimationFrame(animationFrameId);
  }, [timelapse, speed, simulationStartDate]);

  const applySlideSettings = (slide) => {
    const generalSlideTypes = ['conversation', 'thinking', 'group', 'image', 'video'];

    if (generalSlideTypes.includes(slide.theme)) {
      setSelectedCelestialBody('Earth');
      setAxisZoom(10.9);
      setCameraOrbitalSpeed(0);
      
      // Apply the vertical change after a 0.5-second delay
      setTimeout(() => {
        setVertical(90);
      }, 1200);
      
      return;
    }

    const settings = slideSettings[slide.theme];
    if (!settings) return;

    // Determine if the slide is a Continent or Country slide
    const isContinentSlide = slideTemplates.Continents.some(
      (template) => template.theme === slide.theme
    );
    const isCountrySlide = slideTemplates.Countries.some(
      (template) => template.theme === slide.theme
    );

    // If it's a Continent or Country slide, update everything together
    if (isContinentSlide || isCountrySlide) {
      setSelectedCelestialBody(settings.selectedCelestialBody);
      setZoom(settings.zoom ?? 0);
      setAxisD(settings.axisD ?? 0);
      setAxisZoom(isContinentSlide ? 0.1 : 0.5);
      setTimelapse(settings.timelapse ?? 0);
      setCameraOrbitalSpeed(0);
      setVertical(0);
      return;
    }

    // For any other slide types, update selectedCelestialBody immediately
    setSelectedCelestialBody(settings.selectedCelestialBody);
    
    // Update all other settings together
    setTimeout(() => {
      setZoom(settings.zoom ?? 0);
      setAxisD(settings.axisD ?? 0);
      setAxisZoom(settings.axisZoom ?? 1);
      setTimelapse(settings.timelapse ?? 0);
      setCameraOrbitalSpeed(settings.cameraOrbitalSpeed ?? 0);
      setVertical(settings.vertical ?? 0);
      if (settings.showMoons !== undefined) setShowMoons(settings.showMoons);
      if (settings.selectedMoon !== undefined) setSelectedMoon(settings.selectedMoon);
    }, 50);
  };

  // Add handler to update camera distance
  const handleCameraDistanceUpdate = (distance) => {
    setCameraDistance(distance);
  };

  // Create a combined state object for HUD controls
  const hudControlsState = {
    speed, setSpeed,
    orbitLock, setOrbitLock,
    guidanceSystem, setGuidanceSystem,
    timelapse, setTimelapse,
    viewLock, setViewLock,
    selectedCelestialBody, setSelectedCelestialBody,
    showMoons, setShowMoons,
    selectedMoon, setSelectedMoon,
    gravity, setGravity,
    gravityAssist, setGravityAssist,
    land, setLand,
    zoom, setZoom,
    axisD, setAxisD,
    universeScale, setUniverseScale,
    universeDistance, setUniverseDistance,
    axisZoom, setAxisZoom,
    vertical, setVertical,
    horizontal, setHorizontal,
    cameraOrbitalSpeed, setCameraOrbitalSpeed,
    showHud, setShowHud,
    simulationDate
  };

  // Add a ref to store the camera
  const cameraRef = useRef(null);

  // Add a handler to receive the camera from PixiCanvas
  const handleCameraReady = (camera) => {
    cameraRef.current = camera;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
      {/* Template Selector Modal */}
      {showTemplateSelector && (
        <TemplateSelectorModal
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleAddSlide={handleAddSlide}
          onClose={() => setShowTemplateSelector(false)}
          slideTemplates={slideTemplates}
          getGroupedTemplates={getGroupedTemplates}
          tabColors={tabColors}
          textColors={textColors}
        />
      )}

      {/* Fullscreen Modal Container */}
      <div className="flex flex-col w-full h-full bg-white rounded-none shadow-none md:flex-row">
        
        {/* Left Panel (Slides List) - Adjusted width */}
        <div className="flex flex-col w-full p-4 overflow-y-auto border-r md:w-64">
          <h4 className="mb-4 text-lg font-medium text-center">Slides</h4>
          <ul className="flex flex-col items-center flex-1 space-y-4">
            {slides.map((slide, index) => (
              <li
                key={slide.id}
                onClick={() => handleSlideClick(index)}
                className={`cursor-pointer rounded w-48 relative ${
                  currentSlideIndex === index
                    ? 'border-blue-500 shadow-lg scale-105'
                    : 'border-gray-200 hover:scale-105 hover:shadow-lg'
                }`}
                style={{ 
                  backgroundColor: slide.color,
                  color: '#374151', // Changed to dark gray text for better contrast
                  transition: 'all 0.2s'
                }}
              >
                <div className="flex items-center justify-center p-4">
                  <div className="flex flex-col items-center space-y-2">
                    <span className="text-2xl">{slide.preview}</span>
                    <span className="text-sm font-medium">{slide.theme}</span>
                  </div>
                  
                  <button
                    onClick={(e) => handleDeleteSlide(index, e)}
                    className="absolute text-current opacity-75 hover:opacity-100 right-2 top-2"
                    title="Delete slide"
                  >
                    ×
                  </button>
                </div>
              </li>
            ))}
            {/* Add New Slide Tile */}
            <li
              onClick={() => setShowTemplateSelector(true)}
              className="w-48 border border-gray-300 border-dashed rounded cursor-pointer hover:border-blue-500 hover:bg-blue-50"
            >
              <div className="flex items-center justify-center p-4">
                <span className="text-2xl text-gray-400 hover:text-blue-500">+</span>
              </div>
            </li>
          </ul>
        </div>

        {/* Right Panel - Adjusted to take remaining space */}
        <div className="flex flex-col w-full h-full p-6 overflow-hidden md:flex-1">
          {/* Header with Close Button */}
          <div className="flex items-center justify-end mb-4">
            <Button
              onClick={onClose}
              variant="ghost"
              className="ml-4 text-2xl leading-none"
              aria-label="Close Slide Creator"
              disabled={isTransitioning}
            >
              &times;
            </Button>
          </div>

          {currentSlide && (
            <div className="flex flex-col justify-between h-full space-y-4">
              {/* PixiCanvas Integration */}
              <div className="flex flex-col items-center justify-center flex-1 p-4 overflow-hidden bg-gray-100 border rounded-md" 
                   style={{ minHeight: 0 }}>
                <div className="relative flex items-center justify-center w-full h-full">
                  <div className="w-full h-full" style={{ 
                    maxWidth: '100%', 
                    maxHeight: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden'
                  }}>
                    <div style={{
                      width: '100%',
                      height: '0',
                      paddingBottom: '56.25%', /* 9/16 = 0.5625 */
                      position: 'relative',
                      maxWidth: '100%',
                      maxHeight: '100%'
                    }}>
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                      }}>
                        {cameraRef.current && (
                          <CameraController 
                            targetFOV={targetFOV} 
                            camera={cameraRef.current}
                          />
                        )}
                        <PixiCanvas
                          width={1920}
                          height={1080}
                          backgroundColor={0x1099bb}
                          currentSlide={currentSlideIndex + 1}
                          totalSlides={slides.length}
                          speed={speed}
                          orbitLock={orbitLock}
                          guidanceSystem={guidanceSystem}
                          timelapse={timelapse}
                          viewLock={viewLock}
                          selectedCelestialBody={selectedCelestialBody}
                          selectedMoon={selectedMoon}
                          onSceneReady={() => setSceneInitialized(true)}
                          onCameraReady={handleCameraReady}
                          zoom={zoom}
                          axisD={axisD}
                          gravity={gravity}
                          gravityAssist={gravityAssist}
                          land={land}
                          axisZoom={axisZoom}
                          vertical={vertical}
                          horizontal={horizontal}
                          cameraOrbitalSpeed={cameraOrbitalSpeed}
                          onCameraDistanceUpdate={handleCameraDistanceUpdate}
                          cameraDistance={cameraDistance}
                          simulationElapsedDays={simulationElapsedDays}
                          antialias={false}
                          powerPreference="default"
                          sceneCameraControl={sceneCameraControl}
                          targetFOV={targetFOV}
                        />
                      </div>
                    </div>
                  </div>
                  <HudControls
                    controlsState={{
                      speed, setSpeed,
                      orbitLock, setOrbitLock,
                      guidanceSystem, setGuidanceSystem,
                      timelapse, setTimelapse,
                      viewLock, setViewLock,
                      selectedCelestialBody, setSelectedCelestialBody,
                      showMoons, setShowMoons,
                      selectedMoon, setSelectedMoon,
                      gravity, setGravity,
                      gravityAssist, setGravityAssist,
                      land, setLand,
                      zoom, setZoom,
                      axisD, setAxisD,
                      universeScale, setUniverseScale,
                      universeDistance, setUniverseDistance,
                      axisZoom, setAxisZoom,
                      vertical, setVertical,
                      horizontal, setHorizontal,
                      cameraOrbitalSpeed, setCameraOrbitalSpeed,
                      showHud, setShowHud,
                      simulationDate
                    }}
                  />
                </div>
              </div>

              {/* Hotbar */}
              <div className="w-full p-2 bg-gray-200 border rounded-md">
                <div className="flex items-center justify-center h-12 space-x-4">
                  <button
                    type="button"
                    onClick={handlePrevious}
                    disabled={currentSlideIndex === 0 || isTransitioning}
                    className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                      currentSlideIndex === 0 || isTransitioning
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                  >
                    Previous
                  </button>
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-700">Camera:</span>
                    <input
                      type="range"
                      min="81"
                      max="105"
                      value={sceneCameraControl}
                      onChange={(e) => setSceneCameraControl(Number(e.target.value))}
                      className="w-32 h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer"
                    />
                    <span className="text-sm text-gray-700">
                      {(25 / targetFOV).toFixed(1)}x
                    </span>
                  </div>
                  <button
                    onClick={() => setShowHud(!showHud)}
                    className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
                  >
                    HUD
                  </button>
                  <button
                    type="button"
                    onClick={handleNext}
                    disabled={currentSlideIndex === slides.length - 1 || isTransitioning}
                    className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                      currentSlideIndex === slides.length - 1 || isTransitioning
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                  >
                    Next
                  </button>
                </div>
              </div>

              {/* New Text Field */}
              <div className="w-full p-4 bg-white border rounded-md">
                <textarea
                  value={currentSlide?.subtitle || ''}
                  onChange={(e) => {
                    const updatedSlides = [...slides];
                    updatedSlides[currentSlideIndex] = {
                      ...currentSlide,
                      subtitle: e.target.value
                    };
                    setSlides(updatedSlides);
                  }}
                  placeholder="Enter slide text here..."
                  className="w-full p-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SlideCreator.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPopSlide: PropTypes.func,
};

export default SlideCreator;