// Functions for drawing bee eyes with highlights

// Add animation state to track current and target values
const animationState = {
  current: {
    topEyeSquareHeight: 3,
    bottomEyeSquareHeight: 1,
    topEyeCircleHeight: 3,
    bottomEyeCircleHeight: 1
  },
  target: {
    topEyeSquareHeight: 3,
    bottomEyeSquareHeight: 1,
    topEyeCircleHeight: 3,
    bottomEyeCircleHeight: 1
  },
  // Animation settings
  duration: 500, // milliseconds
  startTime: null,
  animating: false
};

// Power2.easeInOut equivalent function
function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
}

// Function to update animation values on each frame
function updateAnimationValues() {
  if (!animationState.animating) return;
  
  const now = performance.now();
  const elapsed = now - animationState.startTime;
  const progress = Math.min(elapsed / animationState.duration, 1);
  
  if (progress < 1) {
    // Calculate eased progress
    const easedProgress = easeInOutQuad(progress);
    
    // Update current values based on eased progress
    Object.keys(animationState.target).forEach(key => {
      const start = animationState.current[key];
      const end = animationState.target[key];
      animationState.current[key] = start + (end - start) * easedProgress;
    });
    
    // Request next frame
    requestAnimationFrame(updateAnimationValues);
  } else {
    // Animation complete, set final values
    Object.keys(animationState.target).forEach(key => {
      animationState.current[key] = animationState.target[key];
    });
    animationState.animating = false;
  }
}

// Function to start animation when target values change
function animateToNewValues(newValues) {
  // Update target values
  Object.keys(newValues).forEach(key => {
    if (animationState.target[key] !== newValues[key]) {
      animationState.target[key] = newValues[key];
      
      // Start animation if not already running
      if (!animationState.animating) {
        animationState.animating = true;
        animationState.startTime = performance.now();
        requestAnimationFrame(updateAnimationValues);
      }
    }
  });
}

// Check for config changes on each draw call
function checkConfigChanges() {
  if (window.beeEyeConfig) {
    animateToNewValues({
      topEyeSquareHeight: window.beeEyeConfig.topEyeSquareHeight,
      bottomEyeSquareHeight: window.beeEyeConfig.bottomEyeSquareHeight,
      topEyeCircleHeight: window.beeEyeConfig.topEyeCircleHeight,
      bottomEyeCircleHeight: window.beeEyeConfig.bottomEyeCircleHeight
    });
  }
}

/**
 * Draws a bee eye with highlight
 * @param {CanvasRenderingContext2D} ctx - Canvas context
 * @param {number} x - X position of the eye
 * @param {number} y - Y position of the eye
 * @param {number} radius - Radius of the eye
 * @param {string} fillColor - Color of the eye (usually "black" or "blue")
 * @param {boolean} includeStroke - Whether to include a stroke around the eye
 * @param {string} strokeColor - Color of the stroke (if included)
 */
export const drawBeeEye = (ctx, x, y, radius, fillColor = "black", includeStroke = false, strokeColor = "black") => {
  // Save the current context state
  ctx.save();
  
  // Create clipping path for the eye circle
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2);
  ctx.clip();
  
  // Draw the main eye circle
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2);
  ctx.fillStyle = fillColor;
  ctx.fill();
  
  if (includeStroke) {
    ctx.strokeStyle = strokeColor;
    ctx.lineWidth = 1;
    ctx.stroke();
  }
  
  // Draw the highlight (white circle slightly offset) inside the clipped area
  const highlightRadius = radius * 0.4;
  const highlightOffsetX = radius * 0.2;
  const highlightOffsetY = -radius * 0.2;
  
  ctx.beginPath();
  ctx.arc(x + highlightOffsetX, y + highlightOffsetY, highlightRadius, 0, Math.PI * 2);
  ctx.fillStyle = "white";
  ctx.fill();
  
  // Draw the yellow squares and circles (will only be visible inside the clipped eye area)
  // These are now drawn AFTER the white highlight so they appear on top
  const squareSize = radius * 2 * 1.05; // 5% larger
  const squareOffsetAbove = radius * animationState.current.topEyeSquareHeight;
  const squareOffsetBelow = radius * animationState.current.bottomEyeSquareHeight;
  
  // Draw yellow squares
  ctx.fillStyle = "#FFDD00";
  // Above
  ctx.fillRect(x - squareSize/2, y - squareOffsetAbove, squareSize, squareSize);
  // Below
  ctx.fillRect(x - squareSize/2, y + squareOffsetBelow, squareSize, squareSize);
  
  // Draw yellow circles
  ctx.beginPath();
  ctx.arc(x, y - (radius * animationState.current.topEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
  ctx.fill();
  ctx.beginPath();
  ctx.arc(x, y + (radius * animationState.current.bottomEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
  ctx.fill();
  
  // Restore context (removes clipping)
  ctx.restore();
};

/**
 * Draws a pair of bee eyes with visibility control based on angle
 * @param {CanvasRenderingContext2D} ctx - Canvas context
 * @param {number} leftEyeX - X position of left eye
 * @param {number} rightEyeX - X position of right eye
 * @param {number} eyeY - Y position of both eyes
 * @param {number} radius - Radius of the eyes
 * @param {number} degrees - Current angle in degrees
 * @param {string} frontColor - Color for front-facing eyes
 * @param {string} backColor - Color for back-facing eyes
 */
export const drawBeeEyePair = (ctx, leftEyeX, rightEyeX, eyeY, radius, degrees, frontColor = "black", backColor = "blue") => {
  // Check for config changes
  checkConfigChanges();
  
  // Draw back-facing eyes first (so they appear behind)
  // Left eye - always visible with black color
  drawBeeEye(ctx, leftEyeX, eyeY, radius, "black");
  
  // Right eye - always visible with black color
  drawBeeEye(ctx, rightEyeX, eyeY, radius, "black", true, "black");
  
  // The purple squares and green circles will be drawn in drawFrontEyePair
  // after the bee body is drawn to ensure proper masking
};

/**
 * Draws the front-facing eyes that should appear on top of the character body
 * @param {CanvasRenderingContext2D} ctx - Canvas context
 * @param {number} leftEyeX - X position of left eye
 * @param {number} rightEyeX - X position of right eye
 * @param {number} eyeY - Y position of both eyes
 * @param {number} radius - Radius of the eyes
 * @param {number} degrees - Current angle in degrees
 * @param {string} frontColor - Color for front-facing eyes
 * @param {Object} bodyDimensions - Dimensions of the bee body for masking
 */
export const drawFrontEyePair = (ctx, leftEyeX, rightEyeX, eyeY, radius, degrees, frontColor = "black", bodyDimensions = null) => {
  // Draw front-facing eyes on top with angle-based visibility
  // Left eye - only draw when angle is between 70 and 270 degrees
  if (degrees >= 70 && degrees <= 270) {
    drawBeeEye(ctx, leftEyeX, eyeY, radius, frontColor);
  }
  
  // Right eye - only draw when angle is between 100 and 290 degrees
  if (degrees >= 100 && degrees <= 290) {
    drawBeeEye(ctx, rightEyeX, eyeY, radius, frontColor, true, "black");
  }
  
  // If we have body dimensions, draw the purple squares and green circles with masking
  if (bodyDimensions) {
    // Save the current context state
    ctx.save();
    
    // Create a clipping path using the bee body shape
    drawVerticalPill(ctx, bodyDimensions.x, bodyDimensions.y, bodyDimensions.width, bodyDimensions.height);
    ctx.clip();
    
    // Now draw the purple squares and green circles - they'll only be visible inside the clipping path
    const squareSize = radius * 2 * 1.05; // 5% larger
    const squareOffsetAbove = radius * animationState.current.topEyeSquareHeight;
    const squareOffsetBelow = radius * animationState.current.bottomEyeSquareHeight;
    
    // Draw for left eye if visible
    if (degrees >= 70 && degrees <= 270) {
      ctx.fillStyle = "#FFDD00";
      // Above
      ctx.fillRect(leftEyeX - squareSize/2, eyeY - squareOffsetAbove, squareSize, squareSize);
      // Below
      ctx.fillRect(leftEyeX - squareSize/2, eyeY + squareOffsetBelow, squareSize, squareSize);
      
      // Yellow circles
      ctx.fillStyle = "#FFDD00";
      ctx.beginPath();
      ctx.arc(leftEyeX, eyeY - (radius * animationState.current.topEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
      ctx.fill();
      ctx.beginPath();
      ctx.arc(leftEyeX, eyeY + (radius * animationState.current.bottomEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
      ctx.fill();
    }
    
    // Draw for right eye if visible
    if (degrees >= 100 && degrees <= 290) {
      ctx.fillStyle = "#FFDD00";
      // Above
      ctx.fillRect(rightEyeX - squareSize/2, eyeY - squareOffsetAbove, squareSize, squareSize);
      // Below
      ctx.fillRect(rightEyeX - squareSize/2, eyeY + squareOffsetBelow, squareSize, squareSize);
      
      // Yellow circles
      ctx.fillStyle = "#FFDD00";
      ctx.beginPath();
      ctx.arc(rightEyeX, eyeY - (radius * animationState.current.topEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
      ctx.fill();
      ctx.beginPath();
      ctx.arc(rightEyeX, eyeY + (radius * animationState.current.bottomEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
      ctx.fill();
    }
    
    // Restore the context to remove the clipping path
    ctx.restore();
  }
};

/**
 * Draws the black eye that appears at the bottom of the character
 * @param {CanvasRenderingContext2D} ctx - Canvas context
 * @param {number} x - X position of the eye
 * @param {number} y - Y position of the eye
 * @param {number} radius - Radius of the eye
 * @param {number} degrees - Current angle in degrees
 */
export const drawBottomEye = (ctx, x, y, radius, degrees) => {
  // Only visible when angle is above 260 or below 90
  if (degrees < 90 || degrees > 260) {
    // Draw the eye first
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.stroke();
    
    // Draw the highlight
    const highlightRadius = radius * 0.4;
    const highlightOffsetX = radius * 0.2;
    const highlightOffsetY = -radius * 0.2;
    
    ctx.beginPath();
    ctx.arc(x + highlightOffsetX, y + highlightOffsetY, highlightRadius, 0, Math.PI * 2);
    ctx.fillStyle = "white";
    ctx.fill();
    
    // Draw yellow squares and circles AFTER the highlight so they appear on top
    const squareSize = radius * 2 * 1.05; // 5% larger
    const squareOffsetAbove = radius * animationState.current.topEyeSquareHeight;
    const squareOffsetBelow = radius * animationState.current.bottomEyeSquareHeight;
    
    ctx.fillStyle = "#FFDD00";
    // Above
    ctx.fillRect(x - squareSize/2, y - squareOffsetAbove, squareSize, squareSize);
    // Below
    ctx.fillRect(x - squareSize/2, y + squareOffsetBelow, squareSize, squareSize);
    
    // Yellow circles
    ctx.fillStyle = "#FFDD00";
    ctx.beginPath();
    ctx.arc(x, y - (radius * animationState.current.topEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
    ctx.fill();
    ctx.beginPath();
    ctx.arc(x, y + (radius * animationState.current.bottomEyeCircleHeight) + radius, radius * 1.05, 0, Math.PI * 2); // 5% larger
    ctx.fill();
  }
};

/**
 * Draws a purple square above the eyes
 * @param {CanvasRenderingContext2D} ctx - Canvas context
 * @param {number} x - X position (center of the square)
 * @param {number} y - Y position (center of the square)
 * @param {number} eyeRadius - Radius of the eye to base square size on
 */
export const drawPurpleSquare = (ctx, x, y, eyeRadius) => {
  // Square width/height based on eye diameter
  const squareSize = eyeRadius * 2 * 1.05; // 5% larger
  
  // Calculate top-left position (centered on x,y)
  const squareX = x - squareSize / 2;
  const squareY = y - squareSize / 2;
  
  // Draw the square
  ctx.fillStyle = "#FFDD00";
  ctx.fillRect(squareX, squareY, squareSize, squareSize);
};

export function drawVerticalPill(ctx, x, y, width, height) {
  const radius = width / 2;
  ctx.beginPath();
  ctx.arc(x + width / 2, y + radius, radius, Math.PI, 0, false);
  ctx.lineTo(x + width, y + height - radius);
  ctx.arc(x + width / 2, y + height - radius, radius, 0, Math.PI, false);
  ctx.closePath();
}

