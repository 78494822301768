import React, { useRef, memo, useMemo, useState, useEffect, createContext, useContext, useCallback } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
// Import your SVG component directly
import PersonWatchingTV from './SkyBackgroundSVG';
// Import only the circle edge component, removing the rect edge import
import ModifiedCircleWithEdges from './CircleEdge';
// Import BeeAnimation
import { BeeAnimation } from './BeeAnimation.js';

// Create a context to manage overlay state
export const OverlayContext3 = createContext();
// Create a context to share sun position - initialize with a proper structure
export const SunContext3 = createContext({ sunContext: { current: { x: -3, y: 4, z: 0.1 } } });

// Shared geometries and materials to reduce memory usage
export const sharedGeometries = {
  // Create the circle geometry once and reuse it for caps
  circleGeometry: new THREE.CircleGeometry(1, 8),
  // Create the unit plane geometry for rectangles
  planeGeometry: new THREE.PlaneGeometry(1, 1)
};

// SVG conversion helpers
export const svgToThreeCoords = (x, y, viewBox) => {
  const [vbX, vbY, vbWidth, vbHeight] = viewBox;
  const scale = Math.min(1/vbWidth, 1/vbHeight);
  return [
    (x - vbWidth/2) * scale,
    -(y - vbHeight/2) * scale,
    0.02  // z-offset
  ];
};

// Helper to extract SVG data from JSX component
export const extractSVGData = (Component) => {
  // Create temporary DOM element
  const div = document.createElement('div');
  const svg = Component().props.children;
  
  // Extract viewBox
  const viewBox = svg.props.viewBox.split(' ').map(Number);
  
  // Extract gradient definitions if they exist
  const defs = svg.props.children.find(child => child?.type === 'defs');
  let gradients = {};
  
  if (defs) {
    const gradientElements = defs.props.children;
    // If there's a single gradient, wrap it in an array
    const gradientsArray = Array.isArray(gradientElements) ? gradientElements : [gradientElements];
    
    gradientsArray.forEach(gradient => {
      if (gradient && gradient.props) {
        const id = gradient.props.id;
        const stops = Array.isArray(gradient.props.children) 
          ? gradient.props.children 
          : [gradient.props.children];
        
        gradients[`url(#${id})`] = {
          type: gradient.type,
          stops: stops.map(stop => ({
            offset: parseFloat(stop.props.offset) / 100,
            color: stop.props.stopColor,
            opacity: parseFloat(stop.props.stopOpacity || 1)
          }))
        };
      }
    });
  }
  
  // Extract shapes from children
  const shapes = svg.props.children
    .filter(child => child?.type && typeof child.type === 'string' && child.type !== 'defs') // Filter out defs and comments
    .map(child => {
      const props = child.props;
      const type = child.type;
      
      // Base attributes
      const baseAttrs = {
        fill: props.fill || '#000000',
        opacity: props.opacity || 1,
        stroke: props.stroke,
        strokeWidth: props['strokeWidth']
      };
      
      // Handle gradient fills
      if (baseAttrs.fill && baseAttrs.fill.startsWith('url(#')) {
        const gradientId = baseAttrs.fill;
        const gradient = gradients[gradientId];
        
        if (gradient) {
          // Store gradient information for later use
          baseAttrs.gradient = gradient;
        } else {
          // Fallback if gradient not found
          baseAttrs.fill = '#87CEEB';
        }
      }
      
      switch (type) {
        case 'rect':
          return {
            type: 'rect',
            x: parseFloat(props.x || 0),
            y: parseFloat(props.y || 0),
            width: parseFloat(props.width),
            height: parseFloat(props.height),
            ...baseAttrs
          };
        case 'circle':
          return {
            type: 'circle',
            cx: parseFloat(props.cx),
            cy: parseFloat(props.cy),
            r: parseFloat(props.r),
            ...baseAttrs
          };
        case 'polygon':
          // Convert polygon to a simple rectangle for now
          console.log('Converting polygon to rectangle placeholder');
          return {
            type: 'rect',
            x: 0,
            y: 0,
            width: 10, // Default size
            height: 10,
            ...baseAttrs
          };
        // Add other shape types as needed
        default:
          console.log(`Skipping unsupported shape type: ${type}`);
          return null;
      }
    })
    .filter(Boolean); // Remove null entries
  return { viewBox, shapes, gradients };
};

// Sun component that moves back and forth
const MovingSun = ({ scale = 1 }) => {
  const sunRef = useRef();
  const direction = useRef(1); // 1 for right, -1 for left
  const sunPosition = useRef({ x: -3, y: 4, z: 0.1 }); // Positioned higher up
  const { sunContext } = useContext(SunContext3);
  
  useFrame(({ clock }) => {
    if (!sunRef.current) return;
    
    // Update sun position
    const speed = 0.01;
    sunPosition.current.x += speed * direction.current;
    
    // Change direction when reaching bounds
    if (sunPosition.current.x > 3) {
      direction.current = -1;
    } else if (sunPosition.current.x < -3) {
      direction.current = 1;
    }
    
    // Apply position to mesh
    sunRef.current.position.x = sunPosition.current.x;
    sunRef.current.position.y = sunPosition.current.y;
    
    // Make sure sunContext exists and has a current property before updating
    if (sunContext && typeof sunContext === 'object') {
      sunContext.current = sunPosition.current;
    }
  });
  
  return (
    <mesh ref={sunRef} position={[sunPosition.current.x, sunPosition.current.y, sunPosition.current.z]} scale={[scale/10, scale/10, 1]}>
      <circleGeometry args={[0.2, 32]} />
      <meshBasicMaterial color="yellow" />
    </mesh>
  );
};

// Replace the SimpleCloud component with this improved version
const CloudGroup = ({ position, size, opacity, speed, renderOrder }) => {
  const groupRef = useRef();
  const initialX = useRef(position[0]);
  
  // Add randomization factors on mount
  const cloudParams = useRef({
    speed: speed * (0.4 + (Math.random() * 0.1 - 0.1)), // ±20% variation
    yOffset: position[1] * (1.15+ (Math.random() * 0.1 - 0.1)), // ±20%`variation
    sizeScale: 0.01 + (Math.random() * 0.2 + 0.2), // ±20% variation
  });
  
  // Calculate spawn position based on sky background viewBox with random distribution
  useEffect(() => {
    if (groupRef.current) {
      const viewBoxWidth = 1000;
      const viewBoxHeight = 800;
      const scale = Math.min(1/viewBoxWidth, 1/viewBoxHeight);
      const rightEdge = (viewBoxWidth/2) * scale *8;
      const leftEdge = -(viewBoxWidth/2) * scale *8;
      
      // Randomly position clouds across the entire width initially
      const randomX = leftEdge + (Math.random() * (rightEdge - leftEdge));
      groupRef.current.position.x = randomX;
    }
  }, []);
  
  useFrame(() => {
    if (!groupRef.current) return;
    groupRef.current.position.x -= cloudParams.current.speed;
    
    // Reset to right edge when going off left side
    // Use the same calculation for right edge as in useEffect
    const viewBoxWidth = 1000;
    const viewBoxHeight = 800;
    const scale = Math.min(1/viewBoxWidth, 1/viewBoxHeight);
    const rightEdge = (viewBoxWidth/2) * scale;
    
    if (groupRef.current.position.x < -rightEdge - 2) {
      groupRef.current.position.x = rightEdge + 2;
    }
  });
  
  const cloudCircles = useMemo(() => {
    const circleCount = 3 + Math.floor(Math.random() * 3);
    const circles = [];
    
    // Use scaled size
    const baseSize = size[0] * cloudParams.current.sizeScale;
    
    circles.push({
      position: [0, 0, 0],
      radius: baseSize,
    });
    
    for (let i = 1; i < circleCount; i++) {
      const angle = (i / (circleCount - 1)) * Math.PI;
      const distance = baseSize * 0.8;
      const x = Math.cos(angle) * distance;
      const y = Math.sin(angle) * distance * 0.5;
      
      circles.push({
        position: [x, y, 0],
        radius: baseSize * 0.8,
      });
    }
    
    return circles;
  }, [size]);
  
  // Change cloudZ to be much higher
  const cloudZ = 1;  // Changed from 0.3 to 1
  
  return (
    <group 
      ref={groupRef} 
      position={[position[0], cloudParams.current.yOffset, 1]} 
      renderOrder={0}
    >
      {cloudCircles.map((circle, index) => (
        <mesh 
          key={`cloud-circle-${index}`}
          position={circle.position}
          renderOrder={0}
        >
          <circleGeometry args={[circle.radius, 32]} />
          <meshBasicMaterial 
            color="#ffffff" 
            transparent={true}
            opacity={opacity}
            depthTest={false}
            depthWrite={false}
            renderOrder={0}
          />
        </mesh>
      ))}
    </group>
  );
};

// Instanced shape manager for circles and rectangles
class ShapeInstanceManager {
  constructor() {
    // Maps to store instanced meshes by material color+opacity
    this.circleInstances = new Map();
    this.rectInstances = new Map();
    
    // Dummy object for matrix updates
    this.dummy = new THREE.Object3D();
    
    // Pre-create geometries
    this.circleGeometry = new THREE.CircleGeometry(1, 32);
    this.rectGeometry = new THREE.PlaneGeometry(1, 1);
  }
  
  // Get a key for the material map
  getMaterialKey(color, opacity) {
    return `${color}_${opacity}`;
  }
  
  // Get or create a circle instanced mesh for a specific material
  getCircleInstance(color, opacity, renderOrder, scene) {
    const key = this.getMaterialKey(color, opacity);
    
    if (!this.circleInstances.has(key)) {
      // Create material
      const material = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: opacity,
        side: THREE.DoubleSide,
        depthTest: false,
        depthWrite: false
      });
      material.renderOrder = renderOrder;
      
      // Create instance mesh - start with capacity for 10 instances
      const instancedMesh = new THREE.InstancedMesh(this.circleGeometry, material, 10);
      instancedMesh.renderOrder = renderOrder;
      instancedMesh.count = 0;
      scene.add(instancedMesh);
      
      this.circleInstances.set(key, {
        mesh: instancedMesh,
        count: 0,
        capacity: 10
      });
    }
    
    const instance = this.circleInstances.get(key);
    
    // Expand capacity if needed
    if (instance.count >= instance.capacity) {
      const oldMesh = instance.mesh;
      const newCapacity = instance.capacity * 2;
      
      const newMesh = new THREE.InstancedMesh(
        this.circleGeometry, 
        oldMesh.material, 
        newCapacity
      );
      newMesh.renderOrder = renderOrder;
      
      // Copy existing matrices
      for (let i = 0; i < instance.count; i++) {
        oldMesh.getMatrixAt(i, this.dummy.matrix);
        newMesh.setMatrixAt(i, this.dummy.matrix);
      }
      
      newMesh.count = instance.count;
      newMesh.instanceMatrix.needsUpdate = true;
      
      // Replace in scene
      scene.remove(oldMesh);
      scene.add(newMesh);
      
      // Update in map
      instance.mesh = newMesh;
      instance.capacity = newCapacity;
    }
    
    // Return the instance and the current index
    return {
      mesh: instance.mesh,
      index: instance.count++
    };
  }
  
  // Get or create a rectangle instanced mesh for a specific material
  getRectInstance(color, opacity, renderOrder, scene) {
    const key = this.getMaterialKey(color, opacity);
    
    if (!this.rectInstances.has(key)) {
      // Create material
      const material = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: opacity,
        side: THREE.DoubleSide,
        depthTest: false,
        depthWrite: false
      });
      material.renderOrder = renderOrder;
      
      // Create instance mesh - start with capacity for 10 instances
      const instancedMesh = new THREE.InstancedMesh(this.rectGeometry, material, 10);
      instancedMesh.renderOrder = renderOrder;
      instancedMesh.count = 0;
      scene.add(instancedMesh);
      
      this.rectInstances.set(key, {
        mesh: instancedMesh,
        count: 0,
        capacity: 10
      });
    }
    
    const instance = this.rectInstances.get(key);
    
    // Expand capacity if needed
    if (instance.count >= instance.capacity) {
      const oldMesh = instance.mesh;
      const newCapacity = instance.capacity * 2;
      
      const newMesh = new THREE.InstancedMesh(
        this.rectGeometry, 
        oldMesh.material, 
        newCapacity
      );
      newMesh.renderOrder = renderOrder;
      
      // Copy existing matrices
      for (let i = 0; i < instance.count; i++) {
        oldMesh.getMatrixAt(i, this.dummy.matrix);
        newMesh.setMatrixAt(i, this.dummy.matrix);
      }
      
      newMesh.count = instance.count;
      newMesh.instanceMatrix.needsUpdate = true;
      
      // Replace in scene
      scene.remove(oldMesh);
      scene.add(newMesh);
      
      // Update in map
      instance.mesh = newMesh;
      instance.capacity = newCapacity;
    }
    
    // Return the instance and the current index
    return {
      mesh: instance.mesh,
      index: instance.count++
    };
  }
  
  // Add a circle to the scene using instancing
  addCircle(position, radius, color, opacity, renderOrder, scene) {
    const { mesh, index } = this.getCircleInstance(color, opacity, renderOrder, scene);
    
    // Set position and scale for this instance
    this.dummy.position.set(position[0], position[1], position[2]);
    this.dummy.scale.set(radius, radius, 1);
    this.dummy.updateMatrix();
    
    // Apply to instance
    mesh.setMatrixAt(index, this.dummy.matrix);
    mesh.instanceMatrix.needsUpdate = true;
    
    return { mesh, index };
  }
  
  // Add a rectangle to the scene using instancing
  addRect(position, scale, color, opacity, renderOrder, scene) {
    const { mesh, index } = this.getRectInstance(color, opacity, renderOrder, scene);
    
    // Set position and scale for this instance
    this.dummy.position.set(position[0], position[1], position[2]);
    this.dummy.scale.set(scale[0], scale[1], 1);
    this.dummy.updateMatrix();
    
    // Apply to instance
    mesh.setMatrixAt(index, this.dummy.matrix);
    mesh.instanceMatrix.needsUpdate = true;
    
    return { mesh, index };
  }
}

// Updated SVGScene component with gradient support
const SVGScene = React.memo(({ renderOrder = -100, scale = 1 }) => {
  // Initialize with a proper structure that includes current
  const sunContextRef = useRef({ current: { x: -3, y: 4, z: 0.1 } });
  const shapeInstanceManager = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  
  // Enable stencil and clipping features globally
  useEffect(() => {
    const renderer = THREE.WebGLRenderer.instance;
    if (renderer) {
      // Enable both local clipping and stencil buffer
      renderer.localClippingEnabled = true;
      renderer.state.buffers.stencil.setTest(true);
      
      // Set some global renderer settings to ensure proper stencil operations
      renderer.autoClearStencil = true;
      renderer.stencil = true;
    }
    
    // Disable frustum culling for edge lines
    const disableCulling = () => {
      const edgeLines = document.querySelectorAll('.edge-line');
      edgeLines.forEach(line => {
        if (line.object && line.object.frustumCulled !== undefined) {
          line.object.frustumCulled = false;
        }
      });
    };
    
    // Run initially and set up an interval to catch any newly added lines
    disableCulling();
    const interval = setInterval(disableCulling, 1000);
    
    return () => clearInterval(interval);
  }, []);
  
  // Initialize the shape instance manager
  useEffect(() => {
    if (!shapeInstanceManager.current) {
      shapeInstanceManager.current = new ShapeInstanceManager();
    }
  }, []);
  
  // Extract SVG data
  const { viewBox, shapes: svgShapes, gradients } = useMemo(() => {
    return extractSVGData(PersonWatchingTV);
  }, []);
  
  // Process all shapes maintaining original ordering from SVG
  const processedShapes = useMemo(() => {
    return svgShapes.map((shape, index) => {
      // Each shape in the SVG gets a base renderOrder based on its position in the array
      const shapeRenderOrder = renderOrder + index;
      
      if (shape.type === 'circle') {
        const scaleValue = Math.min(1/viewBox[2], 1/viewBox[3]);
        const circleRadius = shape.r * scaleValue;
        const position = svgToThreeCoords(
          shape.cx,
          shape.cy,
          viewBox
        );
        
        return {
          type: 'circle',
          position,
          radius: circleRadius,
          color: shape.fill,
          opacity: shape.opacity,
          gradient: shape.gradient,
          renderOrder: shapeRenderOrder,
          shapeIndex: index
        };
      } else if (shape.type === 'rect') {
        const position = svgToThreeCoords(
          shape.x + shape.width/2,
          shape.y + shape.height/2,
          viewBox
        );
        const scaleValue = Math.min(1/viewBox[2], 1/viewBox[3]);
        const scaleX = shape.width * scaleValue;
        const scaleY = shape.height * scaleValue;
        
        return {
          type: 'rect',
          position,
          scale: [scaleX, scaleY, 1],
          color: shape.fill,
          opacity: shape.opacity,
          gradient: shape.gradient,
          renderOrder: shapeRenderOrder,
          shapeIndex: index
        };
      }
      
      return null;
    }).filter(Boolean);
  }, [svgShapes, viewBox, renderOrder]);
  
  // Apply base scale (6.6) multiplied by the passed scale prop
  const baseScale = 6.6 * scale;
  
  return (
    <SunContext3.Provider value={{ sunContext: sunContextRef }}>
      <group scale={[baseScale, baseScale, 1]} position={[0, -0.97, -0.1]}>
        {/* Add moving sun */}
        <MovingSun scale={baseScale} />
        
        {/* Render all shapes based on original ordering */}
        {processedShapes.map((shape) => {
          if (shape.type === 'circle') {
            return (
              <ModifiedCircleWithEdges
                key={`circle-${shape.shapeIndex}`}
                position={shape.position}
                radius={shape.radius}
                color={shape.color}
                opacity={shape.opacity}
                gradient={shape.gradient}
                renderOrder={shape.renderOrder}
                contextType="overlay3"
              />
            );
          } else if (shape.type === 'rect') {
            // Check if this rectangle has a gradient
            if (shape.gradient) {
              return (
                <GradientRect
                  key={`rect-${shape.shapeIndex}`}
                  position={shape.position}
                  scale={shape.scale}
                  gradient={shape.gradient}
                  renderOrder={shape.renderOrder}
                />
              );
            }
            
            // Use regular mesh for rectangles without gradients
            return (
              <mesh
                key={`rect-${shape.shapeIndex}`}
                position={shape.position}
                scale={shape.scale}
                renderOrder={shape.renderOrder}
              >
                <planeGeometry args={[1, 1]} />
                <meshBasicMaterial
                  color={shape.color}
                  transparent={true}
                  opacity={shape.opacity}
                  side={THREE.DoubleSide}
                  depthTest={false}
                  depthWrite={false}
                />
              </mesh>
            );
          }
          
          return null;
        })}
      </group>
    </SunContext3.Provider>
  );
});

// Update the GradientRect component to properly handle renderOrder
const GradientRect = ({ position, scale, gradient, renderOrder }) => {
  const meshRef = useRef();
  
  // Create a shader material with a vertical gradient
  const material = useMemo(() => {
    // Extract gradient stops - ensure we have at least two stops
    const stops = gradient?.stops || [
      { offset: 0, color: '#87CEEB', opacity: 0 },
      { offset: 1, color: '#87CEEB', opacity: 0.8 }
    ];
    
    // Convert colors to THREE.Color objects and create stop positions array
    const colors = stops.map(stop => new THREE.Color(stop.color));
    
    // Create arrays that can be passed to shader uniforms
    const colorArray = new Float32Array(stops.length * 3);
    const stopPositions = new Float32Array(stops.length);
    const opacities = new Float32Array(stops.length);
    
    // Fill the arrays with data
    stops.forEach((stop, i) => {
      const color = new THREE.Color(stop.color);
      colorArray[i * 3] = color.r;
      colorArray[i * 3 + 1] = color.g;
      colorArray[i * 3 + 2] = color.b;
      stopPositions[i] = stop.offset;
      opacities[i] = stop.opacity;
    });
    
    // Create a custom shader material
    return new THREE.ShaderMaterial({
      uniforms: {
        colorArray: { value: colorArray },
        stops: { value: stopPositions },
        opacities: { value: opacities },
        numStops: { value: stops.length }
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform float colorArray[30]; // Support up to 10 color stops (10 * 3 values)
        uniform float stops[10];
        uniform float opacities[10];
        uniform int numStops;
        varying vec2 vUv;
        
        void main() {
          // Y coordinate determines position in gradient (0 at top, 1 at bottom)
          float t = vUv.y;
          
          // Find the right segment in the gradient
          vec3 color = vec3(
            colorArray[0],
            colorArray[1],
            colorArray[2]
          );
          float opacity = opacities[0];
          
          for (int i = 0; i < 10; i++) {
            if (i >= numStops - 1) break;
            
            if (t >= stops[i] && t <= stops[i+1]) {
              // Normalize t within this segment
              float segmentT = (t - stops[i]) / (stops[i+1] - stops[i]);
              
              // Get colors for this segment
              vec3 color1 = vec3(
                colorArray[i * 3],
                colorArray[i * 3 + 1],
                colorArray[i * 3 + 2]
              );
              
              vec3 color2 = vec3(
                colorArray[(i+1) * 3],
                colorArray[(i+1) * 3 + 1],
                colorArray[(i+1) * 3 + 2]
              );
              
              // Interpolate color and opacity
              color = mix(color1, color2, segmentT);
              opacity = mix(opacities[i], opacities[i+1], segmentT);
              break;
            }
          }
          
          gl_FragColor = vec4(color, opacity);
        }
      `,
      transparent: true,
      side: THREE.DoubleSide,
      depthTest: false,
      depthWrite: false,
      renderOrder: -1000 // Force very low render order for sky
    });
  }, [gradient]);
  
  // Set render order on mesh
  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.renderOrder = -1000; // Force very low render order for sky
    }
  }, []);
  
  return (
    <mesh
      ref={meshRef}
      position={position}
      scale={scale}
      renderOrder={-1000} // Force very low render order for sky
    >
      <planeGeometry args={[1, 1]} />
      <primitive object={material} attach="material" />
    </mesh>
  );
};

// Replace the BeeAnimationTexture component with this modified version
const BeeAnimationTexture = memo(({ position = [0, -5, 0.05], scale = 1, renderOrder = 20 }) => {
  const meshRef = useRef();
  const textureRef = useRef();
  const beeAnimationRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const { camera, gl } = useThree();
  
  // Track drag state
  const dragInfo = useRef({
    dragging: false,
    initialMouseX: 0,
    initialMouseY: 0,
    initialCircleX: 0,
    initialCircleY: 0
  });
  
  // Add this at the top of the BeeAnimationTexture component
  const frameCount = useRef(0);
  
  // Create and initialize the BeeAnimation
  useEffect(() => {
    console.log("Initializing BeeAnimation...");
    
    // Create the bee animation with 3x wider size
    const beeAnimation = new BeeAnimation(2400, 600);
    beeAnimationRef.current = beeAnimation;
    
    // Create a texture from the canvas
    const texture = new THREE.CanvasTexture(beeAnimation.getCanvas());
    texture.needsUpdate = true;
    textureRef.current = texture;
    
    console.log("BeeAnimation initialized with canvas:", beeAnimation.getCanvas());
    setIsLoaded(true);
    
    // Clean up on unmount
    return () => {
      if (texture) texture.dispose();
      
      if (beeAnimation && beeAnimation.animationFrameRequest) {
        cancelAnimationFrame(beeAnimation.animationFrameRequest);
        beeAnimation.animationFrameRequest = null;
      }
    };
  }, []);
  
  // Set up drag handlers using a differential approach
  useEffect(() => {
    if (!meshRef.current || !beeAnimationRef.current) return;
    
    const handleMouseDown = (event) => {
      // Check if our mesh was clicked using raycasting
      const rect = gl.domElement.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      
      // Convert to normalized device coordinates
      const ndcX = (mouseX / rect.width) * 2 - 1;
      const ndcY = -(mouseY / rect.height) * 2 + 1;
      
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x: ndcX, y: ndcY }, camera);
      
      const intersects = raycaster.intersectObject(meshRef.current);
      
      if (intersects.length > 0) {
        // We hit the BeeAnimation mesh
        const orangeCircle = beeAnimationRef.current.orangeCircleYellow;
        const canvasRect = beeAnimationRef.current.canvas.getBoundingClientRect();
        
        // Use the canvas to get its scale
        const canvasWidth = beeAnimationRef.current.canvas.width;
        const canvasHeight = beeAnimationRef.current.canvas.height;
        
        // Get UV coordinates of intersection
        const uv = intersects[0].uv;
        
        // Convert UV to canvas-scaled position
        const hitX = uv.x * canvasWidth;
        const hitY = (1 - uv.y) * canvasHeight;
        
        // Check if click is on the orange circle
        const dx = hitX - orangeCircle.x;
        const dy = hitY - orangeCircle.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        if (distance <= orangeCircle.radius * 1.5) {
          console.log("Orange circle clicked!");
          
          // Store initial positions for differential movement
          dragInfo.current = {
            dragging: true,
            initialMouseX: mouseX,
            initialMouseY: mouseY,
            initialCircleX: orangeCircle.x,
            initialCircleY: orangeCircle.y
          };
          
          // Mark it as dragging and fixed position in BeeAnimation
          orangeCircle.dragging = true;
          orangeCircle.positionFixed = true;
          beeAnimationRef.current.markNeedsUpdate();
        }
      }
    };
    
    const handleMouseMove = (event) => {
      if (!dragInfo.current.dragging) return;
      
      const rect = gl.domElement.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      
      // Calculate delta movement since drag started
      const deltaX = mouseX - dragInfo.current.initialMouseX;
      const deltaY = mouseY - dragInfo.current.initialMouseY;
      
      // Scale factor based on Three.js object vs canvas size
      const threeJsWidth = rect.width;
      const threeJsHeight = rect.height;
      const canvasWidth = beeAnimationRef.current.canvas.width;
      const canvasHeight = beeAnimationRef.current.canvas.height;
      
      // Calculate scaling factor
      const scaleFactorX = canvasWidth / threeJsWidth;
      const scaleFactorY = canvasHeight / threeJsHeight;
      
      // Apply delta movement to original position, with scaling
      const orangeCircle = beeAnimationRef.current.orangeCircleYellow;
      orangeCircle.x = dragInfo.current.initialCircleX + (deltaX * scaleFactorX);
      orangeCircle.y = dragInfo.current.initialCircleY + (deltaY * scaleFactorY);
      
      beeAnimationRef.current.markNeedsUpdate();
    };
    
    const handleMouseUp = () => {
      if (dragInfo.current.dragging) {
        const orangeCircle = beeAnimationRef.current.orangeCircleYellow;
        dragInfo.current.dragging = false;
        orangeCircle.dragging = false;
        // Keep positionFixed true so it stays where dropped
        beeAnimationRef.current.markNeedsUpdate();
      }
    };
    
    // Attach listeners to three.js canvas
    const canvas = gl.domElement;
    canvas.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [camera, gl, isLoaded]);
  
  // Update texture on each frame
  useFrame(() => {
    if (textureRef.current && beeAnimationRef.current) {
      // Only update the texture when dragging or every 3rd frame for animation
      // This significantly reduces GPU texture uploads while maintaining responsiveness
      if (dragInfo.current.dragging || frameCount.current % 3 === 0) {
        textureRef.current.needsUpdate = true;
      }
      frameCount.current++;
    }
  });
  
  if (!isLoaded) return null;
  
  return (
    <mesh 
      ref={meshRef} 
      position={position} 
      scale={[scale * 3, scale * (600/800), 1]} 
      renderOrder={renderOrder}
    >
      <planeGeometry args={[1, 1]} />
      <meshBasicMaterial 
        map={textureRef.current} 
        transparent={true} 
        side={THREE.DoubleSide}
        depthTest={false}
        depthWrite={false}
      />
    </mesh>
  );
});

// Add StandaloneBeeAnimation component
const StandaloneBeeAnimation = memo(({ position = [0, 1, 0.1], scale = 1 }) => {
  return (
    <group position={position} scale={[scale, scale, 1]}>
      <BeeAnimationTexture position={[0, .1, 0]} scale={1} renderOrder={0.1} />
    </group>
  );
});

// Custom Overlays component that will contain your custom shapes
export const Overlays3 = memo(({ scale = 1, isCameraChild = true, renderOrder = -100 }) => {
  const svgSceneRef = useRef();
  const { camera, scene } = useThree();
  
  // Disable frustum culling for all lines in the scene
  useEffect(() => {
    const disableCullingForLines = () => {
      scene.traverse(object => {
        // Check if this is a Line object
        if (object.isLine || object.isLineSegments) {
          object.frustumCulled = false;
        }
      });
    };
    
    // Run initially and set up an interval to catch any newly added lines
    disableCullingForLines();
    const interval = setInterval(disableCullingForLines, 1000);
    
    return () => clearInterval(interval);
  }, [scene]);
  
  // Reset the mounted flag when the component is mounted
  useEffect(() => {
    // Reset the flag when the component mounts
    if (isCameraChild) {
      window.__overlay3Mounted = true;
    }
    
    // Clean up on unmount
    return () => {
      if (isCameraChild) {
        window.__overlay3Mounted = false;
      }
    };
  }, [isCameraChild]);
  
  // NEW EFFECT: Disable frustum culling for all objects in this overlay
  useEffect(() => {
    const disableFrustumCullingRecursively = (object) => {
      if (!object) return;
      
      // Disable on the object itself if it has the property
      if (object.frustumCulled !== undefined) {
        object.frustumCulled = false;
      }
      
      // If it has children, process them too (recursively)
      if (object.children && object.children.length > 0) {
        object.children.forEach(child => disableFrustumCullingRecursively(child));
      }
    };
    
    // Run it on the group and set an interval to catch new objects
    const disableAllCulling = () => {
      if (svgSceneRef.current) {
        disableFrustumCullingRecursively(svgSceneRef.current);
      }
    };
    
    // Run immediately and at regular intervals
    disableAllCulling();
    const interval = setInterval(disableAllCulling, 500);
    
    return () => clearInterval(interval);
  }, []);
  
  // Check if this overlay should be active
  const isActive = isCameraChild && window.__overlay3Mounted;
  
  // Only render if active
  if (!isActive) {
    return null;
  }
  
  return (
    <group renderOrder={-100}>
      {/* Sky background with forced low render order */}
      <group ref={svgSceneRef} position={[0, 0, -1]} renderOrder={-1000}>
        <SVGScene renderOrder={-1000} scale={scale} />
      </group>

      {/* Clouds with forced higher render order */}
      <group position={[0, 0, 1]} renderOrder={0}>
        <CloudGroup 
          position={[5, -2.2, 0]} 
          size={[0.2, 0.2]}
          opacity={0.99} 
          speed={0.01} 
        />
        <CloudGroup 
          position={[2, -2.4, 0]} 
          size={[0.15, 0.15]}
          opacity={0.99} 
          speed={0.005} 
        />
        <CloudGroup 
          position={[8, -2.1, 0]} 
          size={[0.18, 0.18]}
          opacity={0.99} 
          speed={0.008} 
        />
        <CloudGroup 
          position={[0, -2.3, 0]} 
          size={[0.16, 0.16]}
          opacity={0.99} 
          speed={0.012} 
        />
        <CloudGroup 
          position={[6, -2.5, 0]} 
          size={[0.2, 0.2]}
          opacity={0.99} 
          speed={0.007} 
        />

        <CloudGroup 
          position={[3, -2.15, 0]} 
          size={[0.17, 0.17]}
          opacity={0.99} 
          speed={0.009} 
        />
        <CloudGroup 
          position={[7, -2.35, 0]} 
          size={[0.19, 0.19]}
          opacity={0.99} 
          speed={0.006} 
        />
        <CloudGroup 
          position={[1, -2.25, 0]} 
          size={[0.14, 0.14]}
          opacity={0.99} 
          speed={0.011} 
        />
        <CloudGroup 
          position={[4, -2.45, 0]} 
          size={[0.21, 0.21]}
          opacity={0.99} 
          speed={0.004} 
        />
        <CloudGroup 
          position={[9, -2.18, 0]} 
          size={[0.16, 0.16]}
          opacity={0.99} 
          speed={0.013} 
        />
      </group>
      
      {/* Add the BeeAnimation */}
      <StandaloneBeeAnimation position={[0, -3.3, 2]} scale={0.6} />
    </group>
  );
});