import React, { useState, useEffect, useRef } from 'react';
import { rtdb } from '../../../firebase'; // Ensure rtdb is correctly exported
import { ref, onValue, off, update, get } from 'firebase/database'; // Added 'update' for XP increment
import { useAuth } from '../../../contexts/AuthContext';
import PropTypes from 'prop-types';
import { FaTimes } from '../../assets/icons'; // Ensure icons are correctly imported
import { useDataController, MODULE_STATES } from '../../../contexts/datacontroller';
import BreathingAnimation from './BeeCharacter/BreathingAnimation';
import OpenAI from 'openai'; // You'll need to install this package

// Import banner image similar to Whiteboard
import PuzzleBanner from '../../../components/banners/animatedsvg.svg'; // Adjust the path if necessary

// Update the OpenAI client initialization
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const SlideComponents = ({ onClose, classID }) => {
  const { currentUser } = useAuth();
  const [bees, setBees] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize as true
  const [error, setError] = useState(null);
  const [selectedBee, setSelectedBee] = useState(null); // For modal view

  // Add new state for new member name input
  const [newMemberName, setNewMemberName] = useState('');

  // Add new state for timer
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timerActive, setTimerActive] = useState(false);

  const { setModuleStatus } = useDataController();

  // Create a ref where the external JS animation will attach its container.
  const animationContainerRef = useRef(null);

  // Add new state variables for the lesson grid
  const [cellContents, setCellContents] = useState(Array(20).fill(''));
  const [iDoContents, setIDoContents] = useState(Array(20).fill(''));
  const [weDoContents, setWeDoContents] = useState(Array(20).fill(''));
  const [youDoContents, setYouDoContents] = useState(Array(20).fill(''));
  const [reflectionContents, setReflectionContents] = useState(Array(20).fill(''));
  
  // Add selection state
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);

  // Add timer utility function
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Add timer effect
  useEffect(() => {
    let interval;
    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining(prev => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setTimerActive(false);
      // Optional: Add any logic for when timer ends
    }
    return () => clearInterval(interval);
  }, [timerActive, timeRemaining]);

  // Fetch bees' names from RTDB where logged is "yes"
  useEffect(() => {
    if (!classID) {
      console.error('Missing classID.');
      setError('Invalid class ID.');
      setLoading(false);
      return;
    }

    const beesRef = ref(rtdb, `activeclasses/${classID}/beeNames`);
    console.log('Listening to RTDB path:', `activeclasses/${classID}/beeNames`); // Debug log

    const handleData = (snapshot) => {
      const data = snapshot.val();
      console.log('Fetched data from RTDB:', data); // Debug log

      if (data) {
        console.log(`Data retrieved for classID ${classID}:`, data);
        const beesArray = Object.keys(data)
          .filter((name) => {
            const isLogged = data[name].logged === 'yes'; // Ensure case matches
            if (!isLogged) {
              console.log(`Bee "${name}" is not logged.`);
            }
            return isLogged;
          })
          .map((name) => ({
            name: name.trim(),
            totalxp: data[name].totalxp || 0,
            classId: classID,
          }));
        console.log('Parsed bees array:', beesArray); // Debug log
        setBees(beesArray);
      } else {
        console.warn(`No data found at path activeclasses/${classID}/beeNames.`);
        setBees([]);
      }
      setLoading(false);
    };

    onValue(beesRef, handleData, (err) => {
      console.error('Error fetching bees:', err);
      setError('Failed to load bees. Please try again.');
      setLoading(false);
    });

    console.log(`Listener attached to activeclasses/${classID}/beeNames`);

    // Cleanup listener on unmount
    return () => {
      off(beesRef, 'value', handleData);
      console.log(`Detached listener from activeclasses/${classID}/beeNames`);
    };
  }, [classID]);

  // Function to handle XP increment
  const handleAddXp = (beeName) => {
    const beeIndex = bees.findIndex((bee) => bee.name === beeName);
    if (beeIndex !== -1) {
      const updatedBees = [...bees];
      updatedBees[beeIndex].totalxp += 1;
      setBees(updatedBees);

      // Update in RTDB
      const beeRef = ref(rtdb, `activeclasses/${classID}/beeNames/${beeName}/totalxp`);
      update(beeRef, updatedBees[beeIndex].totalxp)
        .then(() => {
          console.log(`XP updated for ${beeName}`);
        })
        .catch((err) => {
          console.error('Error updating XP:', err);
        });
    }
  };

  // JSX for a single bee's card
  const BeeCard = ({ bee }) => {
    console.log('Rendering BeeCard for:', bee.name); // Debug log

    return (
      <div className="flex flex-col items-center">
        {/* A4 Page Shape */}
        <div
          className="relative transition-transform bg-white rounded-lg shadow-md cursor-pointer hover:scale-105"
          style={{
            width: '150px', // Adjusted width for screen
            height: '212px', // Height maintaining A4 aspect ratio (150 * 297 / 210 ≈ 212)
            maxWidth: '150px',
            maxHeight: '212px',
          }}
          onClick={() => setSelectedBee(bee)}
        >
          {/* Placeholder for typing content */}
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-400">Typing Area</p>
          </div>

          {/* XP Button */}
          <button
            className="absolute p-1 text-lg transition-opacity bg-blue-500 rounded-full shadow opacity-0 bottom-2 right-2 hover:opacity-100"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the card click
              handleAddXp(bee.name);
            }}
            aria-label="Add XP"
            title="Add XP"
          >
            ❤️
          </button>
        </div>

        {/* Bee Name */}
        <div className="mt-2 text-sm font-medium text-center text-gray-700">
          {bee.name}
        </div>
      </div>
    );
  };

  BeeCard.propTypes = {
    bee: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number,
      classId: PropTypes.string.isRequired,
    }).isRequired,
  };

  // List of bee cards with adjusted grid layout
  const beeList = (
    <div className="grid justify-center grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
      {bees.map((bee) => (
        <BeeCard key={`${bee.classId}-${bee.name}`} bee={bee} />
      ))}
    </div>
  );

  // Modal view for selected bee
  const BeeModal = ({ bee, onClose }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
        <div
          className="relative bg-white rounded-lg shadow-xl"
          style={{
            width: '90%',
            height: '80%',
            maxWidth: '800px',
            maxHeight: '1100px',
            overflow: 'hidden', // Remove scrollbars
          }}
          onClick={onClose}
        >
          <button
            onClick={onClose}
            className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
            aria-label="Close Modal"
            onMouseDown={(e) => e.stopPropagation()} // Prevent closing when clicking the button
          >
            <FaTimes />
          </button>
          <div className="flex flex-col h-full p-12">
            {/* Enlarge the BeeCard inside the modal */}
            <div className="flex items-center justify-center flex-grow">
              <div
                className="relative bg-white rounded-lg shadow-md cursor-default"
                style={{
                  width: '100%', // Make it responsive within the modal
                  height: 'auto',
                  paddingTop: '141.42%', // 210/297 ≈ 0.707, so padding-top: 70.7% to maintain aspect ratio
                  maxWidth: '800px',
                }}
              >
                <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-between h-full p-8">
                  <h2 className="mb-4 text-2xl font-bold text-center">{bee.name}</h2>
                  {/* Additional detailed content can be added here */}
                  <div className="mt-4 text-center">
                    {/* Placeholder for detailed typing content */}
                    <p className="text-gray-400">Detailed Typing Area (To be implemented)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  BeeModal.propTypes = {
    bee: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalxp: PropTypes.number,
      classId: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  // Add handler functions
  const handleCellChange = (index, value) => {
    const newCellContents = [...cellContents];
    newCellContents[index] = value;
    setCellContents(newCellContents);
  };

  const handleGenerate = async () => {
    try {
      // Filter out empty cells and process each non-empty cell
      const nonEmptyCells = cellContents.filter(content => content.trim() !== '');
      
      for (let i = 0; i < nonEmptyCells.length; i++) {
        const content = nonEmptyCells[i];
        const originalIndex = cellContents.indexOf(content);
        
        // Construct the prompt
        const prompt = `Your task is to use the following Learning Intention and Success Criteria and create teacher instruction for the teacher instruction part of lesson.

This should consist of teacher instruction to the students i.e explaining the learning content. It should use language like "Teacher explains..." 

The facts/things that the teacher does in this part should be in dot points.

Ensure all your responses use British/Australian english. i.e the -Ize suffix should be -ise. 

Examples:
- "Teacher draws .... on the whiteboard" 
- "Teacher models solving...." 

Your response should have at least 3 subheadings and each one should ahve at least 3 dot points.

In the dot points, provide actual examples of the learning. 

Your response should not include any resources or explanation of the activity. The response is only explain the actual learning of the content.

Ensure you include specify what to search for on Google Images using the format: [SEARCH: your search terms]. For example: [SEARCH: multiplication array diagram]. Do not restate the LI and SC. Put new lines of text on a new row. Each dot point should also be on a new row. Make subheadings bold. Do not label "teacher instruction". Just return the actual content. 

LI and SC: ${content}`;

        // Make API call
        const completion = await openai.chat.completions.create({
          messages: [{ role: "user", content: prompt }],
          model: "gpt-3.5-turbo",
        });

        // Process the response to convert search terms into Google Image links
        let processedResponse = completion.choices[0].message.content;
        const searchPattern = /\[SEARCH:\s*(.*?)\]/g;
        processedResponse = processedResponse.replace(searchPattern, (match, searchTerms) => {
          const encodedSearch = encodeURIComponent(searchTerms);
          const googleImagesUrl = `https://www.google.com/search?q=${encodedSearch}&tbm=isch`;
          return `<a href="${googleImagesUrl}" target="_blank" class="text-blue-500 hover:underline">[View Image: ${searchTerms}]</a>`;
        });

        // Update the I Do column with the processed response
        const newIDoContents = [...iDoContents];
        newIDoContents[originalIndex] = processedResponse;
        setIDoContents(newIDoContents);
      }
    } catch (error) {
      console.error('Error generating content:', error);
    }
  };

  // Update the input rendering in the I Do column to handle HTML
  const renderContent = (content) => {
    if (!content) return '';
    
    // Convert markdown-style bold text (between **) to HTML bold tags
    let formattedContent = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Convert dashes at the start of lines to bullet points
    formattedContent = formattedContent.replace(/^- /gm, '• ');
    
    // Convert newlines to <br> tags
    formattedContent = formattedContent.replace(/\n/g, '<br>');
    
    return formattedContent;
  };

  const isSelected = (row, col) => {
    if (!selectionStart || !selectionEnd) return false;

    const startRow = Math.min(selectionStart.row, selectionEnd.row);
    const endRow = Math.max(selectionStart.row, selectionEnd.row);
    const startCol = Math.min(selectionStart.col, selectionEnd.col);
    const endCol = Math.max(selectionStart.col, selectionEnd.col);

    return row >= startRow && row <= endRow && col >= startCol && col <= endCol;
  };

  const getSelectedCells = () => {
    if (!selectionStart || !selectionEnd) return null;

    const startRow = Math.min(selectionStart.row, selectionEnd.row);
    const endRow = Math.max(selectionStart.row, selectionEnd.row);
    const startCol = Math.min(selectionStart.col, selectionEnd.col);
    const endCol = Math.max(selectionStart.col, selectionEnd.col);

    // Create a 2D array of the selected cells
    const selectedData = [];
    for (let row = startRow; row <= endRow; row++) {
      const rowData = [];
      for (let col = startCol; col <= endCol; col++) {
        // Get content based on column, use placeholder if empty
        let cellContent = '';
        switch (col) {
          case 0: cellContent = formatForClipboard(iDoContents[row]) || 'I Do'; break;
          case 1: cellContent = formatForClipboard(weDoContents[row]) || 'We Do'; break;
          case 2: cellContent = formatForClipboard(youDoContents[row]) || 'You Do'; break;
          case 3: cellContent = formatForClipboard(reflectionContents[row]) || 'Reflection'; break;
          default: cellContent = '';
        }
        rowData.push(cellContent);
      }
      selectedData.push(rowData);
    }
    return selectedData;
  };

  // Add this new helper function
  const formatForClipboard = (content) => {
    if (!content) return '';
    return content
      .replace(/<strong>(.*?)<\/strong>/g, '**$1**')
      .replace(/•/g, '- ')
      .replace(/<br>/g, '\n');
  };

  const handleCopy = async () => {
    const selectedData = getSelectedCells();
    let data;
    
    if (!selectedData) {
      // If nothing is selected, copy all cells
      data = Array(20).fill().map((_, row) => [
        iDoContents[row] || `Step ${row + 1}`,
        weDoContents[row] || `Step ${row + 1}`,
        youDoContents[row] || `Step ${row + 1}`,
        reflectionContents[row] || `Step ${row + 1}`
      ]);
    } else {
      data = selectedData;
    }

    const plainText = data.map(row => 
      row.map(cell => cell.replace(/\n/g, ' ')).join('\t')
    ).join('\n');

    const htmlContent = data.map(row => 
      row.map(cell => {
        const htmlCell = cell
          .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
          .replace(/^- /gm, '• ')
          .replace(/\n/g, '<br>');
        return `<td style="vertical-align: top; white-space: pre-wrap; padding: 2px; border: 1px solid black;">${htmlCell}</td>`;
      }).join('')
    ).map(row => `<tr>${row}</tr>`).join('');

    const htmlDocument = `
      <html>
        <body>
          <table style="border-collapse: collapse;">${htmlContent}</table>
        </body>
      </html>
    `;

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([plainText], { type: 'text/plain' }),
          'text/html': new Blob([htmlDocument], { type: 'text/html' })
        })
      ]);
    } catch (err) {
      await navigator.clipboard.writeText(plainText);
      console.error('Failed to copy with formatting:', err);
    }
  };

  // Add this debug function to help verify the selection
  const logSelection = () => {
    if (selectionStart && selectionEnd) {
      console.log('Selection:', {
        start: selectionStart,
        end: selectionEnd,
        rows: Math.abs(selectionEnd.row - selectionStart.row) + 1,
        columns: Math.abs(selectionEnd.col - selectionStart.col) + 1
      });
    }
  };

  // Update the mouseup handler to include logging
  useEffect(() => {
    const handleMouseUp = () => {
      if (isSelecting) {
        setIsSelecting(false);
        logSelection(); // Add logging
        handleCopy();
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, [isSelecting]);

  // Add new function to handle flipping the table
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-white">
      {/* Header Section with SVG Banner and Date/Time */}
      <div className="flex flex-col items-center justify-center w-full px-4 py-4 shadow-md">
        {/* Title */}
        <h1 className="text-3xl font-bold text-center">Lesson Creator</h1>
        {/* Current Date and Time */}
        <div className="mt-2 text-xs font-bold text-gray-600">
          {new Date().toLocaleString()}
        </div>
      </div>

      {/* Banner Area with Enhanced Shadow */}
      <div
        className="w-full"
        style={{
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
        }}
      >
        {/* Banner image with fixed height */}
        <img
          src={PuzzleBanner}
          alt="Puzzle Banner"
          style={{ height: '96px', width: '100%', objectFit: 'cover' }}
        />
      </div>

      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute text-3xl text-gray-500 top-4 right-4 hover:text-gray-700"
        aria-label="Close Workbook"
      >
        <FaTimes />
      </button>

      {/* Main Content Area */}
      <div className="flex-grow p-6 mt-4 overflow-auto bg-gray-100">
        <div className="flex items-start gap-4">
          {/* First Column */}
          <div className="max-w-md bg-white rounded-lg shadow">
            <div className="p-4">
              <div className="h-[72px]"> {/* Match height of right column headers */}
                <h2 className="text-xl font-bold text-center">Paste LI and SC</h2>
              </div>
              <div className="space-y-2">
                {cellContents.map((content, index) => (
                  <div key={index} className="flex items-center">
                    <div className="w-8 mr-2 text-sm text-gray-500">{index + 1}</div>
                    <input
                      type="text"
                      value={content}
                      onChange={(e) => handleCellChange(index, e.target.value)}
                      className="w-full h-8 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder={`Cell ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Generate Lessons Button */}
          <button
            onClick={handleGenerate}
            className="flex items-center justify-center h-10 gap-2 px-4 text-white transition-colors duration-200 bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            title="Generate Lessons"
          >
            <svg 
              className="w-4 h-4" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            <span className="text-sm font-medium">Generate</span>
          </button>

          {/* Second Column with table */}
          <div className="flex gap-2">
            {/* Table container */}
            <div className="flex-1 bg-white rounded-lg shadow" style={{ maxWidth: '800px' }}>
              <div className="p-4">
                <div className="h-[72px]">
                  <h2 className="text-xl font-bold text-center">Lesson Components</h2>
                </div>
                
                {isFlipped ? (
                  // Flipped table layout with fixed cell sizes
                  <div className="overflow-x-auto">
                    <table className="w-full table-fixed">
                      {/* Add table header */}
                      <thead>
                        <tr>
                          <th className="w-24"></th> {/* Empty cell for row headers */}
                          {Array(20).fill('').map((_, index) => (
                            <th key={index} className="w-[120px] p-1 text-center font-medium text-gray-700">
                              Lesson {index + 1}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {/* I Do Row */}
                        <tr className="border-b">
                          <td className="w-24 py-2 pr-4 font-semibold text-blue-600">I Do</td>
                          {Array(20).fill('').map((_, index) => (
                            <td key={index} className="w-[120px] p-1 max-w-[120px]">
                              <div
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                  setIsSelecting(true);
                                  setSelectionStart({ row: 0, col: index });
                                  setSelectionEnd({ row: 0, col: index });
                                }}
                                onMouseEnter={() => {
                                  if (isSelecting) {
                                    setSelectionEnd({ row: 0, col: index });
                                  }
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: iDoContents[index] ? 
                                    renderContent(iDoContents[index]) : 
                                    '<span class="text-gray-400">-</span>'
                                }}
                                className={`w-full px-2 py-1 text-xs border border-gray-300 rounded 
                                  h-7 overflow-hidden whitespace-nowrap text-ellipsis cursor-default
                                  max-w-full block
                                  ${isSelected(0, index) ? 'bg-blue-100' : ''}`}
                                style={{ width: '120px' }}
                              />
                            </td>
                          ))}
                        </tr>
                        
                        {/* We Do Row */}
                        <tr className="border-b">
                          <td className="w-24 py-2 pr-4 font-semibold text-green-600">We Do</td>
                          {Array(20).fill('').map((_, index) => (
                            <td key={index} className="w-[120px] p-1 max-w-[120px]">
                              <div
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                  setIsSelecting(true);
                                  setSelectionStart({ row: 1, col: index });
                                  setSelectionEnd({ row: 1, col: index });
                                }}
                                onMouseEnter={() => {
                                  if (isSelecting) {
                                    setSelectionEnd({ row: 1, col: index });
                                  }
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: weDoContents[index] ? 
                                    renderContent(weDoContents[index]) : 
                                    '<span class="text-gray-400">-</span>'
                                }}
                                className={`w-full px-2 py-1 text-xs border border-gray-300 rounded 
                                  h-7 overflow-hidden whitespace-nowrap text-ellipsis cursor-default
                                  max-w-full block
                                  ${isSelected(1, index) ? 'bg-green-100' : ''}`}
                                style={{ width: '120px' }}
                              />
                            </td>
                          ))}
                        </tr>

                        {/* You Do Row */}
                        <tr className="border-b">
                          <td className="w-24 py-2 pr-4 font-semibold text-purple-600">You Do</td>
                          {Array(20).fill('').map((_, index) => (
                            <td key={index} className="w-[120px] p-1 max-w-[120px]">
                              <div
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                  setIsSelecting(true);
                                  setSelectionStart({ row: 2, col: index });
                                  setSelectionEnd({ row: 2, col: index });
                                }}
                                onMouseEnter={() => {
                                  if (isSelecting) {
                                    setSelectionEnd({ row: 2, col: index });
                                  }
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: youDoContents[index] ? 
                                    renderContent(youDoContents[index]) : 
                                    '<span class="text-gray-400">-</span>'
                                }}
                                className={`w-full px-2 py-1 text-xs border border-gray-300 rounded 
                                  h-7 overflow-hidden whitespace-nowrap text-ellipsis cursor-default
                                  max-w-full block
                                  ${isSelected(2, index) ? 'bg-purple-100' : ''}`}
                                style={{ width: '120px' }}
                              />
                            </td>
                          ))}
                        </tr>

                        {/* Reflection Row */}
                        <tr>
                          <td className="w-24 py-2 pr-4 font-semibold text-orange-600">Reflection</td>
                          {Array(20).fill('').map((_, index) => (
                            <td key={index} className="w-[120px] p-1 max-w-[120px]">
                              <div
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                  setIsSelecting(true);
                                  setSelectionStart({ row: 3, col: index });
                                  setSelectionEnd({ row: 3, col: index });
                                }}
                                onMouseEnter={() => {
                                  if (isSelecting) {
                                    setSelectionEnd({ row: 3, col: index });
                                  }
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: reflectionContents[index] ? 
                                    renderContent(reflectionContents[index]) : 
                                    '<span class="text-gray-400">-</span>'
                                }}
                                className={`w-full px-2 py-1 text-xs border border-gray-300 rounded 
                                  h-7 overflow-hidden whitespace-nowrap text-ellipsis cursor-default
                                  max-w-full block
                                  ${isSelected(3, index) ? 'bg-orange-100' : ''}`}
                                style={{ width: '120px' }}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  // Original table layout - keep existing code
                  <div className="grid grid-cols-4 gap-2">
                    {/* I Do Column */}
                    <div className="space-y-1">
                      {Array(20).fill('').map((_, rowIndex) => (
                        <div
                          key={`ido-${rowIndex}`}
                          dangerouslySetInnerHTML={{ 
                            __html: iDoContents[rowIndex] ? 
                              renderContent(iDoContents[rowIndex]) : 
                              '<span class="text-gray-400">I Do</span>' 
                          }}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            setIsSelecting(true);
                            setSelectionStart({ row: rowIndex, col: 0 });
                            setSelectionEnd({ row: rowIndex, col: 0 });
                          }}
                          onMouseEnter={() => {
                            if (isSelecting) {
                              setSelectionEnd({ row: rowIndex, col: 0 });
                            }
                          }}
                          className={`w-full h-7 px-2 py-1 text-xs border border-gray-300 rounded 
                            focus:outline-none focus:ring-1 focus:ring-blue-500 cursor-default
                            overflow-hidden whitespace-nowrap text-ellipsis
                            ${isSelected(rowIndex, 0) ? 'bg-blue-100' : ''}`}
                        />
                      ))}
                    </div>

                    {/* We Do Column */}
                    <div className="space-y-1">
                      {Array(20).fill('').map((_, rowIndex) => (
                        <input
                          key={`wedo-${rowIndex}`}
                          type="text"
                          value={weDoContents[rowIndex]}
                          readOnly
                          onMouseDown={(e) => {
                            e.preventDefault();
                            setIsSelecting(true);
                            setSelectionStart({ row: rowIndex, col: 1 });
                            setSelectionEnd({ row: rowIndex, col: 1 });
                          }}
                          onMouseEnter={() => {
                            if (isSelecting) {
                              setSelectionEnd({ row: rowIndex, col: 1 });
                            }
                          }}
                          className={`w-full h-7 px-2 text-xs border border-gray-300 rounded 
                            focus:outline-none focus:ring-1 focus:ring-green-500 cursor-default
                            ${isSelected(rowIndex, 1) ? 'bg-green-100' : ''}`}
                          placeholder="We Do"
                        />
                      ))}
                    </div>

                    {/* You Do Column */}
                    <div className="space-y-1">
                      {Array(20).fill('').map((_, rowIndex) => (
                        <input
                          key={`youdo-${rowIndex}`}
                          type="text"
                          value={youDoContents[rowIndex]}
                          readOnly
                          onMouseDown={(e) => {
                            e.preventDefault();
                            setIsSelecting(true);
                            setSelectionStart({ row: rowIndex, col: 2 });
                            setSelectionEnd({ row: rowIndex, col: 2 });
                          }}
                          onMouseEnter={() => {
                            if (isSelecting) {
                              setSelectionEnd({ row: rowIndex, col: 2 });
                            }
                          }}
                          className={`w-full h-7 px-2 text-xs border border-gray-300 rounded 
                            focus:outline-none focus:ring-1 focus:ring-purple-500 cursor-default
                            ${isSelected(rowIndex, 2) ? 'bg-purple-100' : ''}`}
                          placeholder="You Do"
                        />
                      ))}
                    </div>

                    {/* Reflection Column */}
                    <div className="space-y-1">
                      {Array(20).fill('').map((_, rowIndex) => (
                        <input
                          key={`reflection-${rowIndex}`}
                          type="text"
                          value={reflectionContents[rowIndex]}
                          readOnly
                          onMouseDown={(e) => {
                            e.preventDefault();
                            setIsSelecting(true);
                            setSelectionStart({ row: rowIndex, col: 3 });
                            setSelectionEnd({ row: rowIndex, col: 3 });
                          }}
                          onMouseEnter={() => {
                            if (isSelecting) {
                              setSelectionEnd({ row: rowIndex, col: 3 });
                            }
                          }}
                          className={`w-full h-7 px-2 text-xs border border-gray-300 rounded 
                            focus:outline-none focus:ring-1 focus:ring-orange-500 cursor-default
                            ${isSelected(rowIndex, 3) ? 'bg-orange-100' : ''}`}
                          placeholder="Reflection"
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Buttons Column */}
            <div className="flex flex-col gap-2">
              {/* Copy Button - existing code */}
              <button
                onClick={handleCopy}
                className="flex items-center justify-center h-10 gap-2 px-4 text-white transition-colors duration-200 bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                title="Copy Selected Cells"
              >
                <svg 
                  className="w-4 h-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                  />
                </svg>
                <span className="text-sm font-medium">Copy</span>
              </button>

              {/* Flip Button */}
              <button
                onClick={handleFlip}
                className="flex items-center justify-center h-10 gap-2 px-4 text-white transition-colors duration-200 bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                title="Flip Table Layout"
              >
                <svg 
                  className="w-4 h-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                  />
                </svg>
                <span className="text-sm font-medium">Flip</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Bee Modal */}
      {selectedBee && <BeeModal bee={selectedBee} onClose={() => setSelectedBee(null)} />}
    </div>
  );
};

// PropTypes for validation
SlideComponents.propTypes = {
  onClose: PropTypes.func.isRequired,
  classID: PropTypes.string.isRequired,
};

export default SlideComponents;