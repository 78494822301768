// BeeLegs.js - Module for leg movement and stepping logic

export class BeeLegs {
    constructor() {
      // Empty constructor - functionality is provided by methods
    }
    
    // Draw a curved leg line
    drawLegLine(ctx, startX, startY, endX, endY, bendValue, color = "black", thickness = 16) {
      var dx = endX - startX;
      var dy = endY - startY;
      var dist = Math.sqrt(dx * dx + dy * dy);
      var fixedLegLength = 20;  
      var midX = (startX + endX) / 2;
      var midY = (startY + endY) / 2;
      var factor = 1 - Math.min(dist / (fixedLegLength * 5.9), 1);
      var angle = Math.atan2(dy, dx);
      var controlX = midX + bendValue * factor * fixedLegLength * 2.25 * Math.cos(angle + Math.PI / 2);
      var controlY = midY + bendValue * factor * fixedLegLength * 2.25 * Math.sin(angle + Math.PI / 2);
      
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.quadraticCurveTo(controlX, controlY, endX, endY);
      ctx.strokeStyle = color;
      ctx.lineWidth = thickness;
      ctx.stroke();
      ctx.closePath();
    }
    
    // Initialize leg state
    initializeLegs(canvas, floorHeight) {
      const dotRadius = 5;
      const floorFootY = canvas.height - floorHeight - dotRadius;
      
      // Character parameters (invisible orange circle controlling the legs)
      const circle = {
        x: 400,
        y: floorFootY - 180,
        radius: 100,
        colour: "orange", 
        velocityY: 0
      };
    
      // Feet parameters
      const dot = {
        offsetX: 20,
        offsetY: 160,
        radius: 10,
        colour: "black"
      };
    
      const stepThreshold = 80;
      const stepIncrement = 1.95;
      const stepHeight = 35;
      let lastSteppedFoot = null;
    
      const leftFoot = {
        x: circle.x - dot.offsetX,
        y: circle.y + dot.offsetY,
        isStepping: false,
        stepProgress: 0,
        startX: circle.x - dot.offsetX,
        startY: circle.y + dot.offsetY,
        targetX: circle.x - dot.offsetX,
        targetY: circle.y + dot.offsetY
      };
    
      const rightFoot = {
        x: circle.x + dot.offsetX,
        y: circle.y + dot.offsetY,
        isStepping: false,
        stepProgress: 0,
        startX: circle.x + dot.offsetX,
        startY: circle.y + dot.offsetY,
        targetX: circle.x + dot.offsetX,
        targetY: circle.y + dot.offsetY
      };
    
      // Red circle (hip) follows the invisible circle
      const redCircle = { x: circle.x, y: circle.y + 20 };
      const redCircleVelocity = { x: 0, y: 0 };
    
      return {
        circle,
        dot,
        stepThreshold,
        stepHeight,
        lastSteppedFoot,
        leftFoot,
        rightFoot,
        redCircle,
        redCircleVelocity,
        floorFootY
      };
    }
    
    // Update foot step with time-based animation
    updateFootStep(foot, stepHeight, deltaTime, stepSpeed = 0.0001, isStationary = false) {
      // Significantly increase the base step speed
      const baseStepSpeed = .5;
      
      // Use the provided stepSpeed as a multiplier on our base speed
      const adjustedStepSpeed = baseStepSpeed * stepSpeed * 20;
      
      // Use half speed for final alignment steps when stationary
      const effectiveStepSpeed = isStationary ? adjustedStepSpeed * .001 : adjustedStepSpeed;
      
      if (foot.isStepping) {
          // Calculate a consistent step increment based on a target frame rate of 60fps
          const targetFrameTime = 16.67; // milliseconds (60fps)
          const normalizedDelta = Math.min(deltaTime / targetFrameTime, 3); // Cap at 3x to prevent huge jumps
          
          // Apply the normalized delta to our effective step speed
          foot.stepProgress += effectiveStepSpeed * normalizedDelta;
  
          if (foot.stepProgress >= 1) {
              foot.stepProgress = 1;
              foot.isStepping = false;
              foot.x = foot.targetX;
              foot.y = foot.targetY;
          } else {
              foot.x = foot.startX + (foot.targetX - foot.startX) * foot.stepProgress;
              foot.y = foot.startY + (foot.targetY - foot.startY) * foot.stepProgress - 
                       stepHeight * Math.sin(Math.PI * foot.stepProgress);
          }
      }
    }
    
    // Update feet positions based on character movement
    updateFeet(circle, leftFoot, rightFoot, dot, stepThreshold, stepHeight, lastCircleX, onFloor, lastSteppedFoot) {
      if (!onFloor) {
        var neutralLeftX = circle.x - dot.offsetX;
        var neutralRightX = circle.x + dot.offsetX;
        
        const movementSpeed = onFloor ? 6.4 : .15;
        leftFoot.x += (neutralLeftX - leftFoot.x) * movementSpeed;
        rightFoot.x += (neutralRightX - rightFoot.x) * movementSpeed;
        leftFoot.y = circle.y + dot.offsetY;
        rightFoot.y = circle.y + dot.offsetY;
        return { updatedLastSteppedFoot: lastSteppedFoot };
      }
      
      var horizontalSpeed = Math.abs(circle.x - lastCircleX);
      var baseY = circle.y + dot.offsetY;
      var desiredLeftX = circle.x - dot.offsetX;
      var desiredRightX = circle.x + dot.offsetX;
      var leftError = Math.abs(leftFoot.x - desiredLeftX);
      var rightError = Math.abs(rightFoot.x - desiredRightX);
      
      let updatedLastSteppedFoot = lastSteppedFoot;
      const isStationary = horizontalSpeed < 0.01;
  
      // Add timestamp for the last stationary step if it doesn't exist
      if (!leftFoot.lastStationaryStepTime) leftFoot.lastStationaryStepTime = 0;
      if (!rightFoot.lastStationaryStepTime) rightFoot.lastStationaryStepTime = 0;
      
      const currentTime = Date.now();
      const STATIONARY_STEP_DELAY = 150; // milliseconds
  
      if (!leftFoot.isStepping && !rightFoot.isStepping) {
          if (isStationary) {
              // When stationary, make legs take final steps to align under hips with delay
              if (leftError > 5 && lastSteppedFoot !== "left" && 
                  (currentTime - rightFoot.lastStationaryStepTime >= STATIONARY_STEP_DELAY)) {
                  leftFoot.isStepping = true;
                  leftFoot.stepProgress = 0;
                  leftFoot.startX = leftFoot.x;
                  leftFoot.startY = leftFoot.y;
                  leftFoot.targetX = desiredLeftX;
                  leftFoot.targetY = baseY;
                  leftFoot.isStationaryStep = true;
                  leftFoot.lastStationaryStepTime = currentTime;
                  updatedLastSteppedFoot = "left";
              } else if (rightError > 5 && lastSteppedFoot !== "right" && 
                        (currentTime - leftFoot.lastStationaryStepTime >= STATIONARY_STEP_DELAY)) {
                  rightFoot.isStepping = true;
                  rightFoot.stepProgress = 0;
                  rightFoot.startX = rightFoot.x;
                  rightFoot.startY = rightFoot.y;
                  rightFoot.targetX = desiredRightX;
                  rightFoot.targetY = baseY;
                  rightFoot.isStationaryStep = true;
                  rightFoot.lastStationaryStepTime = currentTime;
                  updatedLastSteppedFoot = "right";
              }
          } else {
              // Original stepping logic for when character is moving
              if (lastSteppedFoot === "left" && rightError > stepThreshold) {
                  rightFoot.isStepping = true; 
                  rightFoot.stepProgress = 0;
                  rightFoot.startX = rightFoot.x; 
                  rightFoot.startY = rightFoot.y;
                  rightFoot.targetX = desiredRightX; 
                  rightFoot.targetY = baseY;
                  updatedLastSteppedFoot = "right";
              } else if (lastSteppedFoot === "right" && leftError > stepThreshold) {
                  leftFoot.isStepping = true; 
                  leftFoot.stepProgress = 0;
                  leftFoot.startX = leftFoot.x; 
                  leftFoot.startY = leftFoot.y;
                  leftFoot.targetX = desiredLeftX; 
                  leftFoot.targetY = baseY;
                  updatedLastSteppedFoot = "left";
              } else if (leftError > rightError && leftError > stepThreshold) {
                  leftFoot.isStepping = true; 
                  leftFoot.stepProgress = 0;
                  leftFoot.startX = leftFoot.x; 
                  leftFoot.startY = leftFoot.y;
                  leftFoot.targetX = desiredLeftX; 
                  leftFoot.targetY = baseY;
                  updatedLastSteppedFoot = "left";
              } else if (rightError > stepThreshold) {
                  rightFoot.isStepping = true; 
                  rightFoot.stepProgress = 0;
                  rightFoot.startX = rightFoot.x; 
                  rightFoot.startY = rightFoot.y;
                  rightFoot.targetX = desiredRightX; 
                  rightFoot.targetY = baseY;
                  updatedLastSteppedFoot = "right";
              }
          }
      }
      
      return { updatedLastSteppedFoot };
    }
    
    // Update red circle to follow character with physics
    updateRedCircle(redCircle, redCircleVelocity, circle, bounce, onFloor) {
      var effectiveCircleY = onFloor ? circle.y + bounce : circle.y;
      var targetRedX = circle.x;
      var targetRedY = effectiveCircleY + 30;
      var stiffness = 0.15;
      // Use different damping values based on whether the character is on the ground or in the air
      var damping = onFloor ? 0.15 : 0.3;
      var ax = (targetRedX - redCircle.x) * stiffness;
      var ay = (targetRedY - redCircle.y) * stiffness;
      redCircleVelocity.x = (redCircleVelocity.x + ax) * damping;
      redCircleVelocity.y = (redCircleVelocity.y + ay) * damping * 1.8;
      redCircle.x += redCircleVelocity.x;
      redCircle.y += redCircleVelocity.y;
    }
    
    // Draw the legs and feet
    drawLegs(ctx, redCircle, leftFoot, rightFoot, currentBend) {
      // Draw legs connecting to feet with thicker lines
      var hipX = redCircle.x;
      var hipY = redCircle.y + (100 * 0.4); // Using circle.radius * 0.3
      var greenDotOffset = 13;  // Place leg tops further apart
      var greenDotLeftX = hipX - greenDotOffset;
      var greenDotRightX = hipX + greenDotOffset;
      
      // Draw legs
      this.drawLegLine(ctx, greenDotLeftX, hipY, leftFoot.x, leftFoot.y, currentBend, "black", 19.2);    // Left leg in black
      this.drawLegLine(ctx, greenDotRightX, hipY, rightFoot.x, rightFoot.y, currentBend, "black", 19.2); // Right leg in black
      
      // Draw feet
      ctx.beginPath();
      ctx.arc(leftFoot.x, leftFoot.y, 10, 0, Math.PI * 2); // Using dot.radius
      ctx.fillStyle = "black"; // Using dot.colour
      ctx.fill();
      ctx.closePath();
      
      ctx.beginPath();
      ctx.arc(rightFoot.x, rightFoot.y, 10, 0, Math.PI * 2); // Using dot.radius
      ctx.fillStyle = "black"; // Using dot.colour
      ctx.fill();
      ctx.closePath();
    }
  }