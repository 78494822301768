import React from 'react';

const SkyBackground = () => {
  // Create a linear gradient definition that can be referenced
  return (
    <div className="flex items-center justify-center w-full h-screen bg-black">
      <svg viewBox="0 0 1000 800" className="w-full h-full">
        <defs>
          <linearGradient id="skyGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#87CEEB" stopOpacity="0.0" />
            <stop offset="0.1%" stopColor="#87CEEB" stopOpacity="0.99" />
            <stop offset="100%" stopColor="#87CEEB" stopOpacity="0" />
          </linearGradient>
        </defs>
        {/* Single rectangle with gradient fill instead of multiple rectangles */}
        <rect x="0" y="0" width="1000" height="800" fill="url(#skyGradient)" />
      </svg>
    </div>
  );
};

export default SkyBackground;