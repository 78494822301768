import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import gsap from 'gsap';
import { useFrame, useThree } from '@react-three/fiber';
import { Overlays } from './Overlay1-Viewer.js';
import { Overlays2 } from './Overlay2-TV.js';
import { Overlays3 } from './Overlay3-SkyTV.js';

const OverlayManager = ({ camera, sceneCameraControl, vertical }) => {
  // State for overlay positions and scale
  const [overlay1Position, setOverlay1Position] = useState({ x: 0.7, y: 3, z: -10 });
  const [overlay1Scale, setOverlay1Scale] = useState(3);
  const [overlay2Position, setOverlay2Position] = useState({ x: 0, y: 0, z: -30 });
  const [overlay2Scale, setOverlay2Scale] = useState(3);
  // Initialize overlay3 with the correct position immediately
  const [overlay3Position, setOverlay3Position] = useState(() => {
    const normalizedVertical = Math.min(Math.max(vertical, 0), 90);
    const initialY = THREE.MathUtils.mapLinear(normalizedVertical, 0, 90, -21, 10);
    return { x: 0, y: initialY, z: -90 };
  });
  const [overlay3Scale, setOverlay3Scale] = useState(3);
  
  // Initialize with a mount key that doesn't change after initial mounting
  const [mountKey, setMountKey] = useState(0);
  
  // Track the number of render frames that have occurred
  const frameCountRef = useRef(0);
  
  // Visibility state
  const [isVisible, setIsVisible] = useState(false);
  
  // Track previous sceneCameraControl to detect changes
  const prevSceneCameraControlRef = useRef(sceneCameraControl);
  
  // Track previous vertical value to detect changes
  const prevVerticalRef = useRef(vertical);
  
  // Use a ref to store the GSAP animation
  const animationRef = useRef(null);
  
  // Use a ref to store the vertical animation
  const verticalAnimationRef = useRef(null);
  
  // Add initialization ref
  const isInitializedRef = useRef(false);
  
  // Use useFrame to count frames and force initialization after a few frames
  useFrame(() => {
    if (!isInitializedRef.current) {
      frameCountRef.current += 1;
      
      // After 10 frames, initialize overlays
      if (frameCountRef.current >= 10) {
        isInitializedRef.current = true;
        
        // Calculate initial position and scale
        const targetZ = THREE.MathUtils.mapLinear(sceneCameraControl, 65, 105, -50, -5);
        const targetScale = THREE.MathUtils.mapLinear(sceneCameraControl, 65, 105, 3, 4);
        
        // Update position and scale
        setOverlay1Position(prev => ({ ...prev, z: targetZ }));
        setOverlay1Scale(targetScale);
        
        // Make overlays visible
        setIsVisible(true);
        
        console.log('Overlays initialized after', frameCountRef.current, 'frames');
      }
    }
  });
  
  // SEPARATED: Initial mounting logic
  useEffect(() => {
    // Set up a sequence of remounts to force initialization
    const timers = [
      setTimeout(() => setMountKey(1), 100),
      setTimeout(() => setMountKey(2), 500),
      setTimeout(() => setMountKey(3), 1000),
      setTimeout(() => {
        // Final attempt
        setMountKey(4);
        frameCountRef.current = 0;
        isInitializedRef.current = false;
      }, 2000)
    ];
    
    return () => timers.forEach(timer => clearTimeout(timer));
  }, []); // Empty dependency array - only run on mount
  
  // SEPARATED: Camera control animation (completely independent of mounting)
  useEffect(() => {
    // Skip animation while still in initialization phase
    if (mountKey < 2) return;
    
    if (sceneCameraControl !== prevSceneCameraControlRef.current) {
      console.log('Animating overlay from', prevSceneCameraControlRef.current, 'to', sceneCameraControl);
      
      // Kill any existing animation
      if (animationRef.current) {
        animationRef.current.kill();
      }
      
      // Map sceneCameraControl with wider range (65-105) to z position (-50 to -5)
      const normalizedControl = Math.min(Math.max(sceneCameraControl, 65), 105);
      const targetZ = THREE.MathUtils.mapLinear(normalizedControl, 65, 105, -50, -5);
      
      // Map sceneCameraControl to scale (3 to 4)
      const targetScale = THREE.MathUtils.mapLinear(normalizedControl, 65, 105, 3, 4);
      
      // Create temporary objects to animate
      const tempPosition = { ...overlay1Position };
      const tempScale = { value: overlay1Scale };
      
      // Create the GSAP animation with smoother easing and longer duration
      animationRef.current = gsap.timeline()
        .to(tempPosition, {
          z: targetZ,
          duration: 2.5,  // Longer duration for smoother movement
          ease: "power3.out",  // Changed easing for smoother motion
          onUpdate: () => {
            setOverlay1Position({ ...tempPosition });
          }
        }, 0)
        .to(tempScale, {
          value: targetScale,
          duration: 2.5,  // Match duration with position animation
          ease: "power3.out",  // Match easing with position animation
          onUpdate: () => {
            setOverlay1Scale(tempScale.value);
          }
        }, 0);
      
      // Update the previous value ref
      prevSceneCameraControlRef.current = sceneCameraControl;
    }
  }, [sceneCameraControl, overlay1Position, overlay1Scale, mountKey]);

  // Modify the vertical effect
  useEffect(() => {
    if (mountKey < 2) return;
    
    if (vertical !== prevVerticalRef.current) {
      // Kill any existing vertical animation
      if (verticalAnimationRef.current) {
        verticalAnimationRef.current.kill();
      }
      
      const normalizedVertical = Math.min(Math.max(vertical, 0), 90);
      const targetY = THREE.MathUtils.mapLinear(normalizedVertical, 0, 90, -21, 13.8);
      
      // Only animate if this isn't the first position set
      if (prevVerticalRef.current !== undefined) {
        const tempPosition = { ...overlay3Position };
        const isGoingUp = vertical > prevVerticalRef.current;
        const delay = isGoingUp ? 1.3 : 0;
        const duration = isGoingUp ? 4.0 : 1.0;
        
        verticalAnimationRef.current = gsap.to(tempPosition, {
          y: targetY,
          delay,
          duration,
          ease: "power3.inOut",
          onUpdate: () => {
            setOverlay3Position({ ...tempPosition });
          }
        });
      } else {
        // First time setting position, do it immediately
        setOverlay3Position(prev => ({ ...prev, y: targetY }));
      }
      
      prevVerticalRef.current = vertical;
    }
  }, [vertical, mountKey]);

  // Set initial position on mount, but don't animate
  useEffect(() => {
    setIsVisible(true);
    
    if (!animationRef.current || mountKey <= 1) {
      const normalizedControl = Math.min(Math.max(sceneCameraControl, 65), 105);
      const targetZ = THREE.MathUtils.mapLinear(normalizedControl, 65, 105, -50, -5);
      const targetScale = THREE.MathUtils.mapLinear(normalizedControl, 65, 105, 3, 4);
      
      setOverlay1Position(prev => ({ ...prev, z: targetZ }));
      setOverlay1Scale(targetScale);
    }
  }, [mountKey, sceneCameraControl]);

  // Add this new effect to globally disable frustum culling for all lines
  useEffect(() => {
    // Function to disable frustum culling on all line objects
    const disableFrustumCullingForLines = () => {
      // Access the global THREE scene
      if (!camera) return;
      
      // Start from the camera and traverse all its children
      camera.traverse(object => {
        // Check if this is a line object (direct or via inheritance)
        if (
          object.isLine || 
          object.isLineSegments || 
          (object.type && (
            object.type.includes('Line') || 
            object.type.includes('Edge')
          ))
        ) {
          // Disable frustum culling
          object.frustumCulled = false;
        }
      });
    };
    
    // Run immediately
    disableFrustumCullingForLines();
    
    // Set up a more aggressive interval to catch lines that might be added later
    const interval = setInterval(disableFrustumCullingForLines, 500);
    
    return () => {
      clearInterval(interval);
    };
  }, [camera, mountKey]); // React to camera changes and remounting

  return (
    <group>
      <primitive object={camera}>
        {/* First overlay (TV) with fixed position */}
        <group 
          position={[overlay2Position.x, overlay2Position.y, overlay2Position.z]} 
          scale={[5, 5, 5]}
          visible={isVisible}
          // Key ONLY depends on mountKey, not sceneCameraControl
          key={`overlay2-${mountKey}`}
        >
          <Overlays2 isCameraChild={true} renderOrder={10} />
        </group>
        
        {/* Overlay3 with immediate initial position */}
        <group 
          position={[overlay3Position.x, overlay3Position.y, overlay3Position.z]} 
          scale={[5, 5, 5]}
          visible={isVisible}
          key={`overlay3-${mountKey}`}
        >
          <Overlays3 isCameraChild={true} renderOrder={5} />
        </group>
        
        {/* Second overlay (Viewer) with dynamic position and scale */}
        <group 
          position={[overlay1Position.x, overlay1Position.y, overlay1Position.z]} 
          scale={[overlay1Scale, overlay1Scale, overlay1Scale]}
          visible={isVisible}
          // Key ONLY depends on mountKey, not sceneCameraControl
          key={`overlay1-${mountKey}`}
        >
          <Overlays isCameraChild={true} renderOrder={200} />
        </group>
      </primitive>
    </group>
  );
};

OverlayManager.propTypes = {
  camera: PropTypes.object.isRequired,
  sceneCameraControl: PropTypes.number,
  vertical: PropTypes.number  // Add vertical to propTypes
};

OverlayManager.defaultProps = {
  sceneCameraControl: 65, // Default value
  vertical: 0  // Default vertical value
};

export default OverlayManager;